import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  Box,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { addDocument, updateDocument, getFiles } from "../actions/file";
import { load_user } from "../actions/auth";

const AddFileModal = ({ isOpen, onClose, type, initialData }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const user = useSelector(state => state.auth.user);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isFileChanged, setIsFileChanged] = useState(false);

  useEffect(() => {
    dispatch(load_user());
  }, [dispatch]);

  useEffect(() => {
    if (isOpen) {
      if (type === "update" && initialData) {
        setFileName(initialData.file_name);
      } else {
        setFile(null);
        setFileName("");
      }
      setIsFileChanged(false);
    }
  }, [isOpen, type, initialData]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : "");
    setIsFileChanged(true);
  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleSubmit = async () => {
    if (!file && type !== "update") {
      toast({
        title: "Error",
        description: "Please select a file",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    if (file) {
      formData.append("file_name", file);
    }
    formData.append("uploaded_by", user.id);

    try {
      if (type === "update" && initialData) {
        await dispatch(updateDocument(initialData.id, formData));
        toast({
          title: "Success",
          description: "File updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await dispatch(addDocument(formData));
        toast({
          title: "Success",
          description: "File added successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      dispatch(getFiles());
      handleClose();
    } catch (error) {
      console.error("Error uploading file:", error);
      toast({
        title: "Error",
        description: "An error occurred while processing your request",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleClose = () => {
    setFile(null);
    setFileName("");
    setIsFileChanged(false);
    onClose();
  };

  const isUpdateButtonDisabled = type === "update" && !isFileChanged;

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {type === "update" ? "Update File" : "Add File"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>File</FormLabel>
            <Box position="relative">
              <Button
                bg="#001A72"
                color="#FFFFFF"
                _hover={{ bg: "#001A72" }}
                size="sm"
                onClick={handleButtonClick}
              >
                {fileName ? "Change File" : "Choose File"}
              </Button>
              <Input
                id="fileInput"
                type="file"
                opacity="0"
                position="absolute"
                zIndex="-1"
                onChange={handleFileChange}
              />
              {fileName && (
                <Text color="gray.500" fontSize="sm" mt={2}>
                  Selected File: {fileName}
                </Text>
              )}
            </Box>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#001A72"
            color="#FFFFFF"
            _hover={{ bg: "#001A72" }}
            ml={3}
            onClick={handleSubmit}
            isDisabled={isUpdateButtonDisabled}
          >
            {type === "update" ? "Update File" : "Add File"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddFileModal;
