import axios from 'axios';
import {
  GET_TARGET_SALES_SUCCESS,
  GET_TARGET_SALES_FAIL,
  GET_TARGET_SALE_BY_ID_SUCCESS,
  GET_TARGET_SALE_BY_ID_FAIL,
  CREATE_TARGET_SALE_SUCCESS,
  CREATE_TARGET_SALE_FAIL,
  UPDATE_TARGET_SALE_SUCCESS,
  UPDATE_TARGET_SALE_FAIL,
  GET_TARGET_SALE_BY_MONTH_YEAR_SUCCESS,
  GET_TARGET_SALE_BY_MONTH_YEAR_FAIL,
  DELETE_TARGET_SALE_FAIL,
  DELETE_TARGET_SALE_SUCCESS,
} from './types';
import { getAuthConfig } from './config';

export const getAllTargetSales = () => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/targets`, config);
    dispatch({
      type: GET_TARGET_SALES_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_TARGET_SALES_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const getTargetSaleById = (id) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/target/${id}`, config);
    dispatch({
      type: GET_TARGET_SALE_BY_ID_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_TARGET_SALE_BY_ID_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const getTargetSaleByMonthYear = (month, year) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/target/${month}/${year}`, config);
    dispatch({
      type: GET_TARGET_SALE_BY_MONTH_YEAR_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_TARGET_SALE_BY_MONTH_YEAR_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const createTargetSale = (targetSaleData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/target/save`, targetSaleData, config);
    dispatch({
      type: CREATE_TARGET_SALE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_TARGET_SALE_FAIL,
      payload: err.response.data.message,
    });
    throw err;
  }
};

export const updateTargetSale = (id, targetSaleData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/target/${id}/update/`, targetSaleData, config);
    dispatch({
      type: UPDATE_TARGET_SALE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_TARGET_SALE_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const deleteTargetSale = (id) => async (dispatch) => {
    try {
      const config = getAuthConfig();
      await axios.delete(`${process.env.REACT_APP_API_URL}/target/${id}/delete/`, config);
      dispatch({
        type: DELETE_TARGET_SALE_SUCCESS,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: DELETE_TARGET_SALE_FAIL,
        payload: err.message,
      });
      throw err;
    }
  };