import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Box, Image, Heading, Text, Button, Select, List } from '@chakra-ui/react';
import { CartContext } from '../../Context/CartContext';
import {useDispatch, useSelector} from 'react-redux'
import { setCart,getCart } from '../../actions/cart';

function ProductDescription(props) {  
  const dispatch=useDispatch()
  const grayScales=useSelector(state=>state.themeReducer.grayScales) 

  const [price, setPrice] = useState(props.monthlyPrice);
  const [duration, setDuration] = useState("monthly");
  const isAuthenticated=useSelector(state=>state.auth.isAuthenticated)
  const role=useSelector(state=>state.auth.user?.role)
  const navigate = useNavigate();

  const handleDuration = (event) => {
    const value = event.target.value;
    setDuration(value)
    setPrice(value === 'monthly' ? props.monthlyPrice : props.yearlyPrice);
  };

  const handleAddToCart = () => {
    if(isAuthenticated){
      if(role==="Client")
        dispatch(setCart(props.id,duration))
    }
    else
      navigate("/login")
  };
  const handlePayNow = () => {
    if(isAuthenticated){
      if(role==="Client"){
        dispatch(setCart(props.id,duration))
        navigate("/checkout")
      }else
        navigate("")
    }
    else
      navigate("/login")
  };


  return (
    <Flex gap={2} direction={{base:"column", lg:"row", xl:"row", md:"column", sm:"column"}}>
      <Box 
        width={{base:"100%", lg:"50%", xl:"50%", md:"100%", sm:"100%" }} 
        height={{base:"auto", xl:"auto", lg:"auto", sm:"auto", md:"auto"}} 
        ml={{base:"auto", sm:"auto", md:"auto", xl:"none", lg:"none"}}
        mr={{base:"auto", sm:"auto", md:"auto", xl:"none", lg:"none"}}
        bgColor="white"
        boxShadow='2xl'
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Image src={props.image} width={{xl:"auto", lg:"auto", base:"100%", sm:"100%", md:"100%"}} 
          height={{xl:"auto", lg:"auto", base:"50%", sm:"auto", md:"50%"}}
        />
      </Box>

      <Flex direction={"column"} p={8}>
        <Heading>{props.title}</Heading>
        <Text mt="0.8em" mb="3em">{props.description}</Text>
        {props.children && (
          <Flex mb="2em">
            <List>
              {props.children}
            </List>
          </Flex>
        )}
        <Flex gap={2} mb="4em">
          <Text fontWeight="bold">Benefits: </Text>
          <Text>{props.benefit}</Text>
        </Flex>
        <Text>Duration</Text>
        <Select onChange={handleDuration}> 
          <option value='monthly'>Monthly</option>
          <option value='yearly'>Yearly</option>
        </Select>
        <Heading as={"h3"} mb="0.5em" mt="1em">
          {price}
        </Heading>
        <Flex direction="row" gap={2}>
          <Button mt="2em" width="50%" onClick={handleAddToCart} cursor={role!=="Client" && isAuthenticated?"not-allowed":"pointer"}>Add to cart</Button>
          <Button mt="2em" width="50%" onClick={handlePayNow} bgColor={grayScales} cursor={role!=="Client" && isAuthenticated?"not-allowed":"pointer"} color={grayScales==="black"?"white":"black"}>Pay Now</Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ProductDescription;
