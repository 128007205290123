import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import fetchArticlesTeasers from "../services/api/artilcesAPI/fetchArticleTeaser";
import {
  Flex,
  Text,
  Button,
  Image,
  Heading,
  useMediaQuery,
  Hide,
  Box,
  Center,
  IconButton,
  Stack
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import Questions from "../components/questions/Questions";
import ProductsSection from "../components/products/ProductsSection";
import ArticleCard from "../components/articles/ArticleCard";
import xcapitalbanner from "../assets/xcapitalbanner.jpg";
import bankAlmaghrib from "../assets/bankAlmaghrib.png";
import boursedecasa from "../assets/boursedecasa.jpeg";
import tradingview from "../assets/trading_view.jpg";
import map from "../assets/Map.svg";
import worldbank from "../assets/worldbank.png";
import hcp from "../assets/hcp.png";
import TradingViewTicker from "../components/TradingViewTicker";
const MotionFlex = motion(Flex);

const partners = [
  { src: bankAlmaghrib, alt: "Bank Al Maghrib", maxW: "200px", height: "150px" },
  { src: boursedecasa, alt: "La bourse de casablanca", maxW: "300px", height: "auto" },
  { src: tradingview, alt: "TradingView", maxW: "250px", height: "auto" },
  { src: worldbank, alt: "World Bank", maxW: "300px", height: "auto" },
  { src: hcp, alt: "Haut commissariat du plan", maxW: "250px", height: "auto" },
];


export default function Home() {
  const bg2 = useSelector((state) => state.themeReducer.backgroundColor2);
  const [isLargerThan] = useMediaQuery("(min-width: 840px)");
  const [loadMore, setLoadMore] = useState(true);
  const [articles, setArticles] = useState([]);
  const [partnerIndex, setPartnerIndex] = useState(0); 
  const [autoRotate, setAutoRotate] = useState(true);

  const navigate = useNavigate();

  const handleLoadMore = () => {
    setLoadMore((prevLoadMore) => !prevLoadMore);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchArticlesTeasers();
        setArticles(result);
      } catch (error) {
        console.error("Error fetching article's teasers:", error);
      }
    };

    setTimeout(() => {
      fetchData();
    }, 2000);
  }, []);

  const handleDiscoverMore = () => {
    navigate("/articles");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate}`;
  };
  const bgColor=useSelector(state=>state.themeReducer.backgroundColor1)
  const choseColor=(cat)=>{
    switch(cat){
      case "Finance":
        return "blue"
      case "Fashion":
        return "red"
      case "Branding":
        return "green"
      case "Technology":
        return "yellow"
      case "Travel":
        return "purple"
      default :
        return "black"
    }
  }
  const handleGetStartedClick = () => {
    navigate("/wait-list");
  };

  const handleReadMore = (id) => {
    window.location.href="/article/"+id
  };

  useEffect(() => {
    if (autoRotate) {
      const interval = setInterval(() => {
        setPartnerIndex((prevIndex) => (prevIndex + 1) % partners.length);
      }, 6000);
      return () => clearInterval(interval);
    }
  }, [autoRotate]);

  const getCurrentPartners = () => {
    return Array.from({ length:  3}).map((_, i) => partners[(partnerIndex + i) % partners.length]);
  };

  const handleNext = () => {
    // setAutoRotate(false);
    setPartnerIndex((prevIndex) => (prevIndex + 1) % partners.length);
  };

  const handlePrev = () => {
    // setAutoRotate(false);
    setPartnerIndex((prevIndex) => (prevIndex - 1 + partners.length) % partners.length);
  };

  return (
    <>
    <Box bgColor={bg2}>
      
    <TradingViewTicker/>
      <Flex
        align={"center"}
        height={"648px"}
        backgroundImage={xcapitalbanner}
        bgSize={"cover"}
        bgRepeat={"no-repeat"}
        bgPosition={{base:"calc(100% - 70%) bottom", sm:"calc(100% - 70%) bottom", md:"calc(100% - 70%) bottom", xl:"top", lg:"top"}}
      >
        
        {isLargerThan ? (
          <Flex
            direction={"column"}
            justify={"center"}
            color={"white"}
            padding={"24px"}
            gap={"24px"}
            width={"100%"}
            height={"100%"}
            backgroundColor={"rgba(0, 0, 0, 0.5)"}
          >
            <Heading
              as={"h1"}
              fontSize={"42px"}
              fontWeight={"800"}
              marginBottom={"20px"}
              w={{base:"90%",md:"75%"}}
              textAlign={"left"}
              ml={"7.5%"}
            >
              Empower Your Investments with Advanced Analytics and Market Insights{" "}
            </Heading>
            <Text
              width={{base:"90%",md:"75%"}}
              fontSize={"24px"}
              marginTop={"10px"}
              marginBottom={"24px"}
              textAlign={"left"}
              ml={"7.5%"}
            >
              Join the growing number of investors, traders, and financial institutions using X Capital to make data-driven investment decisions. Our platform offers advanced analytics and customized financial tools to help you navigate today’s dynamic markets. Ready to gain a competitive edge? Join us today and be the first to explore X Capital.
            </Text>
            <Button
              bg={"white"}
              width={"160px"}
              height={"40px"}
              borderRadius={"12px"}
              fontSize={"20px"}
              marginBottom={"20px"}
              _hover={"none"}
              color="gray"
              ml={"7.5%"}
              onClick={handleGetStartedClick}
            >
              Book a demo
            </Button>
          </Flex>
        ) : (
          <Flex
            direction={"column"}
            align={"center"}
            justify={"center"}
            color={"white"}
            padding={"24px"}
            gap={"24px"}
            height={"100%"}
            backgroundColor={"rgba(0, 0, 0, 0.5)"}
          >
            <Heading
              as={"h1"}
              fontSize={"28px"}
              fontWeight={"800"}
              marginBottom={"28px"}
              textAlign={"center"}
            >
              Empower Your Investments with Advanced Analytics and Market Insights{" "}
            </Heading>
            <Text
              width={"80%"}
              fontSize={"20px"}
              marginTop={"20px"}
              marginBottom={"24px"}
            >
              Your Gateway to Financial Empowerment. Realize your investment
              potential with our comprehensive market insights and innovative
              solutions. Join us in shaping the future of finance.
            </Text>
            <Button
              bg={"white"}
              width={"160px"}
              height={"40px"}
              borderRadius={"12px"}
              fontSize={"20px"}
              marginBottom={"20px"}
              _hover={"none"}
              onClick={handleGetStartedClick}
            >
              Get started
            </Button>
          </Flex>
        )}
        <Flex></Flex>
      </Flex>
      <Flex
          direction={"column"}
          align={"center"}
          justify={"center"}
          gap={"20px"}
          mt="2em"
        >
          <Heading
            as={"h1"}
            fontSize={"38px"}
            fontWeight={"600"}
            color={"2D2D2D"}
          >
            Our Amazing Network
          </Heading>
          <Flex
            align={"center"}
            justify={"center"}
            position="relative"
            width={"100%"}
            maxW="1200px"
            mt={"24px"}
          >
            <IconButton
              aria-label="Previous Partners"
              icon={<ChevronLeftIcon />}
              onClick={handlePrev}
              size="lg"
              isRound
              bg={"black"}
              color={"white"}
              position="absolute"
              left="0"
              zIndex="1"
            />
            <Flex
              gap={"32px"}
              align={"center"}
              justify={"center"}
              width={"80%"}
            >
              {getCurrentPartners().map((partner, index) => (
                <MotionFlex
                  key={index}
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <Image
                    src={partner.src}
                    alt={partner.alt}
                    maxW={partner.maxW}
                    height={partner.height}
                  />
                </MotionFlex>
              ))}
            </Flex>
            <IconButton
              aria-label="Next Partners"
              icon={<ChevronRightIcon />}
              onClick={handleNext}
              size="lg"
              isRound
              bg={"black"}
              color={"white"}
              position="absolute"
              right="0"
              zIndex="1"
            />
          </Flex>
        </Flex>

      <ProductsSection />
      <Flex
        direction={"column"}
        align={"center"}
        justify={"center"}
        gap={"12px"}
        padding={"40px 0"}
      >
          <Heading>Leading Articles in Finance</Heading>
          <Text fontSize={"12px"}>
            Explore our Insightful Articles for the latest financial trends and
            expert opinions distilled into actionable insights.
          </Text>
          <Flex
            gap={"12px"}
            flexWrap={"wrap"}
            justify={"center"}
          >
            {articles.slice(0, 4).map((article, index) => (
              <Box key={index} flex="0 0 300px" onClick={()=>handleReadMore(article.id)} bgColor={bgColor} paddingBottom={"5px"} cursor={"pointer" }>
                    <Image
                      src={article.image_cover_url}
                      alt={article.title}
                      borderRadius="2px"
                      h="172px"
                      objectFit="cover"
                      w="100%"
                    />
                    <Box padding="15px">
                      <Text fontWeight={"500"} fontSize={"16px"} color={choseColor(article.category.name)}>
                          {article.category.name}
                      </Text>
                      <Stack mt="4">
                        <Heading fontSize="18px">{article.title}</Heading>
                      </Stack>
                      <Text mt="20px" fontWeight={"500"} color={"gray"}>{formatDate(article.created_at)}</Text>
                    </Box> 
              </Box>
            ))}
          </Flex>
      </Flex>
      <Center>
      <Box display="block">
          <Button
            bg={"black"}
            color={"white"}
            width={"60"}
            onClick={handleDiscoverMore}
          >
            Discover More
          </Button>
          </Box>
          </Center>
        
        <Center>
          <Questions />
        </Center>
      </Box>
    </>
  );
}
