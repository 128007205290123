export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAIL = 'GET_OFFERS_FAIL';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_FAIL = 'GET_FILES_FAIL';
export const GET_CANDIDATES_SUCCESS = 'GET_CANDIDATES_SUCCESS';
export const GET_CANDIDATES_FAIL = 'GET_CANDIDATES_FAIL';
export const GET_USERS_BY_ROLE_SUCCESS = 'GET_USERS_BY_ROLE_SUCCESS';
export const GET_USERS_BY_ROLE_FAIL = 'GET_USERS_BY_ROLE_FAIL';
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_FAIL = 'GET_SUBSCRIPTIONS_FAIL';
export const CREATE_SUBSCRIPTIONS_SUCCESS = 'CREATE_SUBSCRIPTIONS_SUCCESS';
export const CREATE_SUBSCRIPTIONS_FAIL = 'CREATE_SUBSCRIPTIONS_FAIL';
export const GET_TRIAL_SUCCESS = 'GET_TRIAL_SUCCESS';
export const GET_TRIAL_FAIL = 'GET_TRIAL_FAIL';
export const GET_NEWSLETTERS_SUCCESS = 'GET_NEWSLETTERS_SUCCESS';
export const GET_NEWSLETTERS_FAIL = 'GET_NEWSLETTERS_FAIL';
export const GET_NEWSLETTER_SUCCESS = 'GET_NEWSLETTER_SUCCESS';
export const GET_NEWSLETTER_FAIL = 'GET_NEWSLETTER_FAIL';
export const CREATE_NEWSLETTER_SUCCESS = 'CREATE_NEWSLETTER_SUCCESS';
export const CREATE_NEWSLETTER_FAIL = 'CREATE_NEWSLETTER_FAIL';
export const UPDATE_NEWSLETTER_SUCCESS = 'UPDATE_NEWSLETTER_SUCCESS';
export const UPDATE_NEWSLETTER_FAIL = 'UPDATE_NEWSLETTER_FAIL';
export const DELETE_NEWSLETTER_SUCCESS = 'DELETE_NEWSLETTER_SUCCESS';
export const DELETE_NEWSLETTER_FAIL = 'DELETE_NEWSLETTER_FAIL';
export const GET_ARTICLES_SUCCESS = 'DELETE_NEWSLETTER_SUCCESS';
export const GET_ARTICLES_FAIL = 'DELETE_NEWSLETTER_FAIL';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAIL = 'DELETE_FILE_FAIL';
export const ADD_FILE_SUCCESS = 'ADD_FILE_SUCCESS';
export const ADD_FILE_FAIL = 'ADD_FILE_FAIL';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_FAIL = 'UPDATE_FILE_FAIL';
export const GET_USERS_BY_ID_FAIL = 'GET_USERS_BY_ID_FAIL';
export const GET_USERS_BY_ID_SUCCESS = 'GET_USERS_BY_ID_SUCCESS';
export const GET_OFFER_DETAILS_SUCCESS = 'GET_OFFER_DETAILS_SUCCESS';
export const GET_OFFER_DETAILS_FAIL = 'GET_OFFER_DETAILS_FAIL';
export const ADD_OFFER_DETAIL_SUCCESS = 'ADD_OFFER_DETAIL_SUCCESS';
export const ADD_OFFER_DETAIL_FAIL = 'ADD_OFFER_DETAIL_FAIL';
export const DELETE_OFFER_DETAIL_SUCCESS = 'DELETE_OFFER_DETAIL_SUCCESS';
export const DELETE_OFFER_DETAIL_FAIL = 'DELETE_OFFER_DETAIL_FAIL';
export const UPDATE_OFFER_DETAIL_SUCCESS = 'UPDATE_OFFER_DETAIL_SUCCESS';
export const UPDATE_OFFER_DETAIL_FAIL = 'UPDATE_OFFER_DETAIL_FAIL';
export const ADD_OFFER_SUCCESS = 'ADD_OFFER_SUCCESS';
export const ADD_OFFER_FAIL = 'ADD_OFFER_FAIL';
export const DELETE_OFFER_SUCCESS = 'DELETE_OFFER_SUCCESS';
export const DELETE_OFFER_FAIL = 'DELETE_OFFER_FAIL';
export const UPDATE_OFFER_SUCCESS = 'UPDATE_OFFER_SUCCESS';
export const UPDATE_OFFER_FAIL = 'UPDATE_OFFER_FAIL';
export const GET_OFFER_BY_ID_SUCCESS = 'GET_OFFER_BY_ID_SUCCESS';
export const GET_OFFER_BY_ID_FAIL = 'GET_OFFER_BY_ID_FAIL';
export const GET_CONTACT_US_SUCCESS = 'GET_CONTACT_US_SUCCESS';
export const GET_CONTACT_US_FAIL = 'GET_CONTACT_US_FAIL';
export const GET_CONTACT_US_BY_ID_SUCCESS = 'GET_CONTACT_US_BY_ID_SUCCESS';
export const GET_CONTACT_US_BY_ID_FAIL = 'GET_CONTACT_US_BY_ID_FAIL';
export const CREATE_CONTACT_US_SUCCESS = 'CREATE_CONTACT_US_SUCCESS';
export const CREATE_CONTACT_US_FAIL = 'CREATE_CONTACT_US_FAIL';
export const UPDATE_CONTACT_US_SUCCESS = 'UPDATE_CONTACT_US_SUCCESS';
export const UPDATE_CONTACT_US_FAIL = 'UPDATE_CONTACT_US_FAIL';
export const DELETE_CONTACT_US_SUCCESS = 'DELETE_CONTACT_US_SUCCESS';
export const DELETE_CONTACT_US_FAIL = 'DELETE_CONTACT_US_FAIL';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';
export const ADD_MANAGER_REVIEW_SUCCESS = 'ADD_MANAGER_REVIEW_SUCCESS';
export const ADD_MANAGER_REVIEW_FAIL = 'ADD_MANAGER_REVIEW_FAIL';
export const UPDATE_MANAGER_REVIEW_SUCCESS = 'UPDATE_MANAGER_REVIEW_SUCCESS';
export const UPDATE_MANAGER_REVIEW_FAIL = 'UPDATE_MANAGER_REVIEW_FAIL';
export const ADD_SALES_REVIEW_SUCCESS = 'ADD_SALES_REVIEW_SUCCESS';
export const ADD_SALES_REVIEW_FAIL = 'ADD_SALES_REVIEW_FAIL';
export const UPDATE_SALES_REVIEW_SUCCESS = 'UPDATE_SALES_REVIEW_SUCCESS';
export const UPDATE_SALES_REVIEW_FAIL = 'UPDATE_SALES_REVIEW_FAIL';
export const GET_MANAGER_REVIEW_BY_OFFER_SUCCESS = 'GET_MANAGER_REVIEW_BY_OFFER_SUCCESS';
export const GET_MANAGER_REVIEW_BY_OFFER_FAIL = 'GET_MANAGER_REVIEW_BY_OFFER_FAIL';
export const GET_SALES_REVIEW_BY_OFFER_SUCCESS = 'GET_SALES_REVIEW_BY_OFFER_SUCCESS';
export const GET_SALES_REVIEW_BY_OFFER_FAIL = 'GET_SALES_REVIEW_BY_OFFER_FAIL';
export const GET_PREORDERS_SUCCESS = 'GET_PREORDERS_SUCCESS';
export const GET_PREORDERS_FAIL = 'GET_PREORDERS_FAIL';
export const GET_PREORDER_BY_ID_SUCCESS = 'GET_PREORDER_BY_ID_SUCCESS';
export const GET_PREORDER_BY_ID_FAIL = 'GET_PREORDER_BY_ID_FAIL';
export const CREATE_PREORDER_SUCCESS = 'CREATE_PREORDER_SUCCESS';
export const CREATE_PREORDER_FAIL = 'CREATE_PREORDER_FAIL';
export const UPDATE_PREORDER_SUCCESS = 'UPDATE_PREORDER_SUCCESS';
export const UPDATE_PREORDER_FAIL = 'UPDATE_PREORDER_FAIL';
export const DELETE_PREORDER_SUCCESS = 'DELETE_PREORDER_SUCCESS';
export const DELETE_PREORDER_FAIL = 'DELETE_PREORDER_FAIL';
export const GET_USERS_BY_ROLE_CONDITION_SUCCESS = 'GET_USERS_BY_ROLE_CONDITION_SUCCESS';
export const GET_USERS_BY_ROLE_CONDITION_FAIL = 'GET_USERS_BY_ROLE_CONDITION_FAIL';

export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAIL = 'GET_MESSAGES_FAIL';
export const GET_MESSAGE_BY_ID_SUCCESS = 'GET_MESSAGE_BY_ID_SUCCESS';
export const GET_MESSAGE_BY_ID_FAIL = 'GET_MESSAGE_BY_ID_FAIL';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_FAIL = 'CREATE_MESSAGE_FAIL';
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';
export const UPDATE_MESSAGE_FAIL = 'UPDATE_MESSAGE_FAIL';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAIL = 'DELETE_MESSAGE_FAIL';
export const GET_MESSAGES_BETWEEN_USERS_SUCCESS = 'GET_MESSAGES_BETWEEN_USERS_SUCCESS';
export const GET_MESSAGES_BETWEEN_USERS_FAIL = 'GET_MESSAGES_BETWEEN_USERS_FAIL';
export const GET_MESSAGES_FOR_USER_SUCCESS = 'GET_MESSAGES_FOR_USER_SUCCESS';
export const GET_MESSAGES_FOR_USER_FAIL = 'GET_MESSAGES_FOR_USER_FAIL';
export const GET_CONVERSATION_SUCCESS = 'GET_CONVERSATION_SUCCESS';
export const GET_CONVERSATION_FAIL = 'GET_CONVERSATION_FAIL';
export const GET_MESSAGES_SENDER_FOR_USER_SUCCESS = 'GET_MESSAGES_FOR_USER_SUCCESS';
export const GET_MESSAGES_SENDER_FOR_USER_FAIL = 'GET_MESSAGES_FOR_USER_FAIL';
export const GET_CAREERS_SUCCESS = 'GET_CAREERS_SUCCESS';
export const GET_CAREERS_FAIL = 'GET_CAREERS_FAIL';
export const GET_CAREER_BY_ID_SUCCESS = 'GET_CAREER_BY_ID_SUCCESS';
export const GET_CAREER_BY_ID_FAIL = 'GET_CAREER_BY_ID_FAIL';
export const CREATE_CAREER_SUCCESS = 'CREATE_CAREER_SUCCESS';
export const CREATE_CAREER_FAIL = 'CREATE_CAREER_FAIL';
export const UPDATE_CAREER_SUCCESS = 'UPDATE_CAREER_SUCCESS';
export const UPDATE_CAREER_FAIL = 'UPDATE_CAREER_FAIL';
export const DELETE_CAREER_SUCCESS = 'DELETE_CAREER_SUCCESS';
export const DELETE_CAREER_FAIL = 'DELETE_CAREER_FAIL';

export const GET_CANDIDATE_BY_ID_SUCCESS = 'GET_CANDIDATE_BY_ID_SUCCESS';
export const GET_CANDIDATE_BY_ID_FAIL = 'GET_CANDIDATE_BY_ID_FAIL';
export const CREATE_CANDIDATE_SUCCESS = 'CREATE_CANDIDATE_SUCCESS';
export const CREATE_CANDIDATE_FAIL = 'CREATE_CANDIDATE_FAIL';
export const UPDATE_CANDIDATE_SUCCESS = 'UPDATE_CANDIDATE_SUCCESS';
export const UPDATE_CANDIDATE_FAIL = 'UPDATE_CANDIDATE_FAIL';
export const DELETE_CANDIDATE_SUCCESS = 'DELETE_CANDIDATE_SUCCESS';
export const DELETE_CANDIDATE_FAIL = 'DELETE_CANDIDATE_FAIL';

export const GET_CANDIDATES_BY_CAREER_SUCCESS = 'GET_CANDIDATES_BY_CAREER_SUCCESS';
export const GET_CANDIDATES_BY_CAREER_FAIL = 'GET_CANDIDATES_BY_CAREER_FAIL';

export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL';
export const GET_COMPANY_BY_ID_SUCCESS = 'GET_COMPANY_BY_ID_SUCCESS';
export const GET_COMPANY_BY_ID_FAIL = 'GET_COMPANY_BY_ID_FAIL';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAIL = 'CREATE_COMPANY_FAIL';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL';

export const GET_SUB_ARTICLES_SUCCESS = 'GET_SUB_ARTICLES_SUCCESS';
export const GET_SUB_ARTICLES_FAIL = 'GET_SUB_ARTICLES_FAIL';
export const GET_SUB_ARTICLE_BY_ID_SUCCESS = 'GET_SUB_ARTICLE_BY_ID_SUCCESS';
export const GET_SUB_ARTICLE_BY_ID_FAIL = 'GET_SUB_ARTICLE_BY_ID_FAIL';
export const CREATE_SUB_ARTICLE_SUCCESS = 'CREATE_SUB_ARTICLE_SUCCESS';
export const CREATE_SUB_ARTICLE_FAIL = 'CREATE_SUB_ARTICLE_FAIL';
export const UPDATE_SUB_ARTICLE_SUCCESS = 'UPDATE_SUB_ARTICLE_SUCCESS';
export const UPDATE_SUB_ARTICLE_FAIL = 'UPDATE_SUB_ARTICLE_FAIL';
export const DELETE_SUB_ARTICLE_SUCCESS = 'DELETE_SUB_ARTICLE_SUCCESS';
export const DELETE_SUB_ARTICLE_FAIL = 'DELETE_SUB_ARTICLE_FAIL';
export const GET_SUB_ARTICLES_BY_ARTICLE_SUCCESS = 'GET_SUB_ARTICLES_BY_ARTICLE_SUCCESS';
export const GET_SUB_ARTICLES_BY_ARTICLE_FAIL = 'GET_SUB_ARTICLES_BY_ARTICLE_FAIL';

export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';
export const GET_CATEGORY_BY_ID_SUCCESS = 'GET_CATEGORY_BY_ID_SUCCESS';
export const GET_CATEGORY_BY_ID_FAIL = 'GET_CATEGORY_BY_ID_FAIL';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';

export const GET_TARGET_SUCCESS = "GET_TARGET_SUCCESS";
export const GET_TARGET_FAIL = "GET_TARGET_FAIL";
export const CREATE_TRIAL_SUCCESS = "CREATE_TRIAL_SUCCESS";
export const CREATE_TRIAL_FAIL = "CREATE_TRIAL_FAIL";
export const UPDATE_TRIAL_SUCCESS = "UPDATE_TRIAL_SUCCESS";
export const UPDATE_TRIAL_FAIL = "UPDATE_TRIAL_FAIL";

export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const GET_SESSION_FAIL = 'GET_SESSION_FAIL';
export const SET_SESSION_SUCCESS = 'SET_SESSION_SUCCESS';
export const SET_SESSION_FAIL = 'SET_SESSION_FAIL';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_FAIL = 'DELETE_SESSION_FAIL';
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS';
export const UPDATE_SESSION_FAIL = 'UPDATE_SESSION_FAIL';
export const DOWNLOAD_PREORDERS_SUCCESS = 'DOWNLOAD_PREORDERS_SUCCESS';
export const DOWNLOAD_PREORDERS_FAIL = 'DOWNLOAD_PREORDERS_FAIL';
export const DOWNLOAD_USERS_EXCEL_SUCCESS = 'DOWNLOAD_USERS_EXCEL_SUCCESS';
export const DOWNLOAD_USERS_EXCEL_FAIL = 'DOWNLOAD_USERS_EXCEL_FAIL';
export const DOWNLOAD_USERS_EXCEL_BY_ROLE_SUCCESS = 'DOWNLOAD_USERS_EXCEL_BY_ROLE_SUCCESS';
export const DOWNLOAD_USERS_EXCEL_BY_ROLE_FAIL = 'DOWNLOAD_USERS_EXCEL_BY_ROLE_FAIL';

export const GET_TARGET_SALES_SUCCESS = 'GET_TARGET_SALES_SUCCESS';
export const GET_TARGET_SALES_FAIL = 'GET_TARGET_SALES_FAIL';
export const GET_TARGET_SALE_BY_ID_SUCCESS = 'GET_TARGET_SALE_BY_ID_SUCCESS';
export const GET_TARGET_SALE_BY_ID_FAIL = 'GET_TARGET_SALE_BY_ID_FAIL';
export const CREATE_TARGET_SALE_SUCCESS = 'CREATE_TARGET_SALE_SUCCESS';
export const CREATE_TARGET_SALE_FAIL = 'CREATE_TARGET_SALE_FAIL';
export const UPDATE_TARGET_SALE_SUCCESS = 'UPDATE_TARGET_SALE_SUCCESS';
export const UPDATE_TARGET_SALE_FAIL = 'UPDATE_TARGET_SALE_FAIL';
export const GET_TARGET_SALE_BY_MONTH_YEAR_SUCCESS = 'GET_TARGET_SALE_BY_MONTH_YEAR_SUCCESS';
export const GET_TARGET_SALE_BY_MONTH_YEAR_FAIL = 'GET_TARGET_SALE_BY_MONTH_YEAR_FAIL';
export const DELETE_TARGET_SALE_SUCCESS = 'DELETE_TARGET_SALE_SUCCESS';
export const DELETE_TARGET_SALE_FAIL = 'DELETE_TARGET_SALE_FAIL';
