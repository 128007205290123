import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Textarea,
  Stack,
  ModalFooter,
} from "@chakra-ui/react";
import { createCareer, updateCareer } from "../../actions/careerActions";
import { useDispatch, useSelector } from "react-redux";
import { load_user } from "../../actions/auth";

const AddCareerModal = ({ isOpen, onClose, careerToUpdate, refreshCareers }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(load_user());
  }, [dispatch]);

  const initialFormState = {
    job_title: "",
    job_type: "",
    description: "",
    location: "",
    created_by: user?.id || 0,
  };

  const [formData, setFormData] = useState(initialFormState);

  const clearForm = () => {
    setFormData(initialFormState);
  };

  useEffect(() => {
    if (isOpen) {
      if (careerToUpdate) {
        setFormData({
          job_title: careerToUpdate.job_title || "",
          job_type: careerToUpdate.job_type || "",
          description: careerToUpdate.description || "",
          location: careerToUpdate.location || "",
          created_by: careerToUpdate.created_by || user?.id || 0,
        });
      } else {
        clearForm();
      }
    }
  }, [isOpen, careerToUpdate, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    if (!formData.job_title || !formData.job_type || !formData.location) {
      toast({
        title: "Missing Fields",
        description: "Please fill in all the required fields.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      if (careerToUpdate) {
        await dispatch(updateCareer(careerToUpdate.id, formData));
        toast({
          title: "Career Updated",
          description: "The career was updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        await dispatch(createCareer(formData));
        toast({
          title: "Career Added",
          description: "The career was added successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      refreshCareers();
      handleClose();
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error saving the career.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleClose = () => {
    clearForm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {careerToUpdate ? "Edit Career" : "Add New Career"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Job Title</FormLabel>
              <Input
                name="job_title"
                value={formData.job_title}
                onChange={handleChange}
                placeholder="Enter job title"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Job Type</FormLabel>
              <Input
                name="job_type"
                value={formData.job_type}
                onChange={handleChange}
                placeholder="Enter job type"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Enter description"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Location</FormLabel>
              <Input
                name="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="Enter location"
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#001A72"
            color="#FFFFFF"
            _hover={{ bg: "#001A72" }}
            ml={3}
            onClick={handleSubmit}
          >
            {careerToUpdate ? "Update Career" : "Add Career"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCareerModal;
