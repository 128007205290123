import React, { useEffect, useState } from 'react'
import { Box ,Button,Divider,Flex,FormLabel,Heading,Spacer,Text, Image, Img } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserById } from '../actions/user'
import {fetchCompany} from '../services/api/companyAPI/fetchCompany'
import { getSubscriptionByClient } from '../actions/subscription'
import fetchProducts from '../services/api/productAPI/fetchProducts'
import defaultImg from "../assets/defaultProfile.png"

const ClientProfile = () => {
    const dispatch=useDispatch()
    const userId=useSelector(state=>state.auth.user?.id)
    const user=useSelector(state=>state.userReducer.users[0])
    const subscriptions=useSelector(state=>state.subscriptionReducer.subscriptions)
    const [company,setCompany]=useState({})
    const [products,setProducts]=useState([])
    const datecreation=new Date(user?.created_at)
    const [subscriptionText,setSubscrirotionText]=useState('')

    const bg=useSelector(state=>state.themeReducer.backgroundColor1)
    const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
    const colorP=useSelector(state=>state.themeReducer.color2)

    useEffect(()=>{
        if(userId){
            dispatch(getUserById(userId))
            dispatch(getSubscriptionByClient(userId))
        }
    },[userId])
    useEffect(()=>{
        if(user?.company)
            fetchCompany(user.company,setCompany)
    },[user])
    useEffect(()=>{
        let tmp=""
        for(let subscription of subscriptions){
            const product=products.filter(p=>p.id===subscription.product)[0]?.title
            if(product)
                tmp+=`${product}, `
        }
        setSubscrirotionText(tmp.slice(0,tmp.length-2))
    },[subscriptions,products])
    useEffect(()=>{
        const getProducts=async()=>{
            const res=await fetchProducts()
            setProducts(res)
        }
        getProducts()
    },[])
  return (
    <Box ml={{base:"0",md:'60'}} minH="95vh" bgColor={bg} position="relative" paddingTop="15px" paddingBottom='15px' paddingInline='5px'>
        <Box  
            ml={{base:"1%",md:"5%",lg:"20%"}}
            bgColor={bg2}
            w={{base:"98%",md:"90%",lg:"60%"}}
            paddingTop="25px"
            paddingBottom="25px"
            borderRadius="15px"
            box-shadow="0px 1.92px 3.84px 0px rgba(0, 0, 0, 0.1)"
        >
            <Box textAlign="center" mb={{base:'25px',md:'15px'}}>
                <Heading as="h1" fontSize={{base:"19px",md:"23px"}} mb="5px">Profile</Heading>
                <Text as="p" fontSize="14px"  color={colorP}>Personal Details And Subscription</Text>
            </Box>
           <Divider color="#F5F5F5"/> 
           <Flex align="center" justify="space-around" height={{base:'150px',md:"175px",lg:"200px"}}>
                <Image src={user?.profile_picture!==null?`${process.env.REACT_APP_SERVER_URL}${user?.profile_picture}`:defaultImg} w={{base:"100px",md:"125px",lg:"150px"}} height={{base:"100px",md:"125px",lg:"150px"}} borderRadius="50%"/>    
           </Flex>
            <Flex direction='column' rowGap="20px" w={{base:"94%",md:'75%',lg:"60%"}} ml={{base:"5%",md:"12.5%",lg:"20%"}} mt="15px">
                <InfosRow colorP={colorP} keys={["First Name","Last Name"]} values={[user?.first_name, user?.last_name]}/>
                <InfosRow colorP={colorP} keys={["Email","Phone Number"]} values={[user?.email, user?.phone_number]}/>
                {
                    user?.type!=="Individual"?
                    <>
                        <InfosRow colorP={colorP} keys={["Company Name","Business Type"]} values={[company?.organization, company?.business_type]}/>
                        <InfosRow colorP={colorP} keys={["Country/Region","City"]} values={[company?.country_region, company?.city]}/>
                        <InfosRow colorP={colorP} keys={["LinkedIn URL","Company Website"]} values={[company?.linkedin_url, company?.company_website]}/>
                    </>:
                        <InfosRow colorP={colorP} keys={["Job Role"]} values={[user?.job_role]}/>
                }
            </Flex>
            <Box w={{base:"60%",sm:"50%",md:"40%"}} ml={{base:"20%",sm:"25%",md:"30%"}} mt="45px">
                <Button as="a" href='/x-capital/terminal/client/settings' w="100%" bgColor="rgba(0, 26, 114, 1)" borderRadius="7px" color="white" padding="7.78px, 12.96px, 7.78px, 12.96px" fontSize="15px">Edit Profile</Button>
            </Box>
            <Box textAlign="center" mt='25px'>
                <Heading as="h1" fontSize={{base:"19px",md:"23px"}} mb="5px">Subscription</Heading>
                <Text as="p" fontSize="15px" color='rgba(134, 142, 150, 1)'>You are currently on the <Text as="span" fontWeight="500">{subscriptionText}</Text> plans</Text>
            </Box>
            <Flex justify="space-around" w="60%" ml="20%" mt="25px">
                <Box textAlign="center">
                    <Text color="rgba(134, 142, 150, 1)" fontSize="14px">Start-date</Text>
                    <Text fontSize="15px">{`${datecreation.getFullYear()}-${datecreation.getMonth()+1}-${datecreation.getDate()}`}</Text>
                </Box>
            </Flex>
        </Box>
    </Box>
  )
}

const InfosRow=({keys,values,colorP})=>{
    return(
        <Flex>
            <Box w={{base:"46%",md:"43%",lg:"40%"}}>
                <FormLabel fontSize={{base:"15px",md:"17px"}}>{keys[0]}</FormLabel>
                <Text as="p" color={colorP} fontSize={{base:"14px",sm:"15px"}}>{values[0]}</Text>
            </Box>
            <Spacer/>
            {
                keys.length===2?
                <Box  width={{base:"46%",md:"43%",lg:"40%"}}>
                    <FormLabel fontSize={{base:"15px",md:"17px"}}>{keys[1]}</FormLabel>
                    <Text as="p" color={colorP} fontSize={{base:"14px",sm:"15px"}}>{values[1]}</Text>
                </Box>:null
            }
        </Flex>
    )
}

export default ClientProfile