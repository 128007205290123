import { Box,Button,Heading,Image,Text } from '@chakra-ui/react'
import React from 'react'
import article1 from "../assets/article1.svg"
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const ArticleClient = ({id,date,img,title,paragraph}) => {
    const navigate=useNavigate()

    const bg=useSelector(state=>state.themeReducer.backgroundColor1)
    const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
    const colorP=useSelector(state=>state.themeReducer.color1)
    const colorH=useSelector(state=>state.themeReducer.color2)
    const grayScales=useSelector(state=>state.themeReducer.grayScales)

    const months=['Januray','February','March','April',
        'May',"June","July","August",
         'September',"October","November","December"]

    let para=""
    paragraph.forEach(p => {
        para+=p+" "
    });
  return (
    <Box borderRadius="15px" flex={{base:"0 0 100%",md:"0 0 48%",lg:"0 0 30%"}} overflow="hidden">
       <Box w="100%" height="150px">
            <Image src={`${img}`} w="100%" height="100%" objectFit="cover"/>
       </Box> 
       <Box padding="10px" bgColor={grayScales==="black"?"white":"black"}>
         <Text as="p" color={colorP} mb='10px' fontSize={{base:"14px",md:"14px",lg:"15px"}}>
            {`${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`}
          </Text>
         <Heading as="h1" mb="15px" fontSize={{base:"19px",md:"22px",lg:"25px"}}>
            {title}
          </Heading>
         <Text as="p" height={{base:"50px",md:"75px",lg:"50px"}} overflow="hidden" color={colorP} fontSize={{base:"14px",md:"14px",lg:"15px"}}>
            {para}...
          </Text>
         <Box w="100px" ml="calc(50% - 50px)" mt="20px">
            <Button w="100%" bgColor={grayScales} color={grayScales==="black"?"white":"black"} onClick={()=>navigate(`/article/${id}`)}>
                See More
            </Button>
         </Box>
       </Box>
    </Box>
  )
}

export default ArticleClient