import React from 'react'
import { Flex, Box, Text,List, ListItem, UnorderedList, Heading } from '@chakra-ui/react'



function AcceptableUsePolicy() {
  return (
    <>
      <Flex direction={"column"} p={10} mb="2em">
        <Box mb="2em">
            <Heading size={{base:"lg", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>Acceptable Use Policy</Heading>
            <Text mb="1em"><b>Effective Date:</b> July 4, 2024</Text>
            <Text textAlign="left">X CAPITAL ("we," "us," "our") expects users to use our website responsibly. This Acceptable Use Policy outlines acceptable and prohibited activities.</Text>
        </Box>

        <Flex direction={"column"} gap={5}>
            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>1. Prohibited Activities</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>Violating any applicable laws or regulations.</ListItem>
                    <ListItem>Infringing on the intellectual property rights of others.</ListItem>
                    <ListItem>Transmitting harmful code or malware.</ListItem>
                    <ListItem>Engaging in fraudulent or deceptive practices.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>2. Consequences</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem listStyleType="none">Violations of this policy may result in account suspension or termination.</ListItem>
                </UnorderedList>
            </List>


            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>3. Contact Us</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem listStyleType="none">If you have any questions about our our Acceptable Use Policy, please contact us at: contact@xcapitalterminal.com or call us at +212 632331441 or +212 632321083.</ListItem>
                </UnorderedList>
            </List>
        </Flex>
      </Flex>
    </>
  )
}

export default AcceptableUsePolicy
