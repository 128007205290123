import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../actions/product";
import { getSubscriptions } from "../../actions/subscription";

export default function ProductTable() {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
  const colorH=useSelector(state=>state.themeReducer.color1)
  const colorP=useSelector(state=>state.themeReducer.color2)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productResponse = await dispatch(getProducts());
        const subscriptionResponse = await dispatch(getSubscriptions());
        setProducts(productResponse);
        setSubscriptions(subscriptionResponse);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  // Function to count subscriptions for each product
  const countSubscriptions = () => {
    const subscriptionCounts = subscriptions.reduce((acc, subscription) => {
      const productId = subscription.product;
      if (!acc[productId]) {
        acc[productId] = 0;
      }
      acc[productId] += 1;
      return acc;
    }, {});

    return subscriptionCounts;
  };

  const subscriptionCounts = countSubscriptions();
  const totalSubs = subscriptions.length;

  const getColor = (index) => {
    const colors = ["blue", "green", "purple", "orange", "red", "yellow"];
    return colors[index % colors.length];
  };

  return (
    <Box
      p={{ base: "4", md: "6" }}
      height="100%"
      bgColor={bg2}
      overflowX="auto"
    >
      <Heading
        as="h2"
        fontSize={{ base: "18px", sm: "20px", md: "22px" }}
        mb={{ base: "2", md: "4" }}
        textColor="#05004E"
      >
        Top Products
      </Heading>
      <Table
        variant="simple"
        width="full"
        height="auto"
        size={{ base: "sm", md: "md" }}
      >
        <Thead>
          <Tr>
            <Th w={{ base: "40px", md: "50px" }}>#</Th>
            <Th fontSize={{ base: "sm", md: "md" }}>Name</Th>
            <Th fontSize={{ base: "sm", md: "md" }}>Sales</Th>
            <Th fontSize={{ base: "sm", md: "md" }}>Popularity</Th>
            <Th fontSize={{ base: "sm", md: "md" }}>%</Th>
          </Tr>
        </Thead>
        <Tbody>
          {products.map((product, index) => {
            const totalSubsForProduct = subscriptionCounts[product.id] || 0;
            const percentageValue = totalSubs
              ? Math.round((totalSubsForProduct / totalSubs) * 100)
              : 0;
            const percentage = `${percentageValue}%`;
            const color = getColor(index);

            return (
              <Tr key={product.id}>
                <Td fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                  {product.id.toString().padStart(2, "0")}
                </Td>
                <Td fontSize={{ base: "sm", md: "md" }}>{product.title}</Td>
                <Td fontSize={{ base: "sm", md: "md" }}>
                  {totalSubsForProduct}
                </Td>
                <Td>
                  <Box
                    position="relative"
                    width="full"
                    height="2"
                    bg="gray.200"
                    borderRadius="md"
                    overflow="hidden"
                  >
                    <Box
                      position="absolute"
                      top="0"
                      left="0"
                      height="2"
                      bg={`${color}.500`}
                      borderRadius="md"
                      width={`${percentageValue}%`}
                      maxWidth="100%"
                    />
                  </Box>
                </Td>
                <Td>
                  <Badge
                    colorScheme={color}
                    border="1px"
                    borderRadius="8px"
                    width="50px"
                    textAlign="center"
                    fontSize={{ base: "sm", md: "md" }}
                  >
                    {percentage}
                  </Badge>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
}
