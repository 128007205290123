

const initialState = {
  backgroundColor2: "#FAFAFA",
  backgroundColor1: "#F5F5F5",
  color1: "#0A0A0A",
  color2: "#757575",
  colorAdmin2: "#8F9BB3",
  colorClicked: "#001A72",
  okStatusBg: "lightGreen",
  errStatusBg: "lightRed",
  grayScales: "black",
  blueAdmin: "#001A72",
  numbColor: "#797A7C",
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "light":
      return {
        ...state,
        backgroundColor2: "#FAFAFA",
        backgroundColor1: "#F5F5F5",
        color1: "#0A0A0A",
        color2: "#606060",
        colorAdmin2: "#8F9BB3",
        colorClicked: "#001A72",
        okStatusBg: "lightGreen",
        errStatusBg: "lightRed",
        grayScales: "black",
        blueAdmin: "#001A72",
        numbColor: "#797A7C",
      };
    case "dark":
      return {
        ...state,
        backgroundColor2: "rgb(65,65,65)",
        backgroundColor1: "rgb(50,50,50)",
        color2: "rgb(215,215,215)",
        color1: "whitesmoke",
        colorAdmin2: "rgb(215,215,215)",
        colorClicked: "lightblue",
        okStatusBg: "green",
        errStatusBg: "red",
        grayScales: "white",
        blueAdmin: "#0784b5",
        numbColor: "white",
      };
    default:
      return state;
  }
};

export default themeReducer