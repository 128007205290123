import {
    GET_TARGET_SALES_SUCCESS,
    GET_TARGET_SALES_FAIL,
    GET_TARGET_SALE_BY_ID_SUCCESS,
    GET_TARGET_SALE_BY_ID_FAIL,
    CREATE_TARGET_SALE_SUCCESS,
    CREATE_TARGET_SALE_FAIL,
    UPDATE_TARGET_SALE_SUCCESS,
    UPDATE_TARGET_SALE_FAIL,
    GET_TARGET_SALE_BY_MONTH_YEAR_SUCCESS,
    GET_TARGET_SALE_BY_MONTH_YEAR_FAIL,
    DELETE_TARGET_SALE_FAIL,
    DELETE_TARGET_SALE_SUCCESS,
} from '../actions/types';

const initialState = {
    targetSales: [],
    targetSale: null,
    loading: true,
    error: null,
};

const targetSalesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TARGET_SALES_SUCCESS:
            return {
                ...state,
                targetSales: action.payload,
                loading: false,
                error: null,
            };
        case GET_TARGET_SALE_BY_ID_SUCCESS:
        case GET_TARGET_SALE_BY_MONTH_YEAR_SUCCESS:
            return {
                ...state,
                targetSale: action.payload,
                loading: false,
                error: null,
            };
        case CREATE_TARGET_SALE_SUCCESS:
            return {
                ...state,
                targetSales: [...state.targetSales, action.payload],
                loading: false,
                error: null,
            };
        case UPDATE_TARGET_SALE_SUCCESS:
            return {
                ...state,
                targetSales: state.targetSales.map((targetSale) =>
                    targetSale.id === action.payload.id ? action.payload : targetSale
                ),
                loading: false,
                error: null,
            };
        case GET_TARGET_SALES_FAIL:
        case GET_TARGET_SALE_BY_ID_FAIL:
        case CREATE_TARGET_SALE_FAIL:
        case UPDATE_TARGET_SALE_FAIL:
        case GET_TARGET_SALE_BY_MONTH_YEAR_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case DELETE_TARGET_SALE_SUCCESS:
            return {
                ...state,
                targetSales: state.targetSales.filter((targetSale) => targetSale.id !== action.payload),
                loading: false,
                error: null,
            };
        case DELETE_TARGET_SALE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export default targetSalesReducer;