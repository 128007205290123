import {
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAIL,
    GET_CATEGORY_BY_ID_SUCCESS,
    GET_CATEGORY_BY_ID_FAIL,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAIL,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAIL,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAIL,
  } from '../actions/types';
  
  const initialState = {
    categories: [],
    category: null,
    loading: true,
    error: null,
  };
  
  const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_CATEGORIES_SUCCESS:
        return {
          ...state,
          categories: action.payload,
          loading: false,
          error: null,
        };
      case GET_CATEGORY_BY_ID_SUCCESS:
        return {
          ...state,
          category: action.payload,
          loading: false,
          error: null,
        };
      case CREATE_CATEGORY_SUCCESS:
        return {
          ...state,
          categories: [...state.categories, action.payload],
          loading: false,
          error: null,
        };
      case UPDATE_CATEGORY_SUCCESS:
        return {
          ...state,
          categories: state.categories.map((category) =>
            category.id === action.payload.id ? action.payload : category
          ),
          loading: false,
          error: null,
        };
      case DELETE_CATEGORY_SUCCESS:
        return {
          ...state,
          categories: state.categories.filter((category) => category.id !== action.payload),
          loading: false,
          error: null,
        };
      case GET_CATEGORIES_FAIL:
      case GET_CATEGORY_BY_ID_FAIL:
      case CREATE_CATEGORY_FAIL:
      case UPDATE_CATEGORY_FAIL:
      case DELETE_CATEGORY_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default categoryReducer;
  