import {
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
  StackDivider,
  Card,
  CardHeader,
  CardBody,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import fetchQuestions from "../../services/api/fetchQuestions";

export default function Questions() {
  const bg = useSelector((state) => state.themeReducer.backgroundColor1);

  const faqList = [
    {
      question: "What is X CAPITAL Terminal?",
      answer:
        "X Capital Terminal is our premier financial technology platform designed to revolutionize investment strategies in the dynamic markets of Africa and the Middle East. It offers real-time data, historical data, comprehensive market analysis, and in-depth insights, empowering investors to make informed decisions and capitalize on emerging opportunities.",
    },
    {
      question: "How do I contact X Capital for support?",
      answer:
        "For support, you can reach out to our dedicated customer service team via email at support@xcapitalterminal.com or call us at +212 632331441 || +212 632321083. Our team is available Monday through Friday from 9 AM to 6 PM to assist you with any inquiries or issues.",
    },
    {
      question: "What sets us apart?",
      answer:
        "At X Capital, we stand out due to our commitment to innovation and excellence. Our use of cutting-edge AI and machine learning technologies provides unparalleled insights and data accuracy. We focus specifically on the unique dynamics of the financial markets in Africa and the Middle East, offering tailored solutions that address the specific needs and challenges of these regions.",
    },
    {
      question: "How will X Capital solve my issue?",
      answer:
        "X Capital will solve your investment challenges by providing you with the tools and insights needed to make data-driven decisions. Whether you are seeking to optimize your investment strategies, manage risks, or explore new market opportunities, our X Capital Terminal offers the comprehensive data and analysis you need. Our dedicated support team is always ready to assist, ensuring you have the guidance and resources necessary to achieve your financial goals.",
    },
  ];

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchQuestions();
        setQuestions(result);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Card margin={"40px 0"} size={"lg"} width={"90%"} bgColor={bg}>
      <CardHeader>
        <Heading size="lg">Frequently asked questions</Heading>
      </CardHeader>

      <CardBody>
        <Accordion allowToggle>
          <Stack divider={<StackDivider />} spacing="4">
            {faqList.map((item, index) => (
              <AccordionItem key={index}>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      {item.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>{item.answer}</AccordionPanel>
              </AccordionItem>
            ))}

            <AccordionItem key="services">
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    What Services Does X Capital Offer?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                X Capital offers a comprehensive suite of services designed to
                empower investors and drive growth in the dynamic financial
                markets of Africa and the Middle East. Our primary services
                include:
                <br />
                <ul>
                  <li>
                    1. The X Capital Terminal is our flagship
                    product, providing real-time market data, historical data,
                    comprehensive analysis, and in-depth insights. This powerful
                    platform equips investors with the knowledge and resources
                    needed to make informed decisions and seize emerging
                    opportunities.
                  </li>
                  <li>
                    2. X Capital Intelligence leverages
                    advanced AI and machine learning algorithms to deliver
                    actionable market insights and predictive analytics. This
                    service helps investors anticipate market trends, optimize
                    their investment strategies, and stay ahead of the
                    competition.
                  </li>
                  <li>
                    3. X Capital Data offers extensive and
                    reliable financial data, including real-time updates and
                    historical records. This service provides the foundation for
                    informed decision-making, ensuring investors have access to
                    accurate and timely information.
                  </li>
                  <li>
                    4. X Capital Articles delivers
                    expert-written content and in-depth analysis on various
                    aspects of the financial markets. This service keeps
                    investors informed about the latest market trends, investment
                    strategies, and economic developments, providing valuable
                    insights and perspectives to support their decision-making
                    process.
                  </li>
                </ul>
              </AccordionPanel>
            </AccordionItem>
          </Stack>
        </Accordion>
      </CardBody>
    </Card>
  );
}