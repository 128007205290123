import {
  Box,
  Heading,
  Spinner,
  Input,
  Textarea,
  Button,
  Select,
  useToast,
  Flex,
  Checkbox,
  IconButton,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import fetchArticle from "../../services/api/artilcesAPI/fetchArticle";
import updateArticle from "../../services/api/artilcesAPI/updateArticle";
import fetchArticlesCategories from "../../services/api/artilcesAPI/fetchArticleCategory";
import UploadImage from "../UploadImage";
import { FiTrash } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { getSubArticlesByArticle, createSubArticle, updateSubArticle, deleteSubArticle } from "../../actions/subArticle";

export default function UpdateArticle() {
  // Dark mode integration
  const bg = useSelector(state => state.themeReducer.backgroundColor1);
  const bg2 = useSelector(state => state.themeReducer.backgroundColor2);
  const colorH = useSelector(state => state.themeReducer.color1);
  const colorP = useSelector(state => state.themeReducer.color2);
  const grayScales = useSelector(state => state.themeReducer.grayScales);
  
  const { articleId } = useParams();
  const [loadingArticle, setLoadingArticle] = useState(true);
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [body, setBody] = useState("");
  const [imageCover, setImageCover] = useState(null);
  const [imageCoverChanged, setImageCoverChanged] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [categories, setCategories] = useState([]);

  const [subArticles, setSubArticles] = useState([]);
  const [showSubArticles, setShowSubArticles] = useState(false);

  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        const result = await fetchArticle(articleId);
        setTitle(result.title);
        setSubTitle(result.sub_title);
        setBody(result.body);
        setImageCover(result.image_cover_url);
        setCategoryId(Number(result.category_id));
  
        const subArticlesData = await dispatch(getSubArticlesByArticle(articleId));
        if (subArticlesData.length > 0) {
          const mappedSubArticles = subArticlesData.map((subArticle) => ({
            id: subArticle.id,
            sub_article_body: subArticle.body || "",
            sub_article_image: subArticle.image || null, 
          }));
          setSubArticles(mappedSubArticles);
          setShowSubArticles(true);
        }
      } catch (error) {
        console.error("Error fetching article:", error);
        toast({
          title: "Error fetching article.",
          description: "Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoadingArticle(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const result = await fetchArticlesCategories();
        setCategories(result);
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast({
          title: "Error fetching categories.",
          description: "Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
  
    if (articleId) {
      fetchArticleData();
      fetchCategories();
    }
  }, [articleId, dispatch, toast]);  

  const handleFileSelect = (name, file) => {
    if (name === "image_cover") {
      setImageCover(file);
      setImageCoverChanged(true);
    }
  };

  const handleSubArticleChange = (index, name, value) => {
    const updatedSubArticles = [...subArticles];
    updatedSubArticles[index] = {
      ...updatedSubArticles[index],
      [name]: value,
    };
    setSubArticles(updatedSubArticles);
  };

  const handleSubArticleFileSelect = (index, file) => {
    const updatedSubArticles = [...subArticles];
    updatedSubArticles[index] = {
      ...updatedSubArticles[index],
      sub_article_image: file,
    };
    setSubArticles(updatedSubArticles);
  };

  const handleAddSubArticle = () => {
    setSubArticles([...subArticles, { sub_article_image: null, sub_article_body: "" }]);
  };

  const handleRemoveSubArticle = async (index) => {
    const updatedSubArticles = [...subArticles];
    const removedSubArticle = updatedSubArticles[index];
    
    if (removedSubArticle.id) {
      try {
        await dispatch(deleteSubArticle(removedSubArticle.id));
      } catch (error) {
        console.error("Error deleting sub-article:", error);
        toast({
          title: "Error deleting sub-article.",
          description: "Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }
    
    updatedSubArticles.splice(index, 1);
    setSubArticles(updatedSubArticles);

    if (updatedSubArticles.length === 0) {
      setShowSubArticles(false);
    }
  };

  const handleUpdate = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("sub_title", subTitle);
    formData.append("body", body);
    formData.append("category_id", String(categoryId));

    if (imageCoverChanged && imageCover instanceof File) {
      formData.append("image_cover", imageCover);
    }

    try {
      await updateArticle(formData, articleId);

      if (showSubArticles) {
        for (const subArticle of subArticles) {
          const subArticleData = new FormData();
          subArticleData.append("body", subArticle.sub_article_body);
          if (subArticle.sub_article_image instanceof File) {
            subArticleData.append("image", subArticle.sub_article_image);
          }
          subArticleData.append("article", articleId);
          subArticleData.append("is_active", true);

          if (subArticle.id) {
            await dispatch(updateSubArticle(subArticle.id, subArticleData));
          } else {
            await dispatch(createSubArticle(subArticleData));
          }
        }
      }

      toast({
        title: "Article updated.",
        description: "Your article has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      navigate("/x-capital/terminal/admin/article-panel");

    } catch (error) {
      console.error("Error updating article:", error);
      toast({
        title: "Error updating article.",
        description: "Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (loadingArticle) {
    return <Spinner size="xl" />;
  }

  return (
    <Box ml={{ base: 0, md: 60 }} p="4" minH="100vh" bg={bg}>
      <Box
        bg={bg2}
        borderRadius="12px"
        p="40px"
        boxShadow="md"
        width="100%"
        margin="0 auto"
      >
        <Heading fontSize={"32px"} marginBottom={"32px"} color={colorH}>
          Update Article:
        </Heading>

        <Flex width="100%" justifyContent="space-between" mb="24px">
          <UploadImage
            name={"image_cover"}
            label="Cover Image"
            inputFileId="coverFileInput"
            onFileSelect={(file) => handleFileSelect("image_cover", file)}
            width={"100%"}
            height={"200px"}
            backgroundColor={grayScales}
            previewImage={imageCover instanceof File ? URL.createObjectURL(imageCover) : imageCover}
          />
        </Flex>
        <Flex width="100%" gap="24px" mb="24px">
          <Select
            placeholder="Select category"
            name="category_id"
            onChange={(e) => setCategoryId(Number(e.target.value))}
            value={categoryId || ""}
            width="48%"
            bg={bg}
            color={colorP}
            _focus={{ borderColor: "transparent", boxShadow: "none" }}
            _hover={{ borderColor: "transparent" }}
          >
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Select>
          <Input
            name="title"
            placeholder="Enter the title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            width="48%"
            bg={bg2}
            color={colorP}
          />
        </Flex>
        <Flex width="100%" mb="24px">
          <Input
            name="sub_title"
            placeholder="Enter the sub title"
            value={subTitle}
            onChange={(e) => setSubTitle(e.target.value)}
            width="100%"
            bg={bg2}
            color={colorP}
          />
        </Flex>
        <Flex width="100%" mb="24px">
          <Textarea
            placeholder="Content..."
            name="body"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            height={"150px"}
            width="100%"
            bg={bg2}
            color={colorP}
          />
        </Flex>

        <Checkbox
          mb="24px"
          isChecked={showSubArticles}
          onChange={(e) => setShowSubArticles(e.target.checked)}
          color={colorP}
        >
          Add Sub-Articles
        </Checkbox>

        {showSubArticles &&
          subArticles.map((subArticle, index) => (
            <Box key={subArticle.id || index} mb="24px">
              <Flex width="100%" justifyContent="center" mb="12px">
                <UploadImage
                  name={`sub_article_image_${index}`}
                  label="Sub-Article Image"
                  inputFileId={`subArticleFileInput_${index}`}
                  onFileSelect={(file) => handleSubArticleFileSelect(index, file)}
                  width="60%"
                  height="200px"
                  backgroundColor={grayScales}
                  previewImage={subArticle.sub_article_image instanceof File ? URL.createObjectURL(subArticle.sub_article_image) : subArticle.sub_article_image}
                />
              </Flex>
              <Flex width="100%" mb="12px">
                <Textarea
                  placeholder="Sub-Article Content..."
                  name={`sub_article_body_${index}`}
                  value={subArticle.sub_article_body}
                  onChange={(e) => handleSubArticleChange(index, "sub_article_body", e.target.value)}
                  height={"150px"}
                  width="100%"
                  bg={bg2}
                  color={colorP}
                />
              </Flex>
              <Flex width="100%" justifyContent="flex-end">
                <IconButton
                  icon={<FiTrash />}
                  colorScheme="red"
                  onClick={() => handleRemoveSubArticle(index)}
                  aria-label="Remove Sub-Article"
                />
              </Flex>
            </Box>
          ))}

        {showSubArticles && (
          <Button mb="24px" onClick={handleAddSubArticle} colorScheme="blue" variant="outline">
            Add Another Sub-Article
          </Button>
        )}

        <Flex width="100%" justifyContent="flex-end">
          <Button onClick={handleUpdate} colorScheme="blue" mt={4}>
            Save Changes
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}