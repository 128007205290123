import { Heading,Box,Flex,Text, Image, Button, Spinner } from '@chakra-ui/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tips from '../assets/tips.png'
import { useNavigate } from 'react-router-dom'
import { getCart, setCart } from '../actions/cart'

const OneSolution = ({title, paragraph, idProduct, pic}) => {
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const grayScales=useSelector(state=>state.themeReducer.grayScales)
    const okStatusBg=useSelector(state=>state.themeReducer.okStatusBg)
    const {subscriptions,loading}=useSelector(state=>state.subscriptionReducer)

    const handleClick=()=>{
        if(/inteligence/i.test(title) || /terminal/i.test(title))
            navigate("/wait-list")
        else{
            dispatch(setCart(idProduct,"monthly"))
            navigate("/Checkout")
        }
    }
  return (
    <Flex justify="space-around" align="center" mb="45px" width="100%" direction={{base:"column-reverse", md:"row"}} key={idProduct}>
        <Box flex={{base:"0 0 100%",md:"0 0 65%"}}>
            <Heading as='h2' mb="20px" fontSize={{base:"20px", md:"24px", lg:"27px"}}>{title}</Heading>
            <Box paddingLeft="15px" borderLeft={`solid 4px ${grayScales}`}>
            <Text mb="20px" fontSize={{base:"13px",lg:"15px"}}>{paragraph}</Text>
                {
                    !loading?(
                        subscriptions.filter(s=> s.product === idProduct && s.is_active).length===0?
                            <Button bgColor={grayScales} color={grayScales==="black"?"white":"black"} borderRadius="15px" onClick={handleClick}>
                                {(/inteligence/i.test(title) || /terminal/i.test(title)) ? "Pre-Ordre" : "Pay Now"}
                            </Button>
                        :
                            <Text padding="15px" borderRadius="15px" backgroundColor={okStatusBg} w="fit-content" fontSize="16px" fontWeight='700' >Subscriped</Text>
                    ):<Spinner/>
                }
            </Box>
        </Box>
        <Box  flex={{base:"0 0 100%",md:"0 0 30%"}} padding="10px" bgColor="#F5F5F5" borderRadius="15px" mb={{base:"15px",md:"0"}}>
            <Image w="100%" h={{base:"100px",lg:"170px"}} objectFit="contain" src={pic}/>
        </Box>
    </Flex>
  )
}

export default OneSolution