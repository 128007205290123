import { combineReducers } from 'redux';
import auth from './auth';
import userReducer from './userReducer';
import offerReducer from './offerReducer';
import fileReducer from './fileReducer';
import candidateReducer from './candidatReducer';
import subscriptionReducer from './subscriptionReducer';
import newsletterReducer from './newsletter';
import articleReducer from './articleReducer';
import contactUsReducer from './contactUsReducer';
import productReducer from './productReducer';
import preorderReducer from './preorderReducer';
import chatReducer from './chatReducer';
import themeReducer from './themeReducer';
import trialReducer from './trialReducer';
import subArticleReducer from './sub_article';
import categoryReducer from './categoryReducer';
import targetSalesReducer from './targetSalesReducer';
import cartReducer from './cartReducer';

export default combineReducers({
    auth,
    userReducer,
    offerReducer,
    fileReducer,
    candidateReducer,
    subscriptionReducer,
    newsletterReducer,
    articleReducer,
    contactUsReducer,
    productReducer,
    preorderReducer,
    chatReducer,
    themeReducer,
    trialReducer,
    subArticleReducer,
    categoryReducer,
    targetSalesReducer,
    cartReducer
});
