import { getAuthConfig } from "../../../actions/config";

export const fetchCompany=async(id,setCompany)=>{
    const config = getAuthConfig();
    const response=await fetch(`${process.env.REACT_APP_API_URL}/company/${id}`,config)
    await response.json().then((res)=>{
        if(response.ok)
            setCompany(res)
    })
}


export const updateCompany=async(id,company)=>{
    const config = getAuthConfig();
    return fetch(`${process.env.REACT_APP_API_URL}/company/${id}/update`,{
        method:"PUT",
        body:JSON.stringify(company),
        headers:{
            "Content-Type":"application/json"
        },
        ...config
    }).then(response=>{
        if(response.ok)
            return true
        else
            return false
    })
}


