import { GET_TRIAL_SUCCESS, GET_TRIAL_FAIL,CREATE_TRIAL_SUCCESS, CREATE_TRIAL_FAIL, UPDATE_TRIAL_SUCCESS } from '../actions/types';

const initialState = {
  trials: [],
  loading: true,
  error: null
};

const trialReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRIAL_SUCCESS:
      return {
        ...state,
        trials: action.payload,
        loading: false,
        error: null
      };
    case GET_TRIAL_FAIL:
      return {
        ...state,
        trials: [],
        loading: false,
        error: action.payload
      };
    case CREATE_TRIAL_SUCCESS:
      return {
        ...state,
        trials: [...state.trials, action.payload],
        loading: false,
        error: null,
      };
      case UPDATE_TRIAL_SUCCESS:
        return{
          ...state,
          trials: [...state.trials, action.payload],
          loading: false,
          error: null,
        }
    default:
      return state;
  }
};

export default trialReducer;
