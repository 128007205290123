
const postApplication = async (applicationData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/candidat/save`, {
      method: 'POST',
      body: applicationData,
    });

    if (!response.ok) {
      throw new Error('Failed to submit application');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error submitting application:', error);
    return null;
  }
};

export default postApplication;
