import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Text, Image } from "@chakra-ui/react";
import { MdRemoveRedEye, MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import {
  getMessagesForUser,
  getMessagesSenderForUser,
  deleteMessage,
  getMessageById,
  updateMessage,
} from "../../../actions/chatActions";
import { useDispatch, useSelector } from "react-redux";
import { load_user } from "../../../actions/auth";
import { getUsers } from "../../../actions/user";
import DefaultProfile from "../../../assets/defaultProfile.png"

const MessagesList = () => {

  //for Dark/light mode
  const bg=useSelector(state=>state.themeReducer.backgroundColor1)
  const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
  const colorH=useSelector(state=>state.themeReducer.color1)
  const colorP=useSelector(state=>state.themeReducer.color2)
  const colorAdminP=useSelector(state=>state.themeReducer.colorAdmin2)
  const grayScales=useSelector(state=>state.themeReducer.grayScales)
  const blueAdmin=useSelector(state=>state.themeReducer.blueAdmin)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState({});
  const [filter, setFilter] = useState("incoming");
  const [unreadCount, setUnreadCount] = useState(0);
  const role=useSelector(state=>state.auth.user?.role)

  const currentUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(load_user());
  }, [dispatch]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const fetchedUsers = await dispatch(getUsers());
        const usersMap = {};
        fetchedUsers.forEach((user) => {
          usersMap["name" + user.id] = user.first_name + " " + user.last_name;
          usersMap["profile" + user.id] = user.profile_picture;
        });
        setUsers(usersMap);
      } catch (err) {
        console.error("Failed to fetch users:", err);
      }
    };

    fetchUsers();
  }, [dispatch]);

  const fetchMessages = async () => {
    if (!currentUser) {
      console.error("No current user found");
      return;
    }

    try {
      let fetchedMessages;
      if (filter === "incoming") {
        fetchedMessages = await dispatch(getMessagesForUser(currentUser.id));
      } else if (filter === "outgoing") {
        fetchedMessages = await dispatch(getMessagesSenderForUser(currentUser.id));
      } else {
        fetchedMessages = await dispatch(getMessagesForUser(currentUser.id));
      }
      setMessages(fetchedMessages);

      // Calculate the number of unread messages
      const unreadMessagesCount = fetchedMessages.filter((message) => message.see === false).length;
      setUnreadCount(unreadMessagesCount);
    } catch (err) {
      console.error("Failed to load messages");
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [dispatch, currentUser, filter]);

  const handleDeleteMessage = async (messageId) => {
    try {
      await dispatch(deleteMessage(messageId));
      fetchMessages(); // Re-fetch messages after deletion
    } catch (err) {
      console.error("Failed to delete message:", err);
    }
  };

  const handleViewMessage = async (messageId) => {
    try {
      const message = await dispatch(getMessageById(messageId));
      if (message && message.see === false) {
        await dispatch(updateMessage(messageId, { see: true }));
      }
      navigate(`/x-capital/terminal/${role}/messages/${messageId}?filter=${filter}`);
    } catch (err) {
      console.error("Failed to update or navigate to message:", err);
    }
  };

  return (
    <Box minH="100vh" ml={{ base: 0, md: 60 }}>
      <Box  p={4} width={{base:"100%",md:"100%",lg:"80%"}} ml={{base:"0%",md:"0%",lg:"10%"}}>
        <Flex direction="column">
          <Flex direction="column" width={{base:"70%",md:'45%',lg:"20%"}} gap={2}>
            <Button
              bgColor="#000042"
              color="white"
              _hover={{
                bgColor: "#000042",
                color: "white",
              }}
            >
              <Link to={`/x-capital/terminal/${role}/messages/send`}>
                New Message
              </Link>
            </Button>

            <Button
              bgColor="#000042"
              color="white"
              _hover={{
                bgColor: "#000042",
                color: "white",
              }}
              onClick={() => setFilter("incoming")}
            >
              Incoming Messages
              {filter === "incoming" && unreadCount > 0 && (
                <Text as="span" ml={2}>
                  ({unreadCount})
                </Text>
              )}
            </Button>

            <Button
              bgColor="#000042"
              color="white"
              _hover={{
                bgColor: "#000042",
                color: "white",
              }}
              onClick={() => setFilter("outgoing")}
            >
              Outgoing Messages
            </Button>
          </Flex>
          {messages.map((message) => (
            <Flex
              key={message.id}
              direction="row"
              width={{base:'100%',md:"92%",lg:"60%"}}
              ml={{base:'0%',md:"4%",lg:"20%"}}
              mr="auto"
              mt="2em"
              border="1px solid grey"
              borderRadius="1em"
              p={5}
              bgColor={
                filter !== "outgoing" && !message.see ? {bg2} : {bg}
              }
              align={"center"}
            >
              <Image
                
                src={
                  users["profile" + message.sender]?`${process.env.REACT_APP_SERVER_URL}${users["profile" + message.sender]}`:
                  DefaultProfile
                }
                width={{base:"3em",md:"4em",lg:"5em"}}
                height={{base:"3em",md:"4em",lg:"5em"}}
                borderRadius="50%"
              />
              <Box ml="1em">
                <Text fontSize={{base:"md",md:"xl",lg:"2xl"}}>
                  {users["name" + message.sender] || "Unknown"}
                </Text>
                <Text
                  fontWeight={
                    filter !== "outgoing" && !message.see ? "bold" : "normal"
                  }
                >
                  {message.subject}
                </Text>
              </Box>
              <Flex direction={{base:'column',md:"row"}} ml="auto" mr="0" mt="auto" mb="auto">
                <Button onClick={() => handleViewMessage(message.id)} mb={{base:"4px",md:"0"}}>
                  <MdRemoveRedEye />
                </Button>
                <Button
                  ml="3px"
                  onClick={() => handleDeleteMessage(message.id)}
                >
                  <MdDelete />
                </Button>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default MessagesList;
