import { Flex, Heading, Button, Text, useColorModeValue } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import deleteArticles from "../../services/api/artilcesAPI/deleteArticle";
import { useSelector } from "react-redux";

export default function DeleteArticleCard({ articleId, onClose }) {
  // For Dark/light mode
  const bg = useSelector(state => state.themeReducer.backgroundColor1);
  const colorH = useSelector(state => state.themeReducer.color1);
  const colorP = useSelector(state => state.themeReducer.color2);

  // Dynamic colors based on color mode
  const bgColor = useColorModeValue("#FFE0E3", "#4A1C1F");
  const borderColor = useColorModeValue("black", "white");
  const cancelBgColor = useColorModeValue("white", "gray.800");

  const handleDelete = async () => {
    try {
      await deleteArticles(articleId);
      onClose();
      // You might want to add some feedback here, like a toast notification
    } catch (error) {
      console.error("Error deleting article:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  return (
    <Flex
      direction={"column"}
      align={"center"}
      justify={"center"}
      gap={"16px"}
      padding={"20px"}
      bg={bg}
    >
      <Flex
        bgColor={bgColor}
        width={"60px"}
        height={"60px"}
        align={"center"}
        justify={"center"}
        borderRadius={"50%"}
      >
        <DeleteIcon boxSize={"2em"} color={"red"} />
      </Flex>
      <Heading fontSize={"28px"} color={colorH}>Delete Article?</Heading>
      <Text width={"82%"} color={colorP}>
        You're about to delete an article. This action cannot be undone.
      </Text>
      <Flex gap={"12px"}>
        <Button 
          bg={cancelBgColor} 
          border={`1px solid ${borderColor}`}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          bgColor={"red"}
          color={"white"}
          _hover={{ bg: "red.600" }}
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Flex>
    </Flex>
  );
}
