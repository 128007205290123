import React, { useState, useRef, useEffect } from 'react';
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom';
import FailedLoginModal from '../components/FailedLoginModal';
import { connect, useSelector } from 'react-redux';
import { login } from '../actions/auth';
import { LOGIN_SUCCESS } from '../actions/types';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Flex,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  IconButton,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from "@chakra-ui/react";
import { HiEye, HiEyeOff } from 'react-icons/hi';

const Login = ({ login, isAuthenticated, user }) => {
  const bg = useSelector(state => state.themeReducer.backgroundColor1);
  const bg2 = useSelector(state => state.themeReducer.backgroundColor2);
  const colorH = useSelector(state => state.themeReducer.color1);
  const colorP = useSelector(state => state.themeReducer.color2);
  const grayScales = useSelector(state => state.themeReducer.grayScales);
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const mergeRef = useMergeRefs(inputRef);
  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };

  const [loginError, setLoginError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  useEffect(() => {
    const rememberedCheck = localStorage.getItem('rememberMe');

    if (rememberedCheck === 'true') {
      setRememberMe(true);
    }
  }, []);

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    const res = await login(email, password);

    if (rememberMe) {
      localStorage.setItem('rememberMe', 'true');
      sessionStorage.setItem('rememberMe', 'true');
    } else {
      sessionStorage.setItem('rememberMe', 'true');
    }

    if (res === LOGIN_SUCCESS) {
      setLoginError(false);
    } else {
      setLoginError(true);
      setTimeout(() => {
        setLoginError(false);
      }, 4000);
    }
  };

  if (isAuthenticated) {
    if (user && user.role) 
      return <Navigate to={`/x-capital/terminal/${user.role}`} />
  }

  return (
    <>
      <Flex
        flexDirection="column"
        width="100wh"
        minHeight="85vh"
        backgroundColor={bg2}
        justifyContent="center"
        alignItems="center"
        paddingX={{ base: "1rem", md: "2rem" }}
      >
        <Container
          maxW="2xl"  // Augmenté de "xl" à "2xl" pour plus de largeur
          py={{ base: "0", md: "12" }}
          px={{ base: "3", sm: "8" }}
          backgroundColor={bg2}
        >
          <Stack spacing="8" transform={{ base: "scaleY(0.9)", md: "scale(0.8)" }}>
            <Box
              py={{ base: "5", sm: "8" }}
              px={{ base: "4", sm: "10" }}
              backgroundColor={bg}
              borderRadius={{ base: "12", sm: "24" }}
            >
              <Heading
                fontFamily="Heebo"
                fontWeight="700"
                fontSize={{ base: "18px", md: "28px" }}
                lineHeight={{ base: "36px", md: "40px" }}
                textAlign="center"
                color={colorH}
                mb={4}
              >
                Welcome to X CAPITAL!
              </Heading>
              <Text
                fontFamily="Inter"
                fontWeight="400"
                fontSize={{ base: "14px", md: "16px" }}
                lineHeight="30px"
                color={colorP}
                textAlign="center"
                mb={6}
              >
                Login to your account
              </Text>
              <Heading
                fontFamily="Heebo"
                fontWeight="700"
                fontSize={{ base: "16px", md: "24px" }}
                lineHeight={{ base: "36px", md: "60px" }}
                textAlign="left"
                color={colorH}
                mb={4}
              >
                Sign in
              </Heading>
              <form onSubmit={onSubmit}>
                <Stack spacing="6">
                  <Stack spacing="5">
                    <FormControl>
                      <FormLabel
                        htmlFor="email"
                        color="#7F989A"
                        fontSize={{ base: "14px", md: "16px" }}
                        fontWeight="500"
                      >
                        E-mail
                      </FormLabel>
                      <Input
                        id="email"
                        type="email"
                        placeholder="Email Address"
                        borderRadius="10px"
                        border="1px solid #7F989A"
                        backgroundColor={bg2}
                        focusBorderColor="#E7EBEE"
                        name="email"
                        value={email}
                        onChange={(e) => onChange(e)}
                        required
                        fontSize={{ base: "14px", md: "16px" }}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        htmlFor="password"
                        color="#7F989A"
                        fontSize={{ base: "14px", md: "16px" }}
                        fontWeight="500"
                      >
                        Password
                      </FormLabel>
                      <InputGroup>
                        <InputRightElement>
                          <Divider
                            orientation="vertical"
                            height="25px"
                            border="solid 1px #CFD9E0"
                          />
                          <IconButton
                            variant="text"
                            aria-label={
                              isOpen ? "Mask password" : "Reveal password"
                            }
                            color="#7F989A"
                            icon={isOpen ? <HiEyeOff /> : <HiEye />}
                            onClick={onClickReveal}
                          />
                        </InputRightElement>
                        <Input
                          id="password"
                          ref={mergeRef}
                          placeholder="Password"
                          name="password"
                          type={isOpen ? "text" : "password"}
                          autoComplete="current-password"
                          borderRadius="10px"
                          border={`1px solid ${colorP}`}
                          backgroundColor={bg2}
                          focusBorderColor="#E7EBEE"
                          value={password}
                          onChange={(e) => onChange(e)}
                          required
                          fontSize={{ base: "14px", md: "16px" }}
                        />
                      </InputGroup>
                    </FormControl>
                  </Stack>
                  <HStack justify="space-between">
                    <Checkbox
                      isChecked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                      colorScheme="blackAlpha"
                      size="md"
                      iconColor="white"
                      borderColor="black"
                    >
                      <Text color="black" fontSize={{ base: "14px", md: "16px" }}>
                        Remember me
                      </Text>
                    </Checkbox>
                    <Link
                      variant="text"
                      color={grayScales}
                      fontSize={{ base: "14px", md: "16px" }}
                      fontWeight="500"
                      to="/reset-password"
                      as={RouterLink}
                    >
                      Forgot password?
                    </Link>
                  </HStack>
                  <Stack spacing="6">
                    <Button
                      backgroundColor="#2D2D2D"
                      type="submit"
                      borderRadius="13px"
                      color="#F7FAFC"
                      colorScheme="gray"
                      fontSize={{ base: "16px", md: "18px" }}
                      py={6}
                    >
                      Sign in
                    </Button>
                    <HStack>
                      <Divider />
                      <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted" fontSize={{ base: "14px", md: "16px" }}>
                        OR
                      </Text>
                      <Divider />
                    </HStack>
                    <HStack justify="center">
                      <Text color="#7F989A" fontSize={{ base: "14px", md: "16px" }}>Don't have an account?</Text>
                      <Link as={RouterLink} to="/signup" color={grayScales} fontSize={{ base: "14px", md: "16px" }}>
                        Create now
                      </Link>
                    </HStack>
                    {loginError && (
                      <FailedLoginModal
                        isOpen={loginError}
                        onClose={() => setLoginError(false)}
                        title="Login not successful"
                        message="Your email or password are incorrect."
                      />
                    )}
                  </Stack>
                </Stack>
              </form>
            </Box>
          </Stack>
        </Container>
      </Flex>
    </>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps, { login })(Login);
