import React, {useEffect, useState} from 'react'
import article1 from '../assets/article1.svg'
import article2 from '../assets/article2.svg'
import article3 from '../assets/article3.svg'
import article4 from '../assets/article5.svg'
import article5 from '../assets/article6.svg'
import {Flex, Box, Button, Text} from '@chakra-ui/react'
import ArticleClient from './ArticleClient'
import {useDispatch, useSelector} from 'react-redux'
import {TbExclamationCircle, TbLock} from 'react-icons/tb'
import {useNavigate} from 'react-router-dom'
import {setCart, getCart} from '../actions/cart'

const MokeUpArtciles = ({id, type}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const grayScales = useSelector(state => state.themeReducer.grayScales)
    const backgroundColor1 = useSelector(state => state.themeReducer.backgroundColor1)
    const [scroll, setScroll] = useState(0)
    window.addEventListener("scroll", () => {
        setScroll(window.scrollY)
    })
    const handleCheckOut = () => {
        dispatch(setCart(id, "monthly"))
        navigate("/Checkout")
    }

    return (
        <>
            <Box position="absolute" w="100%" height="100%" zIndex={2} backdropFilter="blur(10px)" display="flex">
                <Box w={{base: "95%", md: "75%", lg: "55%"}} bgColor={backgroundColor1}
                     boxShadow="0 4px 20px rgba(0, 0, 0, 0.5)" padding={{base: "15px", md: '20px', lg: "25px"}}
                     borderRadius="10px" position="absolute" left="50%" top={`calc(${scroll}px + 150px)`}
                     transform="translateX(-50%)" textAlign="center" transition="ease 0s">
                    <Box ml="calc(50% - 15px)" mb="15px">
                        {
                            type === "NotSubscribed" ?
                                <TbLock fontSize="30px" color='red'/>
                                :
                                <TbExclamationCircle fontSize="30px" color='red'/>
                        }
                    </Box>
                    <Text color={grayScales} fontSize={{base: "17px", md: "18px", lg: "20px"}} mb="20px"
                          fontWeight="500">
                        {
                            type === "NotSubscribed" ?
                                "You are not subscribed to our XCAPITAL ARTICLES Plan"
                                :
                                "This products is not available for the moment"
                        }
                    </Text>
                    <Button
                        color={grayScales === "black" ? "white" : "black"}
                        onClick={() => {
                            type === "NotSubscribed" ? handleCheckOut() : navigate("/x-capital/terminal/client/solutions")
                        }}
                        bgColor={grayScales}
                    >
                        {type === "NotSubscribed" ? 'Get Access' : 'Explore Products'}
                    </Button>
                </Box>
            </Box>
            <Flex w={{base: "95%", lg: "85%"}} ml={{base: "2.5%", lg: "7.5%"}} mt="25px" wrap="wrap" gap="20px"
                  position="relative">
                <ArticleClient
                    date={new Date()}
                    img={article1}
                    title="xxxxxxxx"
                    paragraph={["xxx", "xxx", "xxxx", "xxxxx"]}
                />
                <ArticleClient
                    date={new Date()}
                    img={article2}
                    title="xxxxxxxx"
                    paragraph={["xxx", "xxx", "xxxx", "xxxxx"]}
                />
                <ArticleClient
                    date={new Date()}
                    img={article3}
                    title="xxxxxxxx"
                    paragraph={["xxx", "xxx", "xxxx", "xxxxx"]}
                />
                <ArticleClient
                    date={new Date()}
                    img={article4}
                    title="xxxxxxxx"
                    paragraph={["xxx", "xxx", "xxxx", "xxxxx"]}
                />
                <ArticleClient
                    date={new Date()}
                    img={article5}
                    title="xxxxxxxx"
                    paragraph={["xxx", "xxx", "xxxx", "xxxxx"]}
                />
            </Flex>
        </>
    )
}

export default MokeUpArtciles