import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Box,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { createTargetSale, updateTargetSale } from "../actions/targetSalesActions";
import { getProducts } from '../actions/product';

const AddTargetSaleModal = ({ isOpen, onClose, type, initialData }) => {
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [sales_targeted, setSalesTargeted] = useState('');
    const [productId, setProductId] = useState('');
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();

    const products = useSelector(state => state.productReducer.products);

    const months = [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' }
    ];

    useEffect(() => {
        dispatch(getProducts());
    }, [dispatch]);

    useEffect(() => {
        if (isOpen) {
            if (type === 'update' && initialData) {
                setMonth(initialData.month);
                setYear(initialData.year);
                setSalesTargeted(initialData.sales_targeted);
                setProductId(initialData.product?.id || '');
            } else {
                // Clear the form when opening for a new entry
                setMonth('');
                setYear('');
                setSalesTargeted('');
                setProductId('');
            }
        }
    }, [isOpen, type, initialData]);

    const validateForm = () => {
        const newErrors = {};
        if (!month) newErrors.month = "Month is required";
        if (!year) newErrors.year = "Year is required";
        else if (isNaN(year) || year < 1900 || year > 2100) newErrors.year = "Please enter a valid year";
        if (!sales_targeted) newErrors.sales_targeted = "Target amount is required";
        else if (isNaN(sales_targeted) || sales_targeted <= 0) newErrors.sales_targeted = "Please enter a valid target amount";
        if (!productId) newErrors.product = "Product is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleAddOrUpdateTargetSale = async () => {
        if (!validateForm()) return;

        const formData = {
            month: parseInt(month, 10),
            year: parseInt(year, 10),
            sales_targeted: parseFloat(sales_targeted),
            product: parseInt(productId, 10),  // Send product ID directly
        };

        try {
            if (type === 'update' && initialData) {
                await dispatch(updateTargetSale(initialData.id, formData));
            } else {
                await dispatch(createTargetSale(formData));
            }
            handleClose();
        } catch (error) {
            if (error.response && error.response.data) {
                setErrors(error.response.data);
            } else {
                setErrors({ general: 'An unexpected error occurred' });
            }
        }
    };

    const handleClose = () => {
        setErrors({});
        setMonth('');
        setYear('');
        setSalesTargeted('');
        setProductId('');
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{type === 'update' ? 'Update Target Sale' : 'Add New Target Sale'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl id="product" isRequired isInvalid={Boolean(errors.product)} mt={4}>
                        <FormLabel>Product</FormLabel>
                        <Select
                            value={productId}
                            onChange={(e) => setProductId(e.target.value)}
                            placeholder="Select product"
                            borderColor={errors.product ? "red.500" : undefined}
                        >
                            {products.map((product) => (
                                <option key={product.id} value={product.id}>{product.title}</option>
                            ))}
                        </Select>
                        {errors.product && (
                            <Box color="red.500" fontSize="sm" mt={1}>
                                {errors.product}
                            </Box>
                        )}
                    </FormControl>

                    <FormControl id="month" isRequired isInvalid={Boolean(errors.month)} mt={4}>
                        <FormLabel>Month</FormLabel>
                        <Select
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                            placeholder="Select month"
                            borderColor={errors.month ? "red.500" : undefined}
                        >
                            {months.map((m) => (
                                <option key={m.value} value={m.value}>{m.label}</option>
                            ))}
                        </Select>
                        {errors.month && (
                            <Box color="red.500" fontSize="sm" mt={1}>
                                {errors.month}
                            </Box>
                        )}
                    </FormControl>

                    <FormControl id="year" isRequired isInvalid={Boolean(errors.year)} mt={4}>
                        <FormLabel>Year</FormLabel>
                        <Input
                            type="number"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            placeholder="Enter year"
                            borderColor={errors.year ? "red.500" : undefined}
                        />
                        {errors.year && (
                            <Box color="red.500" fontSize="sm" mt={1}>
                                {errors.year}
                            </Box>
                        )}
                    </FormControl>

                    <FormControl id="sales_targeted" isRequired isInvalid={Boolean(errors.sales_targeted)} mt={4}>
                        <FormLabel>Target Amount</FormLabel>
                        <Input
                            type="number"
                            value={sales_targeted}
                            onChange={(e) => setSalesTargeted(e.target.value)}
                            placeholder="Enter target amount"
                            borderColor={errors.sales_targeted ? "red.500" : undefined}
                        />
                        {errors.sales_targeted && (
                            <Box color="red.500" fontSize="sm" mt={1}>
                                {errors.sales_targeted}
                            </Box>
                        )}
                    </FormControl>

                    {errors.general && (
                        <Box color="red.500" fontSize="sm" mt={4}>
                            {errors.general}
                        </Box>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={handleAddOrUpdateTargetSale}
                        bg="#001A72"
                        color="#FFFFFF"
                        _hover={{ bg: "#001A72" }}
                        ml={3} mr={3}
                    >
                        {type === 'update' ? 'Update' : 'Save'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddTargetSaleModal;