import React, { useState, useEffect } from "react";
import {
    Box,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
    HStack,
    Text,
    Flex,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Spinner,
    Stack,
} from "@chakra-ui/react";
import {
    FiPlus,
    FiSearch,
    FiTrash2,
    FiChevronDown,
    FiArrowLeft,
    FiArrowRight,
    FiEdit,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, getProducts, updateProduct } from "../../actions/product";
import { format } from "date-fns";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import AddProductModal from "../../components/AddProductModal";
import { useNavigate } from "react-router-dom";
import { getUserById } from "../../actions/user";
import fetchAllProducts from "../../services/api/productAPI/fetchAllProducts";
export default function AdminProductsPage() {
    const bg = useSelector((state) => state.themeReducer.backgroundColor1);
    const bg2 = useSelector((state) => state.themeReducer.backgroundColor2);
    const colorH = useSelector((state) => state.themeReducer.color1);
    const colorP = useSelector((state) => state.themeReducer.color2);
    const colorAdminP = useSelector((state) => state.themeReducer.colorAdmin2);
    const grayScales = useSelector((state) => state.themeReducer.grayScales);
    const blueAdmin = useSelector((state) => state.themeReducer.blueAdmin);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    //const products = useSelector((state) => state.productReducer.products);
    const loading = useSelector((state) => state.productReducer.loading);
    const error = useSelector((state) => state.productReducer.error);

    const [currentPage, setCurrentPage] = useState(0);
    const [productsPerPage, setProductsPerPage] = useState(5);
    const [sortOption, setSortOption] = useState("title");
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);
    const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
    const [isUpdateProductModalOpen, setIsUpdateProductModalOpen] = useState(false);
    const [productToUpdate, setProductToUpdate] = useState(null);
    const [userNames, setUserNames] = useState({});
    const [products,setProducts]=useState([])
    const [updated,setUpdated]=useState(false)

    useEffect(() => {
        dispatch(getProducts())
            .then((data) => {
                fetchUserNames(data);
            })
            .catch((error) => {
                console.error("Error fetching products:", error);
                if (error.response && error.response.status === 403) {
                    navigate("/Not-Access");
                } else if (error.response && error.response.status === 401) {
                    navigate("/login");
                }
            });
    }, [dispatch, navigate]);
    useEffect(()=>{
        const setProds=async()=>{
            setProducts(await fetchAllProducts())
        }
        setProds()
    },[updated])

    const fetchUserNames = async (products) => {
        const userIds = [...new Set(products.map((product) => product.created_by))];
        const userNamesMap = {};
        for (const id of userIds) {
            try {
                const user = await dispatch(getUserById(id));
                userNamesMap[id] = `${user.first_name} ${user.last_name}`;
            } catch (error) {
                console.error(`Error fetching user with ID ${id}:`, error);
            }
        }
        setUserNames(userNamesMap);
    };

    useEffect(() => {
        let filtered = filterProducts(products, searchTerm);
        setFilteredProducts(sortProducts(filtered, sortOption));
        setCurrentPage(0);
    }, [products, searchTerm, sortOption]);

    const offset = currentPage * productsPerPage;
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    const sortProducts = (products, option) => {
        return [...products].sort((a, b) => {
            const aValue = a[option];
            const bValue = b[option];
            if (aValue < bValue) {
                return -1;
            }
            if (aValue > bValue) {
                return 1;
            }
            return 0;
        });
    };

    const filterProducts = (products, term) => {
        if (!term) return products;
        return products.filter((product) => {
            const productName = product.title.toLowerCase();
            const createdBy = userNames[product.created_by]
                ? userNames[product.created_by].toLowerCase()
                : "";
            const createdAt = product.created_at
                ? format(new Date(product.created_at), "dd MMM yyyy").toLowerCase()
                : "";
            const updatedAt = product.updated_at
                ? format(new Date(product.updated_at), "dd MMM yyyy").toLowerCase()
                : "";
            const monthlyPrice = product.monthlyPrice.toString().toLowerCase();
            const yearlyPrice = product.yearlyPrice.toString().toLowerCase();

            return (
                productName.includes(term.toLowerCase()) ||
                createdBy.includes(term.toLowerCase()) ||
                createdAt.includes(term.toLowerCase()) ||
                updatedAt.includes(term.toLowerCase()) ||
                monthlyPrice.includes(term.toLowerCase()) ||
                yearlyPrice.includes(term.toLowerCase())
            );
        });
    };

    const handleDeleteClick = (product) => {
        setProductToDelete(product);
        setIsModalOpen(true);
    };

    const handleDeleteConfirm = () => {
        dispatch(deleteProduct(productToDelete.id))
            .then(() => {
                setUpdated(prev=>!prev)
                dispatch(getProducts())
                    .then((data) => {
                        setIsModalOpen(false);
                        setProductToDelete(null);
                    })
                    .catch((error) => console.error("Error fetching products:", error));
            })
            .catch((error) => console.error("Error deleting product:", error));
    };

    const currentProducts = filteredProducts.slice(offset, offset + productsPerPage);

    const handleUpdateClick = (product) => {
        setProductToUpdate(product);
        setIsUpdateProductModalOpen(true);
    };
    const handleAddClick=(product)=>{
        product.is_active=true
        const formData = new FormData();
        formData.append("title", product.title);
        formData.append("monthlyPrice", product.monthlyPrice);
        formData.append("yearlyPrice", product.yearlyPrice);
        formData.append("description", product.description);
        formData.append("total_subs", product.total_subs);
        formData.append("created_by", product.created_by);
        formData.append("is_active", true);
        dispatch(updateProduct(product.id,formData))
        setUpdated(prev=>!prev)
    }
    return (
        <Box minH="100vh" bg={bg}>
            <Box ml={{ base: 0, md: 60 }} p={4}>
                <Heading fontSize="24px" mb={4} color={blueAdmin}>
                    Products
                </Heading>
                <HStack mb={4}>
                    <InputGroup width={{ base: "70%", lg: "80%" }}>
                        <InputLeftElement pointerEvents="none">
                            <FiSearch color="gray" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search products"
                            bg={bg2}
                            borderRadius="16px"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            _focus={{ boxShadow: "none" }}
                        />
                    </InputGroup>
                    <HStack spacing={0} width={{ base: "25%", lg: "20%" }} padding="12px 12px 12px 20px">
                        <Text color={colorH} fontSize={{ base: "12px", md: "13px", lg: "15px" }} fontWeight="600">
                            Sort by
                        </Text>
                        <Menu>
                            <MenuButton
                                as={Button}
                                rightIcon={<FiChevronDown color={grayScales} />}
                                bg={bg}
                                _hover={{ bg: { bg } }}
                                _active={{ bg: { bg } }}
                                _focus={{ boxShadow: "none" }}
                            />
                            <MenuList>
                                <MenuItem onClick={() => setSortOption("title")}>Product Name</MenuItem>
                                <MenuItem onClick={() => setSortOption("monthlyPrice")}>Monthly Price</MenuItem>
                                <MenuItem onClick={() => setSortOption("yearlyPrice")}>Yearly Price</MenuItem>
                                <MenuItem onClick={() => setSortOption("created_at")}>Create Date</MenuItem>
                                <MenuItem onClick={() => setSortOption("updated_at")}>Update Date</MenuItem>
                                <MenuItem onClick={() => setSortOption("created_by")}>Created By</MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>
                </HStack>
                <Flex mb={4} justifyContent="flex-end">
                    <Button
                        rightIcon={<FiPlus />}
                        color="#FFFFFF"
                        mr="30px"
                        backgroundColor="#001A72"
                        _hover={{ backgroundColor: "#001A72" }}
                        _focus={{ backgroundColor: "#001A72" }}
                        onClick={() => setIsAddProductModalOpen(true)}
                        fontSize={{ base: "14px", md: "18px" }}
                        w={{ base: "100%", lg: "fit-content" }}
                    >
                        Add product
                    </Button>
                </Flex>
                <Box overflowX="auto" bg={bg2} borderRadius="16px" border="solid 1px #EFF4FA" p={4}>
                    <Text color={colorH} fontSize="18px" fontWeight="bold" mb="10px" flex="1">
                        List of products
                    </Text>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th color={colorAdminP}></Th>
                                <Th color={colorAdminP}>Product Name</Th>
                                <Th color={colorAdminP}>Monthly Price</Th>
                                <Th color={colorAdminP}>Yearly Price</Th>
                                <Th color={colorAdminP}>Created At</Th>
                                <Th color={colorAdminP}>Updated At</Th>
                                <Th color={colorAdminP}>Created By</Th>
                                <Th color={colorAdminP}>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {loading ? (
                                <Tr>
                                    <Td colSpan={8}>
                                        <Flex justifyContent="center" alignItems="center" h="200px">
                                            <Spinner size="xl" />
                                        </Flex>
                                    </Td>
                                </Tr>
                            ) : error ? (
                                <Tr>
                                    <Td colSpan={8}>
                                        <Flex justifyContent="center" alignItems="center" h="200px">
                                            <Text color="red.500">{error.message}</Text>
                                        </Flex>
                                    </Td>
                                </Tr>
                            ) : (
                                currentProducts.map((product) => (
                                    <Tr key={product.id} >
                                        <Td></Td>
                                        <Td fontSize={{ base: "13px", md: "14px", lg: "16px" }} color={colorP}>
                                            {product.title}
                                        </Td>
                                        <Td fontSize={{ base: "13px", md: "14px", lg: "16px" }} color={colorP}>
                                            {product.monthlyPrice}
                                        </Td>
                                        <Td fontSize={{ base: "13px", md: "14px", lg: "16px" }} color={colorP}>
                                            {product.yearlyPrice}
                                        </Td>
                                        <Td fontSize={{ base: "13px", md: "14px", lg: "16px" }} color={colorP}>
                                            {product.created_at
                                                ? format(new Date(product.created_at), "dd MMM yyyy")
                                                : ""}
                                        </Td>
                                        <Td fontSize={{ base: "13px", md: "14px", lg: "16px" }} color={colorP}>
                                            {product.updated_at
                                                ? format(new Date(product.updated_at), "dd MMM yyyy")
                                                : ""}
                                        </Td>
                                        <Td color={colorP}>{userNames[product.created_by] || "Loading..."}</Td>
                                        <Td>
                                            <HStack spacing={4}>
                                                <Box
                                                    width="30px"
                                                    height="30px"
                                                    borderRadius={2}
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    border={`1px solid ${bg}`}
                                                >
                                                    {product.is_active && <FiTrash2
                                                        color={"red"}
                                                        onClick={() => handleDeleteClick(product)}
                                                    />}
                                                    {!product.is_active && <FiPlus
                                                        color={"green"}
                                                        onClick={() => handleAddClick(product)}
                                                    />}
                                                </Box>
                                                <Box
                                                    width="30px"
                                                    height="30px"
                                                    borderRadius={2}
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    border={`1px solid ${bg}`}
                                                >
                                                    <FiEdit
                                                        color={blueAdmin}
                                                        onClick={() => handleUpdateClick(product)}
                                                    />
                                                </Box>
                                            </HStack>
                                        </Td>
                                    </Tr>
                                ))
                            )}
                        </Tbody>
                    </Table>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" mt="20px" bg={bg} p="10px" borderRadius="8px">
                    <Stack direction="row" spacing={4} alignItems="center">
                        <Text mr="10px" color="#717171">
                            Rows per page:
                        </Text>
                        <select
                            onChange={(e) => {
                                setCurrentPage(0);
                                setProductsPerPage(parseInt(e.target.value, 10));
                            }}
                            value={productsPerPage}
                            style={{
                                background: bg,
                                border: "none",
                                borderRadius: "4px",
                                padding: "5px",
                                marginRight: "10px",
                                color: "#717171",
                            }}
                        >
                            {[5, 10, 15, 20].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                        <Text color="#717171">
                            {offset + 1}–
                            {Math.min(offset + productsPerPage, filteredProducts.length)} of {filteredProducts.length}
                        </Text>
                    </Stack>
                    <Stack direction="row" spacing={4} alignItems="center" ml={10}>
                        <FiArrowLeft
                            onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))}
                            color={currentPage === 0 ? bg : "#717171"}
                            style={{ cursor: currentPage === 0 ? "not-allowed" : "pointer" }}
                        />
                        <FiArrowRight
                            onClick={() =>
                                setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                            }
                            color={currentPage >= totalPages - 1 ? bg : "#717171"}
                            style={{
                                cursor: currentPage >= totalPages - 1 ? "not-allowed" : "pointer",
                            }}
                        />
                    </Stack>
                </Box>
            </Box>

            {productToDelete && (
                <DeleteConfirmationModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onDelete={handleDeleteConfirm}
                    userName={`${productToDelete.title}`}
                    pageName="Product"
                />
            )}
            <AddProductModal isOpen={isAddProductModalOpen} onClose={() => setIsAddProductModalOpen(false)} type="add" />
            <AddProductModal
                isOpen={isUpdateProductModalOpen}
                onClose={() => setIsUpdateProductModalOpen(false)}
                type="update"
                initialData={productToUpdate}
            />
        </Box>
    );
}
