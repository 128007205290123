import { Alert, Box,Button,Heading, useDisclosure,Input, Text, Textarea,Flex,AlertDescription,AlertIcon,AlertTitle } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { sendSupportMsg } from '../services/api/SupportApi'
import { useNavigate } from 'react-router-dom'


const ClientSupport = () => {
    const navigate=useNavigate()
    const {isOpen,onOpen,onClose}=useDisclosure(false)
    const [content,setContent]=useState({subject:"",content:"",is_active:1})
    const [alertDetails,setAlertDetails]=useState({title:"",desc:"",status:"success"})
    const user=useSelector(state=>state.auth.user)
    const handleClick=async()=>{
        if(user){
            if(content.subject=="" || content.msg===""){
                setAlertDetails({title:"Please Fill All Fields",desc:"Do not leave any empty field",status:"warning"})
            }else{
                const serverResponse = await sendSupportMsg({...content,user:user.id},user.email)
                if(serverResponse)
                    setAlertDetails({title:"Email Sent Succesfully",desc:"We will get back to you soon",status:"success"})
                else
                    setAlertDetails({title:"An Error Occurred",desc:"Please try again",status:"error"})
            }
        }else{
            setAlertDetails({title:"No Current User Found",desc:"Please re-login and try in a few moments",status:"warning"})
        }
        setContent({subject:"",content:"",is_active:1})
        onOpen()
        const idT=setTimeout(()=>{
            onClose()
        },5000)
        return ()=>clearTimeout(idT)
    }
    const handleViewMore=()=>{
        navigate("/x-capital/terminal/client/support/list")
    }
  return (
    <Box ml={{base:0,md:60}} minH={"85vh"}>
        <Box mt="25px" textAlign={"center"}>
            <Heading as="h1" fontSize={{base:'17px',md:"20px",lg:"24px"}}>Having A Problem , Tell Us About It</Heading>
            <Text mt='10px' fontSize={{base:'15px',md:"17px",lg:"19px"}}>X-Capital Support</Text>
        </Box>
        <Box 
            mt={"30px"}
            w={{base:'95%',md:"80%",lg:"60%"}}
            ml={{base:'2.5%',md:"5%",lg:"20%"}}
        >
            <Input
                type='text'
                mb={"20px"}
                placeholder='Subject'
                name='subject'
                value={content.subject}
                onChange={(e)=>{setContent((prev)=>{ return {...prev,["subject"]:e.target.value}})}}
            />
            <Textarea
                type='text'
                mb={"20px"}
                placeholder='Message'
                name='msg'
                value={content.content}
                onChange={(e)=>{setContent((prev)=>{ return {...prev,["content"]:e.target.value}})}}
            />
            <Button
                onClick={handleClick}
                bgColor="#000042"
                color="white"
                _hover={{
                bgColor:"#000042",
                color:"white"
                }}
                w={"100%"}
                textAlign={"center"}
                fontSize={{base:'15px',md:"18px",lg:"21px"}}
            >
                Send
            </Button>
            <Button
                mt={"15px"}
                onClick={handleViewMore}
                color="#000042"
                _hover={{
                bgColor:"#000042",
                color:"white"
                }}
                w={"100%"}
                textAlign={"center"}
                fontSize={{base:'15px',md:"17px",lg:"19px"}}
            >
                View History
            </Button>
        </Box>
        <Alert
            opacity={isOpen?"1":"0"}
            w="fit-content"
            position="absolute"
            bottom={"0"}
            left="50%"
            transform="translateX(-50%)"
            status={alertDetails.status}
            borderRadius="5px"
            transition="ease .5s"
            padding="10px"
            display="flex" alignItems="center" gap="10px"
        >
            <AlertIcon/>
            <Flex direction="column">
                <AlertTitle fontSize={{base:"14px",lg:"16px"}}>{alertDetails.title}</AlertTitle>
                <AlertDescription fontSize={{base:"12px",lg:"14px"}}>{alertDetails.desc}</AlertDescription>
            </Flex>
        </Alert>
    </Box>
  )
}

export default ClientSupport