import {
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAIL,
  ADD_OFFER_SUCCESS,
  ADD_OFFER_FAIL,
  DELETE_OFFER_SUCCESS,
  DELETE_OFFER_FAIL,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER_FAIL,
  GET_OFFER_DETAILS_SUCCESS,
  GET_OFFER_DETAILS_FAIL,
  ADD_OFFER_DETAIL_SUCCESS,
  ADD_OFFER_DETAIL_FAIL,
  DELETE_OFFER_DETAIL_SUCCESS,
  DELETE_OFFER_DETAIL_FAIL,
  UPDATE_OFFER_DETAIL_SUCCESS,
  UPDATE_OFFER_DETAIL_FAIL,
  GET_OFFER_BY_ID_SUCCESS,
  GET_OFFER_BY_ID_FAIL,
  ADD_MANAGER_REVIEW_SUCCESS,
  ADD_MANAGER_REVIEW_FAIL,
  UPDATE_MANAGER_REVIEW_SUCCESS,
  UPDATE_MANAGER_REVIEW_FAIL,
  ADD_SALES_REVIEW_SUCCESS,
  ADD_SALES_REVIEW_FAIL,
  UPDATE_SALES_REVIEW_SUCCESS,
  UPDATE_SALES_REVIEW_FAIL,
  GET_MANAGER_REVIEW_BY_OFFER_SUCCESS,
  GET_MANAGER_REVIEW_BY_OFFER_FAIL,
  GET_SALES_REVIEW_BY_OFFER_SUCCESS,
  GET_SALES_REVIEW_BY_OFFER_FAIL
} from "../actions/types";


const initialState = {
  offers: [],
  offerDetails: [],
  managerReviews: [],
  salesReviews: [],
  loading: true,
  error: null,
};

const offerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFERS_SUCCESS:
      return {
        ...state,
        offers: action.payload,
        loading: false,
        error: null,
      };
    case GET_OFFERS_FAIL:
      return {
        ...state,
        offers: [],
        loading: false,
        error: action.payload,
      };
    case GET_OFFER_BY_ID_SUCCESS:
      return {
        ...state,
        selectedOffer: action.payload,
        loading: false,
        error: null,
      };
    case GET_OFFER_BY_ID_FAIL:
      return {
        ...state,
        selectedOffer: null,
        loading: false,
        error: action.payload,
      };
    case ADD_OFFER_SUCCESS:
      return {
        ...state,
        offers: [...state.offers, action.payload],
        loading: false,
        error: null,
      };
    case ADD_OFFER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_OFFER_SUCCESS:
      return {
        ...state,
        offers: state.offers.filter((offer) => offer.id !== action.payload),
        loading: false,
        error: null,
      };
    case DELETE_OFFER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_OFFER_SUCCESS:
      return {
        ...state,
        offers: state.offers.map((offer) =>
          offer.id === action.payload.id ? action.payload : offer
        ),
        loading: false,
        error: null,
      };
    case UPDATE_OFFER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_OFFER_DETAILS_SUCCESS:
      return {
        ...state,
        offerDetails: action.payload,
        loading: false,
        error: null,
      };
    case GET_OFFER_DETAILS_FAIL:
      return {
        ...state,
        offerDetails: [],
        loading: false,
        error: action.payload,
      };
    case ADD_OFFER_DETAIL_SUCCESS:
      return {
        ...state,
        offerDetails: [...state.offerDetails, action.payload],
        loading: false,
        error: null,
      };
    case ADD_OFFER_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_OFFER_DETAIL_SUCCESS:
      return {
        ...state,
        offerDetails: state.offerDetails.filter((detail) => detail.id !== action.payload),
        loading: false,
        error: null,
      };
    case DELETE_OFFER_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_OFFER_DETAIL_SUCCESS:
      return {
        ...state,
        offerDetails: state.offerDetails.map((detail) =>
          detail.id === action.payload.id ? action.payload : detail
        ),
        loading: false,
        error: null,
      };
    case UPDATE_OFFER_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_MANAGER_REVIEW_SUCCESS:
      return {
        ...state,
        managerReviews: [...state.managerReviews, action.payload],
        loading: false,
        error: null,
      };
    case ADD_MANAGER_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_MANAGER_REVIEW_SUCCESS:
      return {
        ...state,
        managerReviews: state.managerReviews.map((review) =>
          review.id === action.payload.id ? action.payload : review
        ),
        loading: false,
        error: null,
      };
    case UPDATE_MANAGER_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_SALES_REVIEW_SUCCESS:
      return {
        ...state,
        salesReviews: [...state.salesReviews, action.payload],
        loading: false,
        error: null,
      };
    case ADD_SALES_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_SALES_REVIEW_SUCCESS:
      return {
        ...state,
        salesReviews: state.salesReviews.map((review) =>
          review.id === action.payload.id ? action.payload : review
        ),
        loading: false,
        error: null,
      };
    case UPDATE_SALES_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case GET_MANAGER_REVIEW_BY_OFFER_SUCCESS:
        return {
          ...state,
          managerReviews: state.managerReviews.map(review =>
            review.offer === action.payload.offer ? action.payload : review
          ),
          loading: false,
          error: null,
        };
      case GET_MANAGER_REVIEW_BY_OFFER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case GET_SALES_REVIEW_BY_OFFER_SUCCESS:
        return {
          ...state,
          salesReviews: state.salesReviews.map(review =>
            review.offer === action.payload.offer ? action.payload : review
          ),
          loading: false,
          error: null,
        };
      case GET_SALES_REVIEW_BY_OFFER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default offerReducer;