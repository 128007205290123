import { Box, Grid, GridItem, Heading } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import ProductTable from "../../components/manager/ProductTable";
import OverviewChart from "../../components/manager/OverviewChart";
import BarChartComponet from "../../components/manager/BarChartComponent";
import OfferChart from "../../components/manager/OfferChart";
import SalesChart from "../../components/manager/SalesChart";

const ManagerDashboard = () => {
  const bg = useSelector((state) => state.themeReducer.backgroundColor1);

  return (
    <Box
      paddingTop="50px"
      paddingBottom="50px"
      backgroundColor={bg}
      minH="90vh"
      ml={{ base: 0, md: 60 }}
      position="relative"
    >
      <Box
        w={{ base: "100%", md: "90%", lg: "95%" }}
        ml={{ base: 0, md: "3%", lg: "3%" }}
        mb="35px"
      >
        <Heading as="h1" mb="10px" fontSize={{ base: "25px", sm: "28px" }}>
          Dashboard
        </Heading>
        <Grid
          templateColumns={{ base: "1fr", md: "1fr", lg: "repeat(3, 1fr)" }}
          gap={8}
          mt="20px"
          px={{ base: "10px", md: "20px" }}
        >
          <GridItem colSpan={{ base: 1, md: 1, lg: 3 }}>
            <Box
              height={{ base: "auto", md: "400px" }}
              overflow="hidden"
              borderRadius="md"
              boxShadow="md"
              pt="10px"
            >
              <OverviewChart />
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 1, lg: 2 }}>
            <Box
              height={{ base: "auto", md: "400px" }}
              overflow="hidden"
              borderRadius="md"
              boxShadow="md"
            >
              <ProductTable />
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 1, lg: 1 }}>
            <Box
              height={{ base: "auto", md: "400px" }}
              overflow="hidden"
              borderRadius="md"
              boxShadow="md"
            >
              <BarChartComponet />
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 1, lg: 2 }}>
            <Box
              height={{ base: "auto", md: "300px" }}
              overflow="hidden"
              borderRadius="xl"
              boxShadow="md"
              mt={{ base: "20px", md: "40px" }}
            >
              <OfferChart />
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 1, lg: 1 }}>
            <Box
              height={{ base: "auto", md: "360px" }}
              overflow="hidden"
              borderRadius="xl"
              boxShadow="md"
            >
              <SalesChart />
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};

export default ManagerDashboard;
