import {
  Box,
  Flex,
  Text,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
  Divider,
  Button,
  ButtonGroup,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import fetchArticlesTeasers from "../services/api/artilcesAPI/fetchArticleTeaser";
import fetchArticlesCategories from "../services/api/artilcesAPI/fetchArticleCategory";
import { useSelector } from "react-redux";


export default function Articles() {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory,setSelectedCategory]=useState("all")
  const [categoryToShow,setCategoryToShow]=useState([])
  const [artcilesToShow,setArticlesToShow]=useState([])
  const bgColor=useSelector(state=>state.themeReducer.backgroundColor1)
  const grayScales=useSelector(state=>state.themeReducer.grayScales)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const articlesResult = await fetchArticlesTeasers();
        setArticles(articlesResult);
        const categoriesResult = await fetchArticlesCategories();
        setCategories(categoriesResult);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

    useEffect(()=>{
      if(selectedCategory==="all")
          setCategoryToShow(articles)
      else
          setCategoryToShow(articles.filter(article=>article.category.name===selectedCategory))
  },[selectedCategory,loading])
  useEffect(()=>{
    setArticlesToShow(categoryToShow)
},[categoryToShow])

  const handleReadMore = (id) => {
    window.location.href="/article/"+id
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate}`;
  };
    const choseColor=(cat)=>{
    switch(cat){
      case "Finance":
        return "blue"
      case "Fashion":
        return "red"
      case "Branding":
        return "green"
      case "Technology":
        return "yellow"
      case "Travel":
        return "purple"
      default :
        return "black"
    }
  }

  if (loading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <>
    <Box padding={"40px"} minHeight="100vh">
      <Heading padding={"24px 0"} fontSize={"40px"}>
        Popular topics
      </Heading>
      <Flex gap={"24px"} padding={"16px 0"}>
        <Text cursor={"pointer"} key="all" onClick={()=>setSelectedCategory("all")}  color={selectedCategory==="all"?"orange":grayScales}  fontWeight={"600"} fontSize={"20px"}>
          All
        </Text>
        {categories.map((category) => (
            <Box key={category.id} onClick={(e)=>setSelectedCategory(category.name)} cursor="pointer">
              <Text fontWeight={"600"} color={selectedCategory===category.name?"orange":grayScales} fontSize={{base:"16px",md:"18px",lg:"20px"}}>
                  {category.name}
              </Text>
            </Box>
        ))}
      </Flex>
      <Flex
        flexWrap={"wrap"}
        gap={"24px"}
        padding={"24px 0"}
      >
        {
          artcilesToShow.map((article) => (
          <Box key={article.id} flex="0 0 300px"  bgColor={bgColor} paddingBottom={"10px"} cursor={"pointer"} onClick={()=>handleReadMore(article.id)}>
            <Image
              src={article.image_cover_url}
              alt={article.title}
              borderRadius="2px"
              h="172px"
              objectFit="cover"
              w="100%"
            />
            <Box padding="15px">
              <Text fontWeight={"500"} fontSize={"18px"} color={choseColor(article.category.name)}>
                  {article.category.name}
              </Text>
              <Stack mt="4">
                <Heading fontSize="22px">{article.title}</Heading>
              </Stack>
              <Text mt="20px" fontWeight={"500"} color={"gray"}>{formatDate(article.created_at)}</Text>
            </Box>
          </Box>
        ))}
      </Flex>
    </Box>
    </>
  );
}
