import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import SidebarWithHeader from '../components/SideBar'
import { useSelector, useDispatch } from 'react-redux';
import { Spinner, Flex, Box } from '@chakra-ui/react';
import { load_user, refreshToken, isTokenExpired } from '../actions/auth';

const AdminLayout = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const role = useSelector(state => state.auth.user?.role)
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const access = useSelector(state => state.auth.access)

  useEffect(() => {
    const initializeAuth = async () => {
      if (access && isTokenExpired(access)) {
        await dispatch(refreshToken());
      }
      dispatch(load_user());
    };

    initializeAuth();

    const refreshInterval = setInterval(() => {
      if (access && isTokenExpired(access)) {
        dispatch(refreshToken());
      }
    });

    return () => clearInterval(refreshInterval);

  }, [dispatch, access]);

  useEffect(() => {
    if (role && role !== "Admin")
      navigate("/Not-Access")
    if (!isAuthenticated)
      navigate("/login")
  }, [role, isAuthenticated, navigate])


  useEffect(() => {
      const rememberMeSession = sessionStorage.getItem('rememberMe');
      const rememberMeLocalStorage = localStorage.getItem('rememberMe');
      if(rememberMeLocalStorage === 'true' && rememberMeSession !== 'true'){
          sessionStorage.setItem('rememberMe', 'true');
      }else if(rememberMeSession !== 'true') {
        localStorage.clear();
      }
    });

  return (
    <Box>
      {
        role === "Admin" ?
          <>
            <SidebarWithHeader />
            <Outlet />
          </>
          :
          <Flex height="100vh" align="center" justify="space-around"><Spinner width="50px" height="50px" /></Flex>
      }
    </Box>
  )
}

export default AdminLayout