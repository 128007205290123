import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Input,
  FormControl,
  FormLabel,
  Tag,
  Box,
  SimpleGrid,
  Flex,
  Divider,
  Icon,
  Text,
} from "@chakra-ui/react";
import { FiDownload } from "react-icons/fi";

const API_URL = process.env.REACT_APP_API_URL;

const downloadFile = (fileUrl, fileName) => {
  fetch(fileUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
};

const CandidateDetailsModal = ({ isOpen, onClose, candidat }) => {
  const {
    first_name,
    last_name,
    email,
    phone,
    skills,
    cv,
    cover_letter,
  } = candidat;

  const skillsArray = Array.isArray(skills) ? skills : skills?.split(',') || [];

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxWidth="800px">
        <ModalHeader textAlign="center">Candidat Details</ModalHeader>
        <Divider />
        <ModalCloseButton />
        <ModalBody mb={4}>
          <Flex direction="column" alignItems="center">
            <FormControl mb={4} width="50%">
              <FormLabel>First Name</FormLabel>
              <Input
                value={first_name}
                isReadOnly
                variant="unstyled"
                bg="#F9F9F9"
                pl={3}
                h="40px"
                borderRadius={10}
              />
            </FormControl>
            <FormControl mb={4} width="50%">
              <FormLabel>Last Name</FormLabel>
              <Input
                value={last_name}
                isReadOnly
                variant="unstyled"
                bg="#F9F9F9"
                pl={3}
                h="40px"
                borderRadius={10}
              />
            </FormControl>
            <FormControl mb={4} width="50%">
              <FormLabel>Email</FormLabel>
              <Input
                value={email}
                isReadOnly
                variant="unstyled"
                bg="#F9F9F9"
                pl={3}
                h="40px"
                borderRadius={10}
              />
            </FormControl>
            <FormControl mb={4} width="50%">
              <FormLabel>Phone</FormLabel>
              <Input
                value={phone}
                isReadOnly
                variant="unstyled"
                bg="#F9F9F9"
                pl={3}
                h="40px"
                borderRadius={10}
              />
            </FormControl>
            <FormControl mb={4} width="50%">
              <FormLabel>Skills</FormLabel>
              <Box
                borderRadius={10}
                p={2}
                minHeight="40px"
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                border="solid 1px #F1F1F1"
              >
                {skillsArray.length > 0 ? (
                  skillsArray.map((skill) => (
                    <Tag key={skill} m={1}>
                      {skill}
                    </Tag>
                  ))
                ) : (
                  <Text>No skills available</Text>
                )}
              </Box>
            </FormControl>
            <SimpleGrid mb={4} columns={2} spacing={4} width="50%">
              <Button
                width="full"
                variant="solid"
                borderRadius={10}
                border="solid 1px #000000"
                backgroundColor="#FFFFFF"
                _hover={{ backgroundColor: "#F5F5F5" }}
                p={0}
                onClick={() => downloadFile(`${API_URL}${cv}`, 'resume.pdf')}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="full"
                  h="full"
                  textAlign="center"
                  p={0}
                  m={0}
                >
                  <Box
                    flex="1"
                    color="#000000"
                    textAlign="center"
                    whiteSpace="normal"
                    p={0}
                    m={0}
                  >
                    Download Resume
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="#171E6C"
                    borderLeft="1px solid #000000"
                    borderRadius="0 10px 10px 0"
                    width="40px"
                    height="40px"
                    p={0}
                    m={0}
                  >
                    <Icon as={FiDownload} color="white" boxSize="24px" />
                  </Box>
                </Box>
              </Button>
              <Button
                width="full"
                variant="solid"
                borderRadius={10}
                border="solid 1px #000000"
                backgroundColor="#FFFFFF"
                _hover={{ backgroundColor: "#F5F5F5" }}
                p={0}
                onClick={() => downloadFile(`${API_URL}${cover_letter}`, 'cover_letter.pdf')}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="full"
                  h="full"
                  textAlign="center"
                  p={0}
                  m={0}
                >
                  <Box
                    flex="1"
                    color="#000000"
                    textAlign="center"
                    whiteSpace="normal"
                    p={0}
                    m={0}
                  >
                    Download Cover Letter
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="#171E6C"
                    width="40px"
                    height="40px"
                    borderLeft="1px solid #000000"
                    borderRadius="0 10px 10px 0"
                    p={0}
                    m={0}
                  >
                    <Icon as={FiDownload} color="white" boxSize="24px" />
                  </Box>
                </Box>
              </Button>
            </SimpleGrid>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CandidateDetailsModal;
