import { Flex, Heading, Button, Text } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import updatePreOrder from "../../services/api/preOrdersAPI/updatePreOrder";

export default function ViewPredOrderCard(props) {
  const handleStatusChange = async (status) => {
    const formData = new FormData();
    formData.append("first_name", props.firstName);
    formData.append("last_name", props.lastName);
    formData.append("email", props.email);
    formData.append("phone_number", props.phoneNumber);
    formData.append("company_id", props.companyId);
    formData.append("product_id", props.productId);
    formData.append("status", status);

    try {
      await updatePreOrder(formData, props.preOrderId);
      window.location.reload(); // Refresh to see the updated status
    } catch (error) {
      console.error("Error updating pre-order status:", error);
    }
  };

  return (
    <Flex
      direction={"column"}
      align={"center"}
      justify={"center"}
      gap={"16px"}
      padding={"20px"}
    >
      <Flex
        bgColor={"#b8c6f5"}
        width={"60px"}
        height={"60px"}
        align={"center"}
        justify={"center"}
        borderRadius={"50%"}
      >
        <EditIcon boxSize={"2em"} color={"#2e4593"} />
      </Flex>
      <Heading fontSize={"28px"}>Pre-Order</Heading>
      <Flex direction={'column'} gap={'8px'} padding={'0 20px'}>
        <Text fontSize={'20px'}>
          <span style={{ fontWeight: '600', marginRight: '4px', fontSize: '20px' }}>Full Name:</span> {props.fullName}
        </Text>
        <Text fontSize={'20px'}>
          <span style={{ fontWeight: '600', marginRight: '4px', fontSize: '20px' }}>Email:</span> {props.email}
        </Text>
        <Text fontSize={'20px'}>
          <span style={{ fontWeight: '600', marginRight: '4px', fontSize: '20px' }}>Phone Number:</span> {props.phoneNumber}
        </Text>
        <Text fontSize={'20px'}>
          <span style={{ fontWeight: '600', marginRight: '4px', fontSize: '20px' }}>Organization:</span> {props.company}
        </Text>
        <Text fontSize={'20px'}>
          <span style={{ fontWeight: '600', marginRight: '4px', fontSize: '20px' }}>Product:</span> {props.product}
        </Text>
        <Text fontSize={'20px'}>
          <span style={{ fontWeight: '600', marginRight: '4px', fontSize: '20px' }}>Demo:</span> {props.demo}
        </Text>
      </Flex>
      <Flex gap={"12px"}>
        <Button
          bg={"none"}
          border={"1px solid black"}
          _hover={"none"}
          onClick={() => handleStatusChange("Rejected")}
        >
          Reject
        </Button>
        <Button
          bgColor={"#001A72"}
          color={"white"}
          _hover={"none"}
          onClick={() => handleStatusChange("Accepted")}
        >
          Accept
        </Button>
      </Flex>
    </Flex>
  );
}
