import React from 'react'
import { Flex, Box, Text,List, ListItem, UnorderedList, Heading } from '@chakra-ui/react'


function RefundPolicy() {
  return (
    <>
       <Flex direction={"column"} p={10} mb="2em">
        <Box mb="2em">
            <Heading size={{base:"lg", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>Refund and Cancellation Policy</Heading>
            <Text mb="1em"><b>Effective Date:</b> July 4, 2024</Text>
            <Text textAlign="left">X CAPITAL ("we," "us," "our") is committed to customer satisfaction. This Refund and Cancellation Policy outlines the conditions under which refunds or cancellations are processed.</Text>
        </Box>

        <Flex direction={"column"} gap={5}>
            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>1. Refunds</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>Refund requests must be made within 7 days of purchase.</ListItem>
                    <ListItem>Refunds are issued to the original payment method.</ListItem>
                    <ListItem>Certain products or services may be non-refundable. Please check the product/service description for details.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>2. Cancellations</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>You may cancel your subscription at any time. Cancellations will take effect at the end of the current billing cycle.</ListItem>
                    <ListItem>To cancel, please contact us at: contact@xcapitalterminal.com or call us at +212 632331441 or +212 632321083.</ListItem>
                </UnorderedList>
            </List>


            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>3. Contact Us</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem listStyleType="none">If you have any questions about our Refund and Cancellation Policy, please contact us at: contact@xcapitalterminal.com or call us at +212 632331441 or +212 632321083.</ListItem>
                </UnorderedList>
            </List>
        </Flex>
      </Flex>
    </>
  )
}

export default RefundPolicy
