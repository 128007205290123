import { Flex, Heading, Text, useMediaQuery, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import SearchBar from "../components/SearchBar";
import ProductCard from "../components/products/ProductCard";
import SuggestedArticles from "../components/suggestedArticles";
import fetchProducts from "../services/api/productAPI/fetchProducts";


export default function Products() {
    const [products, setProducts] = useState([]);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [isLessThan768] = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
        const getProducts = async () => {
          try {
            const result = await fetchProducts();
            setProducts(result);
          } catch (error) {
            console.error("Error fetching articles:", error);
          } finally {
            setLoadingProducts(false);
          }
        };
    
        getProducts();
      }, []);


  return (
    <>
    <Flex direction="column" >
      <Flex
        direction="column"
        bg="#2D2D2D"
        height={isLessThan768 ? "64vh" : "100vh"}
        align="center"
        justify="center"
      >
        <Flex
          width={isLessThan768 ? "84%" : "60%"}
          direction="column"
          gap="35px"
          align="center"
          justify="center"
        >
          <Heading color="#FFFFFF" fontSize={isLessThan768 ? "24px" : "52px"}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            fringilla nunc in molestie feugiat.
          </Heading>
          <Text color="#CECCCC" fontSize={isLessThan768 ? "16px" : "20px"}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            fringilla nunc in molestie feugiat. Nunc auctor consectetur elit,
            quis pulvina. Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Nulla fringilla nunc in molestie feugiat.
          </Text>
          <SearchBar />
        </Flex>
      </Flex>
      <Flex direction="column" align="center" justify="center" padding="40px">
        <Heading color="#2D2D2D" fontSize={isLessThan768 ? "24px" : "44px"}>
          Our Collection Of Products
        </Heading>
        <Flex direction="column" marginTop="40px" gap="60px" align="center">
        {loadingProducts ? (
            <Spinner size="xl" />
          ) : (products.map((product) => 
            <ProductCard
              key={product.id}
              id={product.id}
              title={product.title}
              body={product.description}
              image={product.image_url}
            />
          ))}
        </Flex>
      </Flex>
      <Flex direction="column" align="center" justify="center" gap="40px" padding="40px">
        <Heading fontSize={isLessThan768 ? "28px" : "52px"}>XCAPITAL ARTICLES</Heading>
        <Text width={isLessThan768 ? "" :"80%"}>
          Explore the latest trends, analysis, and insights from the experts at
          X CAPITAL. Our articles cover a wide range of topics, including market
          trends, investment strategies, and industry developments. Whether
          you're a seasoned professional or just starting out, our articles
          provide valuable information to help you navigate the complexities of
          the financial markets and make informed decisions.
        </Text>
      </Flex>
        <SuggestedArticles />
    </Flex>
    </>
  );
}
