import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  useMediaQuery,
  VStack,
  Card,
  CardHeader,
  Tooltip,
  useBreakpointValue,
  Center,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { CheckCircleIcon } from "@chakra-ui/icons";
import SolutionHero from "../../assets/SolutionHero.png";
import SolutionQuestion from "../../components/questions/SolutionQuestions";
import { useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";

const AnimatedFeatureCard = ({ title, description, delay }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const cardWidth = useBreakpointValue({
    base: "90%", // Mobile
    sm: "95%", // Small screens
    md: "85%", // Medium screens
    lg: "1200px", // Large screens and above
  });

  const cardPadding = useBreakpointValue({
    base: "4",
    sm: "6",
  });

  const titleFontSize = useBreakpointValue({
    base: "sm",
    sm: "md",
  });

  const descriptionFontSize = useBreakpointValue({
    base: "xs",
    sm: "sm",
    md: "md",
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.7, delay }}
    >
      <Card width={cardWidth} boxShadow="lg" mx="auto">
        <CardHeader padding={cardPadding}>
          <Flex
            alignItems="flex-start"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Flex alignItems="center" w={"40%"} mb={{ base: 2, sm: 0 }} mr={{ sm: 4 }}>
              <CheckCircleIcon color="green.500" mr="12px" boxSize={6} />
              <Heading size={titleFontSize}>{`${title}:`}</Heading>
            </Flex>
            <Box w={"55%"}>
              <Text fontSize={descriptionFontSize}>{description}</Text>
            </Box>
          </Flex>
        </CardHeader>
      </Card>
    </motion.div>
  );
};

const Intelligence = () => {
  const navigate = useNavigate()
  const [isLargerThan840] = useMediaQuery("(min-width: 840px)"); 

  const bgColor=useSelector(state=>state.themeReducer.backgroundColor1)
  const grayScales=useSelector(state=>state.themeReducer.grayScales)

  const faqList = [
    {
      question: "What is X Capital Intelligence?",
      answer:
        "X Capital Intelligence is an advanced version of X Capital Terminal that includes AI and machine learning features, providing enhanced insights and analytics for investors.",
    },
    {
      question:
        "How does X Capital Intelligence differ from X Capital Terminal?",
      answer:
        "While X Capital Terminal offers real-time data, historical data, and advanced analytics, X Capital Intelligence unlocks additional AI and machine learning capabilities, allowing users to gain deeper insights and more accurate predictive analytics.",
    },
    {
      question: "Who can benefit from using X Capital Intelligence?",
      answer:
        "X Capital Intelligence is ideal for individual investors, financial analysts, and institutional investors who want to leverage advanced AI-driven insights to optimize their investment strategies.",
    },
    {
      question: "What specific AI features does X Capital Intelligence offer?",
      answer:
        "The platform includes features like predictive analytics, machine learning models for trend analysis, and automated decision support tools.",
    },
    {
      question: "Is X Capital Intelligence suitable for beginners?",
      answer:
        "Yes, although it offers advanced tools, the platform is designed to be user-friendly and accessible to investors of all experience levels.",
    },
    {
      question: "How much does X Capital Intelligence cost?",
      answer:
        "Pricing for X Capital Intelligence is tailored based on the needs of the user. Please contact X Capital for a detailed quote.",
    },
    {
      question: "How frequently are AI-driven insights updated?",
      answer:
        "Insights are updated in real-time, ensuring users always have access to the most current and relevant information.",
    },
    {
      question:
        "What kind of customer support is available for X Capital Intelligence?",
      answer:
        "Users have access to comprehensive support, including email support and a knowledge base with tutorials and FAQs tailored to the AI and machine learning features.",
    },
  ];

  return (
    <Box>
      {/* Hero Section */}
      <Box
        height="648px"
        position="relative"
        backgroundImage={`url(${SolutionHero})`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition={
          isLargerThan840 ? "center" : "calc(100% - 70%) bottom"
        }
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="rgba(0, 0, 0, 0.4)"
        />
        <Flex
          position="absolute"
          bottom="15%"
          left="5%"
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-start"
          maxWidth={isLargerThan840 ? "45%" : "80%"}
          color="white"
        >
          <Heading
            as="h1"
            fontSize={isLargerThan840 ? "48px" : "28px"}
            fontWeight="800"
            marginBottom="28px"
          >
            X CAPITAL INTELLIGENCE
          </Heading>
          <Text
            fontSize={isLargerThan840 ? "24px" : "20px"}
            marginBottom="24px"
            maxWidth={isLargerThan840 ? "90%" : "100%"}
          >
            X CAPITAL INTELLIGENCE is the engine driving data-driven
            decision-making. Leveraging cutting-edge AI and machine learning
            algorithms, our intelligence platform analyzes vast datasets to
            uncover hidden trends, identify market anomalies, and generate
            actionable insights. Whether you're looking to optimize your
            investment strategy or gain a competitive edge, X CAPITAL
            INTELLIGENCE equips you with the tools you need to succeed in
            today's dynamic financial landscape.
          </Text>
          <Button
            bg="white"
            color="gray.700"
            width="160px"
            height="40px"
            borderRadius="12px"
            fontSize="20px"
            _hover={{ bg: "gray.100" }}
            onClick={() => navigate('/wait-list')}
          >
            Book a demo
          </Button>
        </Flex>
      </Box>

      {/* Features Section */}
      <Box bg={bgColor} width="full" py="60px">
        <VStack spacing="40px" align="center" maxW="1200px" mx="auto" px={4}>
          <Heading mb="24px">Our Key Features</Heading>
          <AnimatedFeatureCard
            title="AI-Powered Insights"
            description=" Using advanced AI models, this solution predicts trends and identifies emerging opportunities before they become apparent to the general market."
            delay={0.2}
          />
          <AnimatedFeatureCard
            title="Predictive Analytics"
            description="By analyzing massive datasets, the platform provides forward-looking insights that help users anticipate shifts in the market."
            delay={0.2}
          />
          <AnimatedFeatureCard
            title="Customizable Reports"
            description=" Institutions and investors can generate reports tailored to their unique strategies and objectives, making data more actionable."
            delay={0.4}
          />
          <AnimatedFeatureCard
            title="Risk Assessment & Mitigation"
            description=" AI-driven models help assess market risks, offering strategies for minimizing potential losses in uncertain environments."
            delay={0.6}
          />
        </VStack>
      </Box>
      <Center bg={bgColor}>
        <SolutionQuestion faqList={faqList} />
      </Center>
    </Box>
  );
};

export default Intelligence;
