import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  HStack,
  Spinner,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack
} from "@chakra-ui/react";
import {
  FiSearch,
  FiChevronDown,
  FiTrash2,
  FiEdit,
  FiArrowLeft,
  FiArrowRight,
  FiEye,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  getCandidates,
  deleteCandidate,
  getCandidatesByCareer,
} from "../../actions/candidat";
import { getCareerById } from "../../actions/careerActions";
import AddCandidatModal from "../../components/candidates/AddCandidatModal";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import CandidateDetailsModal from "../../components/candidates/CandidateDetailsModal";
import { useLocation } from "react-router-dom";

const AdminCandidatsPage = () => {
  const bg = useSelector((state) => state.themeReducer.backgroundColor1);
  const bg2 = useSelector((state) => state.themeReducer.backgroundColor2);
  const colorH = useSelector((state) => state.themeReducer.color1);
  const colorAdminP = useSelector((state) => state.themeReducer.colorAdmin2);
  const grayScales = useSelector((state) => state.themeReducer.grayScales);
  const blueAdmin = useSelector((state) => state.themeReducer.blueAdmin);

  const location = useLocation();
  const careerId = location.state?.careerId;
  const dispatch = useDispatch();
  const [candidats, setCandidats] = useState([]);
  const [careers, setCareers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCandidats, setFilteredCandidats] = useState([]);
  const [candidatsPerPage, setCandidatsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginatedCandidats, setPaginatedCandidats] = useState([]);
  const [sortOption, setSortOption] = useState("first_name");
  const [candidatToUpdate, setCandidatToUpdate] = useState(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [candidatToDelete, setCandidatToDelete] = useState(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [candidatToView, setCandidatToView] = useState(null);

  const fetchCandidates = async () => {
    try {
      setLoading(true);
      let response;
      if (careerId) {
        response = await dispatch(getCandidatesByCareer(careerId));
      } else {
        response = await dispatch(getCandidates());
      }
      setCandidats(response);

      const careerPromises = response.map((candidat) =>
        dispatch(getCareerById(candidat.career)).then((career) => ({
          id: candidat.career,
          title: career.job_title,
        }))
      );

      const careersData = await Promise.all(careerPromises);
      const careersMap = careersData.reduce((map, career) => {
        map[career.id] = career.title;
        return map;
      }, {});

      setCareers(careersMap);
      setLoading(false);
      setError(null);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchCandidates();
  }, [dispatch, careerId]);

  useEffect(() => {
    const filtered = filterCandidats(candidats, searchTerm);
    const sorted = sortCandidats(filtered, sortOption);
    setFilteredCandidats(sorted);
    setCurrentPage(0);
  }, [candidats, searchTerm, sortOption]);

  useEffect(() => {
    paginate(filteredCandidats, currentPage);
  }, [filteredCandidats, currentPage, candidatsPerPage]);

  const sortCandidats = (candidats, option) => {
    return [...candidats].sort((a, b) => {
      const aValue =
        option === "career" ? careers[a.career] || "" : a[option] || "";
      const bValue =
        option === "career" ? careers[b.career] || "" : b[option] || "";

      if (aValue < bValue) {
        return -1;
      }
      if (aValue > bValue) {
        return 1;
      }
      return 0;
    });
  };

  const filterCandidats = (candidats, term) => {
    if (!term) return candidats;
    return candidats.filter((candidat) => {
      const fullName =
        `${candidat.first_name} ${candidat.last_name}`.toLowerCase();
      const email = candidat.email?.toLowerCase() || "";
      const phone = candidat.phone?.toLowerCase() || "";
      const skills = candidat.skills?.toLowerCase() || "";
      const careerTitle = careers[candidat.career]?.toLowerCase() || "";

      return (
        fullName.includes(term.toLowerCase()) ||
        email.includes(term.toLowerCase()) ||
        phone.includes(term.toLowerCase()) ||
        skills.includes(term.toLowerCase()) ||
        careerTitle.includes(term.toLowerCase())
      );
    });
  };

  const paginate = (items, pageNumber) => {
    const startIndex = pageNumber * candidatsPerPage;
    const paginatedItems = items.slice(
      startIndex,
      startIndex + candidatsPerPage
    );
    setPaginatedCandidats(paginatedItems);
  };

  const handleUpdateClick = (candidat) => {
    setCandidatToUpdate(candidat);
    setIsUpdateModalOpen(true);
  };

  const handleDeleteClick = (candidat) => {
    setCandidatToDelete(candidat);
    setIsDeleteModalOpen(true);
  };

  const handleViewClick = (candidat) => {
    setCandidatToView(candidat);
    setIsDetailModalOpen(true);
  };

  const confirmDelete = () => {
    if (candidatToDelete) {
      dispatch(deleteCandidate(candidatToDelete.id))
        .then(() => {
          fetchCandidates();
          setIsDeleteModalOpen(false);
        })
        .catch((error) => console.error("Error deleting candidate:", error));
    }
  };

  const totalPages = Math.ceil(filteredCandidats.length / candidatsPerPage);
  const offset = currentPage * candidatsPerPage;

  return (
    <Box minH="100vh" bg={bg} ml={{ base: 0, md: 60 }}>
      <Box p={4}>
        <Heading fontSize="24px" mb={4} color={blueAdmin}>
          Candidates
        </Heading>
        <HStack mb={4}>
          <InputGroup width={{base:"70%",lg:"80%"}}>
            <InputLeftElement pointerEvents="none">
              <FiSearch color="gray" />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Search Candidats"
              bg={bg2}
              borderRadius="16px"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              _focus={{ boxShadow: "none" }}
            />
          </InputGroup>
          <HStack
            spacing={0}
            width={{base:"25%",lg:"20%"}}
            margin="10px 20px"
            padding="12px 12px 12px 20px"
          >
            <Text color={colorH} fontSize={{base:"12px",md:"13px",lg:"15px"}} fontWeight="600">
              Sort by
            </Text>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<FiChevronDown color={grayScales} />}
                bg={bg}
                _hover={{ bg: { bg } }}
                _active={{ bg: { bg } }}
                _focus={{ boxShadow: "none" }}
                fontSize={{base:"14px",md:"18px"}}
              />
              <MenuList>
                <MenuItem onClick={() => setSortOption("first_name")}>
                  First Name
                </MenuItem>
                <MenuItem onClick={() => setSortOption("last_name")}>
                  Last Name
                </MenuItem>
                <MenuItem onClick={() => setSortOption("email")}>
                  Email
                </MenuItem>
                <MenuItem onClick={() => setSortOption("phone")}>
                  Phone
                </MenuItem>
                <MenuItem onClick={() => setSortOption("skills")}>
                  Skills
                </MenuItem>
                <MenuItem onClick={() => setSortOption("career")}>
                  Career
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </HStack>
        {loading ? (
          <Spinner size="xl" color="#001A72" />
        ) : error ? (
          <Text color="red.500">Error: {error}</Text>
        ) : (
          <Box bg={bg2} borderRadius="16px" px={15} py={6}>
            <Text
              color={colorH}
              fontSize="18px"
              fontWeight="bold"
              mb="10px"
              flex="1"
              marginBottom="16px"
            >
              List of Candidates
            </Text>
            <Box maxW={"100%"} overflowY={"scroll"}>
            <Table variant="simple" borderRadius="30px">
              <Thead bg={"#b8c3d3"}>
                <Tr>
                  <Th
                    color={colorAdminP}
                    borderTopLeftRadius="8px"
                    fontWeight="600"
                    textAlign="center"
                  >
                    First Name
                  </Th>
                  <Th color={colorAdminP} fontWeight="600" textAlign="center">
                    Last Name
                  </Th>
                  <Th color={colorAdminP} textAlign="center">
                    Email
                  </Th>
                  <Th color={colorAdminP} textAlign="center">
                    Phone
                  </Th>
                  <Th color={colorAdminP} textAlign="center">
                    Skills
                  </Th>
                  <Th color={colorAdminP} textAlign="center">
                    Career
                  </Th>
                  <Th
                    color={colorAdminP}
                    borderTopRightRadius="8px"
                    textAlign="center"
                  >
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedCandidats.map((candidat) => (
                  <Tr key={candidat.id}>
                    <Td textAlign="center">{candidat.first_name}</Td>
                    <Td textAlign="center">{candidat.last_name}</Td>
                    <Td textAlign="center">{candidat.email}</Td>
                    <Td textAlign="center">{candidat.phone}</Td>
                    <Td textAlign="center">{candidat.skills}</Td>
                    <Td textAlign="center">{careers[candidat.career]}</Td>
                    <Td textAlign="center">
                      <HStack spacing={4} justifyContent="center">
                        <Box
                          width="25px"
                          height="25px"
                          borderRadius={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border={`1px solid ${bg}`}
                        >
                          <FiEye
                            color={blueAdmin}
                            onClick={() => handleViewClick(candidat)}
                          />
                        </Box>
                        <Box
                          width="25px"
                          height="25px"
                          borderRadius={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border={`1px solid ${bg}`}
                        >
                          <FiEdit
                            color={blueAdmin}
                            onClick={() => handleUpdateClick(candidat)}
                          />
                        </Box>
                        <Box
                          width="25px"
                          height="25px"
                          borderRadius={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border={`1px solid ${bg}`}
                        >
                          <FiTrash2
                            color={blueAdmin}
                            onClick={() => handleDeleteClick(candidat)}
                          />
                        </Box>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            </Box>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="20px"
          bg={bg}
          p="10px"
          borderRadius="8px"
        >
          <Stack direction="row" spacing={4} alignItems="center">
            <Text mr="10px" color="#717171">
              Rows per page:
            </Text>
            <select
              onChange={(e) => {
                setCandidatsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(0);
              }}
              value={candidatsPerPage}
              style={{
                background: bg,
                border: "none",
                borderRadius: "4px",
                padding: "5px",
                marginRight: "10px",
                color: "#717171",
              }}
            >
              {[5, 10, 15, 20].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <Text color="#717171">
              {offset + 1}–
              {Math.min(offset + candidatsPerPage, filteredCandidats.length)} of{" "}
              {filteredCandidats.length}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center" ml={10}>
            <FiArrowLeft
              onClick={() =>
                setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))
              }
              color={currentPage === 0 ? bg : "#717171"}
              style={{ cursor: currentPage === 0 ? "not-allowed" : "pointer" }}
            />
            <FiArrowRight
              onClick={() =>
                setCurrentPage((prevPage) =>
                  Math.min(prevPage + 1, totalPages - 1)
                )
              }
              color={currentPage >= totalPages - 1 ? bg : "#717171"}
              style={{
                cursor:
                  currentPage >= totalPages - 1 ? "not-allowed" : "pointer",
              }}
            />
          </Stack>
        </Box>
        {candidatToUpdate && (
          <AddCandidatModal
            isOpen={isUpdateModalOpen}
            onClose={() => setIsUpdateModalOpen(false)}
            type="update"
            initialData={candidatToUpdate}
          />
        )}
        {candidatToDelete && (
          <DeleteConfirmationModal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onDelete={confirmDelete}
            userName={`${candidatToDelete.first_name} ${candidatToDelete.last_name}`}
            pageName="Candidat"
          />
        )}
        {candidatToView && (
          <CandidateDetailsModal
            isOpen={isDetailModalOpen}
            onClose={() => setIsDetailModalOpen(false)}
            candidat={candidatToView}
          />
        )}
      </Box>
    </Box>
  );
};

export default AdminCandidatsPage;
