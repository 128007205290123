import React, { createContext, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  const addToCart = (item) => {
    setCart((prevCart) => {
      if (!prevCart.find(cartItem => cartItem.title === item.title)) {
        return [...prevCart, item];
      }
      return prevCart;
    });
  };

  const removeFromCart = (title) => {
    setCart((prevCart) => prevCart.filter(cartItem => cartItem.title !== title));
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart }}>
      {children}
    </CartContext.Provider>
  );
};
