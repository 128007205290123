import { GET_SUBSCRIPTIONS_SUCCESS, GET_SUBSCRIPTIONS_FAIL ,  CREATE_SUBSCRIPTIONS_SUCCESS, CREATE_SUBSCRIPTIONS_FAIL } from '../actions/types';

const initialState = {
  subscriptions: [],
  loading: true,
  error: null
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload,
        loading: false,
        error: null
      };
    case GET_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        subscriptions: [],
        loading: false,
        error: action.payload
      };
      case CREATE_SUBSCRIPTIONS_SUCCESS:
        return {
          ...state,
          subscriptions: [...state.subscriptions, action.payload],
          loading: false,
          error: null
        };
      case CREATE_SUBSCRIPTIONS_FAIL:
    default:
      return state;
  }
};

export default subscriptionReducer;
