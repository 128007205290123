import {
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  GET_MESSAGE_BY_ID_SUCCESS,
  GET_MESSAGE_BY_ID_FAIL,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAIL,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_FAIL,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAIL,
  GET_MESSAGES_BETWEEN_USERS_SUCCESS,
  GET_MESSAGES_BETWEEN_USERS_FAIL,
  GET_MESSAGES_FOR_USER_SUCCESS,
  GET_MESSAGES_FOR_USER_FAIL,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_FAIL,
  GET_MESSAGES_SENDER_FOR_USER_SUCCESS,
  GET_MESSAGES_SENDER_FOR_USER_FAIL,
} from '../actions/types';

const initialState = {
  messages: [],
  message: null,
  loading: true,
  error: null
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGES_SUCCESS:
    case GET_MESSAGES_BETWEEN_USERS_SUCCESS:
    case GET_MESSAGES_FOR_USER_SUCCESS:
    case GET_CONVERSATION_SUCCESS:
    case GET_MESSAGES_SENDER_FOR_USER_SUCCESS:  // Added case
      return {
        ...state,
        messages: action.payload,
        loading: false,
        error: null
      };
    case GET_MESSAGES_FAIL:
    case GET_MESSAGES_BETWEEN_USERS_FAIL:
    case GET_MESSAGES_FOR_USER_FAIL:
    case GET_CONVERSATION_FAIL:
    case GET_MESSAGES_SENDER_FOR_USER_FAIL:  // Added case
      return {
        ...state,
        messages: [],
        loading: false,
        error: action.payload
      };
    case GET_MESSAGE_BY_ID_SUCCESS:
      return {
        ...state,
        message: action.payload,
        loading: false,
        error: null
      };
    case GET_MESSAGE_BY_ID_FAIL:
      return {
        ...state,
        message: null,
        loading: false,
        error: action.payload
      };
    case CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [...state.messages, action.payload],
        loading: false,
        error: null
      };
    case CREATE_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: state.messages.map(message =>
          message.id === action.payload.id ? action.payload : message
        ),
        loading: false,
        error: null
      };
    case UPDATE_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: state.messages.filter(message => message.id !== action.payload),
        loading: false,
        error: null
      };
    case DELETE_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default chatReducer;
