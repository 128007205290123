import { Box, Divider, Heading, Text,ListItem, UnorderedList,Select } from '@chakra-ui/react'
import React, { useState } from 'react'

const CMIpolicies = () => {
    const [isEnglish,setIsEnglish]=useState("eng")
  return (
    <>
    {
    isEnglish==="eng"?
    <Box padding={"50px"}>
        <Select onChange={(e)=>{setIsEnglish(e.target.value)}} width={"50%"} marginLeft={"25%"}>
            <option selected value="eng">English</option>
            <option value="fr">French</option>
        </Select>
        <Heading marginTop={"15px"} marginBottom={"10px"}>WARNING</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
            You have just connected to the www.xcapitalterminal.com website of the company X CAPITAL.
            You must read the following provisions carefully, as they constitute a contract establishing the
            general conditions of the digital products and services in the electronic catalog of X CAPITAL.
            The "click" you will make after completing your order form constitutes the validation of your
            order and will be deemed irrevocable acceptance of these general conditions. Consequently,
            you may only order products or services if you accept the conditions outlined below.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>1. Preamble</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
            The following general terms and conditions of sale govern all transactions established on the X
            CAPITAL web catalog. Any order placed on this site assumes the client's unconditional and
            irrevocable acceptance of these conditions.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>2. Purpose</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
            This contract is a distance contract intended to define the rights and obligations of the parties in
            the context of the sale of X CAPITAL digital products, via the Internet, through the platform.
            www.xcapitalterminal.com is a service and a registered trademark of X CAPITAL.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>3. Definitions</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        In this contract, each of the expressions mentioned below shall be understood in the following
        definitions:
        <UnorderedList>
            <ListItem>
                "Distance contract": any contract for digital goods or services between X CAPITAL and a
                Consumer, within the framework of a remote selling system organized by X CAPITAL,
                using the Internet.
            </ListItem>
            <ListItem>
                "Consumer": any individual who, in this contract, acts for purposes outside the scope of
                their professional activity
            </ListItem>
            <ListItem>
                "Order": an act by which the Consumer agrees to purchase digital products and X
                CAPITAL agrees to provide access to them.
            </ListItem>
            <ListItem>
                "Order form": a document that indicates the characteristics of the digital products
                ordered by the Consumer and which must be signed by them by "double-click" to
                engage their commitment.
            </ListItem>
        </UnorderedList>
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>4. Digital Products</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        All digital products listed in the catalog are accessible upon order completion. X CAPITAL
        reserves the right to update or remove any digital product from the catalog without notice and
        cannot be held responsible for compensation or cancellation of an order due to dissatisfaction
        with the product.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>5. Placing an Order</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
            You have just connected to the www.xcapitalterminal.com website of the company X CAPITAL.
            You must read the following provisions carefully, as they constitute a contract establishing the
            general conditions of the digital products and services in the electronic catalog of X CAPITAL.
            The "click" you will make after completing your order form constitutes the validation of your
            order and will be deemed irrevocable acceptance of these general conditions. Consequently,
            you may only order products or services if you accept the conditions outlined below.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>6. Price</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
            The prices mentioned in the catalog are indicated in Moroccan dirhams, excluding taxes, are
            valid at the time of consultation by the customer, and are those in effect at the time of the order.
            VAT is calculated based on the rate applicable on the day of the order.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>7. Order Execution</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
            The order will be executed immediately after payment confirmation. If the product ordered is
            unavailable, the Consumer will be informed and may cancel the order.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>8. Delivery</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
            Digital products are delivered electronically, and no physical shipping is required. The Consumer
            will receive an email with access details to the digital products immediately after payment
            confirmation.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>9. Payment Method</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
            Payments are secured by the Centre Monétique Interbancaire (CMI). The Consumer
            guarantees that they have the necessary authorizations to use the chosen payment method
            when validating the order form
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>10. Data Privacy</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        The information requested for order processing is treated confidentially. You have the right to
        rectify your data.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>11. Order Cancellation</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        In case of payment by bank card, a cancellation request may be submitted according to the
        conditions defined by X CAPITAL and its banking partner.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>12. Right of Withdrawal</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
            You have a period of 7 days from the date of order confirmation to exercise your right of
            withdrawal and receive a full refund, provided that the digital products have not been accessed
            or downloaded.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>13. Warranty</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
            All digital products are guaranteed to be accessible and functional as described. This warranty
            does not cover issues caused by incorrect usage, technical limitations, or incompatibility with
            the Consumer's system.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>14. Proof of Transactions</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
            The data recorded by the CMI constitutes proof of all commercial transactions between you and
            X CAPITAL.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>15. Force Majeure</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
            X CAPITAL cannot be held responsible for the non-fulfillment of its obligations in cases of force
            majeure
        </Text>
        <Divider/>
    </Box>:
    <Box padding={"50px"}>
        <Select onChange={(e)=>{setIsEnglish(e.target.value)}} width={"50%"} marginLeft={"25%"}>
            <option selected value="eng">English</option>
            <option value="fr">French</option>
        </Select>
        <Heading marginTop={"15px"} marginBottom={"10px"}>Avertissement</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        Vous venez de vous connecter sur le site www.xcapitalterminal.com de la Société X CAPITAL.
Vous devez lire attentivement les dispositions qui vont suivre, car elles constituent un contrat
établissant les conditions générales des produits et services numériques du catalogue
électronique de la Société X CAPITAL.
Le « clic » que vous exécuterez après avoir rempli votre bon de commande constitue la
validation de votre commande et vaudra acceptation irrévocable des présentes conditions
générales. En conséquence, vous ne pouvez commander de produits ou services que si vous
acceptez les conditions prévues ci-dessous
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>1. Préambule</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
            Les conditions générales de vente suivantes régissent l'ensemble des transactions établies sur
            le catalogue web de X CAPITAL. Toute commande passée sur ce site suppose du client son
            acceptation inconditionnelle et irrévocable de ces conditions.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>2. Objet</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        Le présent contrat est un contrat à distance qui a pour objet de définir les droits et obligations
des parties dans le cadre de la vente des produits numériques de la société X CAPITAL, sur
Internet, par l’intermédiaire de la plateforme.
www.xcapitalterminal.com est un service et une marque déposée par X CAPITAL
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>3. Définitions</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        Dans la suite du contrat, chacune des expressions mentionnées ci-dessous s’entendra au sens
de sa définition :
        <UnorderedList>
            <ListItem>
            « Contrat à distance » : tout contrat concernant des biens ou services numériques entre
la Société X CAPITAL et un Consommateur dans le cadre d’un système de vente à
distance organisé par la Société X CAPITAL utilisant exclusivement le réseau Internet.
            </ListItem>
            <ListItem>
            ● « Consommateur » : toute personne physique qui, dans le présent contrat, agit à des
fins qui n’entrent pas dans le cadre de son activité professionnelle.
            </ListItem>
            <ListItem>
            « Commande » : acte par lequel le Consommateur s’engage à acheter des Produits
            numériques et la Société X CAPITAL à les fournir.
            </ListItem>
            <ListItem>
            « Bon de commande » : document qui indique les caractéristiques des Produits
numériques commandés par le Consommateur et qui doit être signé par lui par « double
clic » pour l’engager
            </ListItem>
        </UnorderedList>
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>4. Produits Numériques</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        Tous les produits numériques présents dans le catalogue sont accessibles dès la confirmation
de commande. La société X CAPITAL se réserve le droit de mettre à jour ou de retirer un
produit numérique du catalogue sans préavis et ne peut en aucun cas être tenue responsable
de dédommagement ou d'annulation d'une commande pour cause d'insatisfaction
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>5. Passer une commande</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        Après avoir trouvé le ou les produits numériques qui vous intéressent, cliquez sur le bouton «
commander ». Le produit est alors ajouté dans votre bon de commande. Vous pouvez modifier
votre bon de commande ou poursuivre vos achats à tout moment.
Lorsque vous avez terminé, validez votre commande en cliquant sur « payer ». En cas d’erreur
de saisie, contactez-nous dans les 24 h pour demander l’annulation.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>6. Prix</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        Les prix mentionnés sur le catalogue sont indiqués en dirham marocain hors taxe, sont valables
au moment de leur consultation par le client et sont ceux en vigueur au moment de la
commande. La TVA est calculée en fonction du taux applicable au jour de la commande.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>7. Exécution de la commande</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        La commande sera exécutée immédiatement après la confirmation du paiement. En cas
d’indisponibilité du produit commandé, le consommateur sera informé et pourra annuler sa
commande.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>8. Livraison</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        Les produits numériques sont livrés électroniquement, et aucun envoi physique n’est
nécessaire. Le consommateur reçoit un e-mail avec les détails d’accès aux produits numériques
immédiatement après la confirmation du paiement.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>9. Mode de paiement</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        Les paiements sont sécurisés par le Centre Monétique Interbancaire (CMI). Le Consommateur
garantit qu’il dispose des autorisations nécessaires pour utiliser le mode de paiement choisi lors
de la validation du Bon de commande.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>10. Confidentialité des données</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        Les informations demandées pour le traitement des commandes sont traitées de manière
confidentielle. Vous disposez d’un droit de rectification de vos données.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>11. Annulation d’une commande</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        En cas de paiement par carte bancaire, une demande d'annulation peut être soumise selon les
conditions définies par X CAPITAL et son partenaire bancaire.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>12. Droit de rétractation</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        Vous disposez d'un délai de 7 jours à compter de la date de confirmation de la commande pour
exercer votre droit de rétractation et obtenir un remboursement intégral, sous réserve que les
produits numériques n’aient pas été consultés ou téléchargés
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>13. Garantie
        </Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        Tous les produits numériques sont garantis accessibles et fonctionnels comme décrit. Cette
garantie ne couvre pas les problèmes dus à une mauvaise utilisation, des limitations techniques
ou des incompatibilités avec le système du consommateur.

        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>14. Preuve des transactions
        </Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        Les données enregistrées par le CMI constituent la preuve de l’ensemble des transactions
        commerciales passées entre vous et X CAPITAL.
        </Text>
        <Divider/>
        <Heading marginTop={"15px"} marginBottom={"10px"}>15. Force majeure</Heading>
        <Text padding={"15px"} fontSize={"18px"}>
        X CAPITAL ne peut être tenue responsable de l’inexécution de ses obligations en cas de force
        majeure.
        </Text>
        <Divider/>
    </Box>
    }
    </>
  )
}

export default CMIpolicies