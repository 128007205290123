import { Flex, Heading, Text, Button, useMediaQuery } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

export default function JobCard(props) {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const textStyles = isLargerThan768
    ? {}
    : {
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxHeight: "calc(1.2em * 6)",
      };

  return (
    <Flex
      justify="center"
      direction="column"
      boxShadow="0.1px 0.1px 1px 0.1px black"
      width={isLargerThan768 ? "72%" : "100%"}
      padding={isLargerThan768 ? "36px" : "12px"}
      gap="8px"
    >
      <Flex justify="space-around">
        <Flex direction="column" gap="4px">
          <Heading fontSize={isLargerThan768 ? "32px" : "20px"}>{props.title}</Heading>
          <Text fontSize={isLargerThan768 ?  "16px" : "12px"}>{props.location}</Text>
          <Text fontSize={isLargerThan768 ?  "16px" : "12px"}> {props.type} </Text>
        </Flex>
        <Flex width={isLargerThan768 ? "50%" : "80%"}>
          <Text style={textStyles} fontSize={isLargerThan768 ?  "16px" : "12px"}> {props.description} </Text>
        </Flex>
      </Flex>
      <Flex justify="flex-end">
        <Button bg="black" _hover="none" width="8%" onClick={props.onClick}>
          {" "}
          <ArrowForwardIcon color="white" />{" "}
        </Button>
      </Flex>
    </Flex>
  );
}
