import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  HStack,
  Text,
  Stack,
  Card,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
  Flex,
  Center,
  Select,
  Badge,
} from "@chakra-ui/react";
import {
  FiPlus,
  FiSearch,
  FiTrash2,
  FiEdit3,
  FiChevronDown,
  FiArrowLeft,
  FiArrowRight,
  FiDownload,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  getUsers,
  downloadUsersExcel,
  downloadUsersExcelByRole,
} from "../../actions/user";
import { format } from "date-fns";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import AddUserModal from "../../components/UserModal";
import { useNavigate } from "react-router-dom";
import { FaSlidersH } from "react-icons/fa";

export default function AdminUsersPage() {
  const bg = useSelector((state) => state.themeReducer.backgroundColor1);
  const bg2 = useSelector((state) => state.themeReducer.backgroundColor2);
  const colorH = useSelector((state) => state.themeReducer.color1);
  const colorP = useSelector((state) => state.themeReducer.color2);
  const colorAdminP = useSelector((state) => state.themeReducer.colorAdmin2);
  const grayScales = useSelector((state) => state.themeReducer.grayScales);
  const blueAdmin = useSelector((state) => state.themeReducer.blueAdmin);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector((state) => state.userReducer.users);
  const loading = useSelector((state) => state.userReducer.loading);
  const error = useSelector((state) => state.userReducer.error);

  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(5);
  const [sortOption, setSortOption] = useState("name");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isUpdateUserModalOpen, setIsUpdateUserModalOpen] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const [selectedRole, setSelectedRole] = useState("All");

  useEffect(() => {
    dispatch(getUsers())
      .then((data) => {
        setIsCheckingAccess(false);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);

        if (error.response && error.response.status === 403) {
          navigate("/Not-Access");
        } else if (error.response && error.response.status === 401) {
          navigate("/login");
        } else {
          setIsCheckingAccess(false);
        }
      });
  }, [dispatch, navigate]);

  useEffect(() => {
    const filtered = filterUsers(users, searchTerm);
    setFilteredUsers(sortUsers(filtered, sortOption));
    setCurrentPage(0);
  }, [users, searchTerm, sortOption]);

  const offset = currentPage * usersPerPage;
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const sortUsers = (users, option) => {
    return [...users].sort((a, b) => {
      let aValue, bValue;
      switch (option) {
        case "name":
          aValue = `${a.first_name} ${a.last_name}`.toLowerCase();
          bValue = `${b.first_name} ${b.last_name}`.toLowerCase();
          break;
        case "email":
          aValue = a.email.toLowerCase();
          bValue = b.email.toLowerCase();
          break;
        case "role":
          aValue = a.role.toLowerCase();
          bValue = b.role.toLowerCase();
          break;
        case "status":
          return b.is_active - a.is_active;
        case "created_at":
          return new Date(b.created_at) - new Date(a.created_at);
        case "created_by":
          aValue = getCreatorName(a.created_by).toLowerCase();
          bValue = getCreatorName(b.created_by).toLowerCase();
          break;
        default:
          return 0;
      }
      return aValue.localeCompare(bValue);
    });
  };

  const filterUsers = (users, term) => {
    if (!term) return users;
    return users.filter((user) => {
      const searchableFields = [
        `${user.first_name} ${user.last_name}`,
        user.email,
        user.role,
        user.is_active ? "active" : "inactive",
        format(new Date(user.created_at), "dd MMM yyyy"),
        getCreatorName(user.created_by),
      ];
      return searchableFields.some((field) =>
        field.toLowerCase().includes(term.toLowerCase())
      );
    });
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteUser(userToDelete.id))
      .then(() => {
        dispatch(getUsers())
          .then((data) => {
            setIsModalOpen(false);
            setUserToDelete(null);
          })
          .catch((error) => console.error("Error fetching users:", error));
      })
      .catch((error) => console.error("Error deleting user:", error));
  };

  const handleUpdateClick = (user) => {
    setUserToUpdate(user);
    setIsUpdateUserModalOpen(true);
  };

  const handleDownload = () => {
    if (selectedRole === "All") {
      dispatch(downloadUsersExcel());
    } else {
      dispatch(downloadUsersExcelByRole(selectedRole));
    }
  };

  const getUserCountByRole = (role) => {
    return users.filter((user) => user.role === role).length;
  };

  if (isCheckingAccess) {
    return (
      <Flex justify="center" align="center" minH="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  const getCreatorName = (createdById) => {
    const creator = users.find((user) => user.id === createdById);
    return creator ? `${creator.first_name} ${creator.last_name}` : "N/A";
  };

  const currentUsers = filteredUsers.slice(offset, offset + usersPerPage);

  return (
    <Box minH="100vh" bg={bg}>
      <Box ml={{ base: 0, md: 60 }} p={4}>
        <Heading
          fontSize="24px"
          margin="30px 30px 10px 3rem"
          color={blueAdmin}
          textAlign={{ base: "center", lg: "left" }}
        >
          Users Dashboard
        </Heading>
        <Flex
          direction={{ base: "column", lg: "row" }}
          w={{ base: "100%", lg: "95%" }}
          align={"center"}
          ml={{ base: "0", lg: "2.5%" }}
        >
          <HStack w={{ base: "100%", lg: "80%" }}>
            <InputGroup
              w={{ base: "70%", md: "80%" }}
              padding="12px 12px 12px 20px"
            >
              <InputLeftElement pointerEvents="none" margin="12px 20px">
                <FiSearch color="gray" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search"
                bg={bg2}
                borderRadius="16px"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                _focus={{ boxShadow: "none" }}
              />
            </InputGroup>
            <HStack w={{ base: "30%", md: "20%" }} spacing={0}>
              <Text
                color={colorP}
                fontSize={{ base: "12px", md: "13px", lg: "15px" }}
                fontWeight="600"
              >
                Sort by
              </Text>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<FiChevronDown color={grayScales} />}
                  bg={bg}
                  _hover={{ bg: { bg } }}
                  _active={{ bg: { bg } }}
                  _focus={{ boxShadow: "none" }}
                />
                <MenuList>
                  <MenuItem onClick={() => setSortOption("name")}>
                    Name
                  </MenuItem>
                  <MenuItem onClick={() => setSortOption("role")}>
                    Role
                  </MenuItem>
                  <MenuItem onClick={() => setSortOption("created_at")}>
                    Create Date
                  </MenuItem>
                  <MenuItem onClick={() => setSortOption("created_by")}>
                    Created By
                  </MenuItem>
                  <MenuItem onClick={() => setSortOption("status")}>
                    Status
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </HStack>
          <Button
            w={{ base: "100%", lg: "20%" }}
            mt={"15px"}
            mb={"15px"}
            height={"40px"}
            padding={"20px"}
            rightIcon={<FiPlus />}
            color="#FFFFFF"
            backgroundColor="#001A72"
            _hover={{ backgroundColor: "#001A72" }}
            _focus={{ backgroundColor: "#001A72" }}
            onClick={() => setIsAddUserModalOpen(true)}
            fontSize={{ base: "14px", md: "16px", lg: "18px" }}
          >
            Add user
          </Button>
        </Flex>
        <Center>
          <Flex
            ml={{ base: "0", lg: "2.5%" }}
            mb="20px"
            direction={{ base: "column", md: "row" }}
            wrap={{ base: "wrap", lg: "nowrap" }}
            gap={"10px"}
            w={{ base: "100%", lg: "95%" }}
            justify={"space-around"}
          >
            {["Client", "Demo", "Manager", "Admin", "Sales"].map((role) => (
              <Card
                key={role}
                w={{ base: "100%", md: "48%", lg: "20%" }}
                h={{ base: "100px", md: "90px", lg: "80px" }}
                bg={bg2}
                p="10px 20px"
                border="solid 1px #EFF4FA"
                borderRadius={5}
              >
                <Text
                  color={colorAdminP}
                  fontSize={{ base: "17px", md: "13px" }}
                  fontWeight="400"
                >
                  {role === "Demo" ? "Demo Client" : role}
                </Text>
                <Text
                  color={grayScales}
                  fontSize={{ base: "30px", lg: "20px" }}
                  fontWeight="bold"
                  textAlign={{ base: "center", md: "left" }}
                >
                  {getUserCountByRole(role)}
                </Text>
              </Card>
            ))}
          </Flex>
        </Center>
        <Flex justifyContent="flex-end" alignItems="center" mb={4} mt={4}>
          <HStack spacing={4}>
            <Select
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              width="200px"
              bg={bg2}
              borderRadius="16px"
              color={colorP}
              _focus={{ boxShadow: "none" }}
            >
              <option value="All">All Roles</option>
              <option value="Client">Client</option>
              <option value="Demo">Demo Client</option>
              <option value="Manager">Manager</option>
              <option value="Admin">Admin</option>
              <option value="Sales">Sales Department</option>
            </Select>
            <Button
              leftIcon={<FiDownload />}
              onClick={handleDownload}
              bg="#28A745"
              color="#FFFFFF"
              _hover={{ bg: "#218838" }}
              borderRadius="4px"
              fontSize={{ base: "14px", md: "16px", lg: "18px" }}
            >
              Excel
            </Button>
          </HStack>
        </Flex>

        <Box
          overflowX="auto"
          bg={bg2}
          borderRadius="8px"
          maxWidth="100%"
          border="solid #EFF4FA 1px"
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th color={colorAdminP} onClick={() => setSortOption("name")}>
                  Name
                </Th>
                <Th color={colorAdminP} onClick={() => setSortOption("role")}>
                  Role
                </Th>
                <Th color={colorAdminP} onClick={() => setSortOption("status")}>
                  Status
                </Th>
                <Th
                  color={colorAdminP}
                  onClick={() => setSortOption("created_at")}
                >
                  Create Date
                </Th>
                <Th
                  color={colorAdminP}
                  onClick={() => setSortOption("created_by")}
                >
                  Created By
                </Th>
                <Th color={colorAdminP}>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Tr>
                  <Td colSpan={6}>Loading...</Td>
                </Tr>
              ) : (
                currentUsers?.map((user) => (
                  <Tr key={user.id}>
                    <Td>
                      <Text
                        fontWeight="600"
                        color={colorH}
                      >{`${user.first_name} ${user.last_name}`}</Text>
                      <Text color={colorAdminP}>{user.email}</Text>
                    </Td>
                    <Td>
                      <Button
                        color="#FFFFFF"
                        backgroundColor="#001A72"
                        cursor="default"
                        _hover={{ backgroundColor: "#001A72" }}
                        _focus={{ backgroundColor: "#001A72" }}
                        tabIndex={-1}
                        width="90px"
                        height="28px"
                      >
                        {user.role}
                      </Button>
                    </Td>
                    <Td>
                      <Badge
                        colorScheme={user.is_active ? "green" : "red"}
                        variant="solid"
                        borderRadius="full"
                        px={3}
                        py={1}
                        bgColor={user.is_active ? "green.100" : "red.100"}
                        color={user.is_active ? "green.800" : "red.800"}
                      >
                        {user.is_active ? "Active" : "Inactive"}
                      </Badge>
                    </Td>
                    <Td>
                      {user.created_at
                        ? format(new Date(user.created_at), "dd MMM yyyy")
                        : "N/A"}
                    </Td>
                    <Td>{getCreatorName(user.created_by)}</Td>
                    <Td>
                      <HStack spacing={4}>
                        <FiEdit3
                          color="#C5CEE0"
                          cursor="pointer"
                          onClick={() => handleUpdateClick(user)}
                        />
                        <FiTrash2
                          color="#C5CEE0"
                          cursor="pointer"
                          onClick={() => handleDeleteClick(user)}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="20px"
          bg={bg}
          p="10px"
          borderRadius="8px"
        >
          <Stack direction="row" justify={"space-between"} alignItems="center">
            <Text mr="10px" color="#717171">
              Rows per page:
            </Text>
            <select
              onChange={(e) => {
                setCurrentPage(0);
                setUsersPerPage(parseInt(e.target.value, 10));
              }}
              value={usersPerPage}
              style={{
                background: bg,
                border: "none",
                borderRadius: "4px",
                padding: "5px",
                marginRight: "10px",
                color: "#717171",
              }}
            >
              {[5, 10, 15, 20].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <Text color="#717171">
              {offset + 1}–
              {Math.min(offset + usersPerPage, filteredUsers.length)} of{" "}
              {filteredUsers.length}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center" ml={10}>
            <FiArrowLeft
              onClick={() =>
                setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))
              }
              color={currentPage === 0 ? bg : "#717171"}
              style={{ cursor: currentPage === 0 ? "not-allowed" : "pointer" }}
            />
            <FiArrowRight
              onClick={() =>
                setCurrentPage((prevPage) =>
                  Math.min(prevPage + 1, totalPages - 1)
                )
              }
              color={currentPage >= totalPages - 1 ? bg : "#717171"}
              style={{
                cursor:
                  currentPage >= totalPages - 1 ? "not-allowed" : "pointer",
              }}
            />
          </Stack>
        </Box>
      </Box>
      {userToDelete && (
        <DeleteConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onDelete={handleDeleteConfirm}
          userName={`${userToDelete.first_name} ${userToDelete.last_name}`}
          pageName={"User"}
        />
      )}
      <AddUserModal
        isOpen={isAddUserModalOpen}
        onClose={() => setIsAddUserModalOpen(false)}
      />
      <AddUserModal
        isOpen={isUpdateUserModalOpen}
        onClose={() => setIsUpdateUserModalOpen(false)}
        type="update"
        initialData={userToUpdate}
      />
    </Box>
  );
}
