import React from 'react'
import { ListIcon, ListItem, Text } from '@chakra-ui/react'
import { MdCheckCircle } from "react-icons/md";

function ProductFeatures({feature}) {

    return (
      <>
       
              <ListItem display="flex" mb="1em">
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text>{feature}</Text>
              </ListItem>
         
      </>
    )
  }
  
export default ProductFeatures
