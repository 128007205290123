import React, { useEffect, useState } from 'react'
import { getSuppotsByUserId } from '../services/api/SupportApi'
import { useSelector } from 'react-redux'
import { Heading, Spinner,Box,Text, Flex, Button } from '@chakra-ui/react'
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const ClientSupportList = () => {
    const bg=useSelector(state=>state.themeReducer.backgroundColor1)
    const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
    const colorH=useSelector(state=>state.themeReducer.color1)
    const colorP=useSelector(state=>state.themeReducer.color2)
    const colorAdminP=useSelector(state=>state.themeReducer.colorAdmin2)
    const grayScales=useSelector(state=>state.themeReducer.grayScales)
    const blueAdmin=useSelector(state=>state.themeReducer.blueAdmin)
    
    const navigate=useNavigate()
    const [supports,setSupports]=useState(null)
    const [err,setErr]=useState("")
    const user=useSelector(state=>state.auth.user)
    useEffect(()=>{
        if(user)
            getSuppotsByUserId(user.id,setSupports,setErr)
        else
            setErr("No Current User Found")
    },[])
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
      };
    const handleBack=()=>{
        navigate("/x-capital/terminal/client/support")
    }
  return (
    <Box ml={{base:0,md:60}} paddingBottom={"20px"} position={"relative"}>
        <Button bg={bg} color={colorP} onClick={handleBack} position={"absolute"} top={"5px"} left={"5px"}>
              <FaArrowAltCircleLeft fontSize="1.5em" />
        </Button>
        <Box textAlign="center" mt="20px" w={{base:"80%",md:"90%",lg:"100%"}} ml={{base:"10%",md:"5%",lg:"0%"}}>
            <Heading  fontSize={{base:'17px',md:"20px",lg:"24px"}}>List Of Previous Emails</Heading>
            <Text mt='10px' fontSize={{base:'15px',md:"17px",lg:"19px"}}>X-Capital Support</Text>
        </Box>
        {
            supports!==null && err===""?
                supports.length!==0?
                supports.map((s)=>{
                    return(
                    <Box w={{base:'95%',md:"80%",lg:"60%"}} ml={{base:'2.5%',md:"10%",lg:"20%"}} padding={"20px"} border={"solid 2px "+blueAdmin} mt={"20px"} borderRadius={"8px"}>
                        <Flex justify={'space-between'} align={'center'}>
                            <Heading mb="10px" fontSize={{base:"16px", md:"18px",lg:"20px"}}> Subject: {s.subject}</Heading>
                            <Text fontSize={{base:"14px", md:"16px",lg:"18px"}} flex={"0 0 fit-content"}>{formatDate(s.sent_at)}</Text>
                        </Flex>
                        <Text padding={"10px"} fontSize={{base:"14px", md:"15px",lg:"16px"}}>{s.content}</Text>
                    </Box>)
                })
                :<Heading>No Support Email Sent Before</Heading>
            :<Spinner/>
        }
    </Box>
  )
}

export default ClientSupportList