import {
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAIL,
  ADD_OFFER_SUCCESS,
  ADD_OFFER_FAIL,
  DELETE_OFFER_SUCCESS,
  DELETE_OFFER_FAIL,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER_FAIL,
  GET_OFFER_DETAILS_SUCCESS,
  GET_OFFER_DETAILS_FAIL,
  ADD_OFFER_DETAIL_SUCCESS,
  ADD_OFFER_DETAIL_FAIL,
  DELETE_OFFER_DETAIL_SUCCESS,
  DELETE_OFFER_DETAIL_FAIL,
  UPDATE_OFFER_DETAIL_SUCCESS,
  UPDATE_OFFER_DETAIL_FAIL,
  GET_OFFER_BY_ID_SUCCESS,
  GET_OFFER_BY_ID_FAIL,
  ADD_MANAGER_REVIEW_SUCCESS,
  ADD_MANAGER_REVIEW_FAIL,
  UPDATE_MANAGER_REVIEW_SUCCESS,
  UPDATE_MANAGER_REVIEW_FAIL,
  ADD_SALES_REVIEW_SUCCESS,
  ADD_SALES_REVIEW_FAIL,
  UPDATE_SALES_REVIEW_SUCCESS,
  UPDATE_SALES_REVIEW_FAIL,
  GET_MANAGER_REVIEW_BY_OFFER_SUCCESS,
  GET_MANAGER_REVIEW_BY_OFFER_FAIL,
  GET_SALES_REVIEW_BY_OFFER_SUCCESS,
  GET_SALES_REVIEW_BY_OFFER_FAIL
} from './types';
import axios from 'axios';
import { getAuthConfig } from './config';

export const getAllOffers = () => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/preorders`, config);
    dispatch({
      type: GET_OFFERS_SUCCESS,
      payload: res.data
    });
    return res.data; 
  } catch (err) {
    dispatch({
      type: GET_OFFERS_FAIL,
      payload: err.message 
    });
    throw err; 
  }
};

export const getOfferById = (offerId) => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/preorder/${offerId}/`, config);  
    dispatch({
      type: GET_OFFER_BY_ID_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_OFFER_BY_ID_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const addOffer = (offerData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/offer/save/`, offerData, config);
    dispatch({
      type: ADD_OFFER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_OFFER_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const deleteOffer = (offerId) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    await axios.delete(`${process.env.REACT_APP_API_URL}/offer/${offerId}/delete`, config);
    dispatch({
      type: DELETE_OFFER_SUCCESS,
      payload: offerId,
    });
  } catch (err) {
    dispatch({
      type: DELETE_OFFER_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const updateOffer = (id, offerData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/offer/${id}/update`, offerData, config);
    dispatch({
      type: UPDATE_OFFER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_OFFER_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const getOfferDetails = () => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/preorders`, config);
    dispatch({
      type: GET_OFFER_DETAILS_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_OFFER_DETAILS_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const getOfferDetail = () => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/offer/detail/all`, config);
    dispatch({
      type: GET_OFFER_DETAILS_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_OFFER_DETAILS_FAIL,
      payload: err.message
    });
    throw err;
  }
}

export const addOfferDetail = (offerDetailData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/offer/detail/save`, offerDetailData, config);
    dispatch({
      type: ADD_OFFER_DETAIL_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_OFFER_DETAIL_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const deleteOfferDetail = (offerDetailId) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    await axios.delete(`${process.env.REACT_APP_API_URL}/offer/detail/${offerDetailId}/delete`, config);
    dispatch({
      type: DELETE_OFFER_DETAIL_SUCCESS,
      payload: offerDetailId,
    });
  } catch (err) {
    dispatch({
      type: DELETE_OFFER_DETAIL_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const updateOfferDetail = (id, offerDetailData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/offer/detail/${id}/update`, offerDetailData, config);
    dispatch({
      type: UPDATE_OFFER_DETAIL_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_OFFER_DETAIL_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const addManagerReview = (reviewData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/review/manager/save/`, reviewData, config);
    dispatch({
      type: ADD_MANAGER_REVIEW_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_MANAGER_REVIEW_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const updateManagerReview = (reviewId, reviewData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/review/manager/update/${reviewId}`, reviewData, config);
    dispatch({
      type: UPDATE_MANAGER_REVIEW_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_MANAGER_REVIEW_FAIL,
      payload: err.response.data.errors || err.message,
    });
    throw err;
  }
};

export const addSalesReview = (reviewData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/review/sales/save/`, reviewData, config);
    dispatch({
      type: ADD_SALES_REVIEW_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_SALES_REVIEW_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const updateSalesReview = (reviewId, reviewData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/review/sales/update/${reviewId}`, reviewData, config);
    dispatch({
      type: UPDATE_SALES_REVIEW_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_SALES_REVIEW_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const getManagerReviewByOffer = (offerDetailId) => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/offer/review/manager/${offerDetailId}`, config);
    dispatch({
      type: GET_MANAGER_REVIEW_BY_OFFER_SUCCESS,
      payload: res.data,
    });
    return res.data; 
  } catch (err) {
    dispatch({
      type: GET_MANAGER_REVIEW_BY_OFFER_FAIL,
      payload: err.message,
    });
    console.error("Error fetching manager review:", err.message); 
    throw err;
  }
};


export const getSalesReviewByOffer = (offerDetailId) => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/offer/review/sales/${offerDetailId}`, config);
    dispatch({
      type: GET_SALES_REVIEW_BY_OFFER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SALES_REVIEW_BY_OFFER_FAIL,
      payload: err.message,
    });
    throw err;
  }
};
