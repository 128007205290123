import { GET_FILES_SUCCESS, GET_FILES_FAIL, UPDATE_FILE_FAIL, UPDATE_FILE_SUCCESS, ADD_FILE_FAIL, ADD_FILE_SUCCESS, DELETE_FILE_FAIL, DELETE_FILE_SUCCESS } from './types';
import axios from 'axios';
import { getAuthConfig, getAuthConfigFile } from './config';

export const getFiles = () => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/files`, config);
    dispatch({
      type: GET_FILES_SUCCESS,
      payload: res.data
    });
    return res.data; 
  } catch (err) {
    dispatch({
      type: GET_FILES_FAIL,
      payload: err.message 
    });
    throw err; 
  }
};

export const deleteDocument = (documentId) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    await axios.delete(`${process.env.REACT_APP_API_URL}/file/${documentId}/delete`, config);
    dispatch({
      type: DELETE_FILE_SUCCESS,
      payload: documentId,
    });
  } catch (err) {
    dispatch({
      type: DELETE_FILE_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const addDocument = (documentData) => async (dispatch) => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/file/save`, documentData, config);
    dispatch({
      type: ADD_FILE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_FILE_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const updateDocument = (id, documentData) => async (dispatch) => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/file/${id}/update`, documentData, config);
    dispatch({
      type: UPDATE_FILE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_FILE_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const downloadDocument = (file_id) => async (dispatch) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/file/download/${file_id}`, {
      responseType: 'blob',
    });
    return response;
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error;
  }
};