import { Heading, Box, Spinner } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import OneSolution from "../components/OneSolution";
import ArticleSolution from "../components/ArticleSolution";

const ClientSolutions = () => {
  const bg = useSelector((state) => state.themeReducer.backgroundColor1);
  const grayScales = useSelector((state) => state.themeReducer.grayScales);
  const { products, loading } = useSelector((state) => state.productReducer);

  return (
    <Box
      bgColor={bg}
      ml={{ base: 0, md: 60 }}
      minHeight="95vh"
      paddingTop="15px"
      paddingBottom="25px"
    >
      <Heading
        as="h1"
        color={grayScales}
        textAlign="center"
        mb={{ base: "45px", md: "70px" }}
        w="75%"
        ml="12.5%"
        fontSize={{ base: "28px", md: "30px", lg: "32px" }}
      >
        Our Collection Of Products
      </Heading>
      <Box width={{ base: "90%", lg: "70%" }} ml={{ base: "5%", lg: "15%" }}>
        {!loading ? (
          products.map((product) => {
            return(
              <OneSolution
                title={product.title}
                paragraph={product.description}
                pic={product.image_url}
                idProduct={product.id}
              />
            ) 
          })
        ) : (
          <Spinner />
        )}
      </Box>
    </Box>
  );
};

export default ClientSolutions;