import {
    GET_NEWSLETTERS_SUCCESS,
    GET_NEWSLETTERS_FAIL,
    GET_NEWSLETTER_SUCCESS,
    GET_NEWSLETTER_FAIL,
    CREATE_NEWSLETTER_SUCCESS,
    CREATE_NEWSLETTER_FAIL,
    UPDATE_NEWSLETTER_SUCCESS,
    UPDATE_NEWSLETTER_FAIL,
    DELETE_NEWSLETTER_SUCCESS,
    DELETE_NEWSLETTER_FAIL
  } from '../actions/types';
  
  const initialState = {
    newsletters: [],
    newsletter: null,
    loading: true,
    error: null
  };
  
  const newsletterReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_NEWSLETTERS_SUCCESS:
        return {
          ...state,
          newsletters: action.payload,
          loading: false,
          error: null
        };
      case GET_NEWSLETTERS_FAIL:
        return {
          ...state,
          newsletters: [],
          loading: false,
          error: action.payload
        };
      case GET_NEWSLETTER_SUCCESS:
        return {
          ...state,
          newsletter: action.payload,
          loading: false,
          error: null
        };
      case GET_NEWSLETTER_FAIL:
        return {
          ...state,
          newsletter: null,
          loading: false,
          error: action.payload
        };
      case CREATE_NEWSLETTER_SUCCESS:
        return {
          ...state,
          newsletters: [...state.newsletters, action.payload],
          loading: false,
          error: null
        };
      case CREATE_NEWSLETTER_FAIL:
      case UPDATE_NEWSLETTER_FAIL:
      case DELETE_NEWSLETTER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      case UPDATE_NEWSLETTER_SUCCESS:
        return {
          ...state,
          newsletters: state.newsletters.map(newsletter =>
            newsletter.id === action.payload.id ? action.payload : newsletter
          ),
          loading: false,
          error: null
        };
      case DELETE_NEWSLETTER_SUCCESS:
        return {
          ...state,
          newsletters: state.newsletters.filter(newsletter => newsletter.id !== action.payload),
          loading: false,
          error: null
        };
      default:
        return state;
    }
  };
  
  export default newsletterReducer;
  