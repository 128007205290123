import { Box , Divider, Flex, FormControl, FormLabel, Heading, Input, Text,Button, Center, Spinner, useDisclosure, Alert, AlertTitle, AlertIcon, AlertDescription, Image} from '@chakra-ui/react'
import React, { useState,useEffect, useRef, forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchCompany, updateCompany } from '../services/api/companyAPI/fetchCompany'
import { getUserById, updateUser } from '../actions/user'
import defaultImage from "../assets/defaultProfile.png"
import { TbEdit } from 'react-icons/tb'
import { load_user } from '../actions/auth'
var validRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
var validPhone= /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
var validUrl=/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/

const ClientSettings = () => {
    const {isOpen,onOpen,onClose}=useDisclosure(false)
    const dispatch=useDispatch()
    const userId=useSelector(state=>state.auth.user?.id)
    const userVal=useSelector(state=>state.userReducer.users[0])
    const userErr=useSelector(state=>state.userReducer.error)
    const [userValues,setUserValues]=useState(null)
    const [companyValues,setCompanyValues]=useState(null)
    const [alertDetails,setAlertDetails]=useState({title:"",desc:'',status:'error',bg:""})
    const emailRef=useRef(null)
    const phoneRef=useRef(null)
    const linkedinRef=useRef(null)
    const websiteRef=useRef(null)
    const handleUpdate=async()=>{
        onClose()
        let isvalid=true
        const inputs=document.querySelectorAll('input')
        for(let input of inputs)
            if(input.value==="" && input.name!=="profile_picture")
                isvalid=false
        if(!validRegex.test(emailRef.current?.value) 
            || !validPhone.test(phoneRef.current?.value)  
            || (!validUrl.test(linkedinRef.current?.value) && userValues.type==="Company" )
            || (!validUrl.test(websiteRef.current?.value) && userValues.type==="Company")
        )
            isvalid=false
        window.scrollTo({
            top:0,
            left:0,
            behavior:"smooth"
        })
        if(!isvalid)
            setAlertDetails({title:"Verify Your Inputs",desc:"empty fields or incorrect syntax",status:"error",bg:errStatusBg})
        else{
            if(typeof(userValues.profile_picture)==="string")
                delete userValues.profile_picture
            const resUser=await dispatch(updateUser(userId,userValues))
            if(userValues.type==="Company"){
                let companyInf=companyValues
                if(companyValues.linkedin_url.slice(0,7)!=="http://" && companyValues.linkedin_url.slice(0,8)!=="https://")
                    companyInf={...companyInf,linkedin_url:`http://${companyInf.linkedin_url}`}
                if(companyValues.company_website.slice(0,7)!=="http://" && companyValues.company_website.slice(0,8)!=="https://")
                    companyInf={...companyInf,company_website:`http://${companyInf.company_website}`}
                let companyRes=await updateCompany(companyValues.id,companyInf)
                if(companyRes === true)
                    setAlertDetails({title:"Updated Succesfully",desc:"your profile has been updated",status:"success",bg:okStatusBg})
                else
                    setAlertDetails({title:"Something Went Wrong",desc:"try again.",status:"error",bg:errStatusBg})
            }else{
                typeof(resUser) === "object" ?
                    setAlertDetails({title:"Updated Succesfully",desc:"your profile has been updated",status:"success",bg:okStatusBg})
                :
                    setAlertDetails({title:"Something Went Wrong",desc:"Check your Email or change the Username",status:"error",bg:errStatusBg})
            }
        }
        dispatch(load_user());
        onOpen()
    }
    useEffect(()=>{
        if(userId)
            dispatch(getUserById(userId))
    },[userId])
    useEffect(()=>{
        setUserValues(userVal)
        if(userVal?.company){
            const idT=setTimeout(()=>{
                if(userVal.type==="Company")
                    fetchCompany(userVal.company,setCompanyValues)
            },400)
            return ()=>clearTimeout(idT)
        }
    },[userVal])
    useEffect(()=>{
        
    },[userErr])
    
    const bg=useSelector(state=>state.themeReducer.backgroundColor1)
    const grayScales=useSelector(state=>state.themeReducer.grayScales)
    const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
    const okStatusBg=useSelector(state=>state.themeReducer.okStatusBg)
    const errStatusBg=useSelector(state=>state.themeReducer.errStatusBg)
  return (
    <Box minH="95vh" ml={{base:"0",md:"60"}} bgColor={bg} padding={{base:"5px",md:"15px"}} position="relative">
        <Alert
            opacity={isOpen?"1":"0"}
            w="fit-content"
            position="absolute"
            top="0"
            right="5px"
            status={alertDetails.status}
            borderRadius="5px"
            transition="ease .5s"
            padding="10px"
            display="flex" alignItems="center" gap="10px" 
            bgColor={alertDetails.bg}
        >
            <AlertIcon status={alertDetails.status}/>
            <Flex direction="column">
                <AlertTitle fontSize={{base:"14px",lg:"16px"}}>{alertDetails.title}</AlertTitle>
                <AlertDescription fontSize={{base:"12px",lg:"14px"}}>{alertDetails.desc}</AlertDescription>
            </Flex>
        </Alert>
        <Box 
            w={{base:"100%",md:"96%",lg:"80%"}}
            ml={{base:"0%",md:"2%",lg:"10%"}}
            bgColor={bg2}
            borderRadius="15px"
            paddingTop="25px"
            paddingBottom="25px"
            box-shadow="0px 1.92px 3.84px 0px rgba(0, 0, 0, 0.1)"
        >
           <Box textAlign="center" mb='15px' >
                <Heading as="h1" fontSize={{base:"19px",md:"23px"}} mb="5px">Account Settings</Heading>
                <Text as="p" fontSize="14px" color='rgba(134, 142, 150, 1)'>Edit Your Profile</Text>
            </Box>
            <Divider/>
            {
                userValues!==null && ((userValues?.type==="Company" && companyValues!==null) || userValues?.type==="Individual")?
                <>
                <Box mt='15px' w={{base:"98%",md:"95%",lg:"90%"}} ml={{base:"1%",md:"2.5%",lg:"5%"}}>
                    <Heading as="h2" fontSize='18px' mb={{base:'20px',md:"15px"}}>Personnal Informations</Heading>
                    <Box height="100px" w="100px" position="relative" ml="calc(50% - 50px)" >
                        <Image w="100%" height="100%" objectFit="cover" src={userVal?.profile_picture!==null?`${process.env.REACT_APP_SERVER_URL}${userVal?.profile_picture}`:defaultImage} borderRadius="50%"/>
                        <FormControl position="absolute" bottom="-10px" right="-10px" bg={bg} width="40px" height="40px" borderRadius="50%">
                            <FormLabel cursor="pointer"  margin="8px" w="100%" h="100%" fontSize="25px" color={grayScales}><TbEdit/></FormLabel>
                            <Input 
                                type="file" 
                                accept='image/*' 
                                position="absolute" top="-1000px" 
                                name='profile_picture' 
                                onChange={(e)=>setUserValues((prev)=>{return {...prev,['profile_picture']:e.target.files[0]}})}
                            />
                        </FormControl>
                    </Box>
                    <Flex
                        justifyContent="space-around"
                        wrap="wrap"
                        rowGap="20px"
                        mt="25px"
                        align="center"
                    >
                        <InputCase
                            label="First Name"
                            value={userValues?.first_name}
                            setValue={setUserValues}
                            type="text"
                            name="first_name"
                        />
                        <InputCase
                            label="Last Name"
                            value={userValues?.last_name}
                            setValue={setUserValues}
                            type="text"
                            name="last_name"
                        />
                        <InputCase
                            label="Username"
                            value={userValues?.username}
                            setValue={setUserValues}
                            type="text"
                            name="username"
                        />
                        <InputCase
                            label="Business email"
                            value={userValues?.email}
                            setValue={setUserValues}
                            type="email"
                            name="email"
                            ref={emailRef}
                        />
                        <InputCase
                            label="Phone Number"
                            value={userValues?.phone_number}
                            setValue={setUserValues}
                            type="text"
                            name="phone_number"
                            ref={phoneRef}
                        />
                        <InputCase
                            label="Job Role"
                            value={userValues?.job_role}
                            setValue={setUserValues}
                            type="text"
                            name="job_role"
                        />
                    </Flex>
                </Box>
                {
                    userValues?.type!=="Individual"?
                    <Box mt='15px' w="90%" ml="5%">
                        <Heading as="h2" fontSize='18px'>Company's Informations</Heading>
                        <Flex
                            justifyContent="space-around"
                            wrap="wrap"
                            rowGap="20px"
                            mt="25px"
                            alignItems="center"
                        >
                            <InputCase
                                label="Organization"
                                value={companyValues?.organization}
                                setValue={setCompanyValues}
                                type="text"
                                name="organization"
                            />
                            <InputCase
                                label="Business Type"
                                value={companyValues?.business_type}
                                setValue={setCompanyValues}
                                type="text"
                                name="business_type"
                            />
                            <InputCase
                                label={window.innerWidth > '780px'?"LinkedIn Url (optional)":"LinkedIn Url"}
                                value={companyValues?.linkedin_url}
                                setValue={setCompanyValues}
                                type="url"
                                name="linkedin_url"
                                ref={linkedinRef}
                            />
                            <InputCase
                                label="City"
                                value={companyValues?.city}
                                setValue={setCompanyValues}
                                type="text"
                                name="city"
                            />
                            <InputCase
                                label="Company Website"
                                value={companyValues?.company_website}
                                setValue={setCompanyValues}
                                type="url"
                                name="company_website"
                                ref={websiteRef}
                            />
                        </Flex>
                    </Box>:null
                }
                <Box w="25%" ml="calc(100% - 30%)" mt="45px">
                    <Button onClick={handleUpdate} w="100%" bgColor="rgba(0, 26, 114, 1)" borderRadius="7px" color="white" padding="7.78px, 12.96px, 7.78px, 12.96px" fontSize="15px">Edit Profile</Button>
                </Box>
                </>:<Flex h="50vh" align="center" justify="space-around"><Spinner/></Flex>
            }
        </Box>
    </Box>
  )
}

const InputCase=forwardRef((props,ref)=>{
    const validateInput=()=>{
        if(props.value==="")
           return true
        if(props.type==="email" && !validRegex.test(props.value))
            return true
        if(props.name==="phone_number" && !validPhone.test(props.value))
            return true
        if(props.type==="url" && !validUrl.test(props.value))
            return true
        return false
    }
    const handleChange=(e)=>{
        if(props.name === "phone_number" && !isNaN(e.target.value)) 
            props.setValue((prev)=>{return {...prev,[e.target.name]:e.target.value}})
        else if(props.name !=="phone_number")
            props.setValue((prev)=>{return {...prev,[e.target.name]:e.target.value}})
    }
    return(
        <FormControl flex="0 0 48%">
            <FormLabel fontSize={{base:"15px",md:"17px"}}>{props.label} :</FormLabel>
            <Input
                isInvalid={validateInput()}
                type={props.type}
                onChange={handleChange}
                name={props.name}
                value={props.value}
                fontSize={{base:"14px",md:"16px"}}
                ref={ref}
            />
        </FormControl>
    )
}
)

export default ClientSettings