import React, { useState } from "react";
import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Input,
  Button,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { FiMail, FiPhoneCall } from "react-icons/fi";
import { IoLocationSharp } from "react-icons/io5";
import { FaInstagram, FaLinkedin, FaTwitter, FaFacebook } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { createContactUs } from "../actions/contact_us";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const bg=useSelector(state=>state.themeReducer.backgroundColor1)
  const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
  const colorH=useSelector(state=>state.themeReducer.color1)
  const colorP=useSelector(state=>state.themeReducer.color2)

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    message: "",
  });

  const { first_name, last_name, email, phone_number, message } = formData;

  const dispatch = useDispatch();
  const toast = useToast();

  const onChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone_number") {
      const regex = /^[0-9\b]+$/;
      if (value === "" || regex.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(createContactUs(formData));
      toast({
        title: "Message Sent",
        description: "Your message has been sent successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right", 
      });
      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        message: "",
      });
    } catch (error) {
      toast({
        title: "Error",
        description:
          "There was an error sending your message. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      <Flex
        flexDirection="column"
        width="100%"
        minHeight="85vh"
        backgroundColor={bg2}
        justifyContent="center"
        alignItems="center"
        paddingX={{ base: "1rem", md: "2rem" }}
      >
        <Box width={{ base: "90%", md: "70%" }} marginY="2rem">
          <Heading
            size="lg"
            textAlign="center"
            marginBottom="1rem"
            color={colorH}
            fontFamily="Poppins"
            fontWeight="700"
          >
            Contact Us
          </Heading>
          <Text
            textAlign="center"
            marginBottom="2rem"
            color={colorP}
            fontWeight={500}
          >
            Any question or remarks? Just write us a message!
          </Text>
          <Flex
            justify="space-between"
            alignItems="flex-start"
            backgroundColor={bg}
            padding="1rem"
            borderRadius="md"
            boxShadow="md"
            flexDirection={{ base: "column", md: "row" }}
            flexWrap="wrap"
          >
            <Box
              width={{ base: "100%", md: "40%" }}
              padding="2rem"
              backgroundColor="black"
              borderRadius="md"
              color="white"
              marginBottom={{ base: "2rem", md: "0" }}
            >
              <Heading size="lg" marginBottom="0.5rem" fontSize="1.5rem">
                Contact Information
              </Heading>

              <Text marginBottom="2.5rem" color="#C9C9C9" marginTop="0.5rem">
                Say something to start a live chat!
              </Text>
              <HStack
                marginBottom={{ base: "1rem", md: "1.5rem" }}
                spacing="1.5rem"
              >
                <FiPhoneCall />
                <Text marginLeft="0.5rem" color="#C9C9C9">
                  +212 632331441 || +212 632321083
                </Text>
              </HStack>
              <HStack
                marginBottom={{ base: "1rem", md: "1.5rem" }}
                spacing="1.5rem"
              >
                <FiMail />
                <Text marginLeft="0.5rem" color="#C9C9C9">
                  support@xcapitalterminal.com
                </Text>
              </HStack>
              <HStack
                marginBottom={{ base: "2rem", md: "2.5rem" }}
                spacing="1.5rem"
              >
                <IoLocationSharp />
                <Text marginLeft="0.5rem" color="#C9C9C9">
                  Casablanca, Morocco
                </Text>
              </HStack>
              <Flex justifyContent="space-around">
                <Box
                  backgroundColor="#1B1B1B"
                  width="2rem"
                  height="2rem"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="50%"
                >
                  <Link to="https://x.com/xcapitalfin">
                    <FaTwitter color="#FFFFFF" />
                  </Link>
                </Box>
                <Box
                  backgroundColor="#FFFFFF"
                  width="2rem"
                  height="2rem"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="50%"
                >
                  <Link to="https://www.instagram.com/xcapitalfin/">
                    <FaInstagram color="#000000" />
                  </Link>
                </Box>

                <Box
                  backgroundColor="#1B1B1B"
                  width="2rem"
                  height="2rem"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="50%"
                >
                  <Link to="https://www.linkedin.com/company/x-capitaltechnologies/">
                    <FaLinkedin color="#FFFFFF" />
                  </Link>
                </Box>

                <Box
                  backgroundColor="#FFFFFF"
                  width="2rem"
                  height="2rem"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="50%"
                >
                  <Link to="https://www.facebook.com/profile.php?id=61560422769620">
                    <FaFacebook color="#000000  " />
                  </Link>
                </Box>
              </Flex>
            </Box>
            <Box
              width={{ base: "100%", md: "55%" }}
              padding="2rem"
              textAlign="right"
            >
              <form onSubmit={onSubmit}>
                <Stack spacing="4">
                  <Flex
                    flexWrap="wrap"
                    justifyContent="space-between"
                    flexDirection={{ base: "column", md: "row" }}
                  >
                    <Input
                      name="first_name"
                      value={first_name}
                      onChange={onChange}
                      flex="1"
                      placeholder="First Name"
                      border="none"
                      borderBottom="1px solid #8D8D8D"
                      borderRadius="none"
                      paddingLeft="0.3rem"
                      _focus={{ boxShadow: "none" }}
                      marginBottom="1rem"
                      marginRight={{ base: "0", md: "7" }}
                    />
                    <Input
                      name="last_name"
                      value={last_name}
                      onChange={onChange}
                      flex="1"
                      placeholder="Last Name"
                      border="none"
                      borderBottom="1px solid #8D8D8D"
                      borderRadius="none"
                      paddingLeft="0.3rem"
                      _focus={{ boxShadow: "none" }}
                      marginBottom="1rem"
                      marginLeft={{ base: "0", md: "7" }}
                    />
                  </Flex>
                  <Flex
                    flexWrap="wrap"
                    justifyContent="space-between"
                    flexDirection={{ base: "column", md: "row" }}
                  >
                    <Input
                      name="email"
                      value={email}
                      onChange={onChange}
                      flex="1"
                      type="email"
                      placeholder="Email Address"
                      border="none"
                      borderBottom="1px solid #8D8D8D"
                      borderRadius="none"
                      paddingLeft="0.3rem"
                      _focus={{ boxShadow: "none" }}
                      marginBottom="1rem"
                      marginRight={{ base: "0", md: "7" }}
                    />
                    <Input
                      name="phone_number"
                      value={phone_number}
                      onChange={onChange}
                      flex="1"
                      placeholder="Phone Number"
                      border="none"
                      borderBottom="1px solid #8D8D8D"
                      borderRadius="none"
                      paddingLeft="0.3rem"
                      _focus={{ boxShadow: "none" }}
                      marginBottom="1rem"
                      marginLeft={{ base: "0", md: "7" }}
                    />
                  </Flex>
                  <Input
                    name="message"
                    value={message}
                    onChange={onChange}
                    placeholder="Write your message..."
                    border="none"
                    borderBottom="1px solid #8D8D8D"
                    borderRadius="none"
                    paddingLeft="0.3rem"
                    _focus={{ boxShadow: "none" }}
                    marginBottom="1rem"
                  />
                  <Button
                    type="submit"
                    colorScheme="blackAlpha"
                    backgroundColor="#2D2D2D"
                    color="#FFFFFF"
                    width="auto"
                    alignSelf="flex-end"
                    marginTop="1rem"
                  >
                    Send Message
                  </Button>
                </Stack>
              </form>
            </Box>
          </Flex>
        </Box>
      </Flex>

    </>
  );
};

export default ContactUs;
