import { GET_USERS_BY_ROLE_SUCCESS, GET_USERS_BY_ROLE_FAIL, GET_USERS_SUCCESS, GET_USERS_FAIL, ADD_USER_SUCCESS, ADD_USER_FAIL, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL, GET_USERS_BY_ID_SUCCESS, GET_USERS_BY_ID_FAIL, GET_USERS_BY_ROLE_CONDITION_SUCCESS, GET_USERS_BY_ROLE_CONDITION_FAIL, DOWNLOAD_USERS_EXCEL_SUCCESS, DOWNLOAD_USERS_EXCEL_BY_ROLE_SUCCESS, DOWNLOAD_USERS_EXCEL_FAIL, DOWNLOAD_USERS_EXCEL_BY_ROLE_FAIL } from '../actions/types';

const initialState = {
  users: [],
  loading: true,
  error: null,
  downloadSuccess: false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
    case GET_USERS_BY_ROLE_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null
      };
    case GET_USERS_FAIL:
    case GET_USERS_BY_ROLE_FAIL:
      return {
        ...state,
        users: [],
        loading: false,
        error: action.payload
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        loading: false,
        error: null
      };
    case ADD_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user => 
          user.id === action.payload.id ? action.payload : user
        ),
        loading: false,
        error: null
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
      case GET_USERS_BY_ID_SUCCESS:
        return {
          ...state,
          users: [action.payload], 
          loading: false,
          error: null
        };
      case GET_USERS_BY_ID_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
        case GET_USERS_BY_ROLE_CONDITION_SUCCESS:
          return {
            ...state,
            users: action.payload,
            loading: false,
            error: null,
          };
        case GET_USERS_BY_ROLE_CONDITION_FAIL:
          return {
            ...state,
            users: [],
            loading: false,
            error: action.payload,
          };
        case DOWNLOAD_USERS_EXCEL_SUCCESS:
        case DOWNLOAD_USERS_EXCEL_BY_ROLE_SUCCESS:
          return {
            ...state,
            downloadSuccess: true,
            error: null
          };
        case DOWNLOAD_USERS_EXCEL_FAIL:
        case DOWNLOAD_USERS_EXCEL_BY_ROLE_FAIL:
          return {
            ...state,
            downloadSuccess: false,
            error: action.payload
          };
        default:
          return state;
      }
    };
      
export default userReducer;