import { Box, Heading,Flex, Text, Spinner, Input, Stack,Image, Select } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { TbHeart, TbSearch, TbX } from 'react-icons/tb'
import { useSelector, useDispatch } from 'react-redux'
import ArticleClient from '../components/ArticleClient'
import fetchArticlesCategories from '../services/api/artilcesAPI/fetchArticleCategory'
import { getArticles } from '../actions/article'
import MokeUpArtciles from '../components/MokeUpArtciles'
import { useNavigate } from 'react-router-dom'
import { FiArrowDown, FiArrowUp } from 'react-icons/fi'

const ClientArticles = () => {
    const dispatch=useDispatch()

    const bg=useSelector(state=>state.themeReducer.backgroundColor1)
    const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
    const grayScales=useSelector(state=>state.themeReducer.grayScales)

    const [selectedCategory,setSelectedCategory]=useState("all")
    const [categories,setCategories]=useState([])
    const [categoryToShow,setCategoryToShow]=useState([])
    const [titleToShow,setTitleToShow]=useState([])
    const [artcilesToShow,setArticlesToShow]=useState([])
    const [isSearching,setIsSearching]=useState(false)
    const [isAvailable,setIsAvailable]=useState(null)
    const [isSubscriped,setIsSubscriped]=useState(null)
    const [isTrial,setIsTrial]=useState(null)
    const [showSearch,setShowSearch]=useState(false)
    const [searchInput,setSearchInput]=useState("")
    const [sort,setSort]=useState("desc")
    const [ready,setReady]=useState(false)

    const {articles,loading}=useSelector(state=>state.articleReducer)
    const trials=useSelector(state=>state.trialReducer.trials)
    const products=useSelector(state=>state.productReducer.products).filter(pr=>/articl/i.test(pr.title))
    const subscriptions=useSelector(state=>state.subscriptionReducer.subscriptions)

    useEffect(()=>{
        const fetchCat= async () => { 
            try{
                const categoriesRes=await fetchArticlesCategories()
                setCategories(categoriesRes)
            } catch (error) {
                console.log(error);
            }
        }
        fetchCat()
        dispatch(getArticles())
    },[])

    useEffect(()=>{
        if(selectedCategory==="all")
            setCategoryToShow(articles)
        else
            setCategoryToShow(articles.filter(article=>article.category.name===selectedCategory))
    },[selectedCategory,loading])

    useEffect(()=>{
        const idT=setTimeout(()=>{
            if(articles.length!==0){
                setIsAvailable(true)
                if(subscriptions && trials){
                    const isSub=subscriptions.filter(sb=>sb.article===articles[0].id && sb.is_active)
                    setIsSubscriped(isSub.length===0?false:isSub)
                    const trls=trials.filter(tr=>tr.is_active)
                    setIsTrial(trls.length !== 0)
                }
            }else if(products.length===0)
                setIsAvailable(false)
        },1000)
        return ()=>clearInterval(idT)
    },[products,subscriptions])

    useEffect(()=>{
        if(searchInput!=="" && searchInput!==" "){
            setIsSearching(true)
            const regex=new RegExp(searchInput,'i')
            setTitleToShow(categoryToShow.filter(article=>regex.test(article.title)))
        }else
            setIsSearching(false)
    },[searchInput])

    useEffect(()=>{
        if(sort==="desc")
            setArticlesToShow(artcilesToShow.sort((a,b)=>new Date(b.created_at)-new Date(a.created_at)))
        else
            setArticlesToShow(artcilesToShow.sort((a,b)=>new Date(a.created_at)-new Date(b.created_at)))
        setReady(true)
    },[sort,artcilesToShow])

    useEffect(()=>{
        setReady(false)
        setArticlesToShow(!isSearching?categoryToShow:titleToShow)
    },[isSearching,categoryToShow,titleToShow])

    const handleReadMore = (id) => {
        window.location.href="/article/"+id
    };

    const choseColor=(cat)=>{
        switch(cat){
            case "Finance":
            return "blue"
            case "Fashion":
            return "red"
            case "Branding":
            return "green"
            case "Technology":
            return "yellow"
            case "Travel":
            return "purple"
            default :
            return "black"
        }
    }
    const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate}`;
    };

    console.log(isSubscriped + " " + isTrial + " " + isAvailable)
    
  return (
    <Box bgColor={bg} minHeight="85vh" paddingTop="35px" ml={{base:"0",md:"60"}} paddingBottom='35px' position='relative'>
    {
        (isSubscriped  || isTrial ) && isAvailable?
        <>
        <Box ml={{base:"5%",lg:"10%"}} w={{base:"90%",lg:"80%"}}  overflow="hidden">
            <Flex align="center" justify="space-between" mb="20px" ml="0px" position="relative">
                <Heading as="h1" color={grayScales} fontSize={{base:"21px",lg:"25px"}}>Popular Topics</Heading>
                
                <Flex gap={{base:"15px",lg:"25px"}} align="center">
                    <Flex gap={"10px"}>
                    <Input 
                        bgColor={bg2}
                        borderColor={grayScales}
                        type="text"
                        size={{base:"xs",md:"s",lg:"lg"}}
                        position={{base:"absolute",md:"relative"}}
                        top={{base:!showSearch?"-100px":"0",md:'0'}}
                        left="0"
                        w={{base:"80%",lg:""}}
                        h={{base:"100%",lg:""}}
                        borderRadius="10px"
                        value={searchInput}
                        onChange={(e)=>{setSearchInput(e.target.value)}}
                    />
                    {
                        (showSearch && window.matchMedia("(max-width: 768px)").matches) || !window.matchMedia("(max-width: 768px)").matches ?
                            <Flex align={"center"} justify={"space-around"} width={"25px"} height={"25px"} borderRadius={"50%"} bgColor={grayScales} >  
                                <TbX fontSize="25px" onClick={()=>{setShowSearch(false) ; setSearchInput("") }} cursor="pointer"  color={grayScales==="black"?"white":"black"}/>
                            </Flex>
                        :  
                        <Flex align={"center"} justify={"space-around"} width={"25px"} height={"25px"} borderRadius={"50%"} bgColor={grayScales} >  
                            <TbSearch fontSize="18px" onClick={()=>{setShowSearch(true) ; setSearchInput("") }} cursor="pointer"  color={grayScales==="black"?"white":"black"}/>
                        </Flex>
                    }
                    </Flex>
                    <Flex flex={"0 0 fit-content"} align={"center"} gap={"10px"}>
                    {(!window.matchMedia("(max-width: 768px)").matches) ?
                        <Text fontSize={"16px"} bgColor={bg2} padding={"5px"} borderRadius={"10px"} border={"solid 1px "+grayScales}>{sort==="desc"?"Newest To Oldest":"Oldest To Newest"}</Text>:
                        null
                    }
                        <Flex align={"center"} justify={"space-around"} width={"25px"} height={"25px"} borderRadius={"50%"} bgColor={grayScales} >
                            {
                                sort==="desc"?
                                    <FiArrowDown fontSize={"22px"} color={grayScales==="black"?"white":"black"} cursor="pointer" onClick={()=>setSort("asc")}/>
                                :   <FiArrowUp fontSize={"22px"} color={grayScales==="black"?"white":"black"} cursor="pointer" onClick={()=>setSort("desc")}/>
                            }   
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex align="center" gap={{base:"7px",md:"10px",lg:"15px"}}>
                <Box key="all" onClick={()=>setSelectedCategory("all")} cursor='pointer'>
                    <Text color={selectedCategory==="all"?"orange":grayScales} fontWeight="500" fontSize={{base:"14px",lg:"16px"}}>
                        All
                    </Text>
                </Box>
                {
                    categories.map((category)=>{
                        return (
                            <Box key={category.id} onClick={(e)=>setSelectedCategory(category.name)} cursor="pointer">
                                <Text fontWeight="500" color={selectedCategory===category.name?"orange":grayScales} fontSize={{base:"14px",md:"15px",lg:"16px"}}>
                                    {category.name}
                                </Text>
                            </Box>
                        )
                    })
                }
            </Flex>
        </Box>
        <Flex w={{base:"95%",lg:"85%"}} ml={{base:"2.5%",lg:"7.5%"}} mt="25px" wrap="wrap" gap="20px" position="relative" justify={'space-around'}>
            {
                !loading && ready?
                    artcilesToShow.length!==0?
                        artcilesToShow.map((article)=>{
                            return(
                                <Box key={article.id} flex="0 0 300px"  bgColor={bg} paddingBottom={"10px"} cursor={"pointer"} onClick={()=>handleReadMore(article.id)}>
                                    <Image
                                    src={article.image_cover_url}
                                    alt={article.title}
                                    borderRadius="2px"
                                    h="172px"
                                    objectFit="cover"
                                    w="100%"
                                    />
                                    <Box padding="15px">
                                    <Text fontWeight={"500"} fontSize={"18px"} color={choseColor(article.category.name)}>
                                        {article.category.name}
                                    </Text>
                                    <Stack mt="4">
                                        <Heading fontSize="22px">{article.title}</Heading>
                                    </Stack>
                                    <Text mt="20px" fontWeight={"500"} color={"gray"}>{formatDate(article.created_at)}</Text>
                                    </Box>
                                </Box>
                            )
                        })
                    :
                        <Box 
                            bgColor={grayScales} 
                            color={grayScales==="black"?"white":"black"} 
                            padding="15px" 
                            fontSize="20px"
                            borderRadius='15px'
                            w={{base:"80%",md:"60%",lg:"40%"}}
                            mt="35px"
                            textAlign="center"
                        >
                            <Text>No Articles Available</Text>
                        </Box>
                :<Spinner position="absolute" top="50%" left="50%" transform="translate(-50%,-50%)"/>
            }
        </Flex>
        </>
            :(isSubscriped===null || isTrial===null?(isAvailable===false?<MokeUpArtciles id={products[0]?.id} type="NotAvailable"/>:<Spinner position="absolute" top="50%" left="50%" transform="translate(-50%,-50%)"/>):<MokeUpArtciles id={products[0]?.id} type="NotSubscribed"/>)
    }
    </Box>
  )
}

export default ClientArticles