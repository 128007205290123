import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";
import { FiTrash2 } from "react-icons/fi";
import { useSelector } from "react-redux";

const DeleteConfirmationModal = ({ isOpen, onClose, onDelete, userName, pageName }) => {
  const colorH=useSelector(state=>state.themeReducer.color1)
  const colorP=useSelector(state=>state.themeReducer.color2)
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" color={colorH} fontSize="lg" p={2} mb={0}>
          <Flex justifyContent="center" alignItems="center" mb={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#FFE0E3"
              borderRadius="full"
              boxSize="40px"
            >
              <FiTrash2 color="#DC3545" size="20px" />
            </Box>
          </Flex>
          Delete {pageName} ?
        </ModalHeader>
        <ModalBody textAlign="center" color={colorP} fontSize="sm" p={2} mt={0}>
          <Text>
            You're about to delete {userName}. This action cannot be undone.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Flex w="100%" justifyContent="space-between">
            <Button 
              variant="ghost" 
              onClick={onClose} 
              width="48%" 
              border="2px" 
              borderColor="#5F6980"
              fontSize="sm"
            >
              Cancel
            </Button>
            <Button 
              colorScheme="red" 
              onClick={onDelete} 
              width="48%" 
              fontSize="sm"
            >
              Delete
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteConfirmationModal;
