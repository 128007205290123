import React from 'react'
import { Flex, Box, Text,List, ListItem, UnorderedList, Heading } from '@chakra-ui/react'



function SecurityPolicy() {
  return (
    <>
       <Flex direction={"column"} p={10} mb="2em">
        <Box mb="2em">
            <Heading>Security Policy</Heading>
            <Text mb="1em"><b>Effective Date:</b> July 4, 2024</Text>
            <Text textAlign="left">X CAPITAL ("we," "us," "our") is committed to protecting your information. This Security Policy outlines our measures to safeguard your data.</Text>
        </Box>

        <Flex direction={"column"} gap={5}>
            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>1. Data Protection Measures</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>Use of encryption technologies to protect sensitive data.</ListItem>
                    <ListItem>Regular security assessments and updates.</ListItem>
                    <ListItem>Restricted access to personal data to authorized personnel only.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>2. User Responsibilities</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>Keep your account information and password confidential.</ListItem>
                    <ListItem>Report any suspicious activity to us immediately.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>3. Contact Us</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem listStyleType="none">If you have any questions about our Security Policy, please contact us at: contact@xcapitalterminal.com or call us at +212 632331441 or +212 632321083.</ListItem>
                </UnorderedList>
            </List>
        </Flex>
      </Flex>
    </>
  )
}

export default SecurityPolicy
