import { Box, Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { getUsersByRole } from "../../actions/user";

const getYTicks = (maxValue) => {
  if (maxValue < 10) {
    return [0, 2, 4, 6, 8, 10];
  } else if (maxValue < 100) {
    return [0, 20, 40, 60, 80, 100];
  } else if (maxValue < 1000) {
    return [0, 200, 400, 600, 800, 1000];
  } else {
    const step = Math.ceil(maxValue / 5);
    return Array.from({ length: 6 }, (_, i) => i * step);
  }
};

const OverviewChart = () => {
  const dispatch = useDispatch();
  const [clientData, setClientData] = useState([]);

  //for Dark/light mode
  const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
  const colorH=useSelector(state=>state.themeReducer.color1)
  const colorP=useSelector(state=>state.themeReducer.color2)


  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) { 
      return (
        <Box bg={bg2} p={2} borderRadius="md" boxShadow="md">
          <Text color={colorP}>{`Date : ${payload[0].payload.name}`}</Text>
          <Text color={colorP}>{`Client : ${payload[0].payload.client}`}</Text>
        </Box>
      );
    }
    return null;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const clients = await dispatch(getUsersByRole("Client"));

        // Get the current month and year
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        // Filter clients created in the current month
        const clientsThisMonth = clients.filter((client) => {
          const createdAt = new Date(client.created_at);
          return (
            createdAt.getMonth() === currentMonth &&
            createdAt.getFullYear() === currentYear
          );
        });

        // Format the filtered data
        const formattedData = clientsThisMonth.map((client) => {
          const createdAt = new Date(client.created_at);
          const day = createdAt.getDate();
          const monthName = monthNames[createdAt.getMonth()];

          return {
            name: `${monthName} ${day}`,
            client: 1, // Assuming each entry represents one client
          };
        });

        // Aggregate data by date
        const aggregatedData = formattedData.reduce((acc, curr) => {
          const existing = acc.find((item) => item.name === curr.name);
          if (existing) {
            existing.client += 1;
          } else {
            acc.push(curr);
          }
          return acc;
        }, []);

        setClientData(aggregatedData);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const maxClientValue = clientData.reduce(
    (max, item) => Math.max(max, item.client),
    0
  );

  // Get the appropriate ticks for the YAxis
  const yTicks = getYTicks(maxClientValue);

  return (
    <Box
      height="400px"
      overflow="hidden"
      borderRadius="md"
      boxShadow="md"
      pt="10px"
      bgColor={bg2}
    >
      <Heading as="h5" pl={"15px"} size="sm" mb="20px" textColor={colorH}>
        Client Overview
      </Heading>
      <ResponsiveContainer width="98%" height="85%">
        <AreaChart
          width={200}
          height={60}
          data={clientData}
          margin={{ top: 5, right: 15, left: 5, bottom: 1 }}
        >
         <XAxis 
            dataKey="name" 
            tick={{ fill: colorP }}
            axisLine={{ stroke: colorP }}
          />
          <YAxis 
            ticks={yTicks} 
            tick={{ fill: colorP }}
            axisLine={{ stroke: colorP }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="client"
            stroke="#0E5FD9"
            strokeWidth="3"
            fill="#CFDFF7"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default OverviewChart;
