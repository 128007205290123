import axios from 'axios';
import {
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL
} from './types';
import { getAuthConfig, getAuthConfigFile } from './config';

export const getProducts = () => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
    dispatch({
      type: GET_PRODUCTS_SUCCESS,
      payload: res.data
    });
    return res.data; 
  } catch (err) {
    dispatch({
      type: GET_PRODUCTS_FAIL,
      payload: err.message 
    });
    throw err; 
  }
};

export const createProduct = (productData) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/product/save/`, productData, config);
    dispatch({
      type: CREATE_PRODUCT_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CREATE_PRODUCT_FAIL,
      payload: err.response.data
    });
    throw err;  
  }
};

export const updateProduct = (id, productData) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/product/${id}/update/`, productData, config);
    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATE_PRODUCT_FAIL,
      payload: err.response.data
    });
    throw err;  
  }
};

export const deleteProduct = (id) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    await axios.delete(`${process.env.REACT_APP_API_URL}/product/${id}/delete`, config);
    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: id
    });
  } catch (err) {
    dispatch({
      type: DELETE_PRODUCT_FAIL,
      payload: err.message
    });
  }
};
