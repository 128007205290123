import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password } from '../actions/auth';
import {
    Flex,
    Heading,
    Input,
    Button,
    Stack,
    Box,
    Text,
    InputGroup,
    FormControl
} from '@chakra-ui/react';
import FailedLoginModal from "../components/FailedLoginModal";
import { PASSWORD_RESET_SUCCESS } from '../actions/types';



const ResetPassword = ({ reset_password }) => {
  const [requestSent, setRequestSent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
  });

  const { email } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    const res=await reset_password(email);
    if(res===PASSWORD_RESET_SUCCESS){
      setShowModal(false);
      setRequestSent(true);
    }else{
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 4000);
      setRequestSent(false);
    }
    
  };

  if (requestSent) {
    return <Navigate to='/login' />;
  }

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      minHeight="85vh"
      backgroundColor="#FFFFFF"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={{ base: "90%", md: "500px" }}>
          <form onSubmit={(e) => onSubmit(e)}>
            <Stack
              spacing={4}
              p="1rem"
              padding="40px"
              width={{ base: "90%", md: "400px" }}
              borderRadius="24px"
              border="solid 1px #828282"
              backgroundColor="#FFFFFF"
              boxShadow="md"
              height="100%"
              marginX={{ base: "1rem", md: "2rem" }}
            >
              <FormControl textAlign="center">
                <Heading
                  fontWeight="400"
                  fontSize={{ base: "30px", md: "35px" }}
                  lineHeight="50px"
                  fontFamily="Open Sans"
                  color="#00224E"
                  textAlign="left"
                >
                  Forgot Password
                </Heading>
                {showModal && (
                <FailedLoginModal
                  isOpen={showModal}
                  onClose={() => setShowModal(false)}
                  title="Reset Password Error"
                  message="This Mail not available. Please try again."
                />
                )}
                <Text
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="25px"
                  color="#828282"
                  textAlign="left"
                >
                  Enter your email for the verification proccess,we will send 4
                  digits code to your email.
                </Text>
              </FormControl>
              <FormControl textAlign="center" marginBottom="10px">
                <InputGroup>
                  <Input
                    type="email"
                    placeholder="Email Address"
                    color="#171E6C"
                    _placeholder={{ color: '#9BADCA' }}
                    name="email"
                    border="solid 1px #9BADCA"
                    value={email}
                    onChange={(e) => onChange(e)}
                    marginBottom="10px"
                    padding="8px 15px"
                    borderRadius="5px"
                    required
                    width="100%"
                  />
                </InputGroup>
              </FormControl>
              <Button
                borderRadius={5}
                type="submit"
                variant="solid"
                width="full"
                height="40px"
                border="none"
                marginY="10px"
                bg="#171E6C"
                color="#FFFFFF"
              >
                Continue
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default connect(null, { reset_password })(ResetPassword);
