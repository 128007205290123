import {Box, Divider, Heading,Text } from '@chakra-ui/react'
import React from 'react'
import { useSelector } from 'react-redux'

const ProductDetails = ({id}) => {
    const product=useSelector(state=>state.productReducer.products).filter(pr=>pr.id===id)
  return (
    <Box 
        position="fixed"
        top="50%" 
        left="60%"
        w="max(40%,300px)"
        transform="translate(-60%,-50%)" 
        padding="15px" 
        borderRadius="20px"
        bgColor="white"
        paddingBottom="30px"
        boxShadow="2px 2px 5px gray"
        zIndex="3"
    >
        <Box textAlign="center" paddingBottom="25px" paddingTop="10px">
            <Heading fontSize="28px" mb="5px">Products Details</Heading>
            <Text color="gray">Lorem ipsum dolor sit amet</Text>
        </Box>
        <Divider/>
        <Box paddingTop="25px" paddingLeft="50px">
            <Heading fontSize="20px" mb="10px">Title</Heading>
            <Text fontSize="16px">{product[0].title}</Text>
        </Box>
        <Box  paddingLeft="50px" mt="20px">
            <Heading fontSize="20px" mb="10px">Description</Heading>
            <Text fontSize="16px">{product[0].description}</Text>
        </Box>
    </Box>
  )
}

export default ProductDetails