import axios from 'axios';
import {
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  GET_MESSAGE_BY_ID_SUCCESS,
  GET_MESSAGE_BY_ID_FAIL,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAIL,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_FAIL,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAIL,
  GET_MESSAGES_BETWEEN_USERS_SUCCESS,
  GET_MESSAGES_BETWEEN_USERS_FAIL,
  GET_MESSAGES_FOR_USER_SUCCESS,
  GET_MESSAGES_FOR_USER_FAIL,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_FAIL,
  GET_MESSAGES_SENDER_FOR_USER_SUCCESS,
  GET_MESSAGES_SENDER_FOR_USER_FAIL
} from './types';
import { getAuthConfig, getAuthConfigFile } from './config';

export const getAllMessages = () => async dispatch => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/messages/`, config);
    dispatch({
      type: GET_MESSAGES_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_MESSAGES_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const getMessageById = (id) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/messages/${id}`, config);
    dispatch({
      type: GET_MESSAGE_BY_ID_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_MESSAGE_BY_ID_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const createMessage = (messageData) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/messages/create/`, messageData, config);
    dispatch({
      type: CREATE_MESSAGE_SUCCESS,
      payload: res.data
    });
    return res.data
  } catch (err) {
    dispatch({
      type: CREATE_MESSAGE_FAIL,
      payload: err.response.data.message,
    });
    throw err.response.data.message;
  }
};

export const updateMessage = (id, messageData) => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/messages/update/${id}/`, messageData, config);
    dispatch({
      type: UPDATE_MESSAGE_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATE_MESSAGE_FAIL,
      payload: err.message
    });
  }
};

export const deleteMessage = (id) => async dispatch => {
  try {
    const config = getAuthConfig();
    await axios.delete(`${process.env.REACT_APP_API_URL}/messages/delete/${id}/`, config);
    dispatch({
      type: DELETE_MESSAGE_SUCCESS,
      payload: id
    });
  } catch (err) {
    dispatch({
      type: DELETE_MESSAGE_FAIL,
      payload: err.message
    });
  }
};

export const getMessagesBetweenUsers = (senderId, receiverId) => async (dispatch) => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/messages/between/${senderId}/${receiverId}/`, config);
    dispatch({
      type: GET_MESSAGES_BETWEEN_USERS_SUCCESS,
      payload: res.data
    });
    return res.data; 
  } catch (err) {
    dispatch({
      type: GET_MESSAGES_BETWEEN_USERS_FAIL,
      payload: err.message
    });
    throw err;  
  }
};

export const getMessagesForUser = (receiverId) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/messages/receiver/${receiverId}/`, config);
    dispatch({
      type: GET_MESSAGES_FOR_USER_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_MESSAGES_FOR_USER_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const getMessagesSenderForUser = (senderId) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/messages/sender/${senderId}/`, config);
    dispatch({
      type: GET_MESSAGES_SENDER_FOR_USER_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_MESSAGES_SENDER_FOR_USER_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const getConversation = (subject, senderId, receiverId) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/messages/conversation/${subject}/${senderId}/${receiverId}/`, config);
    dispatch({
      type: GET_CONVERSATION_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_CONVERSATION_FAIL,
      payload: err.message
    });
    throw err;
  }
};