import React from 'react'
import { Flex, Box, Text, List, ListItem, UnorderedList, Heading } from '@chakra-ui/react'


function TermofServices() {
  return (
    <>
     <Flex direction={"column"} p={10} mb="2em">
        <Box mb="2em">
            <Heading>Terms of Service</Heading>
            <Text mb="1em"><b>Effective Date:</b> July 4, 2024</Text>
            <Text textAlign="left">Welcome to X CAPITAL. These Terms of Service ("Terms") govern your use of our website xcapitalterminal.com and our services. By accessing or using our website, you agree to be bound by these Terms.</Text>
        </Box>

        <Flex direction={"column"} gap={5}>
            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>1. Use of Our Services</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>You must be at least 18 years old to use our services.</ListItem>
                    <ListItem>You agree to provide accurate and complete information when creating an account.</ListItem>
                    <ListItem>You are responsible for maintaining the confidentiality of your account and password.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>2. Prohibited Activities</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>Violating any applicable laws or regulations.</ListItem>
                    <ListItem>Infringing on the intellectual property rights of others.</ListItem>
                    <ListItem>Transmitting harmful code or malware.</ListItem>
                    <ListItem>Engaging in fraudulent or deceptive practices.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>3. Intellectual Property</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>All content on our website, including text, graphics, logos, and software, is the property of X CAPITAL and is protected by copyright and trademark laws.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>4. Limitation of Liability</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem listStyleType="none">X CAPITAL will not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of our website.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>5. Changes to Terms</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>We reserve the right to modify these Terms at any time. Your continued use of our website constitutes acceptance of the updated Terms.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>6. Governing Law</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>These Terms are governed by the laws of Morocco. Any disputes will be resolved in the courts of Casablanca, Morocco.</ListItem>
                </UnorderedList>
            </List>
            
            
            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>7. Contact Us</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem listStyleType="none">If you have any questions about these Terms, please contact us at: contact@xcapitalterminal.com or call us at +212 632331441 or +212 632321083.</ListItem>
                </UnorderedList>
            </List>
        </Flex>
      </Flex>
    </>
  )
}

export default TermofServices
