import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  useToast,
  Select,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { createPreOrder, updatePreOrder, getAllPreOrders } from "../actions/preorderActions";
import { load_user } from "../actions/auth";

const AddPreOrderModal = ({ isOpen, onClose, type, initialData }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const toast = useToast();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    status:"",
    privacyChecked: false,
  });
  console.log(formData);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(load_user());
  }, [dispatch]);

  useEffect(() => {
    if (isOpen) {
      if (type === "update" && initialData) {
        setFormData({
          firstName: initialData.first_name,
          lastName: initialData.last_name,
          email: initialData.email,
          phoneNumber: initialData.phone_number,
          status:initialData.status,
          privacyChecked: initialData.privacy_checked,
        });
      } else {
        resetForm();
      }
    }
  }, [type, initialData, isOpen]);

  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      privacyChecked: false,
    });
    setErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Email is invalid";
    if (!formData.phoneNumber) newErrors.phoneNumber = "Phone Number is required";
    else if (!/^\d{10,15}$/.test(formData.phoneNumber)) newErrors.phoneNumber = "Phone Number must be between 10 and 15 digits";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      toast({
        title: "Form Submission Error",
        description: "Please fix the errors in the form.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const preOrderData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      phone_number: formData.phoneNumber,
      privacy_checked: formData.privacyChecked,
      status: formData.status,
      created_by: user.id,
    };

    try {
      if (type === "update" && initialData) {
        await dispatch(updatePreOrder(initialData.id, preOrderData));
      } else {
        await dispatch(createPreOrder(preOrderData));
      }
      dispatch(getAllPreOrders());
      handleClose();
      toast({
        title: "Success",
        description: `PreOrder ${type === "update" ? "updated" : "created"} successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error handling preorder:", error);
      toast({
        title: "Error",
        description: "An error occurred while handling the preorder.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent maxW="600px">
        <ModalHeader>
          {type === "update" ? "Update PreOrder" : "Add New PreOrder"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={!!errors.firstName} mt={4}>
            <Input
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="First Name"
            />
          </FormControl>
          <FormControl isInvalid={!!errors.lastName} mt={4}>
            <Input
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="Last Name"
            />
          </FormControl>
          <FormControl isInvalid={!!errors.email} mt={4}>
            <Input
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
            />
          </FormControl>
          <FormControl isInvalid={!!errors.phoneNumber} mt={4}>
            <Input
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              placeholder="Phone Number"
              type="tel"
            />
          </FormControl>
          <FormControl mt={4}l>
            <Select onChange={handleInputChange} name="status">
            {(formData.status==="Pending")?<option value={"Pending"} selected>Pending</option> :<option value={"Pending"} >Pending</option>}
              {(formData.status==="Accepted") ?<option value={"Accepted"} selected>Accepted</option> :<option value={"Accepted"} >Accepted</option>}
              {(formData.status==="Rejected")?<option value={"Rejected"} selected>Rejected</option>:<option value={"Rejected"}>Rejected</option>}
              {formData.status==="Forwarded"?<option value={"Forwarded"} selected>Forwarded</option>:<option value={"Forwarded"}>Forwarded</option>}
            </Select>
          </FormControl>
          <FormControl mt={4}>
            <label>
              <input
                type="checkbox"
                name="privacyChecked"
                checked={formData.privacyChecked}
                onChange={handleInputChange}
              />
              Privacy Policy Accepted
            </label>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#001A72"
            color="#FFFFFF"
            _hover={{ bg: "#001A72" }}
            ml={3}
            onClick={handleSubmit}
          >
            {type === "update" ? "Update PreOrder" : "Add PreOrder"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddPreOrderModal;
