import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  Textarea,
  Select,
  useToast
} from "@chakra-ui/react";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getUsersByRoleCondition } from '../../../actions/user';
import { load_user } from '../../../actions/auth';
import { createMessage } from '../../../actions/chatActions';

const MessageForm = () => {
  const [role, setRole] = useState('Client'); 
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const userC=useSelector(state=>state.auth.user?.id)

  useEffect(() => {
    dispatch(load_user()).then((data) => {
      setRole(data.role);
    })
    .catch((error) => {
      console.error("Error fetching users:", error);

      if (error.response && error.response.status === 403) {
        navigate("/Not-Access");
      } else if (error.response && error.response.status === 401){
        navigate("/login");
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const fetchedUsers = await dispatch(getUsersByRoleCondition(role));
        setUsers(fetchedUsers);
      } catch (err) {
        toast({
          title: "Error",
          description: "Failed to load users.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchUsers();
  }, [dispatch, role, toast]);

  const handleSendMessage = async () => {
    if (!selectedUser || !subject || (!message && !selectedFile)) {
      toast({
        title: "Error",
        description: "Please fill all required fields.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("sender", userC); 
    formData.append("receiver", selectedUser);
    formData.append("subject", subject);
    formData.append("content", message);
    formData.append("is_active", 1);
    if (selectedFile) {
      formData.append("file", selectedFile);
    }

    try {
      await dispatch(createMessage(formData));
      toast({
        title: "Message Sent",
        description: "Your message has been sent successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Clear form fields
      setSelectedUser('');
      setSubject('');
      setMessage('');
      setSelectedFile(null);

      // Redirect to messages page
      navigate(`/x-capital/terminal/${role}/messages`);
    } catch (error) {
      console.error("Failed to send message:", error);
      toast({
        title: "Error",
        description: "Failed to send message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  return (
    <>
      <Box minH="100vh" ml={{ base: 0, md: 60 }}>
        <Box p={4} width={{base:'100%',md:"100%",lg:"80%"}} ml={{base:"0%",md:"0%",lg:"10%"}}>
          <Button bgColor="transparent" color="#000035">
            <Link to={`/x-capital/terminal/${role}/messages`}>
              <FaArrowAltCircleLeft fontSize="1.5em" />
            </Link>
          </Button>
          <Flex direction="column"
                gap={5}
                width={{base:'90%',md:"85%",lg:"60%"}}
                ml="auto"
                mr="auto"
                mt="5em">
            <Select placeholder='Message to'
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value)}>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.first_name} {user.last_name}
                </option>
              ))}
            </Select>
            <Input
              placeholder='Subject'
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <Textarea
              placeholder='Message'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Input
              type="file"
              accept="*"
              onChange={handleFileChange}
              display="none"
              id="fileInput"
            />
            <Button
              mt="2"
              onClick={() => document.getElementById("fileInput").click()}
            >
              Select File
            </Button>
            <Button
              bgColor="#000042"
              color="white"
              _hover={{
                bgColor:"#000042",
                color:"white"
              }}
              onClick={handleSendMessage}
            >
              Send Message
            </Button>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default MessageForm;
