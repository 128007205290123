import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Flex,
  Heading,
  Input,
  InputLeftAddon,
  InputGroup,
  Select,
  Button,
  Spinner,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import {
  Search2Icon,
  DeleteIcon,
  ViewIcon,
} from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import fetchPreOrders from "../../services/api/preOrdersAPI/fetchPreOrders";
import DeletePreOrderCard from "./DeletePreOrderCard";
import ViewPredOrderCard from "./ViewPreOrderCard";
import StatusDots from "../StatusDots";
import NavBar from "../NavBar";
import Footer from "../Footer";

export default function PreOrderPanel() {
  const [preOrders, setPreOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const overlayStyle = { background: "rgba(0,0,0,0.5)" };
  const contentStyle = { background: "#fff", borderRadius: "12px" };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchPreOrders();
        setPreOrders(result);
      } catch (error) {
        console.error("Error fetching pre-orders:", error);
        setError("Failed to fetch pre-orders");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <>
      <NavBar />
      <Flex direction={"column"} padding={"32px"} gap={"24px"}>
        <Heading>Pre-Orders</Heading>
        <Flex gap={"16px"}>
          <InputGroup>
            <InputLeftAddon>
              <Search2Icon />
            </InputLeftAddon>
            <Input type="text" placeholder="Search..." />
          </InputGroup>
          <Select placeholder="Filter" width={"20%"}>
            <option value="option1">Latest</option>
            <option value="option2">Oldest</option>
          </Select>
        </Flex>
        {loading ? (
          <Spinner size="xl" />
        ) : error ? (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        ) : (
          <TableContainer borderRadius={"12px"}>
            <Table variant="simple">
              <TableCaption>List of pre-orders.</TableCaption>
              <Thead>
                <Tr bg={"#b8c3d3"}>
                  <Th>Full name</Th>
                  <Th>Email</Th>
                  <Th>Status</Th>
                  <Th>Created at</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {preOrders.map((preOrder) => (
                  <Tr key={preOrder.id}>
                    <Td>{`${preOrder.first_name} ${preOrder.last_name}`}</Td>
                    <Td>{preOrder.email}</Td>
                    <Td> <Flex gap={"8px"} align={"center"} > <StatusDots status={preOrder.status} /> {preOrder.status}</Flex></Td>
                    <Td>{formatDate(preOrder.created_at)}</Td>
                    <Td width={"40"}>
                      <Flex gap={"16px"}>
                        <Popup
                          trigger={
                            <Button>
                              <ViewIcon />
                            </Button>
                          }
                          modal
                          position="center center"
                          {...{ overlayStyle, contentStyle }}
                        >
                          <ViewPredOrderCard
                            preOrderId={preOrder.id}
                            email={preOrder.email}
                            fullName={`${preOrder.first_name} ${preOrder.last_name}`}
                            phoneNumber={preOrder.phone_number}
                            company={preOrder.company.organization}
                            product={preOrder.product.title}
                            demo={String(preOrder.is_demo)}
                            companyId={preOrder.company.id}
                            productId={preOrder.product.id}
                            firstName={preOrder.first_name}
                            lastName={preOrder.last_name}
                          />
                        </Popup>
                        <Popup
                          trigger={
                            <Button>
                              <DeleteIcon />
                            </Button>
                          }
                          modal
                          position="center center"
                          {...{ overlayStyle, contentStyle }}
                        >
                          <DeletePreOrderCard preOrderId={preOrder.id} />
                        </Popup>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Flex>
      <Footer />
    </>
  );
}
