import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  HStack,
  Text,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
  Select,
  Stack,
} from "@chakra-ui/react";
import {
  FiPlus,
  FiSearch,
  FiTrash2,
  FiChevronDown,
  FiArrowLeft,
  FiArrowRight,
  FiFileText,
  FiEdit,
  FiDownload,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { deleteDocument, getFiles, downloadDocument } from "../../actions/file";
import { format } from "date-fns";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import AddFileModal from "../../components/AddFileModal";
import { useNavigate } from "react-router-dom";
import { getUserById } from "../../actions/user";  
export default function AdminFilesPage() {
   //for Dark/light mode
  const bg=useSelector(state=>state.themeReducer.backgroundColor1)
  const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
  const colorH=useSelector(state=>state.themeReducer.color1)
  const colorAdminP=useSelector(state=>state.themeReducer.colorAdmin2)
  const grayScales=useSelector(state=>state.themeReducer.grayScales)
  const blueAdmin=useSelector(state=>state.themeReducer.blueAdmin)
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const files = useSelector((state) => state.fileReducer.files);
  const loading = useSelector((state) => state.fileReducer.loading);
  const error = useSelector((state) => state.fileReducer.error);

  const [currentPage, setCurrentPage] = useState(0);
  const [filesPerPage, setFilesPerPage] = useState(5);
  const [sortOption, setSortOption] = useState("file_name");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [isUpdateFileModalOpen, setIsUpdateFileModalOpen] = useState(false);
  const [fileToUpdate, setFileToUpdate] = useState(null);
  const [userNames, setUserNames] = useState({});

  useEffect(() => {
    dispatch(getFiles())
      .then((data) => {
        fetchUserNames(data);
      })
      .catch((error) => {
        console.error("Error fetching files:", error);
        if (error.response && error.response.status === 403) {
          navigate("/Not-Access");
        } else if (error.response && error.response.status === 401) {
          navigate("/login");
        }
      });
  }, [dispatch, navigate]);

  const fetchUserNames = async (files) => {
    const userIds = [...new Set(files.map((file) => file.uploaded_by))];
    const userNamesMap = {};
    for (const id of userIds) {
      try {
        const user = await getUserById(id)(dispatch);
        userNamesMap[id] = `${user.first_name} ${user.last_name}`;
      } catch (error) {
        console.error(`Error fetching user with ID ${id}:`, error);
      }
    }
    setUserNames(userNamesMap);
  };

  useEffect(() => {
    let filtered = filterFiles(files, searchTerm);
    if (selectedMonth) {
      filtered = filtered.filter((file) => {
        const fileMonth = new Date(file.uploaded_at).getMonth();
        return fileMonth === parseInt(selectedMonth);
      });
    }
    setFilteredFiles(sortFiles(filtered, sortOption));
    setCurrentPage(0);
  }, [files, searchTerm, sortOption, selectedMonth]);

  const offset = currentPage * filesPerPage;
  const totalPages = Math.ceil(filteredFiles.length / filesPerPage);

  const sortFiles = (files, option) => {
    return [...files].sort((a, b) => {
      const aValue = a[option];
      const bValue = b[option];
      if (aValue < bValue) {
        return -1;
      }
      if (aValue > bValue) {
        return 1;
      }
      return 0;
    });
  };

  const filterFiles = (files, term) => {
    if (!term) return files;
    return files.filter((file) => {
      const fileName = extractFileName(file.file_name).name.toLowerCase();
      const uploadedBy = userNames[file.uploaded_by]
        ? userNames[file.uploaded_by].toLowerCase()
        : "";
      const uploadedAt = file.uploaded_at
        ? format(new Date(file.uploaded_at), "dd MMM yyyy").toLowerCase()
        : "";

      return (
        fileName.includes(term.toLowerCase()) ||
        uploadedBy.includes(term.toLowerCase()) ||
        uploadedAt.includes(term.toLowerCase())
      );
    });
  };

  const extractFileName = (filePath) => {
    if (!filePath) return { name: "", extension: "" };
    const startIndex = filePath.lastIndexOf("/") + 1;
    const name = filePath.substring(startIndex, filePath.lastIndexOf("."));
    const extension = filePath.substring(filePath.lastIndexOf(".") + 1);
    return { name, extension };
  };

  const handleDeleteClick = (file) => {
    setFileToDelete(file);
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteDocument(fileToDelete.id))
      .then(() => {
        dispatch(getFiles())
          .then((data) => {
            setIsModalOpen(false);
            setFileToDelete(null);
          })
          .catch((error) => console.error("Error fetching files:", error));
      })
      .catch((error) => console.error("Error deleting file:", error));
  };

  const currentFiles = filteredFiles.slice(offset, offset + filesPerPage);

  const handleUpdateClick = (file) => {
    setFileToUpdate(file);
    setIsUpdateFileModalOpen(true);
  };

  const handleDownloadClick = (file) => {
    dispatch(downloadDocument(file.id))
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          extractFileName(file.file_name).name +
            "." +
            extractFileName(file.file_name).extension
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  return (
    <Box minH="100vh" bg={bg}>
      <Box ml={{ base: 0, md: 60 }} p={4}>
        <Heading fontSize="24px" mb={4} color={blueAdmin}>
          Documents
        </Heading>
        <HStack mb={4}>
          <InputGroup width={{base:"65%",lg:"75%"}}>
            <InputLeftElement pointerEvents="none">
              <FiSearch color="gray" />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Search documents"
              bg={bg2}
              borderRadius="16px"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              _focus={{ boxShadow: "none" }}
            />
          </InputGroup>
          <HStack
            spacing={0}
            width={{base:"30%",lg:"25%"}}
            padding="12px 12px 12px 20px"
          >
            <Text color={colorH} fontSize={{base:'12px',md:"13px",lg:"15px"}} fontWeight="600" textAlign={"center"}>
              Sort by
            </Text>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<FiChevronDown color={grayScales} />}
                bg={bg}
                _hover={{ bg: {bg} }}
                _active={{ bg: {bg} }}
                _focus={{ boxShadow: "none" }}
              />
              <MenuList>
                <MenuItem onClick={() => setSortOption("file_name")}>
                  File Name
                </MenuItem>
                <MenuItem onClick={() => setSortOption("uploaded_by")}>
                  Uploaded By
                </MenuItem>
                <MenuItem onClick={() => setSortOption("uploaded_at")}>
                  Upload Date
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </HStack>
        <Flex mb={4} justifyContent="flex-end">
          <Select
            placeholder="Select month"
            mr="4"
            width="200px"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            _focus={{ borderColor: "transparent", boxShadow: "none" }}
            _hover={{ borderColor: "transparent" }}
            flex={{base:"0 0 48%",md:"0 0 35%",lg:"0 0 25%"}}
          >
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i} value={i}>
                {format(new Date(0, i), "MMMM")}
              </option>
            ))}
          </Select>
          <Button
            rightIcon={<FiPlus />}
            color="#FFFFFF"
            backgroundColor="#001A72"
            _hover={{ backgroundColor: "#001A72" }}
            _focus={{ backgroundColor: "#001A72" }}
            onClick={() => setIsAddFileModalOpen(true)}
            flex={{base:"0 0 48%",md:"0 0 35%",lg:"0 0 25%"}}
            fontSize={{base:"14px",md:"16px",lg:"18px"}}
          >
            Add document
          </Button>
        </Flex>
        <Box
          overflowX="auto"
          bg={bg2}
          borderRadius="16px"
          border="solid 1px #EFF4FA"
          p={4}
        >
          <Text
            color={colorH}
            fontSize="18px"
            fontWeight="bold"
            mb="10px"
            flex="1"
          >
            List of documents
          </Text>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th color={colorAdminP} fontSize={{base:"12px",md:"16x"}}></Th>
                <Th color={colorAdminP} fontSize={{base:"12px",md:"16x"}}>File Name</Th>
                <Th color={colorAdminP} fontSize={{base:"12px",md:"16x"}}>Uploaded By</Th>
                <Th color={colorAdminP} fontSize={{base:"12px",md:"16x"}}>Upload Date</Th>
                <Th color={colorAdminP} fontSize={{base:"12px",md:"16x"}}>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Tr>
                  <Td colSpan={5}>
                    <Spinner />
                  </Td>
                </Tr>
              ) : error ? (
                <Tr>
                  <Td colSpan={5}>Error: {error}</Td>
                </Tr>
              ) : (
                currentFiles.map((file) => (
                  <Tr key={file.id}>
                    <Td>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="40px"
                        height="40px"
                        bg="#ECF0F3"
                        borderRadius="8px"
                      >
                        <FiFileText color={blueAdmin} />
                      </Box>
                    </Td>
                    <Td>
                      <Text color={blueAdmin} fontWeight="620" fontSize={{base:"13px",md:"14px"}}>
                        {extractFileName(file.file_name).name}
                      </Text>
                      <Text color={colorH} ml="10px"  fontSize={{base:"13px",md:"14px"}}>
                        {extractFileName(file.file_name).extension}
                      </Text>
                    </Td>
                    <Td fontSize={{base:"13px",md:"14px"}} color={colorH}>{userNames[file.uploaded_by] || file.uploaded_by}</Td>
                    <Td fontSize={{base:"13px",md:"14px"}} color={colorH}>
                      {file.uploaded_at
                        ? format(new Date(file.uploaded_at), "dd-MM-yyyy")
                        : ""}
                    </Td>
                    <Td fontSize={{base:"13px",md:"14px"}}>
                      <HStack spacing={2}>
                        <Box
                          width="25px"
                          height="25px"
                          borderRadius={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border="1px solid #CBD5E0"
                        >
                          <FiDownload color={blueAdmin} onClick={() => handleDownloadClick(file)}/>
                        </Box>
                        <Box
                          width="25px"
                          height="25px"
                          borderRadius={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border="1px solid #CBD5E0"
                        >
                          <FiEdit
                            color={blueAdmin}
                            onClick={() => handleUpdateClick(file)}
                          />
                        </Box>
                        <Box
                          width="25px"
                          height="25px"
                          borderRadius={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border="1px solid #CBD5E0"
                        >
                          <FiTrash2
                            color={blueAdmin}
                            onClick={() => handleDeleteClick(file)}
                          />
                        </Box>
                      </HStack>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="20px"
          bg={bg}
          p="10px"
          borderRadius="8px"
        >
          <Stack direction="row" spacing={4} alignItems="center">
            <Text mr="10px" color="#717171">
              Rows per page:
            </Text>
            <select
              onChange={(e) => {
                setCurrentPage(0);
                setFilesPerPage(parseInt(e.target.value, 10));
              }}
              value={filesPerPage}
              style={{
                background: bg,
                border: "none",
                borderRadius: "4px",
                padding: "5px",
                marginRight: "10px",
                color: "#717171",
              }}
            >
              {[5, 10, 15, 20].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <Text color="#717171">
              {offset + 1}–
              {Math.min(offset + filesPerPage, filteredFiles.length)} of{" "}
              {filteredFiles.length}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center" ml={10}>
            <FiArrowLeft
              onClick={() =>
                setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))
              }
              color={currentPage === 0 ? bg : "#717171"}
              style={{ cursor: currentPage === 0 ? "not-allowed" : "pointer" }}
            />
            <FiArrowRight
              onClick={() =>
                setCurrentPage((prevPage) =>
                  Math.min(prevPage + 1, totalPages - 1)
                )
              }
              color={currentPage >= totalPages - 1 ? bg : "#717171"}
              style={{
                cursor:
                  currentPage >= totalPages - 1 ? "not-allowed" : "pointer",
              }}
            />
          </Stack>
        </Box>
      </Box>
      {fileToDelete && (
        <DeleteConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onDelete={handleDeleteConfirm}
          userName={`${extractFileName(fileToDelete.file_name).name}`}
          pageName="Document"
        />
      )}
      <AddFileModal
        isOpen={isAddFileModalOpen}
        onClose={() => setIsAddFileModalOpen(false)}
      />
      <AddFileModal
        isOpen={isUpdateFileModalOpen}
        onClose={() => setIsUpdateFileModalOpen(false)}
        type="update"
        initialData={fileToUpdate}
      />
    </Box>
  );
}
