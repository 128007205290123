import { GET_ARTICLES_SUCCESS, GET_ARTICLES_FAIL } from '../actions/types';

const initialState = {
  articles: [],
  loading: true,
  error: null
};

const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload,
        loading: false,
        error: null
      };
    case GET_ARTICLES_FAIL:
      return {
        ...state,
        articles: [],
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default articleReducer;