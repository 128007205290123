import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  HStack,
  VStack,
  Text,
  Card,
  Select,
  Circle,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  Flex,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { FiBox, FiFileText, FiTrendingDown, FiTrendingUp, FiUsers } from "react-icons/fi";
import { VscGraphLine } from "react-icons/vsc";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ReferenceLine,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
} from 'recharts';
import { getUsers } from "../../actions/user";
import { getOfferDetails } from "../../actions/offer";
import { getProducts } from "../../actions/product";
import { getFiles } from "../../actions/file";
import { getUsersByRole } from "../../actions/user";
import { getSubscriptions } from "../../actions/subscription";
import { useNavigate } from "react-router-dom";
import { getArticles } from "../../actions/article";
import { fetchAllViews } from "../../services/api/views";

const COLORS = [
  "#0E5FD9",
  "#FF9500",
  "#E84646",
  "#34A853",
  "#FABB05",
  "#8E24AA",
  "#39C0ED",
  "#FF4081",
  "#FFB300",
  "#7CB342",
  "#5E35B1",
  "#00ACC1",
  "#FB8C00",
  "#C0CA33",
  "#8D6E63",
  "#78909C",
];

export default function AdminHomePage() {
  //for Dark/light mode
  const bg=useSelector(state=>state.themeReducer.backgroundColor1)
  const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
  const colorH=useSelector(state=>state.themeReducer.color1)
  const colorP=useSelector(state=>state.themeReducer.color2)
  const colorAdminP=useSelector(state=>state.themeReducer.colorAdmin2)
  const grayScales=useSelector(state=>state.themeReducer.grayScales)

  const [hoveredItem, setHoveredItem] = useState(null);
  const [totalUsers, setTotalUsers] = useState(null);
  const [totalOffers, setTotalOffers] = useState(null);
  const [totalPrices, setTotalPrices] = useState(null);
  const [totalDocuments, setTotalDocuments] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [selectedMonth, setSelectedMonth] = useState("thisMonth");
  const [selectedMonthSub, setSelectedMonthSub] = useState("thisMonth");
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [clientOverviewData, setClientOverviewData] = useState([]);
  const [usersStats, setUsersStat] = useState(0);
  const [offerStats, setOfferStat] = useState(0);
  const [fileStats, setFileStat] = useState(0);
  const [revenueStats, setRevenueStat] = useState(0);
  const navigate = useNavigate();
  const [articlesData, setArticlesData] = useState([]);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const users=useSelector(state=>state.userReducer.users)
  const articles=useSelector(state=>state.articleReducer.articles)
  const [articlesDESC,setArticlesDESC]=useState([])
  const [notDone,setNotDone]=useState(true)

  const [dataOffers, setDataOffers] = useState([
    { name: "Accepted", value: 0 },
    { name: "Rejected", value: 0 },
    { name: "Forwarded", value: 0 },
    { name: "Pending", value: 0 },
  ]);

  const [dataUsers, setDataUsers] = useState([
    { name: "Admin", value: 0 },
    { name: "Manager", value: 0 },
    { name: "Client", value: 0 },
    { name: "Demo Client", value: 0 },
    { name: "Article User", value: 0 },
    { name: "Sales", value: 0 },
  ]);

  const [dataSubscriptions, setDataSubscriptions] = useState([]);
  const [views, setViews] = useState(null);
  
const setInterval=(monthsToSubstract,year,month,intervals)=>{
  let startDate
  const diff=parseInt(month - monthsToSubstract)
  const endDate = new Date(year, month + 1, 0);
  if(diff < 0)
    startDate= new Date(year - 1 , 12 + diff, 1)
  else
    startDate= new Date(year , diff , 1)
  if(diff < 0){
    for(let i = startDate.getMonth() ; i < 12; i++){
      intervals.push({ month : i,year:year - 1});
    }
    for(let i = 0 ; i < endDate.getMonth() + 1 ; i ++){
      intervals.push({ month : i,year:year});
    }
  }else
    for(let i = diff ; i < endDate.getMonth() + 1;i++)
      intervals.push({ month : i,year:year});
}

function getDateIntervals(selectedMonth,month, year) {
  const intervals = [];

  if(selectedMonth==="thisMonth"){
    const daysInMonth = new Date(year, month + 1, 0).getDate(); 
    for (let i = 1; i <= daysInMonth; i += 7) {
      const startDay = i;
      const endDay = Math.min(i + 7 - 1, daysInMonth); 
      intervals.push({ start: startDay, end: endDay });
    }
  }else if(selectedMonth==="lastMonth"){
    let daysInMonth;
    let calculatedYear;
    if(month!==0){
      daysInMonth = new Date(year, month , 0).getDate();
      calculatedYear=year
    }
    else{
      daysInMonth = new Date(year - 1, 11 , 0).getDate(); 
      calculatedYear=year - 1
    }
    for (let i = 1; i <= daysInMonth; i += 7) {
      const startDay = i;
      const endDay = Math.min(i + 7 - 1, daysInMonth); 
      intervals.push({ start: startDay, end: endDay, year: calculatedYear});
    }
  }else if(selectedMonth==="lastQuarter")
    setInterval(2,year,month,intervals)
  else if(selectedMonth==="last6Months")
    setInterval(5,year,month,intervals)
  else if(selectedMonth==="lastYear")
    setInterval(11,year,month,intervals)
  return intervals;
}

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [users, offers, products, files, clients, subscriptions, articles] = await Promise.all([
          dispatch(getUsers()),
          dispatch(getOfferDetails()),
          dispatch(getProducts()),
          dispatch(getFiles()),
          dispatch(getUsersByRole('Client')),
          dispatch(getSubscriptions()),
          dispatch(getArticles())
        ]);
        if(views!==null && articles){
          let arr=[]
          articles.map((ar)=>{
            const viewsPerArticle=views.filter(v=>v.article === ar.id).length
            arr.push({name:ar.title,value:viewsPerArticle,acc:(viewsPerArticle / users.length) * 100})
          })
          setArticlesDESC(arr.sort((a,b)=>b.value - a.value).slice(0,3))
        }
        let date=new Date()
        const today=`${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
        date.setDate(date.getDate() - 1)
        const yesterday=`${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`

        const todaysUserCount=users.filter((u)=>{ 
          let userDate=new Date(u.created_at)
          userDate=`${userDate.getDate()}/${userDate.getMonth()}/${userDate.getFullYear()}`
          return userDate === today
        }).length
        const yesterdaysUserCount=users.filter((u)=>{ 
          let userDate=new Date(u.created_at)
          userDate=`${userDate.getDate()}/${userDate.getMonth()}/${userDate.getFullYear()}`
          return userDate === yesterday
        }).length
        
        setUsersStat(yesterdaysUserCount!==0?(((todaysUserCount * 100) / yesterdaysUserCount ) - 100):(todaysUserCount!==0?100:0))

        const todaysOfferCount=offers.filter((u)=>{ 
          let offerDate=new Date(u.created_at)
          offerDate=`${offerDate.getDate()}/${offerDate.getMonth()}/${offerDate.getFullYear()}`
          return offerDate === today
        }).length
        const yesterdaysOfferCount=offers.filter((u)=>{ 
          let offerDate=new Date(u.created_at)
          offerDate=`${offerDate.getDate()}/${offerDate.getMonth()}/${offerDate.getFullYear()}`
          return offerDate === yesterday
        }).length
        
        setOfferStat(yesterdaysOfferCount!==0?(((todaysOfferCount * 100) / yesterdaysOfferCount ) - 100):(todaysOfferCount!==0?100:0))

        const todaysfileCount=files.filter((u)=>{ 
          let fileDate=new Date(u.uploaded_at)
          fileDate=`${fileDate.getDate()}/${fileDate.getMonth()}/${fileDate.getFullYear()}`
          return fileDate === today
        }).length
        const yesterdaysfileCount=files.filter((u)=>{ 
          let fileDate=new Date(u.uploaded_at)
          fileDate=`${fileDate.getDate()}/${fileDate.getMonth()}/${fileDate.getFullYear()}`
          return fileDate === yesterday
        }).length
        
        setFileStat(yesterdaysfileCount!==0?(((todaysfileCount * 100) / yesterdaysfileCount ) - 100):(todaysfileCount!==0?100:0))

        setTotalUsers(users.length);
        setTotalOffers(offers.length);

        const todaysRevenue=files.filter((u)=>{ 
          let fileDate=new Date(u.uploaded_at)
          fileDate=`${fileDate.getDate()}/${fileDate.getMonth()}/${fileDate.getFullYear()}`
          return fileDate === today
        }).reduce((total, sub) => {
          const product = products.find(product => product.id === sub.product);
          return product ? total + parseFloat(product.monthlyPrice) : total;
        }, 0);
        const yesterdaysRevenue=files.filter((u)=>{ 
          let fileDate=new Date(u.uploaded_at)
          fileDate=`${fileDate.getDate()}/${fileDate.getMonth()}/${fileDate.getFullYear()}`
          return fileDate === yesterday
        }).reduce((total, sub) => {
          const product = products.find(product => product.id === sub.product);
          return product ? total + parseFloat(product.monthlyPrice) : total;
        }, 0);
        
        setRevenueStat(yesterdaysRevenue!==0?(((todaysRevenue / yesterdaysRevenue * 100) / yesterdaysRevenue ) - 100):(todaysRevenue!==0?100:0))

        const totalPrices = subscriptions.reduce((total, sub) => {
          const product = products.find(product => product.id === sub.product);
          return product ? total + parseFloat(product.monthlyPrice) : total;
        }, 0);
        setTotalPrices(totalPrices);

        setTotalDocuments(files.length);

        const productSubscriptions = products.map(product => {
          const subscriptionCount = subscriptions.filter(sub => sub.product === product.id).length;
          return {
            productName: product.title,
            subscriptionCount
          };
        });

        setDataSubscriptions(productSubscriptions);

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const dateIntervals = getDateIntervals(selectedMonth,currentMonth, currentYear);
        const processedData = dateIntervals.map((interval) => {
          const startDay = interval.start;
          const endDay = interval.end;
          const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
          ];
          let intervalName=""
          switch(selectedMonth){
            case('thisMonth'):
              intervalName = `${startDay} - ${endDay} ${monthNames[currentMonth]}`;
              break;
            case('lastMonth'):
              intervalName = `${startDay} - ${endDay} ${monthNames[currentMonth - 1]}`;
              break;
            case('lastQuarter'):
              intervalName = `${monthNames[interval.month]}`;
              break;
            case('last6Months'):
              intervalName = `${monthNames[interval.month]}`;
              break;
            case('lastYear'):
              intervalName = `${monthNames[interval.month]}`;
              break;
          }
          const uv = clients.filter((client) => {
            const clientDate = new Date(client.created_at);
            const clientMonth = clientDate.getMonth();
            const clientYear = clientDate.getFullYear();
            const clientDay = clientDate.getDate();
            if(selectedMonth==="thisMonth"){
              return (
                clientMonth === currentMonth &&
                clientYear === currentYear &&
                clientDay >= startDay &&
                clientDay <= endDay
              );
            }
            if(selectedMonth==="lastMonth"){
              return (
                clientMonth === currentMonth - 1 &&
                clientYear === interval.year &&
                clientDay >= startDay &&
                clientDay <= endDay
              );
            }
            if(selectedMonth==="lastQuarter"){
              return (
                clientMonth === interval.month  &&
                clientYear === currentYear 
              );
            }
            if(selectedMonth==="last6Months"){
              return (
                clientMonth === interval.month  &&
                clientYear === interval.year 
              );
            }
            if(selectedMonth==="lastYear"){
              return (
                clientMonth === interval.month  &&
                clientYear === interval.year
              );
            }
          }).length;
          return { name: intervalName, uv: uv };
        });
        const dateIntervals2 = getDateIntervals(selectedMonthSub,currentMonth, currentYear);
        const processedSubData = dateIntervals2.map((interval) => {
          const startDay = interval.start;
          const endDay = interval.end;
          const month=interval.month
          const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
          ];
          let intervalName=""
          switch(selectedMonthSub){
            case('thisMonth'):
              intervalName = `${startDay} - ${endDay} ${monthNames[currentMonth]}`;
              break;
            case('lastMonth'):
              intervalName = `${startDay} - ${endDay} ${monthNames[currentMonth - 1]}`;
              break;
            case('lastQuarter'):
              intervalName = `${monthNames[interval.month]}`;
              break;
            case('last6Months'):
              intervalName = `${monthNames[interval.month]}`;
              break;
            case('lastYear'):
              intervalName = `${monthNames[interval.month]}`;
              break;
          }
          const totalRevenue = subscriptions.reduce((total, sub) => {
            const subDate = new Date(sub.started_at);
            const subMonth = subDate.getMonth();
            const subYear = subDate.getFullYear();
            const subDay = subDate.getDate();
            if (
               selectedMonthSub==="thisMonth" &&
               subMonth === currentMonth  &&
               subYear === currentYear &&
               subDay >= startDay &&
               subDay <= endDay
            ) {
              const product = products.find(product => product.id === sub.product);
              if (product) {
                return total + parseFloat(product.monthlyPrice);
              }
            }
            if (
               selectedMonthSub==="lastMonth" &&
               subMonth === currentMonth - 1 &&
               subYear === interval.year &&
               subDay >= startDay &&
               subDay <= endDay
            ) {
              const product = products.find(product => product.id === sub.product);
              if (product) {
                return total + parseFloat(product.monthlyPrice);
              }
            }
            if (
               selectedMonthSub==="lastQuarter" &&
               subMonth === interval.month  &&
               subYear === interval.year
            ) {
              const product = products.find(product => product.id === sub.product);
              if (product) {
                return total + parseFloat(product.monthlyPrice);
              }
            }
            if (
               selectedMonthSub==="last6Months" &&
               subMonth === interval.month &&
               subYear === interval.year
            ) {
              const product = products.find(product => product.id === sub.product);
              if (product) {
                return total + parseFloat(product.monthlyPrice);
              }
            }
            if (
               selectedMonthSub==="lastYear" &&
               subMonth === interval.month  &&
                subYear ===interval.year
            ) {
              const product = products.find(product => product.id === sub.product);
              if (product) {
                return total + parseFloat(product.monthlyPrice);
              }
            }
            return total;
          }, 0);
          return { name: intervalName, uv: totalRevenue };
        });

        setClientOverviewData(processedData);
        setSubscriptionData(processedSubData);

        const offersByStatus = {
          Accepted: offers.filter(offer => offer.status === "Accepted").length,
          Rejected: offers.filter(offer => offer.status === "Rejected").length,
          Forwarded: offers.filter(offer => offer.status === "Forwarded").length,
          Pending: offers.filter(offer => offer.status === "Pending").length,
        };

        setDataOffers([
          { name: "Accepted", value: offersByStatus.Accepted },
          { name: "Rejected", value: offersByStatus.Rejected },
          { name: "Forwarded", value: offersByStatus.Forwarded },
          { name: "Pending", value: offersByStatus.Pending },
        ]);

        const usersByRole = {
          Admin: users.filter(user => user.role === "Admin").length,
          Manager: users.filter(user => user.role === "Manager").length,
          Client: users.filter(user => user.role === "Client").length,
          DemoClient: users.filter(user => user.role === "Demo").length,
          ArticleUser: users.filter(user => user.role === "ArticleUser").length,
          Sales: users.filter(user => user.role === "Sales").length,
        };

        setDataUsers([
          { name: "Admin", value: usersByRole.Admin },
          { name: "Manager", value: usersByRole.Manager },
          { name: "Client", value: usersByRole.Client },
          { name: "Demo Client", value: usersByRole.DemoClient },
          { name: "Article User", value: usersByRole.ArticleUser },
          { name: "Sales", value: usersByRole.Sales },
        ]);

        const sortedArticles = articles.sort((a, b) => b.number_users_visited - a.number_users_visited);
        const topArticles = sortedArticles.slice(0, 3); 
        const totalUsersVisited = articles.reduce((sum, article) => sum + article.number_users_visited, 0);
        const newPieData = topArticles.map(article => ({
          id:article.id,
          name: article.title,
          /*value: article.number_users_visited,
          acquisition: ((article.number_users_visited / totalUsersVisited) * 100).toFixed(2)*/
        }));

        setArticlesData(newPieData);

      } catch (error) {
        if (error.response && error.response.status === 403) {
          navigate("/Not-Access");
        }else if (error.response && error.response.status === 401){
          navigate("/login");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, selectedMonth, selectedMonthSub, totalUsers,views]);

  useEffect(()=>{
    fetchAllViews(setViews)
  },[])

  /*useEffect(()=>{
    if(views!==null && articles){
      articles.map((ar)=>{
        const viewsPerArticle=views.filter(v=>v.article === ar.id).length
        setArticlesDESC(prev=>[...prev,{name:ar.title,views:viewsPerArticle}])
      })
    }
  },[views,articles])*/
  console.log(views);
  const formatTotalPrice = (totalPrice) => {
    if (totalPrice >= 1000) {
      return (totalPrice / 1000).toFixed(2) + 'K MAD';
    } else {
      return totalPrice.toFixed(2) + ' MAD';
    }
  };

  if (!isAuthenticated) {
    navigate("/login");
    return null;
  }

  if (loading) {
    return (
      <Flex justify="center" align="center" minH="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  } 
  
  return (
    <Box minH="100vh" bg={bg} pb="3rem">
      <Box ml={{ base: 0, md: 60 }}>
        <Center>
          <Box
            mt="33px"
            mb={"33px"}
            w={"95%"}
            gap={"10px"}
            display={"flex"}
            justifyContent={"space-between"}
            flexDirection={{ base: "column", md: "row" }}
            flexWrap={{ base: "wrap", lg: "nowrap" }}
          >
            <Card
              flex={{ base: "1 0 160px", md: "0 0 48%", lg: "0 0 25%" }}
              h={{ base: "160px", lg: "140px" }}
              bg={bg2}
              p="10px 10px 0px 20px"
              border="solid 1px #EFF4FA"
              borderRadius={5}
              position="relative"
            >
              <Text
                color={colorAdminP}
                fontSize="13px"
                fontWeight="400"
                mt="5px"
              >
                Total User
              </Text>
              <FiUsers
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "25px",
                  fontSize: "37px",
                  borderRadius: "35%",
                  backgroundColor: "rgba(130, 128, 255, 0.21)",
                  color: "#3D42DF",
                  padding: "8px",
                }}
              />
              <Text
                color={grayScales}
                position={{ base: "absolute", md: "relative" }}
                top={{ base: "40%", md: "0" }}
                left={{ base: "50%", md: "0" }}
                transform={{ base: "translate(-50%,-50%)", md: "none" }}
                fontSize={{ base: "31px", lg: "20px" }}
                fontWeight="bold"
                style={{ marginTop: "10px" }}
              >
                {totalUsers !== null ? totalUsers : "0"}
              </Text>
              <HStack
                alignItems="center"
                position="absolute"
                bottom="20px"
                left="20px"
              >
                {usersStats > 0 ? (
                  <FiTrendingUp color="#00B69B" />
                ) : (
                  <FiTrendingDown color="#F93C65" />
                )}
                <Text
                  color={usersStats > 0 ? "#00B69B" : "#F93C65"}
                  fontSize="14px"
                  ml="2"
                >
                  {Math.abs(usersStats).toFixed(0)} %
                </Text>
                <Text color={colorP} fontSize="14px" ml="2">
                  {usersStats <= 0
                    ? "Down from yesterday"
                    : "Up from yesterday"}
                </Text>
              </HStack>
            </Card>

            <Card
              flex={{ base: "1 0 160px", md: "0 0 48%", lg: "0 0 25%" }}
              h={{ base: "160px", lg: "140px" }}
              bg={bg2}
              p="10px 10px 0px 20px"
              border="solid 1px #EFF4FA"
              borderRadius={5}
              position="relative"
            >
              <Text
                color={colorAdminP}
                fontSize="13px"
                fontWeight="400"
                mt="5px"
              >
                Total Offer
              </Text>
              <FiBox
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "25px",
                  fontSize: "37px",
                  borderRadius: "35%",
                  backgroundColor: "rgba(254, 197, 61, 0.21)",
                  color: "#FEC53D",
                  padding: "8px",
                }}
              />
              <Text
                color={grayScales}
                position={{ base: "absolute", md: "relative" }}
                top={{ base: "40%", md: "0" }}
                left={{ base: "50%", md: "0" }}
                transform={{ base: "translate(-50%,-50%)", md: "none" }}
                fontSize={{ base: "31px", lg: "20px" }}
                fontWeight="bold"
                style={{ marginTop: "10px" }}
              >
                {totalOffers !== null ? totalOffers : "0"}
              </Text>
              <HStack
                alignItems="center"
                position="absolute"
                bottom="20px"
                left="20px"
              >
                {offerStats > 0 ? (
                  <FiTrendingUp color="#00B69B" />
                ) : (
                  <FiTrendingDown color="#F93C65" />
                )}
                <Text
                  color={offerStats > 0 ? "#00B69B" : "#F93C65"}
                  fontSize="14px"
                  ml="2"
                >
                  {Math.abs(offerStats).toFixed(0)} %
                </Text>
                <Text color={colorP} fontSize="14px" ml="2">
                  {offerStats <= 0
                    ? "Down from yesterday"
                    : "Up from yesterday"}
                </Text>
              </HStack>
            </Card>
            <Card
              flex={{ base: "1 0 160px", md: "0 0 48%", lg: "0 0 25%" }}
              h={{ base: "160px", lg: "140px" }}
              bg={bg2}
              p="10px 10px 0px 20px"
              border="solid 1px #EFF4FA"
              borderRadius={5}
              position="relative"
            >
              <Text
                color={colorAdminP}
                fontSize="13px"
                fontWeight="400"
                mt="5px"
              >
                Total Sales
              </Text>
              <VscGraphLine
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "25px",
                  fontSize: "37px",
                  borderRadius: "35%",
                  backgroundColor: "rgba(74, 217, 145, 0.21)",
                  color: "#4AD991",
                  padding: "8px",
                }}
              />
              <Text
                color={grayScales}
                position={{ base: "absolute", md: "relative" }}
                top={{ base: "40%", md: "0" }}
                left={{ base: "50%", md: "0" }}
                transform={{ base: "translate(-50%,-50%)", md: "none" }}
                fontSize={{ base: "31px", lg: "20px" }}
                fontWeight="bold"
                style={{ marginTop: "10px" }}
              >
                {totalPrices !== null ? formatTotalPrice(totalPrices) : "0"}
              </Text>
              <HStack
                alignItems="center"
                position="absolute"
                bottom="20px"
                left="20px"
              >
                {revenueStats > 0 ? (
                  <FiTrendingUp color="#00B69B" />
                ) : (
                  <FiTrendingDown color="#F93C65" />
                )}
                <Text
                  color={revenueStats > 0 ? "#00B69B" : "#F93C65"}
                  fontSize="14px"
                  ml="2"
                >
                  {Math.abs(revenueStats).toFixed(0)} %
                </Text>
                <Text color={colorP} fontSize="14px" ml="2">
                  {revenueStats <= 0
                    ? "Down from yesterday"
                    : "Up from yesterday"}
                </Text>
              </HStack>
            </Card>
            <Card
              flex={{ base: "1 0 160px", md: "0 0 48%", lg: "0 0 25%" }}
              h={{ base: "160px", lg: "140px" }}
              bg={bg2}
              p="10px 10px 0px 20px"
              border="solid 1px #EFF4FA"
              borderRadius={5}
              position="relative"
            >
              <Text
                color={colorAdminP}
                fontSize="13px"
                fontWeight="400"
                mt="5px"
              >
                Total Documents
              </Text>
              <FiFileText
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "25px",
                  fontSize: "37px",
                  borderRadius: "35%",
                  backgroundColor: "rgba(255, 144, 102, 0.21)",
                  color: "#FF0000",
                  padding: "8px",
                }}
              />
              <Text
                color={grayScales}
                position={{ base: "absolute", md: "relative" }}
                top={{ base: "40%", md: "0" }}
                left={{ base: "50%", md: "0" }}
                transform={{ base: "translate(-50%,-50%)", md: "none" }}
                fontSize={{ base: "31px", lg: "20px" }}
                fontWeight="bold"
                style={{ marginTop: "10px" }}
              >
                {totalDocuments !== null ? totalDocuments : "0"}
              </Text>
              <HStack
                alignItems="center"
                position="absolute"
                bottom="20px"
                left="20px"
              >
                {fileStats > 0 ? (
                  <FiTrendingUp color="#00B69B" />
                ) : (
                  <FiTrendingDown color="#F93C65" />
                )}
                <Text
                  color={fileStats > 0 ? "#00B69B" : "#F93C65"}
                  fontSize="14px"
                  ml="2"
                >
                  {Math.abs(fileStats).toFixed(0)} %
                </Text>
                <Text color={colorP} fontSize="14px" ml="2">
                  {fileStats <= 0 ? "Down from yesterday" : "Up from yesterday"}
                </Text>
              </HStack>
            </Card>
          </Box>
        </Center>
        <Flex
          gap={"25px"}
          direction={{ base: "column", lg: "row" }}
          m="30px 15px"
        >
          <VStack width={{ base: "100%", lg: "70%" }} spacing={30}>
            <Box
              bg={bg2}
              borderRadius="10px"
              p="20px"
              width="100%"
              position="relative"
            >
              <Box
                bg={bg2}
                borderRadius="10px"
                p="20px"
                mb="20px"
                display="flex"
                alignItems="center"
              >
                <Text
                  color={colorH}
                  fontSize="18px"
                  fontWeight="bold"
                  mb="10px"
                  flex="1"
                >
                  Client Overview
                </Text>
                <Select
                  defaultValue="thisMonth"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  w="120px"
                  mb="10px"
                  borderRadius="3px"
                  bg="#F5F6F7"
                  fontSize="13px"
                  fontWeight="400"
                  color="#4A5154"
                  _focus={{ boxShadow: "none" }}
                  border="none"
                  iconColor="inherit"
                >
                  <option value="thisMonth">This Month</option>
                  <option value="lastMonth">Last Month</option>
                  <option value="lastQuarter">Last Quarter</option>
                  <option value="last6Months">Last 6 Months</option>
                  <option value="lastYear">Last Year</option>
                </Select>
              </Box>
              <Box width="100%">
                <AreaChart
                  width={900}
                  height={250}
                  data={clientOverviewData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorPv1" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#CFDFF7" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#CFDFF7" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                  />
                  <YAxis
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                  />
                  <CartesianGrid
                    vertical={false}
                    strokeDasharray="15 10"
                    stroke="#E5E7E8"
                  />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="uv"
                    stroke="#0E5FD9"
                    strokeWidth={3}
                    fillOpacity={1}
                    fill="url(#colorPv1)"
                  />
                </AreaChart>
              </Box>
            </Box>
            <Box
              bg={bg2}
              borderRadius="10px"
              p="20px"
              width="100%"
              position="relative"
            >
              <Box
                bg={bg2}
                borderRadius="10px"
                p="20px"
                mb="20px"
                display="flex"
                alignItems="center"
              >
                <Text
                  color={colorH}
                  fontSize="18px"
                  fontWeight="bold"
                  mb="10px"
                  flex="1"
                >
                  Total Revenue
                </Text>
                <Box
                  position="absolute"
                  right="50px"
                  display="flex"
                  alignItems="center"
                >
                  <Select
                    defaultValue="thisMonth"
                    value={selectedMonthSub}
                    onChange={(e) => setSelectedMonthSub(e.target.value)}
                    w="120px"
                    mb="10px"
                    borderRadius="3px"
                    bg="#F5F6F7"
                    fontSize="13px"
                    fontWeight="400"
                    color="#4A5154"
                    _focus={{ boxShadow: "none" }}
                    border="none"
                    iconColor="inherit"
                  >
                    <option value="thisMonth">This Month</option>
                    <option value="lastMonth">Last Month</option>
                    <option value="lastQuarter">Last Quarter</option>
                    <option value="last6Months">Last 6 Months</option>
                    <option value="lastYear">Last Year</option>
                  </Select>
                </Box>
              </Box>
              <Box width="100%" marginY="15px">
                <AreaChart
                  width={900}
                  height={250}
                  data={subscriptionData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    tick={({ x, y, payload }) => (
                      <g
                        transform={`translate(${x},${y})`}
                        onMouseEnter={() => setHoveredItem(payload.value)}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        <circle
                          cx={0}
                          cy={0}
                          r={5}
                          fill={
                            hoveredItem === payload.value
                              ? "#2D9CDB"
                              : "#FFFFFF"
                          }
                          stroke={
                            hoveredItem === payload.value ? "none" : "#A3A3A3"
                          }
                          strokeWidth={2}
                        />
                        <text
                          x={0}
                          y={15}
                          dy={3}
                          textAnchor="middle"
                          fill="#A3A3A3"
                          fontSize={12}
                        >
                          {payload.value}
                        </text>
                      </g>
                    )}
                  />
                  <YAxis
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                  />
                  {subscriptionData.map((entry, index) => (
                    <ReferenceLine
                      key={`refLine-${index}`}
                      x={entry.name}
                      stroke="#F3F2F7"
                    />
                  ))}
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="uv"
                    stroke="#2D9CDB"
                    strokeWidth={3}
                    fillOpacity={1}
                    fill="url(#colorPv2)"
                  />
                </AreaChart>
              </Box>
            </Box>
            <Box
              bg={bg2}
              borderRadius="10px"
              p="20px"
              width="100%"
              position="relative"
            >
              <Box
                bg={bg2}
                borderRadius="10px"
                p="20px"
                mb="20px"
                display="flex"
                alignItems="center"
              >
                <Text
                  color={colorH}
                  fontSize="18px"
                  fontWeight="bold"
                  mb="10px"
                  flex="1"
                >
                  Subscription in Product
                </Text>
              </Box>
              <Box width="100%">
                <BarChart
                  width={700}
                  height={250}
                  data={dataSubscriptions}
                  margin={{ top: 10, right: 30, left: 10, bottom: 0 }}
                >
                  <XAxis
                    dataKey="productName"
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                  />
                  <YAxis
                    axisLine={false}
                    tick={{ fontSize: 12 }}
                    tickLine={false}
                    label={{
                      value: "Subscription Count",
                      angle: -90,
                      position: "insideLeft",
                      offset: 0,
                      fill: "#A3A3A3",
                      fontSize: 12,
                    }}
                  />
                  <CartesianGrid
                    vertical={false}
                    strokeDasharray="15 10"
                    stroke="#E5E7E8"
                  />
                  <Tooltip />
                  <Bar
                    dataKey="subscriptionCount"
                    fill="#0E5FD9"
                    barSize={40}
                  />
                </BarChart>
              </Box>
            </Box>
          </VStack>
          <VStack spacing={30} width={{ base: "100%", lg: "30%" }}>
            <Box bg={bg2} borderRadius="10px" width="100%">
              <HStack justifyContent="space-between" mb="5px">
                <Text
                  color={colorH}
                  fontSize="14px"
                  margin="15px"
                  fontWeight="bold"
                >
                  Most Visited Articles
                </Text>
              </HStack>
              <Divider border="solid 1px #E5E7E8" />
              <PieChart width={220} height={180} margin="auto">
                <Pie
                  data={articlesDESC}
                  cx={110}
                  cy={90}
                  innerRadius={40}
                  outerRadius={70}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {articlesDESC.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
              <Box
                overflowX="auto" // Enables horizontal scrolling
                maxWidth="100%" // Ensure the scroll container fits within the parent
                mt="15px"
              >
                <Table variant="simple" size="sm" mt="15px">
                <Thead bg={bg} overflow="hidden">
                  <Tr>
                    <Th color={colorP}>Page Name</Th>
                    <Th color={colorP}>Total Users</Th>
                    <Th color={colorP}>Acq</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {articlesDESC.map((entry, index) => (
                    <Tr key={`row-${index}`}>
                      <Td>
                        <HStack spacing={3}>
                          <Circle
                            size="10px"
                            bg={COLORS[index % COLORS.length]}
                          />
                          <Text fontSize="12px">{entry.name}</Text>
                        </HStack>
                      </Td>
                      <Td>{entry.value}</Td>
                      <Td color="#00B69B">{entry.acc.toFixed(0)}%</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              </Box>
            </Box>

            <Box bg={bg2} borderRadius="10px" p="20px" width="100%" mt="20px">
              <Text color={colorH} fontSize="14px" fontWeight="500" mb="5px">
                Offers Overview
              </Text>
              <Text
                color={grayScales}
                fontSize="24px"
                fontWeight="bold"
                ml="10px"
              >
                {totalOffers ? totalOffers : 0}
              </Text>
              <Flex
                mt="20px"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  {dataOffers.map((offer, index) => (
                    <HStack spacing={2} mt="5px" key={index}>
                      <Box
                        w="16px"
                        h="10px"
                        bg={COLORS[index % COLORS.length]}
                        borderRadius={5}
                      ></Box>
                      <Text fontSize="14px">
                        {totalOffers
                          ? Math.round((offer.value * 100) / totalOffers)
                          : 0}
                        % {offer.name}
                      </Text>
                    </HStack>
                  ))}
                </Box>
                <PieChart width={120} height={120}>
                  <Pie
                    data={dataOffers}
                    cx={60}
                    cy={60}
                    innerRadius={30}
                    outerRadius={50}
                    fill="#8884d8"
                    onMouseEnter={(data, index) => setHoveredItem(index)}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {dataOffers.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                        opacity={hoveredItem === index ? 0.7 : 1}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </Flex>
            </Box>
            <Box bg={bg2} borderRadius="10px" p="20px" width="100%" mt="20px">
              <Text color={colorH} fontSize="14px" fontWeight="500" mb="5px">
                Users
              </Text>
              <Text
                color={grayScales}
                fontSize="24px"
                fontWeight="bold"
                ml="10px"
              >
                {totalUsers ? totalUsers : 0}
              </Text>
              <Flex
                mt="20px"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  {dataUsers.map((user, index) => (
                    <HStack spacing={2} mt="5px" key={index}>
                      <Box
                        w="16px"
                        h="10px"
                        bg={COLORS[index % COLORS.length]}
                        borderRadius={5}
                      ></Box>
                      <Text fontSize="14px">
                        {totalUsers
                          ? Math.round((user.value * 100) / totalUsers)
                          : 0}
                        % {user.name}
                      </Text>
                    </HStack>
                  ))}
                </Box>
                <PieChart width={120} height={120}>
                  <Pie
                    data={dataUsers}
                    cx={60}
                    cy={60}
                    innerRadius={30}
                    outerRadius={50}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {dataUsers.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                        opacity={hoveredItem === index ? 0.7 : 1}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </Flex>
            </Box>
          </VStack>
        </Flex>
      </Box>
    </Box>
  );
}
