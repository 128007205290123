import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, Icon, Heading, Text, Box } from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';

const FailedLoginModal = ({ isOpen, onClose, message, title }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent
        width={{ base: "90%", md: "70%" }} 
        position="fixed"
        top={{ base: "10%", md: "20px" }} 
        right={{ base: "5%", md: "1%" }} 
        borderRadius="5px"
        backgroundColor="#FFF6F8"
      >
        <ModalHeader>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Icon
              as={SmallCloseIcon}
              color="#F44336"
              backgroundColor="#FFF6F8"
              border="solid #F44336"
              width='25px'
              height='25px'
              borderRadius="50%"
              boxShadow="0px 0px 10px 0px rgba(217, 217, 217, 0.75)"
            />
            <Box marginLeft={{ base: "15px", md: "30px" }}>
              <Heading fontSize={{ base: "1rem", md: "1.3rem" }} fontWeight="bold" color="#181818" marginBottom="10px">
                {title}
              </Heading>
              <Text fontSize={{ base: "0.7rem", md: "0.8rem" }} color="#181818">
                {message}
              </Text>
            </Box>
          </Box>
        </ModalHeader>
      </ModalContent>
    </Modal>
  );
};

export default FailedLoginModal;
