import React, { useEffect, useState,useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Image,
  Divider,
  Textarea,
  Input,
  IconButton,
} from "@chakra-ui/react";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMessageById, getConversation, createMessage } from "../../../actions/chatActions";
import { getUsers } from "../../../actions/user";
import { AddIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
import DefaultProfile from "../../../assets/defaultProfile.png"

const API_URL = process.env.REACT_APP_API_URL;

const MessagesContent = () => {

  //for Dark/light mode
  const bg=useSelector(state=>state.themeReducer.backgroundColor1)
  const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
  const colorH=useSelector(state=>state.themeReducer.color1)
  const colorP=useSelector(state=>state.themeReducer.color2)
  const colorAdminP=useSelector(state=>state.themeReducer.colorAdmin2)
  const grayScales=useSelector(state=>state.themeReducer.grayScales)
  const blueAdmin=useSelector(state=>state.themeReducer.blueAdmin)
  const divRef=useRef()
  const { id } = useParams();
  const [message, setMessage] = useState(null);
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sent, setSent] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState({});
  const [response, setResponse] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const role=useSelector(state=>state.auth.user?.role)

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filter = queryParams.get("filter");

  const fetchMessageAndConversation = async () => {
    try {
      const fetchedMessage = await dispatch(getMessageById(id));
      setMessage(fetchedMessage);
      if (fetchedMessage) {
        await fetchMessages(fetchedMessage.subject, fetchedMessage.sender, fetchedMessage.receiver);
      }
    } catch (err) {
      setError("Failed to load message or conversation");
    } finally {
      setLoading(false);
    }
  };

  const fetchMessages = async (subject, sender, receiver) => {
    try {
      const fetchedConversation = await dispatch(getConversation(subject, sender, receiver));
      setConversation(fetchedConversation);
    } catch (err) {
      console.error("Failed to fetch conversation:", err);
    }
  };

  useEffect(() => {
    fetchMessageAndConversation();
  }, [id, dispatch]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const fetchedUsers = await dispatch(getUsers());
        const usersMap = {};
        fetchedUsers.forEach((user) => {
          usersMap["name" + user.id] = user.first_name + " " + user.last_name;
          usersMap["profile" + user.id] = user.profile_picture;
        });
        setUsers(usersMap);
      } catch (err) {
        console.error("Failed to fetch users:", err);
      }
    };

    fetchUsers();
  }, [dispatch]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleSendResponse = async () => {
    if (!response && !selectedFile) {
      alert("Please enter a message or choose a file.");
      return;
    }

    const formData = new FormData();
    formData.append("content", response);
    formData.append("subject", message.subject);
    formData.append("is_active", 1);
    formData.append("sender", filter === "outgoing"?message.sender:message.receiver); 
    formData.append("receiver", filter === "outgoing"?message.receiver:message.sender);
    if (selectedFile) {
      formData.append("file", selectedFile);
    }

    try {
      await dispatch(createMessage(formData));
      setResponse("");
      setSelectedFile(null);
      setSent(prev=>!prev)
      await fetchMessages(message.subject, message.sender, message.receiver);
    } catch (error) {
      console.error("Failed to send response:", error);
    }
  };

  useEffect(()=>{
    if(loading===false && error===null)
      divRef.current.scrollTop=divRef.current.scrollHeight
  },[loading,error,sent])
  
  if (loading) return <Text>Loading message...</Text>;
  if (error) return <Text>Error loading message: {error}</Text>;
 
  return (
    <>
      <Box minH="100vh" ml={{ base: 0, md: 60 }}>
        <Box  p={4} width={{base:"100%",md:"100%",lg:"80%"}} ml={{base:"0%",md:"0%",lg:"10%"}}>
          <Button bg={bg} color={colorP}>
            <Link to={`/x-capital/terminal/${role}/messages`}>
              <FaArrowAltCircleLeft fontSize="1.5em" />
            </Link>
          </Button>
          <Flex
            direction="column"
            width={{base:'100%',md:"75%",lg:"60%"}}
            ml={{base:'0%',md:"12.5%",lg:"20%"}}
            mr="auto"
            mt="5em"
            gap={4}
            border="1px solid grey"
            borderRadius="0.5em"
          >
            <Flex direction="column" p={2}>
              <Flex direction="row" align="center">
                <Image
                  src={
                    users["profile" + message.sender]?`${process.env.REACT_APP_SERVER_URL}${users["profile" + message.sender]}`:
                    DefaultProfile
                  }
                  width={{base:"3em",md:"4em",lg:"5em"}}
                  height={{base:"3em",md:"4em",lg:"5em"}}
                  borderRadius="50%"
                />
                <Flex direction="column" ml="1em" flex="1">
                  <Text fontSize={{base:"xl",md:"xl",lg:"2xl"}}>
                    {users["name" + message?.sender] || "Unknown"}
                  </Text>
                  <Text>{message?.subject || "No subject"}</Text>
                </Flex>
                <Text mt="0.5em" fontSize={{base:"14px",md:"15px",lg:'16px'}} ml="auto" flex={"0 0 25%"} textAlign={"center"}>
                  {message?.sent_at
                    ? formatDate(message.sent_at)
                    : "Unknown Date"}
                </Text>
              </Flex>
            </Flex>
            <Divider />
            <Flex>
              <Box width="90%" ml="auto" mr="auto" mb="1em">
                <Text>{message?.content || "No content available"}</Text>
                {message?.file && (
                  <Text mt="2em">
                    <a href={`${API_URL}${message.file}`} download={message.file}>
                      Link to download the file
                    </a>
                  </Text>
                )}
              </Box>
            </Flex>
          </Flex>
          <Divider mt={4} />
          <Text fontSize="2xl" textAlign="center" mt={4}>
            Conversation
          </Text>
          <Box  width={{base:'100%',md:"75%",lg:"60%"}} ml={{base:'0%',md:"12.5%",lg:"20%"}} mr="auto" mt={4} maxHeight={"60vh"} overflowY={"scroll"} overflowX={"hidden"} ref={divRef}>
            {conversation.sort((a,b)=>new Date(a.sent_at) - new Date(b.sent_at)).map((msg) => (
              <Flex key={msg.id} direction="column" p={4} border="1px solid grey" borderRadius="0.5em" mb={4}>
                <Flex direction="row" align="center">
                  <Image
                    src={
                      users["profile" + msg.sender]?process.env.REACT_APP_SERVER_URL + users["profile" + msg.sender]:
                      DefaultProfile
                    }
                    width="4em"
                    height="4em"
                    borderRadius="50%"
                  />
                  <Flex direction="column" ml="1em" flex="1">
                    <Text fontWeight="bold">{users["name" + msg.sender] || "Unknown"}</Text>
                    <Text fontSize="sm" color="gray.500">
                      {formatDate(msg.sent_at)}
                    </Text>
                  </Flex>
                </Flex>
                <Divider mt={2} />
                <Text mt={2}>{msg.content}</Text>
                {msg.file && (
                  <Text mt={2}>
                    <a href={`${API_URL}${msg.file}`} download={msg.file}>
                      Download attachment
                    </a>
                  </Text>
                )}
              </Flex>
            ))}
          </Box>
          <RespondComponent
            response={response}
            setResponse={setResponse}
            handleFileChange={handleFileChange}
            handleSendResponse={handleSendResponse}
          />
        </Box>
      </Box>
    </>
  );
};

const RespondComponent = ({
  response,
  setResponse,
  handleFileChange,
  handleSendResponse,
}) => {
  return (
    <Flex width={{base:'100%',md:"75%",lg:"60%"}} ml={{base:'0%',md:"12.5%",lg:"20%"}} mr="auto" mt="2em" direction="column">
      <Textarea
        placeholder="Response"
        mb="1em"
        value={response}
        onChange={(e) => setResponse(e.target.value)}
      />
      <Input
        type="file"
        accept="*"
        onChange={handleFileChange}
        display="none"
        id="fileInput"
      />
      <Flex align="center">
        <IconButton
          icon={<AddIcon />}
          aria-label="Select File"
          mr="2"
          mt="1em"
          onClick={() => document.getElementById("fileInput").click()}
        />
        <Button mt="1em" colorScheme="blue" onClick={handleSendResponse}>
          Send
        </Button>
      </Flex>
    </Flex>
  );
};

export default MessagesContent;
