const fetchArticles = async () => {
  const accessToken = localStorage.getItem('access');


  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/articles`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

export default fetchArticles;
