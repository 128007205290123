import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password_confirm } from '../actions/auth';
import { PASSWORD_RESET_CONFIRM_SUCCESS } from '../actions/types';
import FailedLoginModal from '../components/FailedLoginModal';
import {
    Flex,
    Stack,
    Box,
    Heading,
    Text,
    Input,
    Button,
    FormControl,
    FormLabel,
    InputGroup,
    InputRightElement,
    IconButton
} from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const checkIfLinkExpired = (uid, token) => {
  const decodedToken = parseInt(token, 36);
  const activationTokenCreationDate = new Date(decodedToken * 1000); 
  activationTokenCreationDate.setFullYear(activationTokenCreationDate.getFullYear()+31);
  const expirationDurationInHours = 24;
  const expirationDate = new Date(activationTokenCreationDate.getTime() + expirationDurationInHours * 60 * 60 * 1000);
  const currentDate = new Date();
  const isExpired = currentDate > expirationDate;

  return isExpired;
};

const ResetPasswordConfirm = ({ reset_password_confirm }) => {
  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    new_password: '',
    re_new_password: '',
  });
  const [loginError, setLoginError] = useState(false);
  const [expired, setExpired] = useState(false); 

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { new_password, re_new_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const togglePasswordVisibility = () =>
    setShowPassword(!showPassword);

  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const { uid, token } = useParams();

  const onSubmit = async e => {
    e.preventDefault();
    if(new_password===re_new_password){
      const res=await reset_password_confirm(uid, token, new_password, re_new_password);
      if (res === PASSWORD_RESET_CONFIRM_SUCCESS) {
        setLoginError(false);
        setRequestSent(true);
      } else {
        setLoginError(true);
        setTimeout(() => {
          setLoginError(false);
        }, 10000);
        setRequestSent(false);
      }
      }else{
        setLoginError(true);
        setTimeout(() => {
          setLoginError(false);
        }, 10000);
        setRequestSent(false);
      }
      
    }

    useEffect(() => {
      const isLinkExpired = checkIfLinkExpired(uid, token);
      if (isLinkExpired) {
        setExpired(true);
      }
    }, [uid, token]);

  if (requestSent) {
    return <Navigate to="/login" />;
  }

  if (expired) { 
    return (
      <Flex
        flexDirection="column"
        width="100wh"
        minHeight="85vh"
        backgroundColor="#FFFFFF"
        justifyContent="center"
        alignItems="center"
      >
        <Text
          fontWeight="400px"
          fontSize="14px"
          lineHeight="20px"
          color="#828282"
          textAlign="center"
        >
          This Confirm Reset Password link has expired. Please request a new one.
        </Text>
        <Button
          as={Link}
          to="/"
          variant="outline"
          colorScheme="black"
          mt={4}
        >
          Go back to Home
        </Button>
      </Flex>
    );
  }

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      minHeight="85vh"
      backgroundColor="#FFFFFF"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        mt={20}
        
      >
        <Box minW={{ base: "90%", md: "500px" }}>
          <form onSubmit={(e) => onSubmit(e)}>
            <Stack
              spacing={4}
              p="1rem"
              padding="40px"
              margin={'auto'}
              width={{ base: "90%", md: "400px" }}
              borderRadius="24px"
              border="solid 1px #828282"
              backgroundColor="#FFFFFF"
              boxShadow="md"
              height="100%"
            >
              <Heading
                fontWeight="400"
                fontSize="35px"
                lineHeight="50px"
                fontFamily="Open Sans"
                color="#00224E"
                textAlign="center"
              >
                New Password
              </Heading>
              <Text
                fontWeight="400"
                fontSize="13px"
                lineHeight="25px"
                color="#828282"
                textAlign="center"
              >
                Set the new password for your account so you can login and
                access all features.
              </Text>
              <FormControl textAlign="left" marginBottom="10px">
                <FormLabel color="#171E6C">New Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="8 characters at least"
                    color="#171E6C"
                    _placeholder={{ color: '#9BADCA' }}
                    name="new_password"
                    paddingLeft={10}
                    width="100%"
                    value={new_password}
                    onChange={(e) => onChange(e)}
                    required
                  />
                  <InputRightElement width="3rem">
                    <IconButton
                      marginTop={0}
                      paddingRight={0}
                      h="1.35rem"
                      size="sm"
                      color="#171E6C"
                      border="none"
                      backgroundColor="#FFFFFF"
                      icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                      onClick={togglePasswordVisibility}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl textAlign="left">
                <FormLabel color="#171E6C">Confirm New Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="8 characters at least"
                    color="#171E6C"
                    _placeholder={{ color: '#9BADCA' }}
                    name="re_new_password"
                    paddingLeft={10}
                    width="100%"
                    value={re_new_password}
                    onChange={(e) => onChange(e)}
                    required
                  />
                  <InputRightElement width="3rem">
                    <IconButton
                      marginTop={0}
                      paddingRight={0}
                      h="1.35rem"
                      size="sm"
                      color="#171E6C"
                      border="none"
                      backgroundColor="#FFFFFF"
                      icon={showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      onClick={toggleConfirmPasswordVisibility}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                borderRadius={5}
                type="submit"
                variant="solid"
                width="full"
                height="40px"
                border="none"
                marginY="10px"
                bg="#171E6C"
                color="#FFFFFF"
              >
                Reset Password
              </Button>
              {loginError && (
              <FailedLoginModal
                isOpen={loginError}
                onClose={() => setLoginError(false)}
                title="Failed Reset Password"
                message="Failed to Reset your Password. Please try again."
              />
            )}
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default connect(null, { reset_password_confirm })(ResetPasswordConfirm);
