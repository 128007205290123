import { Flex, Input, IconButton } from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import { useState } from "react";

function SearchBar(props) {
  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    if (props.onInputChange) {
      props.onInputChange(e.target.value);
    }
  };

  const handleSearch = () => {
    props.onSearch(query);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Flex border={props.border} borderRadius={props.radius} overflow="hidden" width={props.width}>
      <Input
        placeholder={props.placeHolder}
        color={props.color}
        bg={props.bg}
        border="none"
        _placeholder={{ color: props.placeHolderColor }}
        _focus={{ outline: 'none' }}
        aria-label="Search"
        value={query}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      <IconButton
        icon={<Search2Icon />}
        aria-label="Search"
        colorScheme="whiteAlpha"
        bg="#4A4A4A"
        _hover={"none"}
        borderRadius={props.iconRadius}
        onClick={handleSearch}
      />
    </Flex>
  );
}

export default SearchBar;
