import { GET_SUBSCRIPTIONS_SUCCESS, GET_SUBSCRIPTIONS_FAIL, CREATE_SUBSCRIPTIONS_SUCCESS, CREATE_SUBSCRIPTIONS_FAIL } from './types';
import axios from 'axios';
import { getAuthConfig } from './config';

export const getSubscriptions = () => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/subscriptions`, config);
    dispatch({
      type: GET_SUBSCRIPTIONS_SUCCESS,
      payload: res.data
    });
    return res.data; 
  } catch (err) {
    dispatch({
      type: GET_SUBSCRIPTIONS_FAIL,
      payload: err.message 
    });
    throw err; 
  }
};

export const getSubscriptionByClient=(id)=>async dispatch =>{
  try{
    const config= getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/subscription/user/${id}`,config)
    dispatch({
      type: GET_SUBSCRIPTIONS_SUCCESS,
      payload: res.data
    });
    return res.data; 
  } catch (err) {
    dispatch({
      type: GET_SUBSCRIPTIONS_FAIL,
      payload: err.message 
    });
    throw err; 
  }
}

export const createSubscription=(product,userId,duration,payement)=>async dispatch =>{
  try{
    const config= getAuthConfig();
    let date = new Date()
    if(duration==="monthly"){
      duration="1 month"
      date.setDate(date.getDate() + 30)
    }else{
      duration="1 year"
      date.setDate(date.getDate() + 365)
    }
    console.log(date);
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/save/`,{
        product:product,
        user:userId,
        status:'Active',
        duration:duration,
        started_at:new Date(),
        ending_at:date,
        payement:payement,
      },
      config)
    dispatch({
      type: CREATE_SUBSCRIPTIONS_SUCCESS,
      payload: res.data
    });
    return res.data; 
  } catch (err) {
    dispatch({
      type: CREATE_SUBSCRIPTIONS_FAIL,
      payload: err.message 
    });
    throw err; 
  }
}