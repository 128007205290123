import { 
  GET_FILES_SUCCESS, 
  GET_FILES_FAIL, 
  ADD_FILE_SUCCESS, 
  ADD_FILE_FAIL, 
  DELETE_FILE_SUCCESS, 
  DELETE_FILE_FAIL, 
  UPDATE_FILE_SUCCESS, 
  UPDATE_FILE_FAIL 
} from '../actions/types';

const initialState = {
  files: [],
  loading: true,
  error: null
};

const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILES_SUCCESS:
      return {
        ...state,
        files: action.payload,
        loading: false,
        error: null
      };
    case GET_FILES_FAIL:
      return {
        ...state,
        files: [],
        loading: false,
        error: action.payload
      };
    case ADD_FILE_SUCCESS:
      return {
        ...state,
        files: [...state.files, action.payload],
        loading: false,
        error: null
      };
    case ADD_FILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.payload),
        loading: false,
        error: null
      };
    case DELETE_FILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.map(file => 
          file.id === action.payload.id ? action.payload : file
        ),
        loading: false,
        error: null
      };
    case UPDATE_FILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default fileReducer;