import React, { useEffect } from 'react'
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  useMediaQuery,
  VStack,
  Card,
  CardHeader,
  Tooltip,
  useBreakpointValue,
  Center,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { CheckCircleIcon } from "@chakra-ui/icons";
import SolutionHero from "../../assets/dataSolution1.jpeg";
import SolutionQuestion from "../../components/questions/SolutionQuestions";
import { useDispatch,useSelector } from 'react-redux';
import { getProducts } from '../../actions/product';
import { setCart } from '../../actions/cart';
import { useNavigate } from 'react-router-dom';

const AnimatedFeatureCard = ({ title, description, delay }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const cardWidth = useBreakpointValue({
    base: "90%", // Mobile
    sm: "95%", // Small screens
    md: "85%", // Medium screens
    lg: "1200px", // Large screens and above
  });

  const cardPadding = useBreakpointValue({
    base: "4",
    sm: "6",
  });

  const titleFontSize = useBreakpointValue({
    base: "sm",
    sm: "md",
  });

  const descriptionFontSize = useBreakpointValue({
    base: "xs",
    sm: "sm",
    md: "md",
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.7, delay }}
    >
      <Card width={cardWidth} boxShadow="lg" mx="auto">
        <CardHeader padding={cardPadding}>
          <Flex
            alignItems="flex-start"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Flex alignItems="center" w={"40%"} mb={{ base: 2, sm: 0 }} mr={{ sm: 4 }}>
              <CheckCircleIcon color="green.500" mr="12px" boxSize={6} />
              <Heading size={titleFontSize}>{`${title}:`}</Heading>
            </Flex>
            <Box w={"55%"}>
              <Text fontSize={descriptionFontSize}>{description}</Text>
            </Box>
          </Flex>
        </CardHeader>
      </Card>
    </motion.div>
  );
};

const Data = () => {
  const [isLargerThan840] = useMediaQuery("(min-width: 840px)"); 

  const bgColor=useSelector(state=>state.themeReducer.backgroundColor1)
  const grayScales=useSelector(state=>state.themeReducer.grayScales)

  const navigate=useNavigate()
  const dispatch=useDispatch()
  const isAuth=useSelector(state=>state.auth.isAuthenticated)
  const role=useSelector(state=>state.auth.user?.role)
  const products=useSelector(state=>state.productReducer.products)
  useEffect(()=>{
    dispatch(getProducts())
  },[])
  const handlePayNow=()=>{
    if(isAuth){
      if(role === "Client"){
       const dataProduct=products.filter(pr=>/data/i.test(pr.title)) 
       const id=dataProduct[0]?.id
       dispatch(setCart(id,"monthly"))
       window.location.href="/checkout"
      }
    }else
      navigate("/login")
  }
    const faqList = [
        {
          question: "What is X Capital Data?",
          answer: "X Capital Data is a comprehensive data service providing access to financial statements, macroeconomic indicators, and stock market data in multiple formats, including Excel, JSON, and API.",
        },
        {
          question: "Who should use X Capital Data?",
          answer: "This service is ideal for individual traders, investors, financial institutions, and research firms that require extensive data sets for analysis and reporting.",
        },
        {
          question: "What types of data are included in X Capital Data?",
          answer: "The product includes financial statements, macroeconomic indicators, stock market data, and other relevant financial information.",
        },
        {
          question: "In what formats is the data available?",
          answer: "Data is available in Excel, JSON, and API formats, making it flexible for various uses and integrations.",
        },
        {
          question: "How frequently is the data updated?",
          answer: "Data is updated regularly, with live data options available for users who need the most current information.",
        },
        {
          question: "What is the cost of X Capital Data?",
          answer: "X Capital Data is priced at 40,000 MAD per year.",
        },
        {
          question: "Can X Capital Data be integrated into existing systems?",
          answer: "Yes, the data can be integrated into existing systems via API, making it easy for institutions to incorporate the information into their workflows.",
        },
        {
          question: "What support options are available for X Capital Data?",
          answer: "Users can reach out to customer support via email and access a comprehensive knowledge base for troubleshooting and guidance.",
        },
      ];
      
      return (
        <Box>
          {/* Hero Section */}
          <Box
            height="648px"
            position="relative"
            backgroundImage={`url(${SolutionHero})`}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition={
              isLargerThan840 ? "center" : "calc(100% - 70%) bottom"
            }
          >
            <Box
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              backgroundColor="rgba(0, 0, 0, 0.4)"
            />
            <Flex
              position="absolute"
              bottom="15%"
              left="5%"
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-start"
              maxWidth={isLargerThan840 ? "45%" : "80%"}
              color="white"
            >
              <Heading
                as="h1"
                fontSize={isLargerThan840 ? "48px" : "28px"}
                fontWeight="800"
                marginBottom="28px"
              >
                X CAPITAL DATA
              </Heading>
              <Text
                fontSize={isLargerThan840 ? "24px" : "20px"}
                marginBottom="24px"
                maxWidth={isLargerThan840 ? "90%" : "100%"}
              >
                Access comprehensive financial market data at your fingertips with X CAPITAL DATA. From stock prices and trading volumes to economic indicators and corporate financials, our data platform offers a wealth of information to fuel your analysis and decision-making. With customizable datasets and powerful analytics tools, X CAPITAL DATA empowers you to unlock the full potential of financial data and drive your business forward.
              </Text>
              <Button
                bg="white"
                color="gray.700"
                width="160px"
                height="40px"
                borderRadius="12px"
                fontSize="20px"
                _hover={{ bg: "gray.100" }}
                onClick={handlePayNow}
              >
                Pay Now
              </Button>
            </Flex>
          </Box>
    
          {/* Features Section */}
          <Box bg={bgColor} width="full" ml={'0%'} py="60px">
            <VStack spacing="40px" align="center" w="100%" mx="auto" px={4}>
              <Heading mb="24px">Our Key Features</Heading>
              <AnimatedFeatureCard
                title="Access to Comprehensive Financial Data"
                description=" Users can access financial statements, stock market data, and macroeconomic indicators all in one place, available in Excel, JSON, and API formats."
                delay={0.2}
              />
              <AnimatedFeatureCard
                title="Real-Time Data Feeds"
                description="Constantly updated datasets for institutions and individuals needing accurate, up-to-date information."
                delay={0.4}
              />
              <AnimatedFeatureCard
                title="API Integration"
                description=" Seamlessly integrate data directly into existing financial models, tools, or trading platforms, offering flexibility and scalability."
                delay={0.6}
              />
              <AnimatedFeatureCard
                title="Historical and Macroeconomic Data"
                description="  Ideal for analysts and researchers, with detailed datasets available for in-depth market research and financial forecasting."
                delay={0.8}
              />
            <Button
              bg={grayScales}
              color={grayScales==="black"?"white":"black"}
              width="220px"
              height="55px"
              borderRadius="18px"
              fontSize="23px"
              _hover={{ bg: "gray.100" }}
              onClick={handlePayNow}
              mt={"20px"}
            >
              Pay Now
            </Button>
            </VStack>
          </Box>
          <Center bg={bgColor}>
            <SolutionQuestion faqList={faqList} />
          </Center>
        </Box>
      );
}

export default Data;