import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Input,
  InputLeftAddon,
  InputGroup,
  Select,
  Button,
  Spinner,
  Alert,
  AlertIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
  Text,
  IconButton,
} from "@chakra-ui/react";
import {
  Search2Icon,
  AddIcon,
  ViewOffIcon,
  EditIcon,
  DeleteIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import Popup from "reactjs-popup";
import fetchArticles from "../../services/api/artilcesAPI/fetchArticles";
import DeleteArticleCard from "./DeleteArticleCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ArticlePanel() {
  // for Dark/light mode
  const bg = useSelector((state) => state.themeReducer.backgroundColor1);
  const grayScales = useSelector((state) => state.themeReducer.grayScales);
  const user = useSelector((state) => state.auth.user);

  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [articlesPerPage, setArticlesPerPage] = useState(5);

  const navigate = useNavigate();
  const overlayStyle = { background: "rgba(0,0,0,0.5)" };
  const contentStyle = { background: "#fff", borderRadius: "12px" };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchArticles();
        setArticles(result);
        setFilteredArticles(result);
      } catch (error) {
        console.error("Error fetching articles:", error);
        setError("Failed to fetch articles");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    filterArticles();
  }, [searchTerm, filter, articles]);

  const filterArticles = () => {
    let filtered = articles;

    if (searchTerm) {
      filtered = filtered.filter((article) =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        `${article.created_by.first_name} ${article.created_by.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        formatDate(article.created_at).toLowerCase().includes(searchTerm.toLowerCase()) ||
        formatDate(article.updated_at).toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filter) {
      filtered = filtered.sort((a, b) => {
        if (filter === "Latest") {
          return new Date(b.created_at) - new Date(a.created_at);
        } else if (filter === "Oldest") {
          return new Date(a.created_at) - new Date(b.created_at);
        }
        return 0;
      });
    }

    setFilteredArticles(filtered);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate} ${formattedTime}`;
  };

  const handleViewArticle = (articleId) => () => {
    navigate(`/article/${articleId}`);
  };

  const handleEditArticle = (articleId) => () => {
    navigate(`/x-capital/terminal/admin/article/${articleId}/update/`);
  };

  const handlePublishArticle = () => {
    navigate(`/x-capital/terminal/${user?.role}/article-post`);
  };

  const offset = currentPage * articlesPerPage;
  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);
  const currentArticles = filteredArticles.slice(offset, offset + articlesPerPage);

  return (
    <Box minH="100vh" bg={bg}>
      <Box ml={{ base: 0, md: 60 }}>
        <Flex direction={"column"} padding={"32px"} gap={"24px"}>
          <Heading>Articles</Heading>
          <Flex gap={"16px"}>
            <InputGroup width={{ base: "61%", md: "64%", lg: "79%" }}>
              <InputLeftAddon>
                <Search2Icon />
              </InputLeftAddon>
              <Input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
            <Select
              placeholder="Filter"
              width={{ base: "38%", md: "35%", lg: "20%" }}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="Latest">Latest</option>
              <option value="Oldest">Oldest</option>
            </Select>
          </Flex>
          <Flex>
            <Flex
              align={"center"}
              justify={"center"}
              bg={"#001A72"}
              color={"white"}
              paddingRight={"12px"}
              borderRadius={"12px"}
            >
              <Button
                bg={"none"}
                color={"white"}
                _hover={"none"}
                onClick={handlePublishArticle}
                fontSize={{ base: "14px", md: "16px", lg: "18px" }}
              >
                Add Article
              </Button>
              <AddIcon />
            </Flex>
          </Flex>

          {/* Table with horizontal scroll */}
          <Box overflowX="auto">
            {loading ? (
              <Spinner size="xl" />
            ) : error ? (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            ) : (
              <Table variant="simple" width="full" minWidth="800px">
                <Thead>
                  <Tr bg={"#b8c3d3"}>
                    <Th color="gray">Title</Th>
                    <Th color="gray">Created by</Th>
                    <Th color="gray">Created at</Th>
                    <Th color="gray">Updated at</Th>
                    <Th color="gray">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentArticles.map((article) => (
                    <Tr key={article.id}>
                      <Td fontSize={{ base: "13px", md: "14px", lg: "16px" }}>{article.title}</Td>
                      <Td fontSize={{ base: "13px", md: "14px", lg: "16px" }}>{`${article.created_by.first_name} ${article.created_by.last_name}`}</Td>
                      <Td fontSize={{ base: "13px", md: "14px", lg: "16px" }}>{formatDate(article.created_at)}</Td>
                      <Td fontSize={{ base: "13px", md: "14px", lg: "16px" }}>{formatDate(article.updated_at)}</Td>
                      <Td width={"20"}>
                        <Flex gap={"2px"}>
                          <Button onClick={handleViewArticle(article.id)}>
                            <ViewOffIcon />
                          </Button>
                          <Button>
                            <EditIcon onClick={handleEditArticle(article.id)} />
                          </Button>
                          <Popup
                            trigger={
                              <Button>
                                <DeleteIcon />
                              </Button>
                            }
                            modal
                            position="center center"
                            {...{ overlayStyle, contentStyle }}
                          >
                            {(close) => <DeleteArticleCard articleId={article.id} onClose={close} />}
                          </Popup>
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </Box>

          {/* Pagination */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt="20px"
            bg={bg}
            p="10px"
            borderRadius="8px"
          >
            <Stack direction="row" spacing={4} alignItems="center">
              <Text mr="10px" color="#717171">
                Rows per page:
              </Text>
              <select
                onChange={(e) => {
                  setCurrentPage(0);
                  setArticlesPerPage(parseInt(e.target.value, 10));
                }}
                value={articlesPerPage}
                style={{
                  background: bg,
                  border: "none",
                  borderRadius: "4px",
                  padding: "5px",
                  marginRight: "10px",
                  color: "#717171",
                }}
              >
                {[5, 10, 15, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              <Text color="#717171">
                {offset + 1}–
                {Math.min(offset + articlesPerPage, filteredArticles.length)} of {filteredArticles.length}
              </Text>
            </Stack>
            <Stack direction="row" spacing={4} alignItems="center" ml={10}>
              <IconButton
                icon={<ChevronLeftIcon />}
                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))}
                isDisabled={currentPage === 0}
                aria-label="Previous page"
                bg={bg}
                color={currentPage === 0 ? bg : "#717171"}
                _hover={{ bg: bg }}
                _active={{ bg: bg }}
                _focus={{ boxShadow: "none" }}
              />
              <IconButton
                icon={<ChevronRightIcon />}
                onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
                isDisabled={currentPage >= totalPages - 1}
                aria-label="Next page"
                bg={bg}
                color={currentPage >= totalPages - 1 ? bg : "#717171"}
                _hover={{ bg: bg }}
                _active={{ bg: bg }}
                _focus={{ boxShadow: "none" }}
              />
            </Stack>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
