import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import {
  FiPlus,
  FiSearch,
  FiChevronDown,
  FiArrowLeft,
  FiArrowRight,
  FiTrash2,
  FiEdit,
  FiEye,
} from "react-icons/fi";
import AddCareerModal from "../../components/careers/AddCareerModal";
import { deleteCareer, getAllCareers } from "../../actions/careerActions";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import JobOfferModal from "../../components/careers/JobOfferModal";

const DescriptionToggle = ({ description }) => {
  const blueAdmin = useSelector((state) => state.themeReducer.blueAdmin);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);

  const checkOverflow = useCallback(() => {
    if (textRef.current) {
      const { scrollHeight, clientHeight } = textRef.current;
      setIsOverflowing(scrollHeight > clientHeight);
    }
  }, [description]);

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [checkOverflow]);

  return (
    <Box>
      <Text
        ref={textRef}
        noOfLines={isExpanded ? undefined : 2}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="pre-line"
      >
        {description}
      </Text>
      {isOverflowing && (
        <Button color={blueAdmin} variant="link" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? "See less" : "See more"}
        </Button>
      )}
    </Box>
  );
};


export default function AdminCareersPage() {
  //for Dark/light mode
  const bg = useSelector((state) => state.themeReducer.backgroundColor1);
  const bg2 = useSelector((state) => state.themeReducer.backgroundColor2);
  const colorH = useSelector((state) => state.themeReducer.color1);
  const colorP = useSelector((state) => state.themeReducer.color2);
  const colorAdminP = useSelector((state) => state.themeReducer.colorAdmin2);
  const grayScales = useSelector((state) => state.themeReducer.grayScales);
  const blueAdmin = useSelector((state) => state.themeReducer.blueAdmin);

  const dispatch = useDispatch();
  const [careers, setCareers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [careersPerPage, setCareersPerPage] = useState(5);
  const [sortOption, setSortOption] = useState("job_title");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCareers, setFilteredCareers] = useState([]);
  const [isAddCareerModalOpen, setIsAddCareerModalOpen] = useState(false);
  const [careerToUpdate, setCareerToUpdate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [careerToDelete, setCareerToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCareer, setSelectedCareer] = useState(null);
  const [isJobOfferModalOpen, setIsJobOfferModalOpen] = useState(false);

  const fetchCareers = async () => {
    try {
      setLoading(true);
      const data = await dispatch(getAllCareers());
      setCareers(data);
      setLoading(false);
      setError(null);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    fetchCareers();
  }, [dispatch]);

  useEffect(() => {
    const filtered = filterCareers(careers, searchTerm);
    setFilteredCareers(sortCareers(filtered, sortOption));
    setCurrentPage(0);
  }, [careers, searchTerm, sortOption]);

  const sortCareers = (careers, option) => {
    return [...careers].sort((a, b) => {
      const aValue = a[option] || "";
      const bValue = b[option] || "";

      if (aValue < bValue) {
        return -1;
      }
      if (aValue > bValue) {
        return 1;
      }
      return 0;
    });
  };

  const filterCareers = (careers, term) => {
    if (!term) return careers;
    return careers.filter((career) => {
      const jobTitle = career.job_title?.toLowerCase() || "";
      const jobType = career.job_type?.toLowerCase() || "";
      const location = career.location?.toLowerCase() || "";

      return (
        jobTitle.includes(term.toLowerCase()) ||
        jobType.includes(term.toLowerCase()) ||
        location.includes(term.toLowerCase())
      );
    });
  };

  const handleDeleteClick = (career) => {
    setCareerToDelete(career);
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteCareer(careerToDelete.id))
      .then(() => {
        fetchCareers();
        setIsModalOpen(false);
        setCareerToDelete(null);
      })
      .catch((error) => console.error("Error deleting career:", error));
  };

  const handleUpdateClick = (career) => {
    setCareerToUpdate(career);
    setIsAddCareerModalOpen(true);
  };

  const handleViewClick = (career) => {
    setSelectedCareer(career);
    setIsJobOfferModalOpen(true);
  };

  const handleEdit = (career) => {
    setCareerToUpdate(career);
    setIsAddCareerModalOpen(true);
    setIsJobOfferModalOpen(false);
  };

  const offset = currentPage * careersPerPage;
  const totalPages = Math.ceil(filteredCareers.length / careersPerPage);

  const currentCareers = filteredCareers.slice(offset, offset + careersPerPage);

  return (
    <Box minH="100vh" bg={bg}>
      <Box ml={{ base: 0, md: 60 }} p={4}>
        <Heading fontSize="24px" mb={4} color={blueAdmin}>
          Careers
        </Heading>
        <Flex direction={{base:"column",lg:'row'}}  w={{base:"100%",lg:'95%'}} align={"center"} ml={{base:"0",lg:"2.5%"}}>
          <HStack w={{base:"100%",lg:'80%'}}>
          <InputGroup width={{base:"70%",lg:'80%'}}>
            <InputLeftElement pointerEvents="none">
              <FiSearch color="gray" />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Search careers"
              bg={bg2}
              borderRadius="16px"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              _focus={{ boxShadow: "none" }}
            />
          </InputGroup>
          <HStack
            spacing={0}
            width={{base:"25%",lg:"20%"}}
            margin="10px 20px"
            padding="12px 12px 12px 20px"
          >
            <Text color={colorH} fontSize={{base:"12px",md:"13px",lg:"15px"}} fontWeight="600">
              Sort by
            </Text>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<FiChevronDown color={grayScales} />}
                bg={bg}
                _hover={{ bg: { bg } }}
                _active={{ bg: { bg } }}
                _focus={{ boxShadow: "none" }}
              />
              <MenuList>
                <MenuItem onClick={() => setSortOption("job_title")}>
                  Job Title
                </MenuItem>
                <MenuItem onClick={() => setSortOption("job_type")}>
                  Job Type
                </MenuItem>
                <MenuItem onClick={() => setSortOption("location")}>
                  Location
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
          </HStack>
          <Button
            leftIcon={<FiPlus />}
            onClick={() => setIsAddCareerModalOpen(true)}
            bg="#001A72"
            ml="10px"
            color="#FFFFFF"
            _hover={{ bg: "#0044D6" }}
            borderRadius="4px"
            fontSize={{base:"14px",md:"16px",lg:"18px"}}
            w={{base:"100%",lg:"20%"}}
          >
            Add Career
          </Button>
        </Flex>
        {loading ? (
          <Spinner size="xl" color="#001A72" />
        ) : error ? (
          <Text color="red.500">Error fetching careers: {error.message}</Text>
        ) : (
          <Box bg={bg2} borderRadius="16px" px={15} py={6}>
            <Text
              color={colorH}
              fontSize="18px"
              fontWeight="bold"
              mb="10px"
              flex="1"
              marginBottom="16px"
            >
              List of Careers
            </Text>
            <Box maxW={"100%"} overflowY={"scroll"}>
            <Table variant="simple" borderRadius="30px">
              <Thead bg={"#b8c3d3"}>
                <Tr>
                  <Th
                    color={colorAdminP}
                    borderTopLeftRadius="8px"
                    fontWeight="600"
                    textAlign="center"
                  >
                    Job Title
                  </Th>
                  <Th color={colorAdminP} fontWeight="600" textAlign="center">
                    Job Type
                  </Th>
                  <Th color={colorAdminP} textAlign="center">
                    Description
                  </Th>
                  <Th color={colorAdminP} textAlign="center">
                    Location
                  </Th>
                  <Th
                    color={colorAdminP}
                    borderTopRightRadius="8px"
                    fontWeight="600"
                    textAlign="center"
                  >
                    Actions
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentCareers.map((career) => (
                  <Tr key={career.id}>
                    <Td color={colorP} fontWeight="600" textAlign="center">
                      {career.job_title}
                    </Td>
                    <Td color={colorP} fontWeight="600" textAlign="center">
                      {career.job_type}
                    </Td>
                    <Td>
                      <Text color={colorP} fontWeight="600" textAlign="justify">
                        <DescriptionToggle description={career.description} />
                      </Text>
                    </Td>
                    <Td color={colorP} fontWeight="600" textAlign="center">
                      {career.location}
                    </Td>
                    <Td>
                      <HStack spacing={6} justify="center">
                        <Box
                          width="25px"
                          height="25px"
                          borderRadius={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border={`1px solid ${bg}`}
                        >
                          <FiEye
                            color={blueAdmin}
                            onClick={() => handleViewClick(career)}
                          />
                        </Box>
                        <Box
                          width="25px"
                          height="25px"
                          borderRadius={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border={`1px solid ${bg}`}
                        >
                          <FiEdit
                            color={blueAdmin}
                            onClick={() => handleUpdateClick(career)}
                          />
                        </Box>
                        <Box
                          width="25px"
                          height="25px"
                          borderRadius={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border={`1px solid ${bg}`}
                        >
                          <FiTrash2
                            color={blueAdmin}
                            onClick={() => handleDeleteClick(career)}
                          />
                        </Box>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            </Box>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="20px"
          bg={bg}
          p="10px"
          borderRadius="8px"
        >
          <Stack direction="row" spacing={4} alignItems="center">
            <Text mr="10px" color="#717171">
              Rows per page:
            </Text>
            <select
              onChange={(e) => {
                setCurrentPage(0);
                setCareersPerPage(parseInt(e.target.value, 10));
              }}
              value={careersPerPage}
              style={{
                background: bg,
                border: "none",
                borderRadius: "4px",
                padding: "5px",
                marginRight: "10px",
                color: "#717171",
              }}
            >
              {[5, 10, 15, 20].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <Text color="#717171">
              {offset + 1}–
              {Math.min(offset + careersPerPage, filteredCareers.length)} of{" "}
              {filteredCareers.length}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center" ml={10}>
            <FiArrowLeft
              onClick={() =>
                setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))
              }
              color={currentPage === 0 ? bg: "#717171"}
              style={{ cursor: currentPage === 0 ? "not-allowed" : "pointer" }}
            />
            <FiArrowRight
              onClick={() =>
                setCurrentPage((prevPage) =>
                  Math.min(prevPage + 1, totalPages - 1)
                )
              }
              color={currentPage >= totalPages - 1 ? bg: "#717171"}
              style={{
                cursor:
                  currentPage >= totalPages - 1 ? "not-allowed" : "pointer",
              }}
            />
          </Stack>
        </Box>
      </Box>
      {isJobOfferModalOpen && (
        <JobOfferModal
          isOpen={isJobOfferModalOpen}
          onClose={() => setIsJobOfferModalOpen(false)}
          career={selectedCareer}
          onEdit={handleEdit}
        />
      )}
      <AddCareerModal
        isOpen={isAddCareerModalOpen}
        onClose={() => {
          setIsAddCareerModalOpen(false);
          setCareerToUpdate(null);
        }}
        careerToUpdate={careerToUpdate}
        refreshCareers={fetchCareers}
      />
      {careerToDelete && (
        <DeleteConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onDelete={handleDeleteConfirm}
          userName={`${careerToDelete.job_title}`}
          pageName="Job"
        />
      )}
    </Box>
  );
}
