import {v4 as uuidv4} from 'uuid';
import { getAuthConfig } from "../../actions/config";

const tokinize=async(amount,currency)=>{
    console.log(process.env.REACT_APP_PRI_KEY)
    try {
        const response = await fetch(`https://youcanpay.com/sandbox/api/tokenize`, {
          method:"POST",
          body:JSON.stringify({
            amount:`${amount*100}`,
            currency:currency,
            pri_key:process.env.REACT_APP_PRI_KEY,
            order_id:"3"
          }),
          headers: {
            "Content-Type": "application/json",
          }
        });
        if (!response.ok) {
            return {success:false,msg:"Something went wrong, please try later",loading:false}
        }
        const data = await response.json();
        return data;
      } catch (error) {
        return {success:false,msg:"Something went wrong, please try later",loading:false}
      }
}
const paymentSave=async(amount,userId,currency)=>{
    const config = getAuthConfig();
    const response=await fetch(`${process.env.REACT_APP_API_URL}/payment/save`,{
        method:"POST",
        body:JSON.stringify({
            payement_date:new Date(),
            amount:amount,
            user:userId,
            currency:currency
        }),
        credentials:"include",
        ...config
    })
    if(!response.ok){
        return {success:false,msg:"Something went wrong, text a manager to resolve it",loading:false}
    }
    const data=response.json();
    return data
}
const passPayment=async(payementInfo,setMsg,amount,currency,userId,dispatch,createSubscription,duration)=>{
    setMsg({loading:true})
    const tokinizeResult=await tokinize(amount,currency)
    if(tokinizeResult.token){
        const response=await fetch(`https://youcanpay.com/sandbox/api/pay`,{
            method:"POST",
            body:JSON.stringify({
                token_id:tokinizeResult.token.id,
                pub_key:process.env.REACT_APP_PUB_KEY,
                expire_date:payementInfo.expire_date,
                credit_card:payementInfo.credit_card,
                cvv:payementInfo.cvv,
                card_holder_name:payementInfo.card_holder_name,
                payment_method:{
                    type:"credit_card"
                }
            }),
            headers: {
                "Content-Type": "application/json",
            }
        })
        await response.json().then(async(data)=>{
            if(response.ok){
                const payement=await paymentSave(amount,userId,currency)
                if(payement.id){
                    const idd=payement.id
                    const idT=setTimeout(async()=>{   
                        for(const [key,value] of Object.entries(duration))
                            await dispatch(createSubscription(key,userId,value,idd))
                    },[2000])
                    setMsg({success:true,msg:"Congratulations, Your Subscription is Activted",loading:false})
                    return ()=>clearTimeout(idT)
                }else{
                    setMsg({success:false,msg:"Something went wrong, text a manager to resolve it",loading:false})
                }
            }else{
                setMsg({success:false,msg:data.message,loading:false})
            }
        })
    }else{
        setMsg(tokinizeResult)
    }
}
export default passPayment