import axios from 'axios';
import { 
  GET_NEWSLETTERS_SUCCESS, 
  GET_NEWSLETTERS_FAIL,
  GET_NEWSLETTER_SUCCESS,
  GET_NEWSLETTER_FAIL,
  CREATE_NEWSLETTER_SUCCESS,
  CREATE_NEWSLETTER_FAIL,
  UPDATE_NEWSLETTER_SUCCESS,
  UPDATE_NEWSLETTER_FAIL,
  DELETE_NEWSLETTER_SUCCESS,
  DELETE_NEWSLETTER_FAIL
} from './types';

export const getNewsletters = () => async dispatch => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/newsletters`);
    dispatch({
      type: GET_NEWSLETTERS_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_NEWSLETTERS_FAIL,
      payload: err.message
    });
  }
};

export const getNewsletterById = (id) => async dispatch => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/newsletter/${id}`);
    dispatch({
      type: GET_NEWSLETTER_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_NEWSLETTER_FAIL,
      payload: err.message
    });
  }
};

export const createNewsletter = (data) => async dispatch => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/newsletter/save`, data);
    dispatch({
      type: CREATE_NEWSLETTER_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CREATE_NEWSLETTER_FAIL,
      payload: err.message
    });
  }
};

export const updateNewsletter = (id, data) => async dispatch => {
  try {
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/newsletter/${id}/update`, data);
    dispatch({
      type: UPDATE_NEWSLETTER_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATE_NEWSLETTER_FAIL,
      payload: err.message
    });
  }
};

export const deleteNewsletter = (id) => async dispatch => {
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/newsletter/${id}/delete`);
    dispatch({
      type: DELETE_NEWSLETTER_SUCCESS,
      payload: id
    });
  } catch (err) {
    dispatch({
      type: DELETE_NEWSLETTER_FAIL,
      payload: err.message
    });
  }
};
