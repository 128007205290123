import React from 'react';
import { Flex, Heading, Text, Image, Button, Box, useMediaQuery } from "@chakra-ui/react";

export default function ProductCard(props) {
  const [isLessThan768] = useMediaQuery("(max-width: 768px)");
  const fontSize = isLessThan768 ? "20px" : "24px";
  const textFontSize = isLessThan768 ? "14px" : "16px"; 
  const buttonWidth = isLessThan768 ? "90%" : "32%";
  const buttonFontSize = isLessThan768 ? "12px" : "16px";
  
  const textStyles = isLessThan768
    ? {
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxHeight: "calc(1.2em * 8)",
      }
    : {};

  return (
    <Flex direction="column" gap="20px" width={isLessThan768 ? "100%" : "72%"}>
      <Flex>
        <Heading fontSize={fontSize}>{props.title}</Heading>
      </Flex>
      <Flex gap="20px">
        <Box border="2px solid #2D2D2D" borderRadius="8px"></Box>
        <Flex gap="20px">
          <Flex direction="column" gap="16px">
            <Text fontSize={textFontSize} style={textStyles}>
              {props.body}
            </Text>
            <Button
              width={buttonWidth}
              fontSize={buttonFontSize}
              bg="#2D2D2D"
              color="#FFFFFF"
              _hover={{ bg: "#3d3d3d" }}
              borderRadius="20px"
            >
              Pre-order now
            </Button>
          </Flex>
          <Image src={props.image} alt={props.title} width={isLessThan768 ? "80px" : "160px"} height={isLessThan768 ? "80px" : "160px"} />
        </Flex>
      </Flex>
    </Flex>
  );
}
