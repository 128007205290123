import axios from 'axios';


const deleteArticle = async (article_id) => {
  const accessToken = localStorage.getItem('access');

  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/article/${article_id}/delete/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }
    });
    if (response.status === 204) {
      window.location.reload();
    }
    return response.data;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};

export default deleteArticle;
