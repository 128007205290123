import {
  Flex,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  StackDivider,
  Input,
  Checkbox,
  Button,
  useToast,
  Text,
  Spinner,
  FormControl,
  FormErrorMessage
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";

export default function PreOrder() {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    privacy_checked: false,
  });
  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    return re.test(String(phone));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name.trim()) newErrors.first_name = "First name is required";
    if (!formData.last_name.trim()) newErrors.last_name = "Last name is required";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!formData.phone_number.trim()) {
      newErrors.phone_number = "Phone number is required";
    } else if (!validatePhone(formData.phone_number)) {
      newErrors.phone_number = "Invalid phone number format";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      toast({
        title: "Please correct the errors in the form.",
        status: "error",
        position: "top-center",
        isClosable: true,
      });
      return;
    }

    if (!formData.privacy_checked) {
      toast({
        title: "Please accept the privacy policy to submit.",
        status: "warning",
        position: "top-right",
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/preorder/save`,
        formData
      );
      setLoading(false);
      toast({
        title: "Your Information has been Submitted Successfully",
        status: "success",
        position: "top-center",
        isClosable: true,
      });

      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        privacy_checked: false,
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error submitting data",
        status: "error",
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    if (name === "phone_number") {
      const numericValue = value.replace(/[^0-9+]/g, '');  
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    }

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };


  return (
    <>
      <Card
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={{base:"22px", sm:"22px", md:"22px", xl:"32px", lg:"32px"}}
      >
        <CardHeader>
          <Heading fontSize={{base:"1em", sm:"1em", md:"1em", xl:"2em", lg:"2em" }} textAlign="center">Join us for X CAPITAL TERMINAL</Heading>
          <Text fontSize={{base:"0.9em", sm:"0.9em", md:"0.9em", xl:"1em", lg:"1em"}} textAlign="center" mt="1em">Keep an eye on your inbox! We'll send a confirmation email.</Text>
        </CardHeader>
        
        <CardBody width={{base:"100%", md:"100%", sm:"100%",lg:"60%",xl:"60%"}}>
          <Stack divider={<StackDivider />} spacing="4">
          
            <Flex flexDirection="column" gap="30px">
              <Heading margin="16px 0" fontSize={{base:"md", sm:"md", md:"md", xl:"2xl", lg:"2xl"}}>
                Personal Information
              </Heading>
              <FormControl isInvalid={errors.first_name}>
                <Input
                  placeholder="First name"
                  size="md"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{errors.first_name}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.last_name}>
                <Input
                  placeholder="Last name"
                  size="md"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{errors.last_name}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.email}>
                <Input
                  placeholder="Email"
                  size="md"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.phone_number}>
                <Input
                  placeholder="Phone number"
                  size="md"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{errors.phone_number}</FormErrorMessage>
              </FormControl>
            </Flex>
            
            <Checkbox 
              size={{base:"sm",sm:"sm", md:"sm",lg:"lg",xl:"lg"}}
              name="privacy_checked"
              isChecked={formData.privacy_checked}
              onChange={handleInputChange}
            >
              By submitting my information, I consent to the privacy policy
              and to receive further information about products and services.
            </Checkbox>
            <Button
              bg="#001A72"
              color="white"
              _hover="none"
              isDisabled={!formData.privacy_checked}
              onClick={handleSubmit}
            >
              {loading ? <Spinner /> : <>Join</>}
            </Button>
          </Stack>
        </CardBody>
      </Card>
    </>
  );
}
