import React from 'react'
import { Flex, Box, Text,List, ListItem, UnorderedList, Heading } from '@chakra-ui/react'


function AccessibilityStatement() {
  return (
    <>
       <Flex direction={"column"} p={10} mb="2em">
        <Box mb="2em">
            <Heading size={{base:"lg", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>Accessibility Statement</Heading>
            <Text mb="1em"><b>Effective Date:</b> July 4, 2024</Text>
            <Text textAlign="left">X CAPITAL ("we," "us," "our") is committed to making our website accessible to all users, including those with disabilities.</Text>
        </Box>

        <Flex direction={"column"} gap={5}>
            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>1. Accessibility Features</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>Text alternatives for non-text content.</ListItem>
                    <ListItem>Keyboard navigable content.</ListItem>
                    <ListItem>Accessible forms and inputs.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>2. Feedback</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem listStyleType="none">We welcome your feedback on the accessibility of our website. Please contact us at: contact@xcapitalterminal.com or call us at +212 632331441 or +212 632321083.</ListItem>
                </UnorderedList>
            </List>


            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>3. Contact Us</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem listStyleType="none">If you have any questions about our Accessibility Statement, please contact us at: contact@xcapitalterminal.com or call us at +212 632331441 or +212 632321083.</ListItem>
                </UnorderedList>
            </List>
        </Flex>
      </Flex>
    </>
  )
}

export default AccessibilityStatement
