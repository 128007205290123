import React,{useEffect} from 'react'
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  useMediaQuery,
  VStack,
  Card,
  CardHeader,
  Tooltip,
  useBreakpointValue,
  Center,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { CheckCircleIcon } from "@chakra-ui/icons";
import SolutionHero from "../../assets/articleSolution.jpeg";
import SolutionQuestion from "../../components/questions/SolutionQuestions";
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { getProducts } from '../../actions/product';
import { setCart } from '../../actions/cart';

const AnimatedFeatureCard = ({ title, description, delay }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const cardWidth = useBreakpointValue({
    base: "90%", // Mobile
    sm: "95%", // Small screens
    md: "85%", // Medium screens
    lg: "1200px", // Large screens and above
  });

  const cardPadding = useBreakpointValue({
    base: "4",
    sm: "6",
  });

  const titleFontSize = useBreakpointValue({
    base: "sm",
    sm: "md",
  });

  const descriptionFontSize = useBreakpointValue({
    base: "xs",
    sm: "sm",
    md: "md",
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.7, delay }}
    >
      <Card width={cardWidth} boxShadow="lg" mx="auto">
        <CardHeader padding={cardPadding}>
          <Flex
            alignItems="flex-start"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Flex alignItems="center" w={"40%"} mb={{ base: 2, sm: 0 }} mr={{ sm: 4 }}>
              <CheckCircleIcon color="green.500" mr="12px" boxSize={6} />
              <Heading size={titleFontSize}>{`${title}:`}</Heading>
            </Flex>
            <Box w={"55%"}>
              <Text fontSize={descriptionFontSize}>{description}</Text>
            </Box>
          </Flex>
        </CardHeader>
      </Card>
    </motion.div>
  );
};


const ArticlesSolution = () => {
  const [isLargerThan840] = useMediaQuery("(min-width: 840px)");
  
  const bgColor=useSelector(state=>state.themeReducer.backgroundColor1)
  const grayScales=useSelector(state=>state.themeReducer.grayScales)

  const navigate=useNavigate()
  const dispatch=useDispatch()
  const isAuth=useSelector(state=>state.auth.isAuthenticated)
  const role=useSelector(state=>state.auth.user?.role)
  const products=useSelector(state=>state.productReducer.products)
  useEffect(()=>{
    dispatch(getProducts())
  },[])
  const handlePayNow=()=>{
    if(isAuth){
      if(role === "Client"){
       const articlesProduct=products.filter(pr=>/articl/i.test(pr.title)) 
       const id=articlesProduct[0]?.id
       dispatch(setCart(id,"monthly"))
       window.location.href="/checkout"
      }
    }else
      navigate("/login")
  }
  const faqList = [
    {
      question: "What is X Capital Articles?",
      answer:
        "X Capital Articles is a content platform that offers educational articles, market analysis, thought leadership pieces, and news publications with analysis on their impact on financial markets.",
    },
    {
      question: "Who can benefit from X Capital Articles?",
      answer:
        "The articles are designed for individual investors, students, and financial professionals who want to expand their knowledge and stay updated on the latest market developments.",
    },
    {
      question: "What topics are covered in X Capital Articles?",
      answer:
        "Topics include market trends, investment strategies, financial literacy, industry developments, and news with analysis on its impact on financial markets.",
    },
    {
      question: "In what formats are the articles available?",
      answer:
        "Articles are available in PDF format, with some content also provided in video format for those who prefer visual learning.",
    },
    {
      question: "How often is new content added to X Capital Articles?",
      answer:
        "New content is added regularly to ensure that users have access to the latest information and insights.",
    },
    {
      question: "How much does X Capital Articles cost?",
      answer:
        "The subscription for X Capital Articles is priced at 1,599 MAD per year.",
    },
    {
      question: "Are there free resources available in X Capital Articles?",
      answer:
        "Yes, X Capital Articles offers a mix of free and premium content, allowing users to access valuable information even without a subscription.",
    },
    {
      question: "How can I subscribe to X Capital Articles?",
      answer:
        "You can subscribe directly through the X Capital website, where you can also access customer support for any questions or issues.",
    },
  ];

  return (
    <Box>
      {/* Hero Section */}
      <Box
        height="648px"
        position="relative"
        backgroundImage={`url(${SolutionHero})`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition={
          isLargerThan840 ? "center" : "calc(100% - 70%) bottom"
        }
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="rgba(0, 0, 0, 0.4)"
        />
        <Flex
          position="absolute"
          bottom="15%"
          left="5%"
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-start"
          maxWidth={isLargerThan840 ? "45%" : "80%"}
          color="white"
        >
          <Heading
            as="h1"
            fontSize={isLargerThan840 ? "48px" : "28px"}
            fontWeight="800"
            marginBottom="28px"
          >
            X CAPITAL Articles
          </Heading>
          <Text
            fontSize={isLargerThan840 ? "24px" : "20px"}
            marginBottom="24px"
            maxWidth={isLargerThan840 ? "90%" : "100%"}
          >
           Explore the latest trends, analysis, and insights from the experts at X CAPITAL. Our articles cover a wide range of topics, including market trends, investment strategies, and industry developments. Whether you're a seasoned professional or just starting out, our articles provide valuable information to help you navigate the complexities of the financial markets and make informed decisions.
          </Text>
          <Button
            bg="white"
            color="gray.700"
            width="160px"
            height="40px"
            borderRadius="12px"
            fontSize="20px"
            _hover={{ bg: "gray.100" }}
            onClick={handlePayNow}
          >
            Pay Now
          </Button>
        </Flex>
      </Box>

      {/* Features Section */}
      <Box bg={bgColor} width="full" ml={'0%'} py="60px">
        <VStack spacing="40px" align="center" w="100%" mx="auto" px={4}>
          <Heading mb="24px">Our Key Features</Heading>
          <AnimatedFeatureCard
            title="Market Insights"
            description=" Detailed articles covering important market trends, strategies, and analyses help keep investors educated and informed."
            delay={0.2}
          />
          <AnimatedFeatureCard
            title="Educational Content"
            description="Provides educational resources for both new and seasoned investors, helping them navigate complex financial markets."
            delay={0.4}
          />
          <AnimatedFeatureCard
            title="Impact Analyses"
            description=" Breaking down the implications of financial news and events, giving users a better understanding of how these may affect the markets and their portfolios."
            delay={0.6}
          />
          <AnimatedFeatureCard
            title="Subscription-Based Access"
            description="   Allows users to choose between free or premium content, with premium articles offering deeper insights and analyses."
            delay={0.8}
          />
          <Button
            bg={grayScales}
            color={grayScales==="black"?"white":"black"}
            width="220px"
            height="55px"
            borderRadius="18px"
            fontSize="23px"
            _hover={{ bg: "gray.100" }}
            onClick={handlePayNow}
            mt={"20px"}
          >
            Pay Now
          </Button>
        </VStack>
      </Box>
      <Center bg={bgColor}>
        <SolutionQuestion faqList={faqList} />
      </Center>
    </Box>
  );
};

export default ArticlesSolution;
