import axios from "axios";
import { getAuthConfig } from "./config";
import { GET_TARGET_FAIL, GET_TARGET_SUCCESS } from "./types";


export const getTargetSales = () => async (dispatch) => {
    try {
      const config = getAuthConfig();
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/targets`, config);
      dispatch({
        type: GET_TARGET_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch({
        type: GET_TARGET_FAIL,
        payload: err.message,
      });
      throw err;
    }
  };