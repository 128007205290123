import {
  GET_CANDIDATES_SUCCESS,
  GET_CANDIDATES_FAIL,
  GET_CANDIDATE_BY_ID_SUCCESS,
  GET_CANDIDATE_BY_ID_FAIL,
  CREATE_CANDIDATE_SUCCESS,
  CREATE_CANDIDATE_FAIL,
  UPDATE_CANDIDATE_SUCCESS,
  UPDATE_CANDIDATE_FAIL,
  DELETE_CANDIDATE_SUCCESS,
  DELETE_CANDIDATE_FAIL,
  GET_CANDIDATES_BY_CAREER_SUCCESS,
  GET_CANDIDATES_BY_CAREER_FAIL
} from './types';
import axios from 'axios';
import { getAuthConfig, getAuthConfigFile } from './config';

export const getCandidates = () => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/candidats`, config);
    dispatch({
      type: GET_CANDIDATES_SUCCESS,
      payload: res.data
    });
    return res.data; 
  } catch (err) {
    dispatch({
      type: GET_CANDIDATES_FAIL,
      payload: err.message 
    });
    throw err; 
  }
};

export const getCandidateById = (candidateId) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/candidat/${candidateId}`, config);
    dispatch({
      type: GET_CANDIDATE_BY_ID_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_CANDIDATE_BY_ID_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const getCandidatesByCareer = (careerId) => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/job/candidats/${careerId}`, config);
    dispatch({
      type: GET_CANDIDATES_BY_CAREER_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_CANDIDATES_BY_CAREER_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const createCandidate = (candidateData) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/candidat/save`, candidateData, config);
    dispatch({
      type: CREATE_CANDIDATE_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: CREATE_CANDIDATE_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const updateCandidate = (candidateId, candidateData) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/candidat/${candidateId}/update`, candidateData, config);
    dispatch({
      type: UPDATE_CANDIDATE_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: UPDATE_CANDIDATE_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const deleteCandidate = (candidateId) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    await axios.delete(`${process.env.REACT_APP_API_URL}/candidat/${candidateId}/delete`, config);
    dispatch({
      type: DELETE_CANDIDATE_SUCCESS,
      payload: candidateId
    });
  } catch (err) {
    dispatch({
      type: DELETE_CANDIDATE_FAIL,
      payload: err.message
    });
    throw err;
  }
};