import React,{useEffect} from 'react'
import ProductDescription from '../../components/products/ProductDescription'
import xcapitalterminalimage from "../../assets/Products/XCAPITALTERMINAL.png"
import ProductFeatures from '../../components/products/ProductFeatures'
import { useDispatch,useSelector } from 'react-redux'
import { getProducts } from '../../actions/product'
import { Spinner } from '@chakra-ui/react'


function XcapitalterminalPage() {
  const dispatch=useDispatch()
  const product=useSelector(state=>state.productReducer.products).filter(pr=>/terminal/i.test(pr.title))
  useEffect(()=>{
    dispatch(getProducts())
  },[])
  return (
    <>
    {
      product[0]?
      <ProductDescription
            title="X CAPITAL TERMINAL"
            description="X CAPITAL TERMINAL is our flagship product, a state-of-the-art platform that offers real-time data, comprehensive analytics, and valuable insights into the financial markets of Africa and the Middle East."
            monthlyPrice="4,000 MAD"
            yearlyPrice="40,000 MAD"
            image={xcapitalterminalimage}
            benefit="X CAPITAL TERMINAL equips investors with the information and tools needed to make informed decisions and capitalize on market opportunities efficiently."
      >
            <ProductFeatures 
                feature="Real-Time Data: Stay updated with live market data to never miss a market movement."
            />
            <ProductFeatures 
                feature="Advanced Analytics: Utilize AI and machine learning algorithms to analyze market trends and patterns."
            />
            <ProductFeatures 
              feature="Deep Insights: Gain in-depth insights into market dynamics to make strategic investment decisions. "
          />
      </ProductDescription>:<Spinner size={"lg"} position={"absolute"} top={"25%"} left={"50%"} transform={"translate(-50%,-25%)"}/>
    }
    </>
  )
}

export default XcapitalterminalPage
