import { Flex, Heading, Button, Text } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import deletePreOrder from "../../services/api/preOrdersAPI/deletePreOrder";

export default function DeletePredOrderCard(preOrderId) {
  return (
    <Flex
      direction={"column"}
      align={"center"}
      justify={"center"}
      gap={"16px"}
      padding={"20px"}
    >
      <Flex
        bgColor={"#FFE0E3"}
        width={"60px"}
        height={"60px"}
        align={"center"}
        justify={"center"}
        borderRadius={"50%"}
      >
        {" "}
        <DeleteIcon boxSize={"2em"} color={"red"} />
      </Flex>
      <Heading fontSize={"28px"}>Delete Article?</Heading>
      <Text width={"82%"}>
        You're about to delete an article. This action cannot be undone.
      </Text>
      <Flex gap={"12px"}>
        <Button bg={"none"} border={"1px solid black"}>
          Cancel
        </Button>
        <Button
          bgColor={"red"}
          color={"white"}
          _hover={"none"}
          onClick={() => deletePreOrder(preOrderId.preOrderId)}
        >
          Delete
        </Button>
      </Flex>
    </Flex>
  );
}
