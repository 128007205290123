import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Image,
  Heading,
  Text,
  Button,
  useMediaQuery,
  Spinner,
  IconButton,
  HStack
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import SearchBar from "../components/SearchBar";
import JobCard from "../components/careers/JobCard";
import fetchJobs from "../services/api/careersAPI/fetchJobs";
import careerImg from "../assets/careerImg.svg";
import { useSelector } from "react-redux";

export default function Careers() {
  const bg = useSelector(state => state.themeReducer.backgroundColor1)
  const bg2 = useSelector(state => state.themeReducer.backgroundColor2)
  const colorH = useSelector(state => state.themeReducer.color1)
  const colorP = useSelector(state => state.themeReducer.color2)
  const grayScales = useSelector(state => state.themeReducer.grayScales)

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [jobs, setJobs] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const jobsPerPage = 6;
  const navigate = useNavigate();
  const jobsSectionRef = useRef(null);

  useEffect(() => {
    const getJobs = async () => {
      try {
        const result = await fetchJobs();
        setJobs(result);
      } catch (error) {
        console.error("Error fetching articles:", error);
      } finally {
        setLoadingJobs(false);
      }
    };

    getJobs();
  }, []);

  const handleCheckJob = (id) => {
    navigate(`/career/${id}`);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredJobs.length / jobsPerPage)) setCurrentPage(currentPage + 1);
  };

  const handleBrowseClick = () => {
    if (jobsSectionRef.current) {
      jobsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const filteredJobs = jobs.filter(job => 
    job.job_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    job.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
    job.job_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
    job.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

  return (
    <>
      <Flex direction="column" bgColor={bg}>
        <Flex
          height={isLargerThan768 ? "100vh" : "40vh"}
          bg={grayScales === "black" ? "white" : "black"}
          align="center"
          justify="space-around"
        >
          <Flex
            direction="column"
            width={isLargerThan768 ? "40%" : "90%"}
            gap="16px"
            align={isLargerThan768 ? null : "center"}
            justify={isLargerThan768 ? null : "center"}
          >
            <Heading fontSize={isLargerThan768 ? "44px" : "28px"}>
              Empowering the Future of Finance and Technology
            </Heading>
            <Text fontSize={isLargerThan768 ? "20px" : "16px"}>
              Join our dynamic team and be a part of the revolution in the
              financial industry.
            </Text>
            <Button
              bg="#2D2D2D"
              color="#FFFFFF"
              width={isLargerThan768 ? "60%" : "40%"}
              onClick={handleBrowseClick}  
            >
              Browse Jobs
            </Button>
          </Flex>
          {isLargerThan768 ? <Image src={careerImg} alt="career" /> : null}
        </Flex>
        <Flex
          direction="column"
          justify="center"
          align="center"
          gap="40px"
          padding={"40px"}
          ref={jobsSectionRef} 
        >
          <Flex direction="column" justify="center" align="center" gap="20px">
            <Heading fontSize={isLargerThan768 ? "40px" : "28px"}>
              Current Job Openings
            </Heading>
            <Text
              width={isLargerThan768 ? "72%" : ""}
              fontSize={isLargerThan768 ? "20px" : "16px"}
            >
              Explore our diverse range of career opportunities and join our
              growing fintech team.
            </Text>
          </Flex>
          <SearchBar
            border="1px black solid"
            width={isLargerThan768 ? "72%" : "100%"}
            placeHolder="Search jobs..."
            iconRadius="none"
            bgColor={bg2}
            onSearch={handleSearch}
            onInputChange={handleSearch}
          />
        </Flex>
        
        <Flex
          direction="column"
          align="center"
          justify="center"
          padding="40px"
          gap="24px"
        >
          {loadingJobs ? (
            <Spinner size="xl" />
          ) : (
            currentJobs.map((job) => (
              <JobCard
                key={job.id}
                title={job.job_title}
                location={job.location}
                type={job.job_type}
                description={job.description}
                onClick={() => handleCheckJob(job.id)}
              />
            ))
          )}
          <HStack spacing={4}>
            <IconButton
              icon={<ArrowLeftIcon />}
              onClick={handlePreviousPage}
              isDisabled={currentPage === 1}
            />
            <Text>{`Page ${currentPage} of ${Math.ceil(filteredJobs.length / jobsPerPage)}`}</Text>
            <IconButton
              icon={<ArrowRightIcon />}
              onClick={handleNextPage}
              isDisabled={currentPage === Math.ceil(filteredJobs.length / jobsPerPage)}
            />
          </HStack>
        </Flex>
      </Flex>
    </>
  );
}
