import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import SidebarWithHeader from '../components/SideBar'
import { useSelector , useDispatch} from 'react-redux';
import { Spinner,Flex,Box } from '@chakra-ui/react';
import { load_user } from '../actions/auth';

const ManagerLayout = () => {

  const navigate = useNavigate()
  const dispatch=useDispatch()
  const role=useSelector(state=>state.auth.user?.role)
  const isAuthenticated=useSelector(state=>state.auth.isAuthenticated)

  useEffect(()=>{
    if(role && role!=="Manager")
    navigate("/Not-Access")
    if(!isAuthenticated)
    navigate("/login")
},[role,isAuthenticated])
useEffect(()=>{
    const idT=setTimeout(()=>{
    dispatch(load_user())
    },500)
    return ()=>clearTimeout(idT)
},[])

    
  return (
    <Box>
      {
        role==="Manager"?
        <>
          <SidebarWithHeader/>
          <Outlet/>
        </>
        :
        <Flex height="100vh" align="center" justify="space-around"><Spinner width="50px" height="50px"/></Flex>
      }
    </Box>
  )
}

export default ManagerLayout