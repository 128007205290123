
import { getAuthConfig } from "../../actions/config";

export const sendSupportMsg=async(content,mail)=>{
    const config = getAuthConfig();
    return await fetch(`${process.env.REACT_APP_API_URL}/support/save/${mail}`,{
        method:"POST",
        body:JSON.stringify(content),
        ...config
    }).then((response)=>{
        if(response.status===201)
            return true
        else 
            return false
    })
}
export const getSuppotsByUserId=async(id,setSupports,setErr)=>{
    const config = getAuthConfig();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/support/user/${id}`,{
        method:"GET",
        ...config
    })
    await response.json().then((data)=>{
        if(response.ok)
            setSupports(data)
        else
            setErr("An Error Occurred")
    })
}

