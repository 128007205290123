import axios from 'axios';


const updatePreOrder = async (formData, preOrderId) => {
  const accessToken = localStorage.getItem('access');

  try {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/preorder/${preOrderId}/update`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${accessToken}`
      }
    });
    
    if (response.status === 204) {
      window.location.reload();
    }
    
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Server responded with an error status:', error.response.status);
      console.error('Error details:', error.response.data);
    } else if (error.request) {
      console.error('No response received from server:', error.request);
    } else {
      console.error('Error setting up the request:', error.message);
    }
    
    throw error;
  }
};

export default updatePreOrder;
