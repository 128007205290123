import {
  GET_SUB_ARTICLES_SUCCESS,
  GET_SUB_ARTICLES_FAIL,
  GET_SUB_ARTICLE_BY_ID_SUCCESS,
  GET_SUB_ARTICLE_BY_ID_FAIL,
  CREATE_SUB_ARTICLE_SUCCESS,
  CREATE_SUB_ARTICLE_FAIL,
  UPDATE_SUB_ARTICLE_SUCCESS,
  UPDATE_SUB_ARTICLE_FAIL,
  DELETE_SUB_ARTICLE_SUCCESS,
  DELETE_SUB_ARTICLE_FAIL,
  GET_SUB_ARTICLES_BY_ARTICLE_SUCCESS,
  GET_SUB_ARTICLES_BY_ARTICLE_FAIL
} from './types';
import axios from 'axios';
import { getAuthConfig, getAuthConfigFile } from './config';

export const getSubArticles = () => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/sub-articles/`, config);
    dispatch({
      type: GET_SUB_ARTICLES_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_SUB_ARTICLES_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const getSubArticleById = (subArticleId) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/sub-article/${subArticleId}/`, config);
    dispatch({
      type: GET_SUB_ARTICLE_BY_ID_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_SUB_ARTICLE_BY_ID_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const getSubArticlesByArticle = (articleId) => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/sub-articles/article/${articleId}/`, config);
    dispatch({
      type: GET_SUB_ARTICLES_BY_ARTICLE_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_SUB_ARTICLES_BY_ARTICLE_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const createSubArticle = (subArticleData) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/sub-article/save/`, subArticleData, config);
    dispatch({
      type: CREATE_SUB_ARTICLE_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: CREATE_SUB_ARTICLE_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const updateSubArticle = (subArticleId, subArticleData) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/sub-article/${subArticleId}/update/`, subArticleData, config);
    dispatch({
      type: UPDATE_SUB_ARTICLE_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: UPDATE_SUB_ARTICLE_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const deleteSubArticle = (subArticleId) => async dispatch => {
  try {
    const config = getAuthConfigFile();
    await axios.delete(`${process.env.REACT_APP_API_URL}/sub-article/${subArticleId}/delete/`, config);
    dispatch({
      type: DELETE_SUB_ARTICLE_SUCCESS,
      payload: subArticleId
    });
  } catch (err) {
    dispatch({
      type: DELETE_SUB_ARTICLE_FAIL,
      payload: err.message
    });
    throw err;
  }
};
