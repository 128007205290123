import React from 'react'
import ProductDescription from '../../components/products/ProductDescription'
import xcapitaldataimage from "../../assets/Products/XCAPITALDATA.png"
import ProductFeatures from '../../components/products/ProductFeatures'
import { getProducts } from '../../actions/product'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Spinner } from '@chakra-ui/react'


function XcapitaldataPage() {
  const dispatch=useDispatch()
  const product=useSelector(state=>state.productReducer.products).filter(pr=>/data/i.test(pr.title))
  useEffect(()=>{
    dispatch(getProducts())
  },[])
  return (
    <>
    {
      product[0]?
    <ProductDescription
          id={`${product[0]?.id} MAD`}
          title="X CAPITAL DATA"
          description="X CAPITAL DATA offers a robust database of financial information, encompassing historical data, economic indicators, and market metrics for comprehensive research and analysis."
          monthlyPrice={`${product[0]?.monthlyPrice} MAD`}
          yearlyPrice={`${product[0]?.yearlyPrice} MAD`}
          image={xcapitaldataimage}
          benefit="X CAPITAL DATA is essential for researchers, analysts, and investors who require reliable and comprehensive financial data to support their decision-making processes."
    >
          <ProductFeatures 
              feature="Extensive Database: Access a vast repository of historical and real-time financial data."
          />
          <ProductFeatures 
              feature="Economic Indicators: Monitor key economic indicators that impact market performance."
          />
           <ProductFeatures 
              feature="Customizable Data Feeds: Receive data feeds tailored to your specific research and analytical needs."
          />
    </ProductDescription>:<Spinner size={"lg"} position={"absolute"} top={"25%"} left={"50%"} transform={"translate(-50%,-25%)"}/>
    }
  </>
  )
}

export default XcapitaldataPage
