import React ,{useEffect} from 'react'
import ProductDescription from '../../components/products/ProductDescription'
import xcapitalarticlesimage from "../../assets/Products/XCAPITALARTICLES.png"
import ProductFeatures from '../../components/products/ProductFeatures'
import { useDispatch,useSelector } from 'react-redux'
import { getProducts } from '../../actions/product'
import { Spinner } from '@chakra-ui/react'


function XcapitalarticlesPage() {
  const dispatch=useDispatch()
  const product=useSelector(state=>state.productReducer.products).filter(pr=>/articl/i.test(pr.title))
  useEffect(()=>{
    dispatch(getProducts())
  },[])
  return (
    <>
    {
      product[0]?
    <ProductDescription
          id={product[0].id}
          title="X CAPITAL Articles"
          description="X CAPITAL ARTICLES delivers expert commentary, analysis, and insights on the latest trends and developments in African and Middle Eastern financial markets."
          monthlyPrice={product[0].monthlyPrice}
          yearlyPrice={product[0].yearlyPrice}
          image={xcapitalarticlesimage}
          benefit="This product keeps users informed and educated, providing them with the knowledge needed to navigate the financial markets effectively."
    >
          <ProductFeatures 
              feature="Expert Analysis: Read articles written by industry experts and market analysts."
          />
          <ProductFeatures 
              feature="Market Updates: Stay informed with regular updates on market movements and economic developments."
          />
           <ProductFeatures 
              feature="Educational Content: Access a wealth of educational resources to enhance your financial knowledge."
          />
    </ProductDescription>:<Spinner size={"lg"} position={"absolute"} top={"25%"} left={"50%"} transform={"translate(-50%,-25%)"}/>
    }
  </>
  )
}

export default XcapitalarticlesPage
