import { React, useState, useEffect } from "react";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Stack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Image,
  Divider,
  useColorMode,
  Img,
} from "@chakra-ui/react";
import {
  FiUsers,
  FiCommand,
  FiTag,
  FiMenu,
  FiChevronDown,
  FiFileText,
  FiMessageSquare,
  FiChevronUp,
  FiUser,
  FiLogIn,
  FiPackage,
  FiSun,
  FiBriefcase,
  FiBell,
  FiBook,
  FiFile,
  FiFilter,
  FiShoppingBag,
  FiTarget,
  FiShoppingCart
} from "react-icons/fi";
import { MdOutlineDarkMode } from "react-icons/md";
import { FaRegNewspaper } from "react-icons/fa";
import { MdOutlineMessage } from "react-icons/md";
import logo from "../assets/logo_admin.png";
import { Link as LinkRouter, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  isTokenExpired,
  refreshToken,
  load_user,
  logout,
} from "../actions/auth";
import UserProfileModal from "./UserProfileModal"; 
import { getUserById } from "../actions/user";
import { toggleMode } from "../actions/theme";
import { getUserPic } from "../services/api/userInfos";
import { getCart } from "../actions/cart";

const LinkItems = [
  { name: "Dashboard", icon: FiCommand, path: "/x-capital/terminal/admin" },
  { name: "Users", icon: FiUsers, path: "/x-capital/terminal/admin/users" },
  { name: "Documents", icon: FiFileText, path: "/x-capital/terminal/admin/documents" },
  { 
    name: "Articles", 
    icon: FiBook, 
    children: [
      { name: "Articles", icon: FiFile, path: "/x-capital/terminal/admin/article-panel" },
      { name: "Categories", icon: FiFilter, path: "/x-capital/terminal/admin/category" }
    ],
  },
  { name: "Pre-Orders", icon: FiTag, path: "/x-capital/terminal/admin/pre-orders" },
  {
    name: "Careers",
    icon: FiBriefcase,
    children: [
      { name: "Jobs", icon: FiBell , path: "/x-capital/terminal/admin/careers" },
      { name: "Candidates", icon: FiUsers, path: "/x-capital/terminal/admin/candidates" },
    ],
  },
  { name: "Terminal", icon: FiBook, path: process.env.REACT_APP_TERMINAL_FRONTEND_URL },
  { name: "Message", icon: FiMessageSquare, path: "/x-capital/terminal/admin/messages" },
  { 
    name: "Solutions", 
    icon: FiPackage, 
    children: [
      { name: "Products", icon: FiShoppingBag, path: "/x-capital/terminal/admin/products" },
      { name: "Target Revenue", icon: FiTarget, path: "/x-capital/terminal/admin/target-revenue" }
    ],
  },
];

const ClientLinkItems = [
  { name: "Dashboard", icon: FiCommand, path: "/x-capital/terminal/client" },
  { name: "Solutions", icon: FaRegNewspaper, path: "/x-capital/terminal/client/solutions" },
  { name: "Articles", icon: FiFileText, path: "/x-capital/terminal/client/Articles" },
  { name: "Terminal", icon: FiBook, path: process.env.REACT_APP_TERMINAL_FRONTEND_URL  },
  { name: "Messages", icon: FiMessageSquare, path: "/x-capital/terminal/client/Messages" },
  { name: "Support", icon: MdOutlineMessage, path: "/x-capital/terminal/client/support"},
];

const ManagerLinkItems = [
  { name: "Dashboard", icon: FiCommand, path: "/x-capital/terminal/manager" },
  { name: "Users", icon: FiUsers, path: "/x-capital/terminal/manager/users" },
  {
    name: "Pre-Orders",
    icon: FiTag,
    path: "/x-capital/terminal/manager/pre-orders",
  },
  {
    name: "Documents",
    icon: FiFileText,
    path: "/x-capital/terminal/manager/documents",
  },
  {
    name: "Terminal",
    icon: FiBook,
    path: process.env.REACT_APP_TERMINAL_FRONTEND_URL
  },
  {
    name: "Message",
    icon: FiMessageSquare,
    path: "/x-capital/terminal/manager/messages",
  },
  {
    name: "Products",
    icon: FiPackage,
    path: "/x-capital/terminal/manager/products",
  },
  {
    name: "Articles",
    icon: FiFileText,
    path: "/x-capital/terminal/manager/article-panel",
  },
];

export default function SidebarWithHeader({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);
  const [marginItem, setMarginItem] = useState(null);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const updated= useSelector((state) => state.userReducer.users);
  const [picProfile,setPic]=useState("")
  const access = useSelector((state) => state.auth.access);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bgSideBar = useSelector((state) => state.themeReducer.backgroundColor2);
  const colorHeaderH = useSelector((state) => state.themeReducer.color1);
  const colorHeaderP = useSelector((state) => state.themeReducer.color2);
  const colorClicked = useSelector((state) => state.themeReducer.colorClicked);
  const bgHeader = useSelector((state) => state.themeReducer.backgroundColor1);
  const { colorMode, toggleColorMode } = useColorMode();
  const session=useSelector(state=>state.cartReducer.cartElements)

  useEffect(()=>{
    if(isAuthenticated && user.role==="Client")
      dispatch(getCart())
  },[isAuthenticated])

  const handleItemClick = (itemName) => {
    setSelectedItem((prevItem) => (prevItem === itemName ? null : itemName));
  };

  useEffect(() => {
    if (isTokenExpired(access)) {
      dispatch(refreshToken());
    }
  }, [dispatch, access]);

  if (!isAuthenticated) {
    navigate("/login");
  }

  useEffect(() => {
    dispatch(load_user());
  }, [dispatch]);

  useEffect(()=>{
    if(user.id)
        getUserPic(user.id,setPic)
  },[user.id])

  const [links, setLinks] = useState([]);
  useEffect(() => {
    if (user?.role === "Client") setLinks(ClientLinkItems);
    else if (user?.role === "Manager") setLinks(ManagerLinkItems);
    else setLinks(LinkItems);
  }, [user]);

  useEffect(() => {
    dispatch(toggleMode(colorMode));
  }, [colorMode, user]);

  return (
    <Box>
      <SidebarContent
        selectedItem={selectedItem}
        onClose={() => onClose()}
        handleItemClick={handleItemClick}
        display={{ base: "none", md: "block" }}
        marginItem={marginItem}
        user={user}
        links={links}
        colorH={colorHeaderH}
        colorClicked={colorClicked}
        bg={bgSideBar}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            selectedItem={selectedItem}
            handleItemClick={handleItemClick}
            onClose={onClose}
            marginItem={marginItem}
            links={links}
            bg={bgSideBar}
          />
        </DrawerContent>
      </Drawer>
      <MobileNav
        onOpen={onOpen}
        user={user}
        onProfileOpen={() => setIsProfileOpen(true)}
        bg={bgHeader}
        colorH={colorHeaderH}
        colorP={colorHeaderP}
        colorMode={colorMode}
        toggleColorMode={toggleColorMode}
        session={session}
        picProfile={picProfile}
      />
      <UserProfileModal
        isOpen={isProfileOpen}
        onClose={() => setIsProfileOpen(false)}
        user={user}
      />
    </Box>
  );
}

function SidebarContent({
  onClose,
  handleItemClick,
  selectedItem,
  marginItem,
  user,
  links,
  colorH,
  colorClicked,
  ...rest
}) {
  const currentPath = window.location.pathname;
  const [expandedItems, setExpandedItems] = useState([]);
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    const activeLink = links.find((item) =>
      item.children
        ? item.children.some((child) => child.path === currentPath)
        : item.path === currentPath
    );

    if (activeLink) {
      setActiveItem(activeLink.children ? currentPath : activeLink.name);
    }
  }, [currentPath, links]);

  const handleItemExpand = (itemName) => {
    setExpandedItems((prevItems) =>
      prevItems.includes(itemName)
        ? prevItems.filter((item) => item !== itemName)
        : [...prevItems, itemName]
    );
  };

  const handleItemSelection = (itemName, itemPath) => {
    setActiveItem(itemPath || itemName);
    handleItemClick(itemName);
  };

  return (
    <Box
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="100vh"
      overflowY="auto"
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
      }}
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image src={logo} alt="" width={"100px"} height={"100px"} />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Stack>
        {links.map((link) =>
          link.children ? (
            <Box key={link.name}>
              <NavItem
                path={link.path}
                key={link.name}
                icon={link.icon}
                color={colorH}
                isSelected={false}
                onClick={() => handleItemExpand(link.name)}
                marginItem={marginItem}
                marginTop="0"
                hasChevron={true}
                isExpanded={expandedItems.includes(link.name)}
              >
                {link.name}
              </NavItem>
              {expandedItems.includes(link.name) && (
                <Stack pl="6">
                  {link.children.map((child) => (
                    <NavItem
                      path={child.path}
                      key={child.name}
                      icon={child.icon}
                      color={activeItem === child.path ? colorClicked : colorH}
                      isSelected={activeItem === child.path}
                      onClick={() => handleItemSelection(child.name, child.path)}
                      marginItem={marginItem}
                      marginTop="0"
                    >
                      {child.name}
                    </NavItem>
                  ))}
                </Stack>
              )}
            </Box>
          ) : (
            <NavItem
              path={link.path}
              key={link.name}
              icon={link.icon}
              color={activeItem === link.name ? colorClicked : colorH}
              isSelected={activeItem === link.name}
              onClick={() => handleItemSelection(link.name)}
              marginItem={marginItem}
              marginTop="0"
            >
              {link.name}
            </NavItem>
          )
        )}
      </Stack>
    </Box>
  );
}

function NavItem({
  icon,
  children,
  color,
  isSelected,
  marginItem,
  path,
  hasChevron,
  isExpanded,
  ...rest
}) {
  return (
    <LinkRouter to={path} onClick={() => console.log(path)} target={path?.startsWith("http") ? "_blank" : "_self"} style={{ textDecoration: "none" }}>
      <Flex
        align="center"
        justify="space-between"
        py="3"
        marginRight="0"
        paddingLeft="10px"
        marginLeft={isSelected && marginItem === children ? "20px" : "4"}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color={color}
        _hover={{
          bg: "gray.200",
          color: "black",
        }}
        {...rest}
      >
        <Flex align="center">
          {icon && <Icon mr="4" fontSize="16" as={icon} />}
          <Text>{children}</Text>
        </Flex>
        {hasChevron && (
          <Icon 
            as={isExpanded ? FiChevronUp : FiChevronDown} 
            ml="2"
          />
        )}
        {isSelected && (
          <Divider
            orientation="vertical"
            height="20px"
            border="solid 2px #001A72"
          />
        )}
      </Flex>
    </LinkRouter>
  );
}

function MobileNav({ onOpen, user, picProfile,onProfileOpen, colorH, colorP,colorMode,toggleColorMode, session,...rest }) {
  const bgSideBar=useSelector(state=>state.themeReducer.backgroundColor2)
  const grayScales=useSelector(state=>state.themeReducer.grayScales)
  const backgroundColor1=useSelector(state=>state.themeReducer.backgroundColor1)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const navigate = useNavigate();
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg="#F5F5F5"
      justifyContent="space-between"
      {...rest}
    >
      {user && (
        <Box>
          <Text
            fontSize="20px"
            fontWeight="bold"
            fontFamily="Open Sans"
            lineHeight="30px"
            color={colorH}
          >
            Hello, {user.first_name}
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            fontFamily="Open Sans"
            lineHeight="21px"
            letterSpacing="0.25px"
            color={colorP}
          >
            Have a nice day
          </Text>
        </Box>
      )}
        
      <HStack spacing={{ base: "0", md: "6" }}>
        <HStack marginRight="15px">
          {
            colorMode==="light"?<MdOutlineDarkMode fontSize="25px" cursor="pointer" onClick={toggleColorMode}/>:<FiSun fontSize="25px" cursor="pointer" onClick={toggleColorMode}/>
          }
          {
            user.role=="Client"?
            <Box position={"relative"} onClick={()=>navigate("/checkout")}>
              <Flex bg={"white"} color={"black"} position={"absolute"} top={"-2px"} right={"-7px"} width={"15px"} height={"15px"} justify={"space-around"} align={"center"} borderRadius={"50%"}>
                <Text fontSize={"13px"}>{session?Object.keys(session).length:0}</Text>
              </Flex>
              <FiShoppingCart fontSize="25px" cursor="pointer"/>
            </Box>:null
          }
        </HStack>
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
          marginRight="10px"
        />
        <Flex alignItems={"center"}>
          <Divider
            orientation="vertical"
            height="35px"
            border="solid 1px #C2C2C2"
            mr="5"
          />
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
              onClick={toggleMenu}
            >
              <HStack marginRight="20px" spacing="4">
                <Avatar size={"sm"} src={`${user.profile_picture}`} />
                {user && (
                  <VStack
                    display={{ base: "none", md: "flex" }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text
                      fontSize="20px"
                      fontWeight="bold"
                      fontFamily="Open Sans"
                      lineHeight="30px"
                      color={colorH}
                    >
                      {user.first_name}
                    </Text>
                    <Text fontSize="xs" color={colorP}>
                      {user.role}
                    </Text>
                  </VStack>
                )}
                <Box display={{ base: "none", md: "flex" }}>
                  {isMenuOpen ? <FiChevronUp /> : <FiChevronDown />}
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              paddingLeft="5px"
              border="1px solid #F3F3F3"
              boxShadow="0.5px 0.5px 4px 0 rgba(0, 0, 0, 0.25)"
              bg={bgSideBar}
              zIndex={4}
            >
              {user && (
                <>
                  <Box>
                    <HStack marginBottom="8px" padding="4px" paddingLeft="35px">
                      <Avatar size={"sm"} src={`${user.profile_picture}`} />
                      <VStack
                        display={{ base: "none", md: "flex" }}
                        alignItems="flex-start"
                        spacing="1px"
                        ml="2"
                      >
                        <Text
                          fontSize="16px"
                          fontWeight="bold"
                          fontFamily="Open Sans"
                          lineHeight="30px"
                          color={grayScales}
                        >
                          {user.first_name}
                        </Text>
                        <Text fontSize="xs" color={colorP}>
                          {user.role}
                        </Text>
                      </VStack>
                    </HStack>
                  </Box>
                  <Box marginBottom="5px" padding="8px" cursor="pointer" onClick={user.role!=="Client"?onProfileOpen:()=>navigate("/x-capital/terminal/client/profile")} _hover={{bgColor:{backgroundColor1},borderRadius:"5px"}}>
                    <HStack as="a" w="100%">
                      <FiUser color={colorP}/>
                      <Text fontSize="sm" color={colorP}>
                        Profile
                      </Text>
                    </HStack>
                  </Box>
                  <MenuDivider color="#C4C4C4" />
                  <Box marginBottom="5px" padding="4px" onClick={handleLogout} cursor="pointer"  _hover={{bgColor:{backgroundColor1},borderRadius:"5px"}}>
                    <HStack>
                      <FiLogIn color={colorP}/>
                      <Text fontSize="sm" color={colorP}>
                        Logout
                      </Text>
                    </HStack>
                  </Box>
                </>
              )}
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
}