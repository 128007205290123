import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  Select,
  HStack,
  Box,
  Icon,
  Text,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { createCandidate, updateCandidate } from "../../actions/candidat";
import { getAllCareers } from "../../actions/careerActions";
import { load_user } from "../../actions/auth";
import { FaUpload } from "react-icons/fa";

const CustomFileUpload = ({ onClick, text }) => (
  <Box
    as="button"
    onClick={onClick}
    p={4}
    borderWidth={1}
    borderColor="gray.300"
    borderRadius="md"
    display="flex"
    alignItems="center"
    justifyContent="center"
    width="full"
    bg="white"
    _hover={{ bg: "gray.100" }}
  >
    <Icon as={FaUpload} boxSize={6} mr={2} />
    <Text fontSize="sm">{text}</Text>
  </Box>
);

const AddCandidatModal = ({ isOpen, onClose, type, initialData }) => {
  const dispatch = useDispatch();
  const [careers, setCareers] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [skills, setSkills] = useState([]);
  const [cv, setCv] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const [careerId, setCareerId] = useState("");
  const [skillOptions, setSkillOptions] = useState([
    "JavaScript",
    "React",
    "Node.js",
    "Django",
    "Python",
    "Docker",
    "HTML",
    "CSS",
    "TypeScript",
    "Flask",
    "Git Workflow",
    "Kubernetes",
  ].sort());
  const [cvFileName, setCvFileName] = useState("");
  const [coverLetterFileName, setCoverLetterFileName] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const cvInputRef = useRef(null);
  const coverLetterInputRef = useRef(null);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  useEffect(() => {
    dispatch(load_user());
    const fetchCareers = async () => {
      const response = await dispatch(getAllCareers());
      setCareers(response);
    };
    fetchCareers();
  }, [dispatch]);

  useEffect(() => {
    if (type === "update" && initialData) {
      setFirstName(initialData.first_name);
      setLastName(initialData.last_name);
      setEmail(initialData.email);
      setPhone(initialData.phone);
      setSkills(initialData.skills.split(","));
      setCareerId(initialData.career);
    }
  }, [type, initialData]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    // Ensure only numbers are allowed
    const re = /^[0-9]*$/;
    return re.test(phone);
  };

  const handleFileChange = (e, setFile, setFileName) => {
    const file = e.target.files[0];
    setFile(file);
    setFileName(file.name);
  };

  const handleSubmit = async () => {
    const errors = {};
    if (!validateEmail(email)) {
      errors.email = "Invalid email format";
    }
    if (!validatePhone(phone)) {
      errors.phone = "Invalid phone number format. Only numbers are allowed.";
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("skills", skills.join(","));
    formData.append("cv", cv);
    formData.append("cover_letter", coverLetter);
    formData.append("career", careerId);

    try {
      if (type === "update" && initialData) {
        await dispatch(updateCandidate(initialData.id, formData));
      } else {
        await dispatch(createCandidate(formData));
      }
      onClose();
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de l'enregistrement du candidat :", error);
    }
  };

  const handleFileInputClick = (inputRef) => {
    inputRef.current.click();
  };

  const handleSkillSelect = (e) => {
    const selectedSkill = e.target.value;
    if (selectedSkill && !skills.includes(selectedSkill)) {
      setSkills((prevSkills) => [...prevSkills, selectedSkill]);
    }
    setIsSelectOpen(false);
  };

  const removeSkill = (skillToRemove) => {
    setSkills(skills.filter((skill) => skill !== skillToRemove));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="850px" px={10}>
        <ModalHeader>
          {type === "update" ? "Update Candidat" : "Add Candidat"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack spacing={4} mb={6} align="start">
            <FormControl flex="1" isInvalid={formErrors.firstName}>
              <Input
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {formErrors.firstName && (
                <Text color="red.500">{formErrors.firstName}</Text>
              )}
            </FormControl>
            <FormControl flex="1" isInvalid={formErrors.lastName}>
              <Input
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {formErrors.lastName && (
                <Text color="red.500">{formErrors.lastName}</Text>
              )}
            </FormControl>
          </HStack>
          <FormControl mb={6} isInvalid={formErrors.email}>
            <Input
              placeholder="Email"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (e.target.value && !validateEmail(e.target.value)) {
                  setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    email: "Invalid email format",
                  }));
                } else {
                  setFormErrors((prevErrors) => {
                    const { email, ...rest } = prevErrors;
                    return rest;
                  });
                }
              }}
            />
            {formErrors.email && (
              <Text color="red.500">{formErrors.email}</Text>
            )}
          </FormControl>
          <HStack spacing={4} mb={6}>
            <FormControl flex="1" isInvalid={formErrors.phone}>
              <Input
                placeholder="Phone"
                value={phone}
                onChange={(e) => {
                  const value = e.target.value;
                  if (validatePhone(value)) {
                    setPhone(value);
                    setFormErrors((prevErrors) => {
                      const { phone, ...rest } = prevErrors;
                      return rest;
                    });
                  }
                }}
              />
              {formErrors.phone && (
                <Text color="red.500">{formErrors.phone}</Text>
              )}
            </FormControl>
            <FormControl flex="1">
              <Select
                placeholder="Select Career"
                value={careerId}
                onChange={(e) => setCareerId(e.target.value)}
              >
                {careers.map((career) => (
                  <option key={career.id} value={career.id}>
                    {career.job_title}
                  </option>
                ))}
              </Select>
            </FormControl>
          </HStack>
          <FormControl mb={6}>
            <Select
              placeholder="Select Skills"
              value=""
              onChange={handleSkillSelect}
              onClick={() => setIsSelectOpen(true)}
              size="lg"
            >
              {skillOptions.map((skill) => (
                <option key={skill} value={skill}>
                  {skill}
                </option>
              ))}
            </Select>
          </FormControl>
          <Box mb={6}>
            {skills.map((skill) => (
              <Tag
                key={skill}
                size="lg"
                m={1}
                variant="solid"
                colorScheme="teal"
              >
                <TagLabel>{skill}</TagLabel>
                <TagCloseButton onClick={() => removeSkill(skill)} />
              </Tag>
            ))}
          </Box>
          <HStack spacing={4} mb={6}>
            <FormControl flex="1">
              <CustomFileUpload
                text={cvFileName || "Upload CV"}
                onClick={() => handleFileInputClick(cvInputRef)}
              />
              <Input
                type="file"
                ref={cvInputRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e, setCv, setCvFileName)}
              />
            </FormControl>
            <FormControl flex="1">
              <CustomFileUpload
                text={coverLetterFileName || "Upload Cover Letter"}
                onClick={() => handleFileInputClick(coverLetterInputRef)}
              />
              <Input
                type="file"
                ref={coverLetterInputRef}
                style={{ display: "none" }}
                onChange={(e) =>
                  handleFileChange(e, setCoverLetter, setCoverLetterFileName)
                }
              />
            </FormControl>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#001A72"
            mb={6}
            color="#FFFFFF"
            _hover={{ bg: "#001A72" }}
            onClick={handleSubmit}
          >
            {type === "update" ? "Update Candidat" : "Add Candidat"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCandidatModal;
