import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Input,
  Textarea,
  Heading,
  Select,
  useToast,
  Box,
  Checkbox,
  IconButton,
} from "@chakra-ui/react";
import postArticle from "../../services/api/artilcesAPI/postArticle";
import fetchArticlesCategories from "../../services/api/artilcesAPI/fetchArticleCategory";
import UploadImage from "../UploadImage";
import { useNavigate } from "react-router-dom";
import { FiPlus, FiTrash } from "react-icons/fi";
import { createSubArticle } from "../../actions/subArticle";
import { useDispatch, useSelector } from "react-redux";

const FormInput = ({ name, placeholder, value, onChange, error, width, bg, color }) => (
  <Flex direction={"column"} gap={"4px"} width={width}>
    <Input
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      bg={bg}
      color={color}
    />
  </Flex>
);

export default function PublishArticle() {
  const bg = useSelector(state => state.themeReducer.backgroundColor1);
  const bg2 = useSelector(state => state.themeReducer.backgroundColor2);
  const colorH = useSelector(state => state.themeReducer.color1);
  const colorP = useSelector(state => state.themeReducer.color2);
  const grayScales = useSelector(state => state.themeReducer.grayScales);

  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const [formData, setFormData] = useState({
    title: "",
    sub_title: "",
    body: "",
    category_id: "",
    image_cover: null,
  });

  const [subArticles, setSubArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [showSubArticles, setShowSubArticles] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchArticlesCategories();
        setCategories(result);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setError("Error fetching categories. Please try again later.");
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileSelect = (name, file) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: file,
    }));
  };

  const handleSubArticleChange = (index, name, value) => {
    const updatedSubArticles = [...subArticles];
    updatedSubArticles[index] = {
      ...updatedSubArticles[index],
      [name]: value,
    };
    setSubArticles(updatedSubArticles);
  };

  const handleSubArticleFileSelect = (index, file) => {
    const updatedSubArticles = [...subArticles];
    updatedSubArticles[index] = {
      ...updatedSubArticles[index],
      sub_article_image: file,
    };
    setSubArticles(updatedSubArticles);
  };

  const handleAddSubArticle = () => {
    setSubArticles([...subArticles, { sub_article_image: null, sub_article_body: "" }]);
  };

  const handleRemoveSubArticle = (index) => {
    const updatedSubArticles = [...subArticles];
    updatedSubArticles.splice(index, 1);
    setSubArticles(updatedSubArticles);

    if (updatedSubArticles.length === 0) {
      setShowSubArticles(false);
    }
  };

  const handleCheckboxChange = (e) => {
    setShowSubArticles(e.target.checked);
    if (e.target.checked && subArticles.length === 0) {
      handleAddSubArticle();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const data = new FormData();
      data.append("title", formData.title);
      data.append("sub_title", formData.sub_title);
      data.append("body", formData.body);
      data.append("category_id", formData.category_id);

      if (formData.image_cover) {
        data.append("image_cover", formData.image_cover);
      }

      const articleResponse = await postArticle(data);
      const articleId = articleResponse.id;

      if (showSubArticles) {
        for (const subArticle of subArticles) {
          const subArticleData = new FormData();
          subArticleData.append("body", subArticle.sub_article_body);
          subArticleData.append("image", subArticle.sub_article_image);
          subArticleData.append("article", articleId);
          subArticleData.append("is_active", true);

          await dispatch(createSubArticle(subArticleData));
        }
      }

      toast({
        title: "Submission successful",
        status: "success",
        position: "top-right",
        isClosable: true,
      });

      setFormData({
        title: "",
        body: "",
        sub_title: "",
        category_id: "",
        image_cover: null,
      });
      setSubArticles([]);
      setError(null);
      navigate(`/x-capital/terminal/${user.role}/article-panel`);

    } catch (error) {
      console.error("Error submitting article:", error);
      setError("Failed to submit the article. Please try again.");
    }
  };

  return (
    <Box ml={{ base: 0, md: 60 }} p="4" minH="100vh" bg={bg}>
      <Box
        bg={bg2}
        borderRadius="12px"
        p="40px"
        boxShadow="md"
        width="100%"
        margin="0 auto"
      >
        <Heading fontSize={{base:"24px",md:"28px",lg:"32px"}} marginBottom={"32px"} color={colorH}>
          Publish Articles:
        </Heading>

        <Flex width="100%" mb="24px">
          <UploadImage
            name={"image_cover"}
            label="Cover Image"
            inputFileId="coverFileInput"
            onFileSelect={(file) => handleFileSelect("image_cover", file)}
            width={"100%"}
            backgroundColor={grayScales}
            height={{base:"100px",md:"150px",lg:"200px"}}
          />
        </Flex>

        <Flex width="100%" gap="24px" mb="24px" direction={{base:"column",lg:"row"}}>
          <Select
            name="category_id"
            placeholder="Select category"
            onChange={handleChange}
            value={formData.category_id}
            bg={bg}
            color={colorP}
            _focus={{ borderColor: "transparent", boxShadow: "none" }}
            _hover={{ borderColor: "transparent" }}
            width={{base:"100%",lg:"48%"}}
          >
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Select>
          <FormInput
            name="title"
            placeholder="Enter the title"
            value={formData.title}
            onChange={handleChange}
            error={error}
            width={{base:"100%",lg:"48%"}}
            bg={bg2}
            color={colorP}
          />
        </Flex>

        <Flex width="100%" mb="24px">
          <FormInput
            name="sub_title"
            placeholder="Enter the sub title"
            value={formData.sub_title}
            onChange={handleChange}
            error={error}
            width="100%"
            bg={bg2}
            color={colorP}
          />
        </Flex>
        <Flex width="100%" mb="24px">
          <Textarea
            placeholder="Content..."
            name="body"
            value={formData.body}
            onChange={handleChange}
            height={"150px"}
            width="100%"
            bg={bg2}
            color={colorP}
          />
        </Flex>

        <Checkbox
          mb="24px"
          isChecked={showSubArticles}
          onChange={handleCheckboxChange}
          color={colorP}
        >
          Add Sub-Articles
        </Checkbox>

        {showSubArticles && subArticles.map((subArticle, index) => (
          <Box key={index} mb="24px">
            <Flex width="100%" justifyContent="center" mb="12px">
              <UploadImage
                name={`sub_article_image_${index}`}
                label="Sub-Article Image"
                inputFileId={`subArticleFileInput_${index}`}
                onFileSelect={(file) => handleSubArticleFileSelect(index, file)}
                width="60%"
                height="200px"
                backgroundColor={grayScales}
              />
            </Flex>
            <Flex width="100%" mb="12px">
              <Textarea
                placeholder="Sub-Article Content..."
                name={`sub_article_body_${index}`}
                value={subArticle.sub_article_body}
                onChange={(e) => handleSubArticleChange(index, "sub_article_body", e.target.value)}
                height={"150px"}
                width="100%"
                bg={bg2}
                color={colorP}
              />
            </Flex>
            <Flex width="100%" justifyContent="flex-end">
              <IconButton
                icon={<FiTrash />}
                colorScheme="red"
                onClick={() => handleRemoveSubArticle(index)}
                aria-label="Remove Sub-Article"
              />
            </Flex>
          </Box>
        ))}

        {showSubArticles && (
          <Button
            mb="24px"
            onClick={handleAddSubArticle}
            colorScheme="blue"
            variant="outline"
            w={{base:"100%",lg:"fit-content"}}
          >
            Add Another Sub-Article
          </Button>
        )}

        <Flex width="100%" justifyContent="flex-end">
          <Button
            type="submit"
            rightIcon={<FiPlus />}
            color="#FFFFFF"
            backgroundColor="#001A72"
            _hover={{ backgroundColor: "#001A72" }}
            _focus={{ backgroundColor: "#001A72" }}
            w={{base:"100%",lg:"fit-content"}}
            onClick={handleSubmit}
          >
            Publish Article
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}
