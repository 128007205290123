import { GET_ARTICLES_SUCCESS, GET_ARTICLES_FAIL } from './types';
import axios from 'axios';
import { getAuthConfig } from './config';

export const getArticles = () => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/articles`, config);
    dispatch({
      type: GET_ARTICLES_SUCCESS,
      payload: res.data
    });
    return res.data; 
  } catch (err) {
    dispatch({
      type: GET_ARTICLES_FAIL,
      payload: err.message 
    });
    throw err; 
  }
};