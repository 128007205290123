import axios from 'axios';
import {
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORY_BY_ID_SUCCESS,
  GET_CATEGORY_BY_ID_FAIL,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
} from './types';
import { getAuthConfig } from './config';

export const getAllCategories = () => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/articles-categories`, config);
    dispatch({
      type: GET_CATEGORIES_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_CATEGORIES_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const getCategoryById = (id) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/category/${id}/`, config);
    dispatch({
      type: GET_CATEGORY_BY_ID_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_CATEGORY_BY_ID_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const createCategory = (categoryData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/category/save/`, categoryData, config);
    dispatch({
      type: CREATE_CATEGORY_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_CATEGORY_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const updateCategory = (id, categoryData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/category/${id}/update/`, categoryData, config);
    dispatch({
      type: UPDATE_CATEGORY_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_CATEGORY_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const deleteCategory = (id) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    await axios.delete(`${process.env.REACT_APP_API_URL}/category/${id}/delete/`, config);
    dispatch({
      type: DELETE_CATEGORY_SUCCESS,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: DELETE_CATEGORY_FAIL,
      payload: err.message,
    });
    throw err;
  }
};
