import React from 'react'
import {
         Flex,
         Heading,
         Card,
         CardBody,
         CardHeader,
         CardFooter,
         Button,
         Text,
         Grid
 } from '@chakra-ui/react'    
 import { motion } from 'framer-motion' 
 import { Link } from 'react-router-dom';
 import { useSelector } from 'react-redux';

 const MotionFlex = motion(Flex);
 
function ProductsSection() {
  const bg=useSelector(state=>state.themeReducer.backgroundColor1)

  return (
  
        <>
      <Flex align={"center"} justify={"center"} marginTop={"40px"}>
        <Heading fontWeight={"800"}>Our solutions</Heading>
      </Flex>
      <Flex
        align={"center"}
        justify={"center"}
        flexWrap={"wrap"}
        gap={{ base: "20px", md: "32px" }}
        padding={{ base: "24px", md: "80px" }}
      >
        <Grid templateColumns={{base:'repeat(1, 1fr)', md:'repeat(2, 1fr)', sm:'repeat(1, 1fr)'}} gap={6}>
        <MotionFlex
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
            >
        <Card
          align={"center"}
          width={{ base: "100%", md: "460px" }}
          height={{base:"auto", md:'400px', sm:'auto'}}
          boxShadow={"0 1px 1px 0 black"}
          bgColor={bg}
        >
          <CardHeader>
            <Heading size="md"> X CAPITAL TERMINAL</Heading>
          </CardHeader>
          <CardBody>
            <Text>
              X CAPITAL TERMINAL is your gateway to comprehensive financial market data and analytics.
              With real-time and historical data on stocks, bonds, and more, our terminal provides
              traders, investors, and businesses with the insights they need to make informed decisions.
              From live market updates to advanced charting tools, X CAPITAL TERMINAL empowers you to
              stay ahead of the curve and capitalize on market opportunities.
            </Text>
          </CardBody>
          <CardFooter>
            <Link to="/wait-list">
            <Button bg={"black"} color={"white"}>
              Book a demo
            </Button></Link>
          </CardFooter>
        </Card>
        </MotionFlex>

        <MotionFlex
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
            >

        <Card
          align={"center"}
          width={{ base: "100%", md: "460px" }}
          height={{base:"auto", md:'400px', sm:'auto'}}
          boxShadow={"0 1px 1px 0 black"}
          bgColor={bg}
        >
          <CardHeader>
            <Heading size="md"> X CAPITAL INTELLIGENCE</Heading>
          </CardHeader>
          <CardBody>
            <Text>
              X CAPITAL INTELLIGENCE is the engine driving data-driven decision-making. Leveraging
              cutting-edge AI and machine learning algorithms, our intelligence platform analyzes vast
              datasets to uncover hidden trends, identify market anomalies, and generate actionable
              insights. Whether you're looking to optimize your investment strategy or gain a competitive
              edge, X CAPITAL INTELLIGENCE equips you with the tools you need to succeed in today's
              dynamic financial landscape.
            </Text>
          </CardBody>
          <CardFooter>
            <Link to="/wait-list">
            <Button bg={"black"} color={"white"}>
              Book a demo
            </Button>
            </Link>
          </CardFooter>
        </Card>

        </MotionFlex>

        <MotionFlex
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
            >


        <Card
          align={"center"}
          width={{ base: "100%", md: "460px" }}
          height={{base:"auto", md:'400px', sm:'auto'}}
          boxShadow={"0 1px 1px 0 black"}
          bgColor={bg}
        >
          <CardHeader>
            <Heading size="md">X CAPITAL DATA</Heading>
          </CardHeader>
          <CardBody>
            <Text>
              Access comprehensive financial market data at your fingertips with X CAPITAL DATA. From
              stock prices and trading volumes to economic indicators and corporate financials, our data
              platform offers a wealth of information to fuel your analysis and decision-making. With
              customizable datasets and powerful analytics tools, X CAPITAL DATA empowers you to unlock
              the full potential of financial data and drive your business forward.
            </Text>
          </CardBody>
          <CardFooter>
          <Link to="/solutions/x-capital-data">
            <Button bg={"black"} color={"white"}>
              Start Now
            </Button>
            </Link>
          </CardFooter>
        </Card>

        </MotionFlex>

        <MotionFlex
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
            >


        <Card
          align={"center"}
          width={{ base: "100%", md: "460px" }}
          height={{base:"auto", md:'400px', sm:'auto'}}
          boxShadow={"0 1px 1px 0 black"}
          bgColor={bg}
        >
          <CardHeader>
            <Heading size="md">X CAPITAL ARTICLES</Heading>
          </CardHeader>
          <CardBody>
            <Text>
              Explore the latest trends, analysis, and insights from the experts at X CAPITAL. Our articles
              cover a wide range of topics, including market trends, investment strategies, and industry
              developments. Whether you're a seasoned professional or just starting out, our articles provide
              valuable information to help you navigate the complexities of the financial markets and make
              informed decisions.
            </Text>
          </CardBody>
          <CardFooter>
          <Link to="/solutions/x-capital-articles">
            <Button bg={"black"} color={"white"}>
              Start Now
            </Button>
            </Link>
          </CardFooter>
        </Card>

        </MotionFlex>
        </Grid>
      </Flex>
    </>
  
  )
}

export default ProductsSection
