import {
    GET_PREORDERS_SUCCESS,
    GET_PREORDERS_FAIL,
    GET_PREORDER_BY_ID_SUCCESS,
    GET_PREORDER_BY_ID_FAIL,
    CREATE_PREORDER_SUCCESS,
    CREATE_PREORDER_FAIL,
    UPDATE_PREORDER_SUCCESS,
    UPDATE_PREORDER_FAIL,
    DELETE_PREORDER_SUCCESS,
    DELETE_PREORDER_FAIL,
    DOWNLOAD_PREORDERS_FAIL,
    DOWNLOAD_PREORDERS_SUCCESS
  } from '../actions/types';
  
  const initialState = {
    preOrders: [],
    preOrder: null,
    loading: true,
    error: null
  };
  
  const preorderReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_PREORDERS_SUCCESS:
        return {
          ...state,
          preOrders: action.payload,
          loading: false,
          error: null
        };
      case GET_PREORDERS_FAIL:
        return {
          ...state,
          preOrders: [],
          loading: false,
          error: action.payload
        };
      case GET_PREORDER_BY_ID_SUCCESS:
        return {
          ...state,
          preOrder: action.payload,
          loading: false,
          error: null
        };
      case GET_PREORDER_BY_ID_FAIL:
        return {
          ...state,
          preOrder: null,
          loading: false,
          error: action.payload
        };
      case CREATE_PREORDER_SUCCESS:
        return {
          ...state,
          preOrders: [...state.preOrders, action.payload],
          loading: false,
          error: null
        };
      case CREATE_PREORDER_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        };
      case UPDATE_PREORDER_SUCCESS:
        return {
          ...state,
          preOrders: state.preOrders.map(preOrder =>
            preOrder.id === action.payload.id ? action.payload : preOrder
          ),
          loading: false,
          error: null
        };
      case UPDATE_PREORDER_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        };
      case DELETE_PREORDER_SUCCESS:
        return {
          ...state,
          preOrders: state.preOrders.filter(preOrder => preOrder.id !== action.payload),
          loading: false,
          error: null
        };
      case DELETE_PREORDER_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        };
      case DOWNLOAD_PREORDERS_SUCCESS:
        return {
          ...state,
          downloadError: null
        };
      case DOWNLOAD_PREORDERS_FAIL:
        return {
          ...state,
          downloadError: action.payload
        };
      default:
        return state;
    }
  };

  export default preorderReducer;