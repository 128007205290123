import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Select,
  Stack,
  FormControl,
  SimpleGrid,
  Divider,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { addUser, getUsers, updateUser } from "../actions/user";

const AddUserModal = ({ isOpen, onClose, type, initialData }) => {
  const dispatch = useDispatch();
  const userLog = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    password: "",
    re_password: "",
    role: "",
    showPassword: false,
    phone_number: "",
  });

  useEffect(() => {
    if (initialData && type === "update") {
      setFormData({
        first_name: initialData.first_name || "",
        last_name: initialData.last_name || "",
        email: initialData.email || "",
        username: initialData.username || "",
        password: "",
        re_password: "",
        role: initialData.role || "",
        phone_number: initialData.phone_number || "",
        showPassword: false,
      });
    } else {
      resetForm();
    }
  }, [initialData, type, isOpen]);

  const resetForm = () => {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      password: "",
      re_password: "",
      role: "",
      showPassword: false,
      phone_number: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number") {
      const regex = /^[0-9\b]+$/;
      if (value === "" || regex.test(value)) {
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleTogglePassword = () => {
    setFormData((prevData) => ({
      ...prevData,
      showPassword: !prevData.showPassword,
    }));
  };

  const handleSubmit = () => {
    const trimmedPassword = formData.password.trim();
    const firstName = formData.first_name.trim();

    if (!firstName) {
      alert("First name cannot be empty!");
      return;
    }

    const userData = {
      ...formData,
      password: trimmedPassword,
      firstName,
    };

    const action = type === "update" 
      ? updateUser(initialData.id, userData)
      : addUser({ ...userData, created_at: new Date().toISOString() });

    dispatch(action)
      .then(() => {
        onClose();
        dispatch(getUsers());
        resetForm();
      })
      .catch((error) => {
        console.error(`Error ${type === "update" ? "updating" : "adding"} user:`, error);
      });
  };

  const getRoleOptions = () => {
    if (userLog.role === "Admin") {
      return (
        <>
          <option value="Admin">Admin</option>
          <option value="Manager">Manager</option>
          <option value="Sales">Sales Department</option>
          <option value="Client">Client</option>
          <option value="Demo">Demo Client</option>
        </>
      );
    } else if (userLog.role === "Manager") {
      return (
        <>
          <option value="Manager">Manager</option>
          <option value="Sales">Sales Department</option>
          <option value="Client">Client</option>
          <option value="Demo">Demo Client</option>
        </>
      );
    } else {
      return (
        <>
          <option value="Client">Client</option>
          <option value="Demo">Demo Client</option>
        </>
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxWidth="800px" width="800px">
        <ModalHeader>
          {type === "update" ? "Update User" : "Add User"}
        </ModalHeader>
        <ModalCloseButton />
        <Stack spacing={4} mx="10px" mb="20px">
          <Divider border="solid 1px #EFF4FA" />
        </Stack>

        <ModalBody>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={4}>
            <FormControl isRequired>
              <Input
                placeholder="First Name *"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <Input
                placeholder="Last Name *"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
              />
            </FormControl>
            {type === "update" ? (
              <FormControl isRequired>
                <Input
                  placeholder="Username *"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </FormControl>
            ) : null}

            <FormControl
              isRequired
              gridColumn={type === "update" ? null : { base: null, lg: "span 2" }}
            >
              <Input
                placeholder="Email *"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <Input
                placeholder="Phone Number *"
                name="phone_number"
                value={formData.phone_number || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <Select
                placeholder="Select Role Type *"
                name="role"
                value={formData.role}
                onChange={handleChange}
              >
                {getRoleOptions()}
              </Select>
            </FormControl>

            <FormControl isRequired>
              <InputGroup>
                <Input
                  placeholder="Password *"
                  type={formData.showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <InputRightElement width="3rem">
                  <IconButton
                    h="1.75rem"
                    size="sm"
                    onClick={handleTogglePassword}
                    icon={formData.showPassword ? <HiEye /> : <HiEyeOff />}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl isRequired>
              <Input
                placeholder="Confirm Password *"
                type={formData.showPassword ? "text" : "password"}
                name="re_password"
                value={formData.re_password}
                onChange={handleChange}
              />
            </FormControl>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter mr={{ base: 0, lg: 7 }}>
          <Button
            bg="#001A72"
            color="#FFFFFF"
            _hover={{ bg: "#001A72" }}
            onClick={handleSubmit}
            w={{ base: "100%", lg: "fit-content" }}
            fontSize={{ base: "15px", md: "16px", lg: "18px" }}
          >
            {type === "update" ? "Update User" : "Add User"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddUserModal;