import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Flex,
  Heading,
  Input,
  Divider,
  Box,
  FormControl,
  FormLabel,
  Button,
  Select,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  WrapItem,
  HStack,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import postApplication from "../../services/api/careersAPI/postAplication";

const skillsOptions = [
  "JavaScript",
  "Python",
  "C++",
  "Java",
  "React",
  "Node.js",
  "CSS",
  "HTML",
];

export default function JobApply() {
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [cv, setResume] = useState(null);
  const [cover_letter, setCoverLetter] = useState(null);
  const [resumeFileName, setResumeFileName] = useState("Upload Resume");
  const [coverLetterFileName, setCoverLetterFileName] = useState("Upload Cover Letter");
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const { jobId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    return re.test(String(phone));
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (newEmail && !validateEmail(newEmail)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const handlePhoneChange = (e) => {
    const newPhone = e.target.value.replace(/[^0-9+()-. ]/g, ""); 
    setPhoneNumber(newPhone);
    if (newPhone && !validatePhone(newPhone)) {
      setPhoneError('Please enter a valid phone number');
    } else {
      setPhoneError('');
    }
  };

  const handleAddSkill = (event) => {
    const value = event.target.value;
    if (value && !selectedSkills.includes(value)) {
      setSelectedSkills([...selectedSkills, value]);
    }
  };

  const handleRemoveSkill = (skill) => {
    setSelectedSkills(selectedSkills.filter((s) => s !== skill));
  };

  const handleFileChange = (event, setFile, setFileName) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setFile(file);
      setFileName(file.name);
    } else {
      alert("Please upload a PDF file.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (emailError || phoneError) {
      toast({
        title: "Invalid input",
        description: "Please correct the errors in the form.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const formData = new FormData();
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('skills', selectedSkills.join(', '));
    formData.append('cv', cv);
    formData.append('cover_letter', cover_letter);
    formData.append('career', jobId);

    const response = await postApplication(formData);
    if (response) {
      toast({
        title: "Application submitted successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setFirstName('');
      setLastName('');
      setEmail('');
      setPhoneNumber('');
      setSelectedSkills([]);
      setResume(null);
      setCoverLetter(null);
      setResumeFileName('Upload Resume');
      setCoverLetterFileName('Upload Cover Letter');
      navigate('/careers');
    } else {
      toast({
        title: "Failed to submit application.",
        description: "There was an error submitting your application. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Divider my={4} />
      <Flex direction="column" align="center" p={4}>
        <Heading mb={4}>Apply For This Role</Heading>
        <Box as="form" width="100%" maxW="500px" onSubmit={handleSubmit}>
          <FormControl mb={4}>
            <FormLabel>First Name</FormLabel>
            <Input
              type="text"
              placeholder="Enter your first name"
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Last Name</FormLabel>
            <Input
              type="text"
              placeholder="Enter your last name"
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4} isInvalid={emailError}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
            />
            <FormErrorMessage>{emailError}</FormErrorMessage>
          </FormControl>
          <FormControl mb={4} isInvalid={phoneError}>
            <FormLabel>Phone Number</FormLabel>
            <Input
              type="tel"
              placeholder="Enter your phone number"
              value={phone}
              onChange={handlePhoneChange}
            />
            <FormErrorMessage>{phoneError}</FormErrorMessage>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Skills</FormLabel>
            <Select placeholder="Select skill" onChange={handleAddSkill}>
              {skillsOptions.map((skill) => (
                <option key={skill} value={skill}>
                  {skill}
                </option>
              ))}
            </Select>
            <Wrap mt={2}>
              {selectedSkills.map((skill) => (
                <WrapItem key={skill}>
                  <Tag
                    size="md"
                    borderRadius="full"
                    variant="solid"
                    bg="#2D2D2D"
                    color="#FFFFFF"
                  >
                    <TagLabel>{skill}</TagLabel>
                    <TagCloseButton onClick={() => handleRemoveSkill(skill)} />
                  </Tag>
                </WrapItem>
              ))}
            </Wrap>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Resume</FormLabel>
            <Box position="relative">
              <Input
                type="file"
                accept="application/pdf"
                onChange={(e) => handleFileChange(e, setResume, setResumeFileName)}
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                width="100%"
                height="100%"
                zIndex="1"
              />
              <HStack>
                <Button bg="#2D2D2D" color="#FFFFFF" width="full" as="span" position="relative" zIndex="0">
                  {resumeFileName}
                </Button>
                {cv && <CheckIcon color="green.500" />}
              </HStack>
            </Box>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Cover Letter</FormLabel>
            <Box position="relative">
              <Input
                type="file"
                accept="application/pdf"
                onChange={(e) => handleFileChange(e, setCoverLetter, setCoverLetterFileName)}
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                width="100%"
                height="100%"
                zIndex="1"
              />
              <HStack>
                <Button bg="#2D2D2D" color="#FFFFFF" width="full" as="span" position="relative" zIndex="0">
                  {coverLetterFileName}
                </Button>
                {cover_letter && <CheckIcon color="green.500" />}
              </HStack>
            </Box>
          </FormControl>
          <Button bg="#2D2D2D" color="#FFFFFF" type="submit">
            Submit Application
          </Button>
        </Box>
      </Flex>
    </>
  );
}
