import React, { useContext, useState,useEffect } from 'react';
import { Box, VStack, HStack, Text, Input, Button, Select,SimpleGrid, IconButton, Spinner,Flex, Center, Divider,Alert,AlertIcon,AlertTitle,AlertDescription } from '@chakra-ui/react';
import { CartContext } from '../Context/CartContext';
import { CloseIcon } from '@chakra-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { delCart, getCart, updateCart } from '../actions/cart';
import passPayment from "../services/api/checkoutAPI"
import { createSubscription } from '../actions/subscription';

function ReceiptSummary({selectedCurrencies,session,handleDuration,removeFromCart,formattedDate,formattedTime,finalPricings,finalPricingsTva,setSelectedCurrency}) {
  const grayScales=useSelector(state=>state.themeReducer.grayScales)
  const blueAdmin=useSelector(state=>state.themeReducer.blueAdmin)
  const bg2=useSelector(state=>state.themeReducer.backgroundColor2)

  
  return (
    <Flex
    bg={bg2}
    borderRadius="md"
    boxShadow="md"
    w={{base:"100%",md:"90%",lg:"75%"}}
    direction="column"
    paddingTop={"15px"}
  >
    <Text fontSize="xl" fontWeight="bold" textAlign="center">Receipt Summary</Text>
      <Flex
        width="100%"
        direction="column"
        padding={"20px"}
        mb={{ base: "2em", sm: "2em", md: "2em", lg: "0", xl: "0" }}
        borderBottom="0.5px solid black" 
      >
        {session && Object.keys(session).length > 0 && (
          <SimpleGrid columns={4} spacing={2} w="100%">
            <Text textAlign={"center"} fontWeight={800} fontSize={{base:"15px",md:"17px",lg:"18px"}}>Product</Text>
            <Text textAlign={"center"}fontWeight={800} fontSize={{base:"13px",md:"15px",lg:"16px"}}>Price</Text>
            <Text textAlign={"center"}fontWeight={800} fontSize={{base:"13px",md:"15px",lg:"16px"}}>Duration</Text>
            <Text></Text>
          </SimpleGrid>)
        }
        {session && Object.keys(session).length > 0 ? (
          Object.entries(session).map(([key,value]) => {
            return(
            <SimpleGrid columns={4} spacing={2} w="100%" key={key} alignItems="center" p="1em 0">
              <Text textAlign={"center"} fontWeight={600} fontSize={{base:"15px",md:"17px",lg:"18px"}}>{value.title}</Text>
              <Text textAlign={"center"}  fontSize={{base:"13px",md:"15px",lg:"16px"}}>{value.period==="monthly"?value.monthlyPrice:value.yearlyPrice}</Text>
              <Select onChange={(e)=>handleDuration(e,key)}>
                {
                  value.period==="monthly"?
                  <>
                    <option value='monthly' selected>Monthly</option>
                    <option value='yearly'>Yearly</option>
                  </>
                  :
                  <>
                    <option value='monthly'>Monthly</option>
                    <option value='yearly' selected>Yearly</option>
                  </>
                }
              </Select>
              <IconButton
                aria-label="Remove item"
                icon={<CloseIcon />}
                onClick={() => removeFromCart(key)}
                size="sm"
                width="75%"
                ml="12.5%"
                bgColor={"red"}
                color={"white"}
                fontWeight={"600"}
              />
            </SimpleGrid>
          )})
        ) : (
          <Box textAlign="center" mt="15px" mb="15px" w={"100%"}>
            <Text 
              fontWeight={600} 
              fontSize={{base:"15px",md:'17px',lg:"19px"}}
              bgColor={grayScales} 
              color={grayScales==="black"?"white":"black"}
              width={"50%"}
              ml={"25%"}
              borderRadius={"15px"}
              padding={"8px"}
            >
              Cart is Empty
            </Text>
            <Link to="/pricing"><Text textDecoration={"underline"} mt="10px" color={blueAdmin} fontSize={{base:"13px",md:'14px',lg:"15px"}}>Browse our solutions</Text></Link>
          </Box>
        )}
      </Flex>
      <Flex padding={"20px"} ml={"10%"} direction={"column"} w="80%">
        <SimpleGrid columns={2} spacing={2}>
          <Text fontWeight={"500"} fontSize={{base:"13px",md:'14px',lg:"15px"}}>Date:</Text>
          <Text fontSize={{base:"13px",md:'14px',lg:"15px"}}>{formattedDate}</Text>
          <Text fontWeight={"500"} fontSize={{base:"13px",md:'14px',lg:"15px"}}>Time:</Text>
          <Text fontSize={{base:"13px",md:'14px',lg:"15px"}}>{formattedTime}</Text>
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={2} mt={"15px"}>
            <Text paddingTop={"5px"} fontWeight={"500"} fontSize={{base:"13px",md:'14px',lg:"15px"}}>Total:</Text>
            <Text 
              fontSize={{base:"13px",md:'14px',lg:"15px"}} 
              color={grayScales}
              w={"fit-content"}
              padding={"5px"}
              paddingInline={"9px"}
              borderRadius={"7px"}
            >
              {typeof finalPricings === 'number'
                ?`${finalPricings.toFixed(2)} ${selectedCurrencies}`
              : 'N/A'}
            </Text>
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={2}>
            <Text paddingTop={"5px"} fontWeight={"500"} fontSize={{base:"13px",md:'14px',lg:"15px"}}>Tax :</Text>
            <Text 
              fontSize={{base:"13px",md:'14px',lg:"15px"}} 
              color={grayScales}
              w={"fit-content"}
              padding={"5px"}
              paddingInline={"9px"}
              borderRadius={"7px"}
            >
              + 20 %
            </Text>
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} mt={"15px"} paddingTop={"35px"}>
            <Text paddingTop={"5px"} fontWeight={"500"} fontSize={{base:"15px",md:'16px',lg:"17px"}} >Total (Tax Included):</Text>
            <Text 
              fontSize={{base:"15px",md:'16px',lg:"17px"}} 
              bgColor={grayScales} 
              color={grayScales==="black"?"white":"black"}
              w={"fit-content"}
              padding={"5px"}
              paddingInline={"9px"}
              borderRadius={"7px"}
            >
              {typeof finalPricingsTva === 'number'
                ?`${finalPricingsTva.toFixed(2)} ${selectedCurrencies}/year`
              : 'N/A'}
            </Text>
            <Select width={"100px"} border={"solid 2px black"} fontSize={"14px"} onChange={(e)=>setSelectedCurrency(e.target.value)}>
              <option value='MAD' selected>MAD</option>
              <option value='EUR'>EUR</option>
              <option value='USD'>USD</option>
            </Select>
        </SimpleGrid>
      </Flex>
  </Flex>
  );
}

function ShoppingCart({session,duration,formattedDate,formattedTime,finalPricingsTva,selectedCurrencies,userId}) {
  const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
  const grayScales=useSelector(state=>state.themeReducer.grayScales)
  const [checkoutValue,setCheckOutValues]=useState({card_holder_name:"",credit_card:"",expire_date:"",cvv:""})
  const [msg,setMsg]=useState({loading:false})
  const dispatch=useDispatch()
  const handleCheckout=()=>{
   if (session && Object.keys(session).length > 0 && finalPricingsTva!==0)
      passPayment(checkoutValue,setMsg,finalPricingsTva,selectedCurrencies,userId,dispatch,createSubscription,duration)
  }
  useEffect(()=>{
    if(msg.loading===false && msg.msg && msg.success===true){
      Object.keys(session).forEach((key)=>{
        dispatch(delCart(key))
      })
      setCheckOutValues({card_holder_name:"",credit_card:"",expire_date:"",cvv:""})
    }
  },[msg])
  return (
    <Box w={{base:"100%",md:"50%"}} position={"relative"}>
      {
        msg.msg?
        <Alert status={msg.success?"success":"error"} >
          <AlertIcon />
          <AlertTitle>{msg.msg}</AlertTitle>
        </Alert>:""
      }
      <VStack
        bg={bg2}
        p={4}
        borderRadius="md"
        boxShadow="md"
        spacing={4}
      >
        <Text fontSize="xl" fontWeight="bold">{!msg.msg?"Shopping Card":""}</Text>
        <Input type="text" placeholder="Full name on card" name="card_holder_name" value={checkoutValue.card_holder_name} onChange={(e)=>{setCheckOutValues((prev)=>{return {...prev,[e.target.name]:e.target.value}})}}/>
        <Input type="text" placeholder="1234 1234 1234 1234" name='credit_card' value={checkoutValue.credit_card} onChange={(e)=>{setCheckOutValues((prev)=>{return {...prev,[e.target.name]:e.target.value}})}}/>
        <HStack spacing={4} w="100%">
          <Input type="text" placeholder="MM/YY" name='expire_date' value={checkoutValue.expire_date} onChange={(e)=>{setCheckOutValues((prev)=>{return {...prev,[e.target.name]:e.target.value}})}}/>
          <Input type="text" placeholder="CVV" name='cvv' value={checkoutValue.cvv} onChange={(e)=>{setCheckOutValues((prev)=>{return {...prev,[e.target.name]:e.target.value}})}}/>
        </HStack>
        {
          msg.loading===false?
            <Button bgColor={grayScales} color={grayScales==="black"?"white":"black"} size="lg" w="100%" onClick={handleCheckout} >Check Out</Button>
          :
            <Spinner size='lg' />
        }
      </VStack>
    </Box>
  
  );
}

export default function CheckoutPage() {
  
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const bg1=useSelector(state=>state.themeReducer.backgroundColor1)
  const currentDateTime = new Date();
  const formattedDate = currentDateTime.toLocaleDateString('en-US');
  const formattedTime = currentDateTime.toLocaleTimeString('en-US');
  const session=useSelector(state=>state.cartReducer.cartElements)
  const [selectedCurrencies,setSelectedCurrency]=useState("MAD")
  const [totalAmount,setTotalAmount]=useState(0)
  const [totalAmountTva,setTotalAmountTva]=useState(0)
  const [finalPricings,setFinalPricings]=useState(0)
  const [finalPricingsTva,setFinalPricingsTva]=useState(0)
  const [currencies,setCurrencies]=useState(null)
  const isAuthenticated=useSelector(state=>state.auth.isAuthenticated)
  const role=useSelector(state=>state.auth.user?.role)
  const userId=useSelector(state=>state.auth.user?.id)
  const [duration,setDuration]=useState()
  useEffect(()=>{
    if(session){
      let buff=0
      let obj={}
      for (const [key, value] of Object.entries(session)) {
        console.log(key,value);
        if(value.period=="monthly")
          buff+=parseFloat(value.monthlyPrice)
        else
          buff+=parseFloat(value.yearlyPrice)
        obj={...obj,[key]:value.period}
      }
      setDuration(obj)
      setTotalAmount(buff)
    }
  },[session])
  useEffect(()=>{
    setTotalAmountTva(totalAmount + (totalAmount * 0.2))
  },[totalAmount])
  const removeFromCart=(id)=>{
    dispatch(delCart(id))
  }
  const handleDuration=(e,key)=>{
    setDuration((prev)=>{return {...prev,[key]:e.target.value==="monthly"?"1 month":"1 year"}})
    dispatch(updateCart(key,e.target.value))
  }
  useEffect(()=>{
    if(!isAuthenticated || role!=="Client")
      navigate("/login")
  },[])
  useEffect(()=>{
    const fetchCurrency=async()=>{
      const response=await fetch("https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/mad.json",{
        method:'GET'
      })
      await response.json().then((data)=>{
        setCurrencies(data)
      })
    }
    fetchCurrency()
  },[])
  useEffect(()=>{
    if(selectedCurrencies==="MAD"){
      setFinalPricings(totalAmount * 1)
      setFinalPricingsTva(totalAmountTva * 1)
    }else if(selectedCurrencies==="EUR"){
      setFinalPricings(totalAmount * currencies?.mad.eur)
      setFinalPricingsTva(totalAmountTva * currencies?.mad.eur)
    }else{
      setFinalPricings(totalAmount * currencies?.mad.usd)
      setFinalPricingsTva(totalAmountTva * currencies?.mad.usd)
    }
    
  },[selectedCurrencies,totalAmountTva])
  return (
    <Box p={8} bg={bg1} minH="100vh" >
      <Flex direction={{base:"column",md:"row"}} gap={10} w={{base:"100%",lg:"80%"}} justifyContent={"space-around"} ml={{base:"0",lg:"10%"}}>
        <ReceiptSummary
          selectedCurrencies={selectedCurrencies}
          session={session} 
          handleDuration={handleDuration} 
          removeFromCart={removeFromCart} 
          formattedDate={formattedDate}
          formattedTime={formattedTime} 
          finalPricings={finalPricings}
          finalPricingsTva={finalPricingsTva} 
          setSelectedCurrency={setSelectedCurrency}
        />
        <ShoppingCart 
          selectedCurrencies={selectedCurrencies}
          session={session} 
          duration={duration} 
          formattedDate={formattedDate}
          formattedTime={formattedTime}
          finalPricingsTva={finalPricingsTva}
          userId={userId}
        />
      </Flex>
    </Box>
  );
}
