import React from 'react'
import { Flex, Box, Text,List, ListItem, UnorderedList, Heading } from '@chakra-ui/react'



function CookiePolicy() {
  return (
    <>
       <>
      <Flex direction={"column"} p={10} mb="2em">
        <Box mb="2em">
            <Heading>Cookie Policy</Heading>
            <Text mb="1em"><b>Effective Date:</b> July 4, 2024</Text>
            <Text textAlign="left">X CAPITAL ("we," "us," "our") uses cookies on xcapitalterminal.com. By using our website, you consent to the use of cookies.</Text>
        </Box>

        <Flex direction={"column"} gap={5}>
            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>1. What Are Cookies?</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem listStyleType="none">Cookies are small text files stored on your device that help us enhance your experience on our website.</ListItem>
                    </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>2. How We Use Cookies</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem><b>ssential Cookies:</b>E Necessary for the operation of our website.</ListItem>
                    <ListItem><b>Performance Cookies: </b>Help us understand how visitors use our website.</ListItem>
                    <ListItem><b>Functional Cookies:</b> Enable enhanced functionality and personalization.</ListItem>
                    <ListItem><b>Advertising Cookies:</b> Deliver relevant advertisements to you.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>3. Managing Cookies</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem listStyleType="none">You can manage your cookie preferences through your browser settings. However, disabling cookies may affect the functionality of our website.</ListItem>
                </UnorderedList>
            </List>


            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>4. Contact Us</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem listStyleType="none">If you have any questions about our Cookie Policy, please contact us at: contact@xcapitalterminal.com or call us at +212 632331441 or +212 632321083.</ListItem>
                </UnorderedList>
            </List>
        </Flex>
      </Flex>
    </>
    </>
  )
}

export default CookiePolicy
