import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  Text,
  Box,
  Heading,
  Button,
  Input,
  FormControl,
  Image,
  FormLabel,
} from "@chakra-ui/react";
import { TbEdit } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { updateUser } from "../actions/user";
import defaultImage from "../assets/defaultProfile.png";
import { load_user } from "../actions/auth";

const UserProfileModal = ({ isOpen, onClose, user }) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({ ...user });
  const [newProfilePicture, setNewProfilePicture] = useState(null);

  useEffect(() => {
    setFormData({ ...user });
  }, [user]);

  useEffect(() => {
    return () => {
      if (newProfilePicture) {
        URL.revokeObjectURL(URL.createObjectURL(newProfilePicture));
      }
    };
  }, [newProfilePicture]);

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = () => {
    const firstName = formData.first_name?.trim() || "";
    if (!firstName) {
      alert("First name cannot be empty!");
      return;
    }

    const updatedData = {
      ...formData,
      first_name: firstName,
    };

    if (newProfilePicture) {
      updatedData.profile_picture = newProfilePicture;
    }

    dispatch(updateUser(user.id, updatedData))
      .then(() => {
        setEditMode(false);
        setNewProfilePicture(null);
        dispatch(load_user());
        onClose();
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent maxWidth="500px">
        <ModalHeader textAlign="center">
          <Heading fontSize="2xl" color="#212529">
            Profile
          </Heading>
          <Text fontSize="medium" color="#868E96">
            Personal information
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={6}>
          {user ? (
            <>
              <Box
                height="100px"
                width="100px"
                position="relative"
                margin="0 auto 20px"
              >
                <Image
                  w="100%"
                  height="100%"
                  objectFit="cover"
                  src={
                    newProfilePicture && newProfilePicture instanceof File
                      ? URL.createObjectURL(newProfilePicture)
                      : user.profile_picture || defaultImage
                  }
                  borderRadius="50%"
                />
                {editMode && (
                  <FormControl
                    position="absolute"
                    bottom="-10px"
                    right="-10px"
                    bg="white"
                    width="40px"
                    height="40px"
                    borderRadius="50%"
                  >
                    <FormLabel
                      cursor="pointer"
                      margin="8px"
                      w="100%"
                      h="100%"
                      fontSize="25px"
                      color="gray.500"
                    >
                      <TbEdit />
                    </FormLabel>
                    <Input
                      type="file"
                      accept="image/*"
                      position="absolute"
                      top="-1000px"
                      name="profile_picture"
                      onChange={(e) => {
                        if (e.target.files[0]) {
                          setNewProfilePicture(e.target.files[0]);
                        }
                      }}
                    />
                  </FormControl>
                )}
              </Box>
              <SimpleGrid columns={2} spacing={6}>
                <Box>
                  <Text fontWeight="bold" color="#212529">
                    First name
                  </Text>
                  {editMode ? (
                    <FormControl>
                      <Input
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                      />
                    </FormControl>
                  ) : (
                    <Text color="#495057">{user.first_name}</Text>
                  )}
                </Box>
                <Box>
                  <Text fontWeight="bold" color="#212529">
                    Last name
                  </Text>
                  {editMode ? (
                    <FormControl>
                      <Input
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                      />
                    </FormControl>
                  ) : (
                    <Text color="#495057">{user.last_name}</Text>
                  )}
                </Box>
                <Box>
                  <Text fontWeight="bold" color="#212529">
                    Username
                  </Text>
                  {editMode ? (
                    <FormControl>
                      <Input
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                      />
                    </FormControl>
                  ) : (
                    <Text color="#495057">{user.username}</Text>
                  )}
                </Box>
                <Box>
                  <Text fontWeight="bold" color="#212529">
                    Email
                  </Text>
                  {editMode ? (
                    <FormControl>
                      <Input
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </FormControl>
                  ) : (
                    <Text color="#495057">{user.email}</Text>
                  )}
                </Box>
                <Box>
                  <Text fontWeight="bold" color="#212529">
                    Phone Number
                  </Text>
                  {editMode ? (
                    <FormControl>
                      <Input
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                      />
                    </FormControl>
                  ) : (
                    <Text color="#495057">{user.phone_number}</Text>
                  )}
                </Box>
                <Box>
                  <Text fontWeight="bold" color="#212529">
                    Job Role
                  </Text>
                  {editMode ? (
                    <FormControl>
                      <Input
                        name="job_role"
                        value={formData.job_role}
                        onChange={handleChange}
                      />
                    </FormControl>
                  ) : (
                    <Text color="#495057">{user.job_role}</Text>
                  )}
                </Box>
                {user.type === "Company" && (
                  <>
                    <Box gridColumn="span 2">
                      <Text fontWeight="bold" color="#212529">
                        Organization
                      </Text>
                      {editMode ? (
                        <FormControl>
                          <Input
                            name="organization"
                            value={formData.organization || ""}
                            onChange={handleChange}
                          />
                        </FormControl>
                      ) : (
                        <Text color="#495057">
                          {user.organization || "N/A"}
                        </Text>
                      )}
                    </Box>
                    <Box>
                      <Text fontWeight="bold" color="#212529">
                        Business Type
                      </Text>
                      {editMode ? (
                        <FormControl>
                          <Input
                            name="business_type"
                            value={formData.business_type || ""}
                            onChange={handleChange}
                          />
                        </FormControl>
                      ) : (
                        <Text color="#495057">
                          {user.business_type || "N/A"}
                        </Text>
                      )}
                    </Box>
                    <Box>
                      <Text fontWeight="bold" color="#212529">
                        LinkedIn URL
                      </Text>
                      {editMode ? (
                        <FormControl>
                          <Input
                            name="linkedin_url"
                            value={formData.linkedin_url || ""}
                            onChange={handleChange}
                          />
                        </FormControl>
                      ) : (
                        <Text color="#495057">
                          {user.linkedin_url || "N/A"}
                        </Text>
                      )}
                    </Box>
                    <Box>
                      <Text fontWeight="bold" color="#212529">
                        City
                      </Text>
                      {editMode ? (
                        <FormControl>
                          <Input
                            name="city"
                            value={formData.city || ""}
                            onChange={handleChange}
                          />
                        </FormControl>
                      ) : (
                        <Text color="#495057">{user.city || "N/A"}</Text>
                      )}
                    </Box>
                    <Box>
                      <Text fontWeight="bold" color="#212529">
                        Company Website
                      </Text>
                      {editMode ? (
                        <FormControl>
                          <Input
                            name="company_website"
                            value={formData.company_website || ""}
                            onChange={handleChange}
                          />
                        </FormControl>
                      ) : (
                        <Text color="#495057">
                          {user.company_website || "N/A"}
                        </Text>
                      )}
                    </Box>
                  </>
                )}
              </SimpleGrid>
              <Button
                width="100%"
                mt={4}
                color="white"
                bg="#001A72"
                _hover={{ bg: "#001A72" }}
                onClick={editMode ? handleSave : handleEditToggle}
              >
                {editMode ? "Save Profile" : "Edit Profile"}
              </Button>
            </>
          ) : (
            <Text>Loading...</Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UserProfileModal;
