import { GET_SESSION_SUCCESS, GET_SESSION_FAIL, SET_SESSION_SUCCESS,SET_SESSION_FAIL ,DELETE_SESSION_SUCCESS, DELETE_SESSION_FAIL,UPDATE_SESSION_SUCCESS,UPDATE_SESSION_FAIL } from '../actions/types';

const initialState = {
  cartElements: {},
  loading: true,
  error: null
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SESSION_SUCCESS:
      return {
        ...state,
        cartElements: action.payload,
        loading: false,
        error: null
      };
    case GET_SESSION_FAIL:
    case SET_SESSION_SUCCESS:
      return {
        ...state,
        cartElements: action.payload,
        loading: false,
        error: null
      };
    case SET_SESSION_FAIL:
    case DELETE_SESSION_SUCCESS:
      return {
        ...state,
        cartElements: action.payload,
        loading: false,
        error: null
      };
    case DELETE_SESSION_FAIL:
    case UPDATE_SESSION_SUCCESS:
      return {
        ...state,
        cartElements: action.payload,
        loading: false,
        error: null
      };
    case UPDATE_SESSION_FAIL:
    default:
      return state;
  }
};

export default cartReducer;
