import { GET_TRIAL_FAIL, GET_TRIAL_SUCCESS, CREATE_TRIAL_FAIL, CREATE_TRIAL_SUCCESS,UPDATE_TRIAL_FAIL, UPDATE_TRIAL_SUCCESS} from './types';
import axios from 'axios';
import { getAuthConfig } from './config';


export const getTrialByClient=(id)=>async dispatch =>{
    try{
      const config= getAuthConfig();
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/trial/user/${id}`,config)
      dispatch({
        type: GET_TRIAL_SUCCESS,
        payload: res.data
      });
      return res.data; 
    } catch (err) {
      dispatch({
        type: GET_TRIAL_FAIL,
        payload: err.message 
      });
      throw err; 
    }
  }

  export const createTrial=(trialInfos)=>async dispatch=>{
    try{
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/trial/save/`,{
        product:trialInfos.product_id,
        user:trialInfos.user_id
      })
      dispatch({
        type: CREATE_TRIAL_SUCCESS,
        payload: res.data
      });
      return res.data; 
    } catch (err) {
      dispatch({
        type: CREATE_TRIAL_FAIL,
        payload: err.message 
      });
      throw err; 
    }
  }
  
  export const updtaeTrialStatus=(trialId)=>async dispatch=>{
    try{
      const config= getAuthConfig();
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/trial/${trialId}/update/`,{
        is_active:false
      },config)
      dispatch({
        type: UPDATE_TRIAL_SUCCESS,
        payload: res.data
      });
      return res.data; 
    } catch (err) {
      dispatch({
        type: UPDATE_TRIAL_FAIL,
        payload: err.message 
      });
      throw err; 
    }
  }
  