import { Box, Heading, Table, Tbody, Tr, Td, Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPreOrders } from "../../actions/preorderActions";

export default function PreOrderChart() {
  const dispatch = useDispatch();
  const [preOrderData, setPreOrderData] = useState({
    accepted: 0,
    rejected: 0,
    pending: 0,
  });

  // for Dark/light mode
  const bg2 = useSelector((state) => state.themeReducer.backgroundColor2);
  const colorH = useSelector((state) => state.themeReducer.color1);
  const numbColor = useSelector((state) => state.themeReducer.numbColor);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const preorders = await dispatch(getAllPreOrders());

        // Calculate the percentage of each status
        const totalPreOrders = preorders.length;
        const acceptedPreOrders = preorders.filter(
          (o) => o.status === "Accepted"
        ).length;
        const rejectedPreOrders = preorders.filter(
          (o) => o.status === "Rejected"
        ).length;
        const pendingPreOrders = preorders.filter(
          (o) => o.status === "Pending"
        ).length;

        setPreOrderData({
          accepted: (acceptedPreOrders / totalPreOrders) * 100,
          rejected: (rejectedPreOrders / totalPreOrders) * 100,
          pending: (pendingPreOrders / totalPreOrders) * 100,
        });
      } catch (error) {
        console.error("Failed to fetch preorders:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <Box
      height="100%"
      overflow="auto"
      borderRadius="xl"
      boxShadow="md"
      bgColor={bg2}
      p={4}
    >
      <Heading as="h3" fontSize="20px" textColor="#05004E" pl="20px" mb="20px">
        Pre-Orders
      </Heading>
      <Table variant="simple" size="sm" width="full">
        <Tbody width="full">
          <Td
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="600"
            color={colorH}
            whiteSpace="nowrap"
          >
            <Icon viewBox="0 0 200 200" color="#A0B2F8" mr="2">
              <path
                fill="currentColor"
                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
              />
            </Icon>
            Accepted
          </Td>
          <Tr>
            <Td width="full">
              <Box
                position="relative"
                width="full"
                height="3"
                bg="gray.200"
                borderRadius="md"
              >
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  height="3"
                  bg="#A0B2F8"
                  borderRadius="md"
                  width={`${preOrderData.accepted}%`}
                />
              </Box>
            </Td>
            <Td
              textAlign="right"
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="600"
              color={numbColor}
            >
              {`${preOrderData.accepted.toFixed(2)}%`}
            </Td>
          </Tr>
          <Td
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="600"
            color={colorH}
            whiteSpace="nowrap"
          >
            <Icon viewBox="0 0 200 200" color="#A0CCF8" mr="2">
              <path
                fill="currentColor"
                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
              />
            </Icon>
            Rejected
          </Td>
          <Tr>
            <Td>
              <Box
                position="relative"
                width="full"
                height="3"
                bg="gray.200"
                borderRadius="md"
              >
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  height="3"
                  bg="#A0CCF8"
                  borderRadius="md"
                  width={`${preOrderData.rejected}%`}
                />
              </Box>
            </Td>

            <Td
              textAlign="right"
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="600"
              color={numbColor}
            >
              {`${preOrderData.rejected.toFixed(2)}%`}
            </Td>
          </Tr>
          <Td
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="600"
            color={colorH}
            whiteSpace="nowrap"
          >
            <Icon viewBox="0 0 200 200" color="#2373EB" mr="2">
              <path
                fill="currentColor"
                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
              />
            </Icon>
            Pending
          </Td>
          <Tr>
            <Td>
              <Box
                position="relative"
                width="full"
                height="3"
                bg="gray.200"
                borderRadius="md"
              >
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  height="3"
                  bg="#2373EB"
                  borderRadius="md"
                  width={`${preOrderData.pending}%`}
                />
              </Box>
            </Td>
            <Td
              textAlign="right"
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="600"
              color={numbColor}
            >
              {`${preOrderData.pending.toFixed(2)}%`}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
}
