import { Box, Stack, Center } from "@chakra-ui/react";
import ProductsSection from "../components/products/ProductsSection";
import Questions from "../components/questions/Questions";
import XCapitalVideo from "../assets/XCapitalVideo.mp4";

const ProductPage = () => {
  return (
    <>
      <Box>
        <Stack spacing={8}>
          <Box
            py={10}
            bg="#2D2D2D"
            color="white"
            height={{ base: "55vh", md: "75vh", sm: "60vh" }}
          >
            <Center>
              <video width="1050px" height="800px" controls>
                <source src={XCapitalVideo} type="video/mp4" />
              </video>
            </Center>
          </Box>

          <ProductsSection />

          <Center>
            <Questions />
          </Center>
        </Stack>
      </Box>
    </>
  );
};

export default ProductPage;
