import fetchArticlesTeasers from "../services/api/artilcesAPI/fetchArticleTeaser";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
  Divider,
  Button,
  ButtonGroup,
  Spinner,
  useMediaQuery,
  Box
} from "@chakra-ui/react";
import { useSelector } from "react-redux";

export default function SuggestedArticles({category,id}) {
  const [loadingTeasers, setLoadingTeasers] = useState(true);
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();
  const [isLessThan768] = useMediaQuery("(max-width: 768px)");
  useEffect(() => {
    const fetchTeasers = async () => {
      try {
        const result = await fetchArticlesTeasers();
        const diffCategory=result.filter(r=>r.category.id!==category && r.id!==id)
        const sameCategory=result.filter(r=>r.category.id===category && r.id!==id)
        if(sameCategory.length!==0)
          setArticles([...sameCategory,...diffCategory]);
        else
          setArticles(diffCategory)
      } catch (error) {
        console.error("Error fetching articles:", error);
      } finally {
        setLoadingTeasers(false);
      }
    };
    fetchTeasers();
  }, []);
  useEffect(()=>{

  },[articles])

  const handleReadMore = (id) => {
    window.location.href="/article/"+id
  };

  const handleExploreMore = () => {
    navigate("/articles");
  };
  const choseColor=(cat)=>{
    switch(cat){
      case "Finance":
        return "blue"
      case "Fashion":
        return "red"
      case "Branding":
        return "green"
      case "Technology":
        return "yellow"
      case "Travel":
        return "purple"
      default :
        return "black"
    }
  }
  const bgColor=useSelector(state=>state.themeReducer.backgroundColor1)
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate}`;
  };
  return (
    <Flex padding="40px" direction="column" id="sugg">
      <Heading fontSize={isLessThan768 ? "28px" : "36px"} color="#3D3D3D">
        Suggested Articles{" "}
      </Heading>
      <Stack>
        <Divider />
        <Flex
          flexWrap={"wrap"}
          gap={"24px"}
          padding={"24px 0"}
          justify={"center"}
        >
          {loadingTeasers ? (
            <Spinner size="xl" />
          ) : (
            articles.slice(0,5).map((teaser) => (
              <Box key={teaser.id} flex="0 0 300px" onClick={()=>handleReadMore(teaser.id)} bgColor={bgColor} paddingBottom={"5px"} cursor={"pointer" }>
                    <Image
                      src={teaser.image_cover_url}
                      alt={teaser.title}
                      borderRadius="2px"
                      h="172px"
                      objectFit="cover"
                      w="100%"
                    />
                    <Box padding="15px">
                      <Text fontWeight={"500"} fontSize={"18px"} color={choseColor(teaser.category.name)}>
                          {teaser.category.name}
                      </Text>
                      <Stack mt="4">
                        <Heading fontSize="22px">{teaser.title}</Heading>
                      </Stack>
                      <Text mt="20px" fontWeight={"500"} color={"gray"}>{formatDate(teaser.created_at)}</Text>
                    </Box> 
              </Box>
            ))
          )}
        </Flex>
        <Flex align={"center"} justify={"center"} padding={"32px 0"}>
          <Button
            size={"lg"}
            bg={"black"}
            color={"white"}
            onClick={handleExploreMore}
          >
            Explore more
          </Button>
        </Flex>
      </Stack>
    </Flex>
  );
}
