import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  Alert,
  AlertIcon,
  Flex,
  Box,
  Text,
  Image,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { createProduct, updateProduct } from "../actions/product";
import { load_user } from "../actions/auth";
import { FaUpload, FaTimes } from "react-icons/fa";

const AddProductModal = ({ isOpen, onClose, type, initialData }) => {
  const [name, setName] = useState("");
  const [monthlyPrice, setMonthlyPrice] = useState("");
  const [yearlyPrice, setYearlyPrice] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(load_user());
  }, [dispatch]);

  useEffect(() => {
    if (type === "update" && initialData) {
      setName(initialData.title);
      setMonthlyPrice(initialData.monthlyPrice);
      setYearlyPrice(initialData.yearlyPrice);
      setDescription(initialData.description || "");
      if (initialData.image) {
        setImagePreview(initialData.image);
      }
    } else {
      clearForm();
    }
  }, [type, initialData, isOpen]);

  const clearForm = () => {
    setName("");
    setMonthlyPrice("");
    setYearlyPrice("");
    setDescription("");
    setImage(null);
    setImagePreview(null);
    setErrors({});
  };

  const loggedInUser = useSelector((state) => state.auth.user);

  const handleAddOrUpdateProduct = async () => {
    setErrors({});

    if (!image && !imagePreview) {
      setErrors((prev) => ({ ...prev, image: "Please select an image" }));
      return;
    }

    if (loggedInUser) {
      const formData = new FormData();
      formData.append("title", name);
      formData.append("monthlyPrice", monthlyPrice);
      formData.append("yearlyPrice", yearlyPrice);
      formData.append("description", description);
      formData.append("total_subs", 0);
      formData.append("created_by", loggedInUser.id);
      formData.append("is_active", true);
      if (image) {
        formData.append("image", image);
      }

      try {
        if (type === "update" && initialData) {
          await dispatch(updateProduct(initialData.id, formData));
        } else {
          await dispatch(createProduct(formData));
        }
        handleClose();
      } catch (error) {
        if (error.response && error.response.data) {
          setErrors(error.response.data);
        } else {
          setErrors({ general: "An unexpected error occurred" });
        }
      }
    } else {
      console.error("User data not available");
      setErrors({ general: "User data not available" });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImagePreview(null);
  };

  const handleClose = () => {
    clearForm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {type === "update" ? "Update Product" : "Add New Product"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="name" isRequired isInvalid={errors.title}>
            <FormLabel>Name</FormLabel>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Product name"
            />
            {errors.title && (
              <FormErrorMessage>{errors.title}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl id="monthlyPrice" mt={4} isRequired isInvalid={errors.monthlyPrice}>
            <FormLabel>Monthly Price</FormLabel>
            <Input
              value={monthlyPrice}
              onChange={(e) => setMonthlyPrice(e.target.value)}
              placeholder="Monthly price"
              type="number"
            />
            {errors.monthlyPrice && (
              <FormErrorMessage>{errors.monthlyPrice}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl id="yearlyPrice" mt={4} isRequired isInvalid={errors.yearlyPrice}>
            <FormLabel>Yearly Price</FormLabel>
            <Input
              value={yearlyPrice}
              onChange={(e) => setYearlyPrice(e.target.value)}
              placeholder="Yearly price"
              type="number"
            />
            {errors.yearlyPrice && (
              <FormErrorMessage>{errors.yearlyPrice}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl id="description" mt={4} isRequired isInvalid={errors.description}>
            <FormLabel>Description</FormLabel>
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Product description"
            />
             {errors.description && (
              <FormErrorMessage>{errors.description}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl id="image" mt={4} isInvalid={errors.image}>
            <FormLabel>Image</FormLabel>
            <Flex
              alignItems="center"
              justifyContent="center"
              p={2}
              border="2px dashed"
              borderColor={errors.image ? "red.500" : "gray.300"}
              borderRadius="md"
              cursor="pointer"
              onClick={() =>
                !imagePreview && document.getElementById("file-input").click()
              }
              position="relative"
            >
              <Input
                type="file"
                id="file-input"
                accept="image/*"
                onChange={handleImageChange}
                display="none"
              />
              {imagePreview ? (
                <>
                  <Image
                    src={imagePreview}
                    alt="Selected product"
                    maxH="200px"
                  />
                  <Button
                    position="absolute"
                    top="5px"
                    right="5px"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveImage();
                    }}
                  >
                    <FaTimes />
                  </Button>
                </>
              ) : (
                <Box textAlign="center">
                  <FaUpload size="24px" color="#001A72" />
                  <Text mt={2}>Click to upload image</Text>
                </Box>
              )}
            </Flex>
            {errors.image && (
              <FormErrorMessage>{errors.image}</FormErrorMessage>
            )}
          </FormControl>
          {errors.general && (
            <Alert status="error" mt={4}>
              <AlertIcon />
              {errors.general}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#001A72"
            color="#FFFFFF"
            _hover={{ bg: "#001A72" }}
            ml={3}
            mr={3}
            onClick={handleAddOrUpdateProduct}
          >
            {type === "update" ? "Update" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddProductModal;
