import axios from 'axios';
import { getAuthConfig, getAuthConfigFile } from './config';
import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  GET_USERS_BY_ROLE_SUCCESS,
  GET_USERS_BY_ROLE_FAIL,
  GET_USERS_BY_ID_FAIL,
  GET_USERS_BY_ID_SUCCESS,
  GET_USERS_BY_ROLE_CONDITION_SUCCESS,
  GET_USERS_BY_ROLE_CONDITION_FAIL,
  DOWNLOAD_USERS_EXCEL_BY_ROLE_FAIL,
  DOWNLOAD_USERS_EXCEL_BY_ROLE_SUCCESS,
  DOWNLOAD_USERS_EXCEL_SUCCESS,
  DOWNLOAD_USERS_EXCEL_FAIL,
} from './types';

export const getUsers = () => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/users`, config);
    dispatch({
      type: GET_USERS_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_USERS_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    await axios.delete(`${process.env.REACT_APP_API_URL}/user/${userId}/delete`, config);
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: userId,
    });
  } catch (err) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const addUser = (userData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/`, userData, config);
    dispatch({
      type: ADD_USER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_USER_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const updateUser = (userId, userData) => async (dispatch) => {
  try {
    const config = getAuthConfigFile();
    const formData=new FormData()
    formData.append("first_name",userData.first_name)
    formData.append("last_name",userData.last_name)
    formData.append("email",userData.email)
    formData.append("username",userData.username)
    formData.append("job_role",userData.job_role)
    formData.append("phone_number",userData.phone_number)
    if(userData.profile_picture)
      formData.append("profile_picture",userData.profile_picture)
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/user/${userId}/update`, formData ,config);
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: err.message,
    });
    return err.message;
  }
};

export const getUsersByRole = (role) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/users/role/${role}`);
    dispatch({
      type: GET_USERS_BY_ROLE_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_USERS_BY_ROLE_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const getUserById = (userId) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/${userId}`, config);
    dispatch({
      type: GET_USERS_BY_ID_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_USERS_BY_ID_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const getUsersByRoleAndDate = (role, startDate, endDate) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/users/role/${role}`, config);
    const filteredUsers = res.data.filter((user) => {
      const userDate = new Date(user.created_at);
      return userDate >= new Date(startDate) && userDate <= new Date(endDate);
    });
    dispatch({
      type: GET_USERS_BY_ROLE_SUCCESS,
      payload: filteredUsers,
    });
    return filteredUsers;
  } catch (err) {
    dispatch({
      type: GET_USERS_BY_ROLE_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const getUsersByRoleCondition = (role) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/chat/${role}`, config);
    dispatch({
      type: GET_USERS_BY_ROLE_CONDITION_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_USERS_BY_ROLE_CONDITION_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const downloadUsersExcel = () => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/download`, {
      ...config,
      responseType: 'blob'
    });
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'users.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();

    dispatch({
      type: DOWNLOAD_USERS_EXCEL_SUCCESS
    });
  } catch (err) {
    dispatch({
      type: DOWNLOAD_USERS_EXCEL_FAIL,
      payload: err.message
    });
    throw err;
  }
};

export const downloadUsersExcelByRole = (role) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${role}/download`, {
      ...config,
      responseType: 'blob'
    });
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `users_${role}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();

    dispatch({
      type: DOWNLOAD_USERS_EXCEL_BY_ROLE_SUCCESS
    });
  } catch (err) {
    dispatch({
      type: DOWNLOAD_USERS_EXCEL_BY_ROLE_FAIL,
      payload: err.message
    });
    throw err;
  }
};