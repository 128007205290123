import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Stack,
  Icon,
  SimpleGrid,
  Hide,
  HStack,
  Divider
} from '@chakra-ui/react';
import {FaStar, FaChartLine, FaUserCheck } from 'react-icons/fa';
import ProductsSection from '../components/products/ProductsSection';
import { CgShapeRhombus } from "react-icons/cg";
import xcapitalbanner from "../assets/xcapitalbanner.jpg"
import xcapitalImage from "../assets/xcapitalImage.png"
import { Link } from 'react-router-dom';
import { MdHomeRepairService } from "react-icons/md";import { useSelector } from 'react-redux';


const AboutUs = () => {
  const bg=useSelector(state=>state.themeReducer.backgroundColor1)
  const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
  const colorH=useSelector(state=>state.themeReducer.color1)
  const colorP=useSelector(state=>state.themeReducer.color2)
  const grayScales=useSelector(state=>state.themeReducer.grayScales)

    return(
    <>
    <Box bgColor={bg2}>
    <Flex
      w="full"
      h={{ base: '60vh', md: '80vh' }}
      backgroundImage={`url(${xcapitalImage})`}
      backgroundSize="cover"
      backgroundPosition="center"
      align="center"
      justify="flex-start"
      color="white"
      textAlign="left"
      p={8}
      position="relative"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg="rgba(0, 0, 0, 0.4)"
        boxShadow="inset 0 0 120px 50px rgba(255, 255, 255, 0.3)"
      />
      <Box
        position="relative"
        maxW={{
          base: 'md',
          md: '60%',
          lg: '30%',
        }}
        pl={{
          base: '1em',
          md: '2em',
        }}
      >
        <Heading
          as="h1"
          fontSize={{
            base: '2xl',
            md: '3xl',
            lg: '4xl',
          }}
          lineHeight="shorter"
          borderLeft="5px solid white"
          pl="1em"
        >
          What Is X CAPITAL And How Valid Is It?
        </Heading>
        <Text fontSize={{ base: 'sm', md: 'md' }} mt={4}>
          X Capital is a pioneering fintech startup dedicated to transforming the financial markets of Africa and the Middle East. Leveraging advanced AI and machine learning, we empower B2B investors with real-time data, in-depth analysis, and actionable insights. Our flagship product, the X Capital Terminal, provides the tools needed to navigate market complexities and seize emerging opportunities. Join us as we drive growth and shape the future of finance.
        </Text>
        <Stack
          direction={{
            base: 'column',
            md: 'row',
          }}
          spacing={4}
          mt={8}
        >
          
            <Button 
                  bgColor="white"
                  color="black"
            ><Link to="/solutions">Get Started</Link></Button>
          
          <Button variant="outline" colorScheme="whiteAlpha" color="white">
            <Link to="/contact-us">Contact Us</Link>
                
                
                </Button>
        </Stack>
      </Box>
    </Flex>


      <Box paddingTop={"15px"} paddingBottom={"15px"} width={"75%"} ml={"12.5%"} mb={"20px"}>
        <Box textAlign={"center"} mb={4} width={"100%"}>
            <Icon as={CgShapeRhombus} w={7} h={7} color="blue.500" bgColor="#C2D9FF" p="1px"/>
            <Heading as="h2" fontSize="30px" ml={2}>
              About Us
            </Heading>
        </Box>
        <Heading as="h3" fontSize="24px" mb={"15px"}>
            Our Company Overview
        </Heading>
        <Text mb={6}  lineHeight={"35px"} fontSize={"18px"}>
        At X Capital, we are dedicated to revolutionizing the financial landscape of Africa and the Middle East through innovative fintech solutions. Our mission is to empower investors with cutting-edge AI and machine learning technologies, driving growth and shaping the future of finance.
        At X Capital, we understand the complexities and opportunities inherent in the financial markets. Our flagship product, X Capital Terminal, provides real-time data, historical insights, comprehensive analysis, and in-depth financial reporting. This powerful tool equips investors with the knowledge and resources they need to make informed decisions and seize emerging opportunities.
        Our primary focus is on serving B2B entities that invest and manage funds in the financial markets, with a special emphasis on the stock market. We are dedicated to delivering innovative solutions that enhance investment strategies, optimize returns, and foster sustainable growth.        </Text>
      </Box>

          <Divider/>

        <ProductsSection/>

        <Flex
            w="full"
            py={10}
            px={4}
            backgroundImage={xcapitalbanner}
            backgroundSize="cover"
            backgroundPosition="center"
            color="white"
            direction="column"
            align="flex-start"
            textAlign="left"
        >
            <Hide above='840px'>
                <Stack direction="row" align="center" mb={4}>
                <Icon as={MdHomeRepairService} w={7} h={7} color="white" />
                </Stack>
                <Heading as="h3" fontSize="2xl" mb={4}>
                The Feature Of FinTech That You Will Benefit From
                </Heading>
                <Link to="/solutions">
                  <Button colorScheme="whiteAlpha" variant="outline" mb={8}>
                  Visit Products Page
                  </Button>
                </Link>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full">
                <Flex direction="column" align="center" p={4} border="1px" borderColor="whiteAlpha.500" borderRadius="md" boxShadow="0 0 10px 2px rgba(255, 255, 255, 0.75)">
                    <Icon as={FaStar} w={8} h={8} mb={4} />
                    <Text fontSize="lg" textAlign="center">Leveraging AI and Machine Learning in Financial Market</Text>
                </Flex>
                <Flex direction="column" align="center" p={4} border="1px" borderColor="whiteAlpha.500" borderRadius="md" boxShadow="0 0 10px 2px rgba(255, 255, 255, 0.75)">
                    <Icon as={FaChartLine} w={8} h={8} mb={4} />
                    <Text fontSize="lg">Latest News And Data</Text>
                </Flex>
                <Flex direction="column" align="center" p={4} border="1px" borderColor="whiteAlpha.500" borderRadius="md" boxShadow="0 0 10px 2px rgba(255, 255, 255, 0.75)">
                    <Icon as={FaUserCheck} w={8} h={8} mb={4} />
                    <Text fontSize="lg">Advanced Analytics</Text>
                </Flex>
                </SimpleGrid>
            </Hide>

            <Hide below='840px'>
                <HStack width="full"
                position="relative"
                >
                <Box>
                    <Flex mb={4}>
                        <Icon as={MdHomeRepairService} w={7} h={7} color="white" mr={1}/>
                    </Flex>
                    <Heading as="h2" fontSize="4xl" mb={6}>
                    The Feature Of FinTech That<br/> You Will Benefit From
                    </Heading>
                    <Link to="/solutions">
                      <Button colorScheme="whiteAlpha" color="white" variant="outline" mb={8}>
                      Visit Products Page
                      </Button>
                    </Link>
                </Box>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} w="60%" ml="auto" h="40vh">
                <Flex direction="column" align="center" p={4} border="1px" borderColor="whiteAlpha.500" borderRadius="md" pt="14vh" 
                      boxShadow="0 0 10px 2px rgba(255, 255, 255, 0.75)"
                >
                    <Icon as={FaStar} w={8} h={8} mb={4} />
                    <Text fontSize="lg" fontWeight="bold" textAlign="center">Leveraging AI and Machine Learning in Financial Market</Text>
                </Flex>
                <Flex direction="column" align="center" p={4} border="1px" borderColor="whiteAlpha.500" borderRadius="md" pt="14vh"
                      boxShadow="0 0 10px 2px rgba(255, 255, 255, 0.75)"
                >
                    <Icon as={FaChartLine} w={8} h={8} mb={4} />
                    <Text fontSize="lg" fontWeight="bold" textAlign="center">Latest News <br/>And Data</Text>
                </Flex>
                <Flex direction="column" align="center" p={4} border="1px" borderColor="whiteAlpha.500" borderRadius="md" pt="14vh"
                      boxShadow="0 0 10px 2px rgba(255, 255, 255, 0.75)"
                >
                    <Icon as={FaUserCheck} w={8} h={8} mb={4} />
                    <Text fontSize="lg" fontWeight="bold" textAlign="center">Advanced<br/> Analytics</Text>
                </Flex>
                </SimpleGrid>
                </HStack>
            </Hide>
        </Flex>
    </Box>
  </>)
};

export default AboutUs;
