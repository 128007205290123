import axios from 'axios';


const updateArticle = async (formData, article_id) => {
  const accessToken = localStorage.getItem('access');


  try {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/article/${article_id}/update/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${accessToken}`
      }
    });
    
    if (response.status === 204) {
      window.location.reload();
    }
    
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Server responded with an error status:', error.response.status);
      console.error('Error details:', error.response.data);
    } else if (error.request) {
      console.error('No response received from server:', error.request);
    } else {
      console.error('Error setting up the request:', error.message);
    }
    
    throw error;
  }
};

export default updateArticle;
