import React, { useState, useRef, useEffect } from "react";
import { Navigate, Link as RouterLink } from "react-router-dom";
import FailedLoginModal from "../components/FailedLoginModal";
import { connect, useDispatch } from "react-redux";
import { signup } from "../actions/auth";
import { SIGNUP_SUCCESS } from "../actions/types";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Flex,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  IconButton,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  useToast,
  Select,
  Checkbox,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { createCompany, getAllCompanies } from "../actions/companyActions";
import { getUsersByRole } from "../actions/user";
import { createTrial } from "../actions/trial";

const Signup = ({ signup, isAuthenticated }) => {
  const bg = useSelector((state) => state.themeReducer.backgroundColor1);
  const bg2 = useSelector((state) => state.themeReducer.backgroundColor2);
  const colorP = useSelector((state) => state.themeReducer.color2);
  const grayScales = useSelector((state) => state.themeReducer.grayScales);

  const [accountCreated, setAccountCreated] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const toast = useToast();
  const [companies,setCompanies]=useState([])
  const [isExistant,setIsExistant]=useState(true)
  const [companyId,setCompanyId]=useState(null)
  console.log(companyId);
  useEffect(()=>{
    const fetchComp=async()=>{
      setCompanies(await dispatch(getAllCompanies()))
    }
    fetchComp()
  },[])
  const handleCheck=(e)=>{
    if(e.target.checked)
      setIsExistant(false)
    else
      setIsExistant(true)
  }
  const mergeRef = useMergeRefs(inputRef);
  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };
  const [signupError, setSignupError] = useState(false);
  const [errorSignupMessage, setErrorSignupMessage] = useState("");
  const [accountType, setAccountType] = useState("Individual");

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    re_password: "",
    role: "Client",
    organization: "",
    business_type: "",
    job_role: "",
    country_region: "",
    city: "",
    linkedin_url: "",
    company_website: "",
    type: "Individual"
  });

  const [validationState, setValidationState] = useState({
    email: true,
    phone_number: true,
  });

  const {
    first_name,
    last_name,
    email,
    phone_number,
    password,
    re_password,
    role,
    organization,
    business_type,
    job_role,
    country_region,
    city,
    linkedin_url,
    company_website,
  } = formData;

  // Email validation using regex
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // Phone validation using regex (numeric and 10-15 digits)
  const validatePhone = (phone) => {
    const re = /^[0-9]{10,15}$/;
    return re.test(String(phone));
  };

  // Prevent non-numeric characters in phone input
  const handlePhoneInput = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    setFormData({ ...formData, phone_number: onlyNums });
    setValidationState((prev) => ({
      ...prev,
      phone_number: validatePhone(onlyNums),
    }));
  };

  // Handle input changes and validate email format
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      setValidationState((prev) => ({ ...prev, email: validateEmail(value) }));
    }
  };

  // Handle form submission
  const onSubmit = async (e) => {
    e.preventDefault();
    if (password === re_password) {
      try {
       let companyIdNew=null
        if (accountType === "Company" && !isExistant) {
          const companyData = {
            organization,
            business_type,
            job_role,
            country_region,
            city,
            linkedin_url,
            company_website,
          };
          const companyResponse = await dispatch(createCompany(companyData));
          companyIdNew=companyResponse.id
        }
        let res=null
        if(isExistant)
           res = await signup(
            first_name,
            last_name,
            email,
            phone_number,
            password,
            re_password,
            role,
            accountType,
            companyId,
          );
          else
           res = await signup(
            first_name,
            last_name,
            email,
            phone_number,
            password,
            re_password,
            role,
            accountType,
            companyIdNew,
          );

        if (res === SIGNUP_SUCCESS) {
          setSignupError(false);
          setAccountCreated(true);
          const users=await dispatch(getUsersByRole("Client"))
          console.log(users);
          if(users.length<=1000){
            const body={
              product_id:7,
              user_id:users.filter(u=>u.email===email)[0]?.id
            }
            const trial=await dispatch(createTrial(body))
          }
          toast({
            title: "Account created.",
            description: "Your account has been successfully created.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          setSignupError(true);
          setErrorSignupMessage(res.message || "An error occurred");
          setTimeout(() => {
            setSignupError(false);
          }, 10000);
        }
      } catch (error) {
        setSignupError(true);
        setErrorSignupMessage(error.message || "An error occurred");
        setTimeout(() => {
          setSignupError(false);
        }, 10000);
      }
    } else {
      setSignupError(true);
      setErrorSignupMessage("Passwords do not match.");
      setTimeout(() => {
        setSignupError(false);
      }, 10000);
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  /*if (accountCreated) {
    return <Navigate to="/login" />;
  }*/

  
  return (
    <>
      <Flex
        flexDirection="column"
        width="100wh"
        minHeight="85vh"
        backgroundColor={bg2}
        justifyContent="center"
        alignItems="center"
        paddingX={{ base: "1rem", md: "2rem" }}
        paddingY="0rem"
      >
        <Container
          maxW={accountType === "Company" ? "4xl" : "lg"}
          py={{ base: "0", md: "12" }}
          px={{ base: "3", sm: "8" }}
          backgroundColor={bg2}
        >
          <Stack spacing="0" direction="row" justify="center" mb="4">
            <Button
              onClick={() => setAccountType("Individual")}
              variant={accountType === "Individual" ? "solid" : "outline"}
              backgroundColor={
                accountType === "Individual" ? "#2D2D2D" : "transparent"
              }
              color={accountType === "Individual" ? "#F7FAFC" : "#2D2D2D"}
              borderColor="#2D2D2D"
              borderWidth="1px"
              borderRightWidth="0"
              borderLeftRadius="13px"
              borderRightRadius="0"
              _hover={{
                backgroundColor:
                  accountType === "Individual" ? "#3D3D3D" : "#E2E8F0",
              }}
            >
              Individual
            </Button>
            <Button
              onClick={() => setAccountType("Company")}
              variant={accountType === "Company" ? "solid" : "outline"}
              backgroundColor={
                accountType === "Company" ? "#2D2D2D" : "transparent"
              }
              color={accountType === "Company" ? "#F7FAFC" : "#2D2D2D"}
              borderColor="#2D2D2D"
              borderWidth="1px"
              borderLeftWidth="0"
              borderRightRadius="13px"
              borderLeftRadius="0"
              _hover={{
                backgroundColor:
                  accountType === "Company" ? "#3D3D3D" : "#E2E8F0",
              }}
            >
              Company
            </Button>
          </Stack>
          <Stack spacing="8">
            <Box
              py={{ base: "5", sm: "8" }}
              px={{ base: "4", sm: "10" }}
              backgroundColor={bg}
              borderRadius={{ base: "12", sm: "24" }}
            >
              <Heading
                fontFamily="Heebo"
                fontWeight="700"
                fontSize={{ base: "15px", md: "25px" }}
                lineHeight={{ base: "36px", md: "40px" }}
                textAlign="center"
              >
                Welcome to X CAPITAL!
              </Heading>
              <Text
                fontFamily="Inter"
                fontWeight="400"
                fontSize="14px"
                lineHeight="30px"
                color={colorP}
                textAlign="center"
              >
                Create an account
              </Text>
              <Heading
                fontFamily="Heebo"
                fontWeight="700"
                fontSize={{ base: "12px", md: "22px" }}
                lineHeight={{ base: "36px", md: "60px" }}
                textAlign="left"
              >
                Sign up
              </Heading>
              <form onSubmit={onSubmit}>
                <Stack spacing="6">
                  <Stack spacing="5">
                    <Stack
                      spacing="5"
                      direction={{ base: "column", md: "row" }}
                    >
                      <FormControl>
                        <FormLabel
                          htmlFor="first_name"
                          color="#7F989A"
                          fontSize="13px"
                          fontWeight="500"
                        >
                          First Name
                        </FormLabel>
                        <Input
                          id="first_name"
                          type="text"
                          placeholder="First Name"
                          borderRadius="10px"
                          border="1px solid #7F989A"
                          backgroundColor={bg2}
                          focusBorderColor="#E7EBEE"
                          name="first_name"
                          value={first_name}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          htmlFor="last_name"
                          color="#7F989A"
                          fontSize="13px"
                          fontWeight="500"
                        >
                          Last Name
                        </FormLabel>
                        <Input
                          id="last_name"
                          type="text"
                          placeholder="Last Name"
                          borderRadius="10px"
                          border="1px solid #7F989A"
                          backgroundColor={bg2}
                          focusBorderColor="#E7EBEE"
                          name="last_name"
                          value={last_name}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </FormControl>
                    </Stack>
                    <FormControl>
                      <FormLabel
                        htmlFor="email"
                        color="#7F989A"
                        fontSize="13px"
                        fontWeight="500"
                      >
                        E-mail
                      </FormLabel>
                      <Input
                        id="email"
                        type="email"
                        placeholder="Email Address"
                        borderRadius="10px"
                        border="1px solid #7F989A"
                        backgroundColor={bg2}
                        focusBorderColor="#E7EBEE"
                        name="email"
                        value={email}
                        onChange={(e) => onChange(e)}
                        required
                        borderColor={
                          validationState.email ? "#7F989A" : "red.500"
                        }
                      />
                      {!validationState.email && (
                        <Text fontSize="12px" color="red.500">
                          Invalid email format
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        htmlFor="phone"
                        color="#7F989A"
                        fontSize="13px"
                        fontWeight="500"
                      >
                        Telephone
                      </FormLabel>
                      <Input
                        id="phone"
                        type="tel"
                        placeholder="Telephone"
                        borderRadius="10px"
                        border="1px solid #7F989A"
                        backgroundColor={bg2}
                        focusBorderColor="#E7EBEE"
                        name="phone_number"
                        value={phone_number}
                        onChange={handlePhoneInput}
                        required
                        borderColor={
                          validationState.phone_number ? "#7F989A" : "red.500"
                        }
                      />
                      {!validationState.phone_number && (
                        <Text fontSize="12px" color="red.500">
                          Invalid phone number format (10-15 digits)
                        </Text>
                      )}
                    </FormControl>
                    {accountType === "Company"  &&(
                      <>
                    {
                      isExistant && ( 
                    <FormControl>
                      <Select 
                        color="#7F989A"
                        name="company"
                        borderRadius="10px"
                        border="1px solid #7F989A"
                        backgroundColor={bg2}
                        focusBorderColor="#E7EBEE"
                        onChange={(e)=>setCompanyId(e.target.value)}
                        placeholder="Select Your Company"
                      >
                        {companies?.sort((a,b)=>a - b).map((cm)=>{return <option value={cm.id}>{cm.organization}</option>})}
                      </Select>
                    </FormControl>)
                    }
                    <FormControl textAlign={"center"} width={"70%"} marginLeft={"15%"}>
                      <Checkbox size={"lg"} onChange={handleCheck}  padding={"7px"}>Add a new company</Checkbox>
                    </FormControl>
                    {
                     !isExistant &&
                      (<>
                        <Stack
                          spacing="5"
                          direction={{ base: "column", md: "row" }}
                        >
                          <FormControl>
                            <FormLabel
                              htmlFor="organization"
                              color="#7F989A"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              Organization
                            </FormLabel>
                            <Input
                              id="organization"
                              type="text"
                              placeholder="Organization Name"
                              borderRadius="10px"
                              border="1px solid #7F989A"
                              backgroundColor={bg2}
                              focusBorderColor="#E7EBEE"
                              name="organization"
                              value={organization}
                              onChange={(e) => onChange(e)}
                              required
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel
                              htmlFor="business_type"
                              color="#7F989A"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              Business Type
                            </FormLabel>
                            <Input
                              id="business_type"
                              type="text"
                              placeholder="Business Type"
                              borderRadius="10px"
                              border="1px solid #7F989A"
                              backgroundColor={bg2}
                              focusBorderColor="#E7EBEE"
                              name="business_type"
                              value={business_type}
                              onChange={(e) => onChange(e)}
                              required
                            />
                          </FormControl>
                        </Stack>
                        <Stack
                          spacing="5"
                          direction={{ base: "column", md: "row" }}
                        >
                          <FormControl>
                            <FormLabel
                              htmlFor="job_role"
                              color="#7F989A"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              Job Role
                            </FormLabel>
                            <Input
                              id="job_role"
                              type="text"
                              placeholder="Job Role"
                              borderRadius="10px"
                              border="1px solid #7F989A"
                              backgroundColor={bg2}
                              focusBorderColor="#E7EBEE"
                              name="job_role"
                              value={job_role}
                              onChange={(e) => onChange(e)}
                              required
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel
                              htmlFor="country_region"
                              color="#7F989A"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              Country/Region
                            </FormLabel>
                            <Input
                              id="country_region"
                              type="text"
                              placeholder="Country/Region"
                              borderRadius="10px"
                              border="1px solid #7F989A"
                              backgroundColor={bg2}
                              focusBorderColor="#E7EBEE"
                              name="country_region"
                              value={country_region}
                              onChange={(e) => onChange(e)}
                              required
                            />
                          </FormControl>
                        </Stack>
                        <Stack
                          spacing="5"
                          direction={{ base: "column", md: "row" }}
                        >
                          <FormControl>
                            <FormLabel
                              htmlFor="city"
                              color="#7F989A"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              City
                            </FormLabel>
                            <Input
                              id="city"
                              type="text"
                              placeholder="City"
                              borderRadius="10px"
                              border="1px solid #7F989A"
                              backgroundColor={bg2}
                              focusBorderColor="#E7EBEE"
                              name="city"
                              value={city}
                              onChange={(e) => onChange(e)}
                              required
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel
                              htmlFor="linkedin_url"
                              color="#7F989A"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              LinkedIn URL
                            </FormLabel>
                            <Input
                              id="linkedin_url"
                              type="url"
                              placeholder="LinkedIn URL"
                              borderRadius="10px"
                              border="1px solid #7F989A"
                              backgroundColor={bg2}
                              focusBorderColor="#E7EBEE"
                              name="linkedin_url"
                              value={linkedin_url}
                              onChange={(e) => onChange(e)}
                            />
                          </FormControl>
                        </Stack>
                        <FormControl>
                          <FormLabel
                            htmlFor="company_website"
                            color="#7F989A"
                            fontSize="13px"
                            fontWeight="500"
                          >
                            Company Website
                          </FormLabel>
                          <Input
                            id="company_website"
                            type="url"
                            placeholder="Company Website"
                            borderRadius="10px"
                            border="1px solid #7F989A"
                            backgroundColor={bg2}
                            focusBorderColor="#E7EBEE"
                            name="company_website"
                            value={company_website}
                            onChange={(e) => onChange(e)}
                          />
                        </FormControl>
                        </>)
                      }
                      </>
                    )}
                    <FormControl>
                      <FormLabel
                        htmlFor="password"
                        color="#7F989A"
                        fontSize="13px"
                        fontWeight="500"
                      >
                        Password
                      </FormLabel>
                      <InputGroup>
                        <InputRightElement>
                          <Divider
                            orientation="vertical"
                            height="25px"
                            border="solid 1px #CFD9E0"
                          />
                          <IconButton
                            variant="text"
                            aria-label={
                              isOpen ? "Mask password" : "Reveal password"
                            }
                            color="#7F989A"
                            icon={isOpen ? <FaEyeSlash /> : <FaEye />}
                            onClick={onClickReveal}
                          />
                        </InputRightElement>
                        <Input
                          id="password"
                          ref={mergeRef}
                          placeholder="Password"
                          name="password"
                          type={isOpen ? "text" : "password"}
                          autoComplete="new-password"
                          borderRadius="10px"
                          border="1px solid #7F989A"
                          backgroundColor={bg2}
                          focusBorderColor="#E7EBEE"
                          value={password}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        htmlFor="re_password"
                        color="#7F989A"
                        fontSize="13px"
                        fontWeight="500"
                      >
                        Confirm Password
                      </FormLabel>
                      <InputGroup>
                        <Input
                          id="re_password"
                          type={isOpen ? "text" : "password"}
                          placeholder="Confirm Password"
                          borderRadius="10px"
                          border="1px solid #7F989A"
                          backgroundColor={bg2}
                          focusBorderColor="#E7EBEE"
                          name="re_password"
                          value={re_password}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </InputGroup>
                    </FormControl>
                  </Stack>
                  <Stack spacing="6">
                    <Button
                      backgroundColor="#2D2D2D"
                      type="submit"
                      borderRadius="13px"
                      color="#F7FAFC"
                      colorScheme="gray"
                    >
                      Sign up
                    </Button>
                    <HStack justify="center">
                      <Text color={colorP}>Already have an account?</Text>
                      <Link as={RouterLink} to="/login" color={grayScales}>
                        Sign in
                      </Link>
                    </HStack>
                    {signupError && (
                      <FailedLoginModal
                        isOpen={signupError}
                        onClose={() => setSignupError(false)}
                        title="Signup Error"
                        message={errorSignupMessage}
                      />
                    )}
                  </Stack>
                </Stack>
              </form>
            </Box>
          </Stack>
        </Container>
      </Flex>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.auth.error,
  errorMessage: state.auth.errorMessage,
});

export default connect(mapStateToProps, { signup })(Signup);
