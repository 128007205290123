import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  HStack,
  Text,
  Flex,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import {
  FiPlus,
  FiSearch,
  FiTrash2,
  FiArrowLeft,
  FiArrowRight,
  FiEdit,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { deleteCategory, getAllCategories } from "../../actions/categoryActions";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import AddCategoryModal from "../../components/articles/AddCategoryModal";
import { useNavigate } from "react-router-dom";

export default function AdminCategoriesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categories = useSelector((state) => state.categoryReducer.categories);
  const loading = useSelector((state) => state.categoryReducer.loading);
  const error = useSelector((state) => state.categoryReducer.error);

  // Reuse the same light/dark mode colors from the products page
  const bg = useSelector((state) => state.themeReducer.backgroundColor1);
  const bg2 = useSelector((state) => state.themeReducer.backgroundColor2);
  const colorH = useSelector((state) => state.themeReducer.color1);
  const colorP = useSelector((state) => state.themeReducer.color2);
  const colorAdminP = useSelector((state) => state.themeReducer.colorAdmin2);
  const blueAdmin = useSelector((state) => state.themeReducer.blueAdmin);
  const grayScales = useSelector((state) => state.themeReducer.grayScales);

  const [currentPage, setCurrentPage] = useState(0);
  const [categoriesPerPage, setCategoriesPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
  const [isUpdateCategoryModalOpen, setIsUpdateCategoryModalOpen] = useState(false);
  const [categoryToUpdate, setCategoryToUpdate] = useState(null);

  useEffect(() => {
    dispatch(getAllCategories()).catch((error) => {
      if (error.response && error.response.status === 403) {
        navigate("/Not-Access");
      } else if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    });
  }, [dispatch, navigate]);

  useEffect(() => {
    let filtered = filterCategories(categories, searchTerm);
    setFilteredCategories(filtered);
    setCurrentPage(0);
  }, [categories, searchTerm]);

  const offset = currentPage * categoriesPerPage;
  const totalPages = Math.ceil(filteredCategories.length / categoriesPerPage);

  const filterCategories = (categories, term) => {
    if (!term) return categories;
    return categories.filter((category) => {
      const categoryName = category.name.toLowerCase();
      return categoryName.includes(term.toLowerCase());
    });
  };

  const handleDeleteClick = (category) => {
    setCategoryToDelete(category);
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteCategory(categoryToDelete.id))
      .then(() => {
        setIsModalOpen(false);
        setCategoryToDelete(null);
        dispatch(getAllCategories());
      })
      .catch((error) => console.error("Error deleting category:", error));
  };

  const currentCategories = filteredCategories.slice(offset, offset + categoriesPerPage);

  const handleUpdateClick = (category) => {
    setCategoryToUpdate(category);
    setIsUpdateCategoryModalOpen(true);
  };

  return (
    <Box minH="100vh" bg={bg}>
      <Box ml={{ base: 0, md: 60 }} p={4}>
        <Heading fontSize="24px" mb={4} color={blueAdmin}>
          Categories
        </Heading>

        <Flex justifyContent="center" alignItems="center" mb={4}>
          <HStack spacing={4} width="100%">
            <InputGroup flex={5}>
              <InputLeftElement pointerEvents="none">
                <FiSearch color="gray" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search categories"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                bg={bg2}
                borderRadius="16px"
                _focus={{ boxShadow: "none" }}
              />
            </InputGroup>
            <Button
              rightIcon={<FiPlus />}
              color="#FFFFFF"
              backgroundColor="#001A72"
              onClick={() => setIsAddCategoryModalOpen(true)}
            >
              Add category
            </Button>
          </HStack>
        </Flex>

        <Box
          overflowX="auto"
          bg={bg2}
          borderRadius="16px"
          border="solid 1px #EFF4FA"
          p={4}
        >
          <Text
            color={colorH}
            fontSize="18px"
            fontWeight="bold"
            mb="10px"
            flex="1"
          >
            List of categories
          </Text>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th width="70%" color={colorAdminP}>Name</Th> 
                <Th width="30%" textAlign="center" color={colorAdminP}>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Tr>
                  <Td colSpan={2}>
                    <Flex justifyContent="center" alignItems="center" h="200px">
                      <Spinner size="xl" />
                    </Flex>
                  </Td>
                </Tr>
              ) : (
                currentCategories.map((category) => (
                  <Tr key={category.id}>
                    <Td color={colorP}>{category.name}</Td>
                    <Td textAlign="center">
                      <HStack spacing={4} justifyContent="center">
                        <FiTrash2 color={blueAdmin} onClick={() => handleDeleteClick(category)} />
                        <FiEdit color={blueAdmin} onClick={() => handleUpdateClick(category)} />
                      </HStack>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="20px"
          bg={bg}
          p="10px"
          borderRadius="8px"
        >
          <Stack direction="row" spacing={4} alignItems="center">
            <Text mr="10px" color="#717171">
              Rows per page:
            </Text>
            <select
              onChange={(e) => {
                setCurrentPage(0);
                setCategoriesPerPage(parseInt(e.target.value, 10));
              }}
              value={categoriesPerPage}
              style={{
                background: bg,
                border: "none",
                borderRadius: "4px",
                padding: "5px",
                marginRight: "10px",
                color: "#717171",
              }}
            >
              {[5, 10, 15, 20].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <Text color="#717171">
              {offset + 1}–
              {Math.min(offset + categoriesPerPage, filteredCategories.length)} of{" "}
              {filteredCategories.length}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center" ml={10}>
            <FiArrowLeft
              onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))}
              color={currentPage === 0 ? bg : "#717171"}
              style={{ cursor: currentPage === 0 ? "not-allowed" : "pointer" }}
            />
            <FiArrowRight
              onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
              color={currentPage >= totalPages - 1 ? bg : "#717171"}
              style={{
                cursor: currentPage >= totalPages - 1 ? "not-allowed" : "pointer",
              }}
            />
          </Stack>
        </Box>
      </Box>

      {categoryToDelete && (
        <DeleteConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onDelete={handleDeleteConfirm}
          userName={categoryToDelete.name}
          pageName="Category"
        />
      )}
      <AddCategoryModal
        isOpen={isAddCategoryModalOpen}
        onClose={() => setIsAddCategoryModalOpen(false)}
        type="add"
      />
      <AddCategoryModal
        isOpen={isUpdateCategoryModalOpen}
        onClose={() => setIsUpdateCategoryModalOpen(false)}
        type="update"
        initialData={categoryToUpdate}
      />
    </Box>
  );
}
