import { getAuthConfig } from "../../actions/config"

export const getUserPic=async(id,setPic)=>{
    const config=getAuthConfig()
    const response=await fetch(`${process.env.REACT_APP_API_URL}/user/${id}`,{
        method:"GET",
        ...config
    })
    await response.json().then((data)=>{
        if(response.ok)
            setPic(data.profile_picture);
    })
}