import { Box, Button, Flex, FormLabel, Heading, Spacer, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import '../App.css'
import { useDispatch, useSelector } from 'react-redux'
import fetchProducts from '../services/api/productAPI/fetchProducts'
import { fetchCompany } from '../services/api/companyAPI/fetchCompany'
import ProductDetails from '../components/ProductDetails'
import { useNavigate } from 'react-router-dom'
import { getCart, setCart } from '../actions/cart'


const handleDetails=(id,setDetail)=>{
    setDetail({isOpen:true,id:id})
}

const ClientHomePage = () => {
    const navigate=useNavigate()
    const dispatch=useDispatch()

    const user=useSelector(state=>state.auth.user)
    const subscriptions=useSelector(state=>state.subscriptionReducer.subscriptions)
    const trials=useSelector(state=>state.trialReducer.trials)
    const companyId=useSelector(state=>state.userReducer.users[0]?.company)
    const [products,setProducts]=useState([])
    const [company,setCompany]=useState({})
    const [detail,setDetail]=useState({isOpen:false,id:null})

    const bg=useSelector(state=>state.themeReducer.backgroundColor1)
    const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
    const colorH=useSelector(state=>state.themeReducer.color1)
    const colorP=useSelector(state=>state.themeReducer.color2)
    const colorClicked=useSelector(state=>state.themeReducer.colorClicked)
    const grayScales=useSelector(state=>state.themeReducer.grayScales)

    const months=["January","February","Mars","April","May","June","July","August","September","October","November","December"]
   useEffect(()=>{
        const getProducts=async()=>{
            const res=await fetchProducts()
            setProducts(res)
        }
        getProducts()
    },[])

    useEffect(()=>{
        if(companyId)
            fetchCompany(companyId,setCompany)
    },[companyId])

    const handleClose=()=>{
        if(detail.isOpen)
            setDetail({isOpen:false,id:null})
    }
  return (
    <>
    {detail.isOpen && <ProductDetails id={detail.id}/>}
    <Box paddingTop="50px" paddingBottom="50px" backgroundColor={bg} minH="90vh" ml={{base:0,md:60}} position="relative" onClick={handleClose}>
        
        <Box
            w={{base:"80%",md:"65%",lg:"50%"}}
            ml={{base:"10%",md:"17.5%",lg:"25%"}}
            mb="35px"
            textAlign="center"
        >
            <Heading as="h1" mb="10px" fontSize={{base:"25px",sm:"28px"}}>DashBoard</Heading>
            <Text as="p" fontSize={{base:"14px",sm:"18px"}}>Welcome To Your DashBoard</Text>
        </Box>
        <Box
            w={{base:"98%",sm:"85%",lg:"75%"}}
            ml={{base:"1%",sm:"7.5%",lg:"12.5%"}}
            border="solid 1px #D9D9D9"
            backgroundColor={bg2}
            padding="25px"
            borderRadius="12px"
        >
            <Heading as="h2" fontSize={{base:"17px",sm:"20px"}}>Personal Informations</Heading>
            <Box mt="15px">
                <Flex mb="15px">
                    <InfosContainer colorH={colorH} colorP={colorP} label="Name" info={`${user?.first_name} ${user?.last_name}`}/>
                    <Spacer/>
                    <InfosContainer colorH={colorH} colorP={colorP} label="Email" info={`${user?.email}`}/>
                </Flex>
                {
                    user.type==="Company" &&
                    <Flex mb="15px">
                        <InfosContainer colorH={colorH} colorP={colorP} label="Country" info={company.country_region}/>
                        <Spacer/>
                        <InfosContainer colorH={colorH} colorP={colorP} label="City" info={company.city}/>
                    </Flex>
                }
                <Flex mb="15px">
                    <InfosContainer colorH={colorH} colorP={colorP} label="Phone Number" info={user?.phone_number}/>
                    <Spacer/>
                    <InfosContainer colorH={colorH} colorP={colorP} label="Job Role" info={user?.job_role}/>
                </Flex>
            </Box>
        </Box>
        <Flex 
            mt="35px"
            w={{base:"98%",sm:"85%",lg:"75%"}}
            ml={{base:"1%",sm:"7.5%",lg:"12.5%"}}
            wrap="wrap"
            rowGap="25px"
        >
            <Box
                border="solid 1px #D9D9D9" 
                borderRadius="12px"
                padding="25px 0px 25px 0px"
                bgColor={bg2}
                width={{base:"100%",lg:"48%"}}
            >
                <Heading as="h2" paddingLeft="25px" fontSize={{base:"17px",sm:"20px"}}>Subscriptions</Heading>
                {
                    subscriptions?.length!=0?
                        subscriptions?.map((sub)=>{
                            let product=products.filter(product=>product.id===sub.product)[0]
                            let ending_at=new Date(sub.ending_at)
                            return (
                                product && (<Flex as='div' padding="25px" direction="column" rowGap="20px" className='subFlex'>
                                    <Flex>
                                        <Text as="p" fontWeight="500" fontSize={{base:"16px",sm:"17px",md:"18px"}} color={colorH}>Plan</Text>
                                        <Spacer/>
                                        <Text as="p" fontWeight="400" fontSize={{base:"14px",sm:"15px",md:"16px"}} color={colorP}>{product.title}</Text>
                                    </Flex>
                                    <Flex>
                                        <Text as="p" fontWeight="500" fontSize={{base:"16px",sm:"17px",md:"18px"}} color={colorH}>Renewal Date</Text>
                                        <Spacer/>
                                        <Text as="p" fontWeight="400" fontSize={{base:"14px",sm:"15px",md:"16px"}} color={colorP}>{`${months[ending_at.getMonth()]} ${ending_at.getDate()}, ${ending_at.getFullYear()}`}</Text>
                                    </Flex>
                                    <Flex columnGap="35%">
                                        <Text as="p" fontWeight="500" fontSize={{base:"16px",sm:"17px",md:"18px"}} color={colorH} >Status</Text>
                                        <Spacer/>
                                        <StatusButton status={sub.is_active} isTrial={sub.is_trial} type="Subscription"/>
                                    </Flex>
                                </Flex>)
                            )
                        }):<Box textAlign="center" width="80%" marginLeft="10%" padding="10px" borderRadius="7px" marginTop="10%" bgColor={grayScales} color={grayScales==="black"?"white":"black"}><Text fontSize={{base:"14px",md:"17px"}}>No Subscriptions Yet</Text></Box>
                }
            </Box>
            <Spacer/>
            <Box
                border="solid 1px #D9D9D9" 
                borderRadius="12px"
                padding="25px 0px 25px 0px"
                bgColor={bg2}
                width={{base:"100%",lg:"48%"}}
                paddingInline="25px"
            >
                <Heading as="h2"  fontSize={{base:"17px",sm:"20px"}} marginBottom="25px">Products</Heading>
                <Flex direction='column' rowGap="15px">
                    <Flex paddingBottom="10px" borderBottom="solid 3px #E6E6E6">
                        <Box paddingLeft="10px" width="40%">
                            <Text as="p" fontSize={{base:"15px",lg:"18px"}} color={colorH} fontWeight="400">Name</Text>
                        </Box>
                        <Spacer/>
                        <Box paddingLeft="10px" width={{base:"24%",lg:"20%"}}>
                            <Text as="p" fontSize={{base:"15px",lg:"18px"}} color={colorH} fontWeight="400">Status</Text>
                        </Box>
                        <Spacer/>
                        <Box paddingLeft="10px" width={{base:"24%",lg:"20%"}}>
                            <Text as="p" fontSize={{base:"15px",lg:"18px"}} color={colorH} fontWeight="400">Action</Text>
                        </Box>
                    </Flex>
                    {
                        products.map((product)=>{
                            let clientSubscribed=subscriptions.filter(subscription=>subscription.product===product.id && subscription.is_active)[0]
                            let clientOnTrial=trials.filter(trial=>trial.product===product.id)[0]
                            return(
                                <Flex paddingBottom="10px" key={product.id}>
                                    <Box paddingLeft="10px" width="40%">
                                        <Text as="p" fontSize={{base:"14px",sm:"15px",md:"16px"}} color={colorH} fontWeight="500">{product.title}</Text>
                                    </Box>
                                    <Spacer/>
                                    <Box width="24%">
                                        <StatusButton status={product.is_active && clientSubscribed } isTrial={clientOnTrial?true:false} colorClicked={colorClicked} type="Product"/>
                                    </Box>
                                    <Spacer/>
                                    <Box width="24%">
                                        <ActionButton content={product.is_active && clientSubscribed} id={product.id} navigate={navigate} prod={product.title} dispatch={dispatch} setDetail={setDetail}/>
                                    </Box>
                                </Flex>
                            )
                        })
                    }
                </Flex>
            </Box>
        </Flex>
    </Box>
    </>
  )
}
 const InfosContainer=({label,info,colorH,colorP})=>{
    
    return (
        <Box width={{base:"47%",sm:"48%"}} borderBottom="solid 1px gray">
            <FormLabel as="p" fontSize={{base:"16px",sm:"18px"}} color={colorH}>{label}</FormLabel>
            <Text as="p" paddingBottom="10px" color={colorP} fontSize={{base:"14px",sm:'16px'}}>{info}</Text>
        </Box>
    )
 }

 const StatusButton=({status,type,colorClicked,isTrial})=>{
    let color=""
    let bgColor=""
    let content=""
    if(status){
        color="rgba(37, 204, 118, 1)"
        bgColor="rgba(17, 199, 0, 0.1)"
        content="Active"
    }else if(isTrial){
        color="#D0C700"
        bgColor="#FFFA90"
        content="Free Trial"
    }else if(!status && type==="Subscription"){
        color="rgba(200, 0, 0, 1)" 
        bgColor="rgba(200, 0, 0, 0.2)" 
        content="Expired"
    }else if(!status && type==="Product"){
        color={colorClicked}
        bgColor="rgba(0, 89, 200, 0.2)"
        content="Available"
    }

    return (
        <Button padding="2px 4px 2px 4px" bgColor={bgColor} color={color} borderRadius="10px" fontSize={{base:"13px",lg:"15px"}} w="95%" h="25px">{content}</Button>
    )
 }

 const ActionButton=({content,id,setDetail,navigate,prod,dispatch})=>{
    const handleClick=()=>{
        if(!content){
            if((/terminal/i).test(prod) || (/inteligence/i).test(prod))
                navigate("/wait-list")
            else{
                dispatch(setCart(id,"monthly"))
                navigate("/checkout")
            }
        }else
            handleDetails(id,setDetail)
    }
    
    return(
        <Button 
            w="100%" 
            padding="2px 4px 2px 4px"  
            h="25px" 
            fontSize={{base:"13px",lg:"15px"}} 
            borderRadius="8px"  
            fontWeight="500" 
            bgColor="black" 
            color="white" 
            onClick={handleClick}
        >
        {content?"Details":((/terminal/i).test(prod) || (/inteligence/i).test(prod)?"Pre-Order":"Pay Now")}
        </Button>
    )
 }

export default ClientHomePage