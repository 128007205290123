import { Box } from "@chakra-ui/react";

export default function StatusDots({ status }) {
    const colors = {
        "Pending": "yellow.500",
        "Accepted": "green.500",
        "Rejected": "red.500",
    };

    return (
        <Box 
            borderRadius="50%" 
            width="12px" 
            height="12px" 
            bgColor={colors[status]} 
        ></Box>
    );
}
