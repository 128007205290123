import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
  Stack,
  Flex
} from "@chakra-ui/react";
import {
  FiPlus,
  FiSearch,
  FiChevronDown,
  FiArrowLeft,
  FiArrowRight,
} from "react-icons/fi";
import AddPreOrderModal from "../components/AddPreOrderModal";
import { deletePreOrder, getAllPreOrders } from "../actions/preorderActions";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import { FiDownload } from "react-icons/fi";
import { downloadPreOrders } from "../actions/preorderActions";

export default function AdminPreOrdersPage() {
  //for Dark/light mode
  const bg=useSelector(state=>state.themeReducer.backgroundColor1);
  const bg2=useSelector(state=>state.themeReducer.backgroundColor2);
  const colorH=useSelector(state=>state.themeReducer.color1);
  const colorP=useSelector(state=>state.themeReducer.color2);
  const colorAdminP=useSelector(state=>state.themeReducer.colorAdmin2);
  const grayScales=useSelector(state=>state.themeReducer.grayScales);
  const blueAdmin=useSelector(state=>state.themeReducer.blueAdmin);

  const dispatch = useDispatch();
  const [preOrders, setPreOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [preOrdersPerPage, setPreOrdersPerPage] = useState(5);
  const [sortOption, setSortOption] = useState("first_name");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPreOrders, setFilteredPreOrders] = useState([]);
  const [isAddPreOrderModalOpen, setIsAddPreOrderModalOpen] = useState(false);
  const [preOrderToUpdate, setPreOrderToUpdate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [preOrderToDelete, setPreOrderToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getStatusColor=(status)=>{
    switch(status){
      case "Accepted":
        return { color: "#34A853", backgroundColor: "#34A8531A" };
      case "Rejected":
        return { color: "#E84646", backgroundColor: "#E846461A" };
      case "Pending":
        return { color: "#0E5FD9", backgroundColor: "#0E5FD91A" };
      case "Forwarded":
        return { color: "#FF9500", backgroundColor: "#FF95001A" };
      default:
        return { color: "#6B6B6B", backgroundColor: "transparent" };
    }
  }
  const fetchPreOrders = async () => {
    try {
      setLoading(true);
      const data = await dispatch(getAllPreOrders());
      setPreOrders(data);
      setLoading(false);
      setError(null);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    fetchPreOrders();
  }, [dispatch]);

  useEffect(() => {
    const filtered = filterPreOrders(preOrders, searchTerm);
    setFilteredPreOrders(sortPreOrders(filtered, sortOption));
    setCurrentPage(0);
  }, [preOrders, searchTerm, sortOption]);

  const sortPreOrders = (preOrders, option) => {
    return [...preOrders].sort((a, b) => {
      const aValue = a[option] || "";
      const bValue = b[option] || "";

      if (aValue < bValue) {
        return -1;
      }
      if (aValue > bValue) {
        return 1;
      }
      return 0;
    });
  };

  const filterPreOrders = (preOrders, term) => {
    if (!term) return preOrders;
    return preOrders.filter((preOrder) => {
      const firstName = preOrder.first_name?.toLowerCase() || "";
      const lastName = preOrder.last_name?.toLowerCase() || "";
      const email = preOrder.email?.toLowerCase() || "";
      const phoneNumber = preOrder.phone_number?.toLowerCase() || "";

      return (
        firstName.includes(term.toLowerCase()) ||
        lastName.includes(term.toLowerCase()) ||
        email.includes(term.toLowerCase()) ||
        phoneNumber.includes(term.toLowerCase())
      );
    });
  };

  const handleAddPreOrderClick = () => {
    setPreOrderToUpdate(null);
    setIsAddPreOrderModalOpen(true);
  };

  const handleDeleteClick = (preOrder) => {
    setPreOrderToDelete(preOrder);
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(deletePreOrder(preOrderToDelete.id))
      .then(() => {
        fetchPreOrders();
        setIsModalOpen(false);
        setPreOrderToDelete(null);
      })
      .catch((error) => console.error("Error deleting pre-order:", error));
  };

  const handleDownload = () => {
    dispatch(downloadPreOrders());
  };

  const offset = currentPage * preOrdersPerPage;
  const totalPages = Math.ceil(filteredPreOrders.length / preOrdersPerPage);

  const currentPreOrders = filteredPreOrders.slice(
    offset,
    offset + preOrdersPerPage
  );

  const handleUpdateClick = (preOrder) => {
    setPreOrderToUpdate(preOrder);
    setIsAddPreOrderModalOpen(true);
  };

  const handleModalClose = () => {
    setIsAddPreOrderModalOpen(false);
    setPreOrderToUpdate(null);
    fetchPreOrders();
  };

  return (
    <Box minH="100vh" bg={bg}>
      <Box ml={{ base: 0, md: 60 }} p={4}>
        <Heading fontSize="24px" mb={4} color={blueAdmin}>
          PreOrders
        </Heading>

        <Flex mb={4} align="center" wrap={"wrap"}>
          <HStack width={{ base: "100%", md: '100%', lg: '80%' }}>
            <InputGroup width={{ base: "60%", lg: "75%" }}>
              <InputLeftElement pointerEvents="none">
                <FiSearch color="gray" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search preorders"
                bg={bg2}
                borderRadius="16px"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                _focus={{ boxShadow: "none" }}
              />
            </InputGroup>
            <HStack
              spacing={0}
              width="25%"
              padding="12px 12px 12px 20px"
            >
              <Text color={colorH} fontSize={{ base: "12px", md: "13px", lg: "15px" }} fontWeight="600">
                Sort by
              </Text>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<FiChevronDown color={grayScales} />}
                  bg={bg}
                  _hover={{ bg: { bg } }}
                  _active={{ bg: { bg } }}
                  _focus={{ boxShadow: "none" }}
                />
                <MenuList>
                  <MenuItem onClick={() => setSortOption("first_name")}>
                    First Name
                  </MenuItem>
                  <MenuItem onClick={() => setSortOption("last_name")}>
                    Last Name
                  </MenuItem>
                  <MenuItem onClick={() => setSortOption("email")}>
                    Email
                  </MenuItem>
                  <MenuItem onClick={() => setSortOption("phone_number")}>
                    Phone Number
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </HStack>
          <Button
            leftIcon={<FiPlus />}
            onClick={handleAddPreOrderClick}
            bg="#001A72"
            color="#FFFFFF"
            _hover={{ bg: "#0044D6" }}
            borderRadius="4px"
            width={{base:"100%",md:'100%',lg:'20%'}}
            fontSize={{base:"14px",md:"16px",lg:"18px"}}
          >
            Add PreOrder
          </Button>
        </Flex>

        {loading ? (
          <Spinner size="xl" color="#001A72" />
        ) : error ? (
          <Text color="red.500">Error fetching preorders: {error.message}</Text>
        ) : (
          <Box bg={bg2} borderRadius="16px" p={4}>
            {/* Flex container for title and download button */}
            <Flex justifyContent="space-between" alignItems="center" mb="16px">
              <Text
                color={colorH}
                fontSize="18px"
                fontWeight="bold"
              >
                List of PreOrders
              </Text>
              <Button
                leftIcon={<FiDownload />}
                onClick={handleDownload}
                bg="#28A745"
                color="#FFFFFF"
                _hover={{ bg: "#218838" }}
                borderRadius="4px"
                fontSize={{ base: "14px", md: "16px", lg: "18px" }}
              >
                Excel
              </Button>
            </Flex>

            <Box maxW={"100%"} overflowY={"scroll"}>
              <Table variant="simple" borderRadius="30px">
                <Thead bg={"#b8c3d3"}>
                  <Tr>
                    <Th color={colorAdminP} borderTopLeftRadius="8px" fontWeight="600">First Name</Th>
                    <Th color={colorAdminP} fontWeight="600">Last Name</Th>
                    <Th color={colorAdminP}>Email</Th>
                    <Th color={colorAdminP}>Phone</Th>
                    <Th color={colorAdminP}>Status</Th>
                    <Th color={colorAdminP} borderTopRightRadius="8px" fontWeight="600">
                      Actions
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentPreOrders.map((preOrder) => {
                    const statusDesc=getStatusColor(preOrder.status)
                    return (
                    <Tr key={preOrder.id}>
                      <Td color={colorP} fontWeight="600">{preOrder.first_name}</Td>
                      <Td color={colorP} fontWeight="600">{preOrder.last_name}</Td>
                      <Td><Text color={colorP} fontWeight="600">{preOrder.email}</Text></Td>
                      <Td color={colorP} fontWeight="600">{preOrder.phone_number}</Td>
                      <Td  fontWeight="600" ><Button width={"80px"} color={statusDesc.color} fontSize={"14px"} bgColor={statusDesc.backgroundColor}>{preOrder.status}</Button></Td>
                      <Td color={colorP} fontWeight="600">
                        <Stack direction="row" spacing={4}>
                          <Button
                            size="sm"
                            onClick={() => handleUpdateClick(preOrder)}
                            bg="#001A72"
                            color={"white"}
                          >
                            Update
                          </Button>
                          <Button
                            size="sm"
                            bg="#FF1111"
                            color={"white"}
                            onClick={() => handleDeleteClick(preOrder)}
                          >
                            Delete
                          </Button>
                        </Stack>
                      </Td>
                    </Tr>)
                  })}
                </Tbody>
              </Table>
            </Box>
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="20px"
          bg={bg}
          p="10px"
          borderRadius="8px"
        >
          <Stack direction="row" spacing={4} alignItems="center">
            <Text mr="10px" color="#717171">
              Rows per page:
            </Text>
            <select
              onChange={(e) => {
                setCurrentPage(0);
                setPreOrdersPerPage(parseInt(e.target.value, 10));
              }}
              value={preOrdersPerPage}
              style={{
                background: bg,
                border: "none",
                borderRadius: "4px",
                padding: "5px",
                marginRight: "10px",
                color: "#717171",
              }}
            >
              {[5, 10, 15, 20].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <Text color="#717171">
              {offset + 1}–
              {Math.min(offset + preOrdersPerPage, filteredPreOrders.length)} of{" "}
              {filteredPreOrders.length}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center" ml={10}>
            <FiArrowLeft
              onClick={() =>
                setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))
              }
              color={currentPage === 0 ? bg : "#717171"}
              style={{ cursor: currentPage === 0 ? "not-allowed" : "pointer" }}
            />
            <FiArrowRight
              onClick={() =>
                setCurrentPage((prevPage) =>
                  Math.min(prevPage + 1, totalPages - 1)
                )
              }
              color={currentPage >= totalPages - 1 ? bg : "#717171"}
              style={{
                cursor:
                  currentPage >= totalPages - 1 ? "not-allowed" : "pointer",
              }}
            />
          </Stack>
        </Box>
      </Box>

      <AddPreOrderModal
        isOpen={isAddPreOrderModalOpen}
        onClose={handleModalClose}
        type={preOrderToUpdate ? "update" : "create"}
        initialData={preOrderToUpdate}
      />
      {preOrderToDelete && (
        <DeleteConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onDelete={handleDeleteConfirm}
          userName={`${preOrderToDelete.first_name} ${preOrderToDelete.last_name}`}
          pageName="Pre-Order"
        />
      )}
    </Box>
  );
}
