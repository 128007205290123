import axios from 'axios';
import {
  GET_CONTACT_US_SUCCESS,
  GET_CONTACT_US_FAIL,
  GET_CONTACT_US_BY_ID_SUCCESS,
  GET_CONTACT_US_BY_ID_FAIL,
  CREATE_CONTACT_US_SUCCESS,
  CREATE_CONTACT_US_FAIL,
  UPDATE_CONTACT_US_SUCCESS,
  UPDATE_CONTACT_US_FAIL,
  DELETE_CONTACT_US_SUCCESS,
  DELETE_CONTACT_US_FAIL
} from './types';
import { getAuthConfig } from './config'; 

export const getAllContactUs = () => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/contact/us/all`, config);
    dispatch({
      type: GET_CONTACT_US_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_CONTACT_US_FAIL,
      payload: err.message
    });
  }
};

export const getContactUsById = (id) => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/contact/us/${id}`, config);
    dispatch({
      type: GET_CONTACT_US_BY_ID_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_CONTACT_US_BY_ID_FAIL,
      payload: err.message
    });
  }
};

export const createContactUs = (contactData) => async dispatch => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/contact/us/save`, contactData);
    dispatch({
      type: CREATE_CONTACT_US_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CREATE_CONTACT_US_FAIL,
      payload: err.response.data.message,
    });
    throw err.response.data.message;
  }
};

export const updateContactUs = (id, contactData) => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/contact/us/${id}/update`, contactData, config);
    dispatch({
      type: UPDATE_CONTACT_US_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATE_CONTACT_US_FAIL,
      payload: err.message
    });
  }
};

export const deleteContactUs = (id) => async dispatch => {
  try {
    const config = getAuthConfig();
    await axios.delete(`${process.env.REACT_APP_API_URL}/contact/us/${id}/delete`, config);
    dispatch({
      type: DELETE_CONTACT_US_SUCCESS,
      payload: id
    });
  } catch (err) {
    dispatch({
      type: DELETE_CONTACT_US_FAIL,
      payload: err.message
    });
  }
};
