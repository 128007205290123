import {
    GET_CONTACT_US_SUCCESS,
    GET_CONTACT_US_FAIL,
    GET_CONTACT_US_BY_ID_SUCCESS,
    GET_CONTACT_US_BY_ID_FAIL,
    CREATE_CONTACT_US_SUCCESS,
    CREATE_CONTACT_US_FAIL,
    UPDATE_CONTACT_US_SUCCESS,
    UPDATE_CONTACT_US_FAIL,
    DELETE_CONTACT_US_SUCCESS,
    DELETE_CONTACT_US_FAIL
  } from '../actions/types';
  
  const initialState = {
    contacts: [],
    contact: null,
    loading: true,
    error: null
  };
  
  const contactUsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_CONTACT_US_SUCCESS:
        return {
          ...state,
          contacts: action.payload,
          loading: false,
          error: null
        };
      case GET_CONTACT_US_FAIL:
        return {
          ...state,
          contacts: [],
          loading: false,
          error: action.payload
        };
      case GET_CONTACT_US_BY_ID_SUCCESS:
        return {
          ...state,
          contact: action.payload,
          loading: false,
          error: null
        };
      case GET_CONTACT_US_BY_ID_FAIL:
        return {
          ...state,
          contact: null,
          loading: false,
          error: action.payload
        };
      case CREATE_CONTACT_US_SUCCESS:
        return {
          ...state,
          contacts: [...state.contacts, action.payload],
          loading: false,
          error: null
        };
      case CREATE_CONTACT_US_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        };
      case UPDATE_CONTACT_US_SUCCESS:
        return {
          ...state,
          contacts: state.contacts.map(contact =>
            contact.id === action.payload.id ? action.payload : contact
          ),
          loading: false,
          error: null
        };
      case UPDATE_CONTACT_US_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        };
      case DELETE_CONTACT_US_SUCCESS:
        return {
          ...state,
          contacts: state.contacts.filter(contact => contact.id !== action.payload),
          loading: false,
          error: null
        };
      case DELETE_CONTACT_US_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        };
      default:
        return state;
    }
  };
  
  export default contactUsReducer;
  