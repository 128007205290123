import {
  Flex,
  Heading,
  Spinner,
  Text,
  Box,
  Divider,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { transparentize } from "@chakra-ui/theme-tools";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import fetchJob from "../services/api/careersAPI/fetchJob";
import JobApply from "../components/careers/JobApply";
import jobBg from "../assets/jobBg.svg";

export default function Job() {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [loadingJob, setLoadingJob] = useState(true);
  const bgColor = transparentize("#2d2d2d", 0.7);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const result = await fetchJob(jobId);
        setJob(result);
      } catch (error) {
        console.error("Error fetching article:", error);
      } finally {
        setLoadingJob(false);
      }
    };

    if (jobId) {
      fetchJobData();
    }
  }, [jobId]);

  const handleBack = (id) => {
    navigate(`/careers`);
  };

  if (loadingJob) {
    return <Spinner size="xl" />;
  }

  return (
    <Flex direction="column">
      <Flex
        height={isLargerThan768 ? "60vh" : "52vh"}
        backgroundImage={jobBg}
        backgroundRepeat={"no-repeat"}
        width={"100%"}
      >
        <Flex
          direction={"column"}
          bg={bgColor}
          width={"100%"}
          justify="center"
          align={isLargerThan768 ? "" : "center"}
        >
          <Box padding={isLargerThan768 ? "52px" : "16px"}>
            <Heading
              color="#FFFFFF"
              fontSize={isLargerThan768 ? "80px" : "36px"}
            >
              {job.job_title}
            </Heading>
            <Text
              color={"#FFFFFF"}
              fontSize={isLargerThan768 ? "32px" : "24px"}
            >
              {" "}
              {job.location}{" "}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Flex
        padding={"40px"}
        justify={"space-around"}
        direction={isLargerThan768 ? "row" : "column"}
        gap={isLargerThan768 ? "" : "20px"}
      >
        <Flex
          direction={"column"}
          padding={isLargerThan768 ? "20px" : ""}
          gap={"20px"}
          width={isLargerThan768 ? "24%" : "100%"}
        >
          <Flex
            direction="column"
            padding={"40px"}
            gap={"12px"}
            width={"100%"}
            boxShadow="0.1px 0.1px 1px 0.1px black"
          >
            <Box>
              <Heading fontSize={"32px"}>Location</Heading>
              <Text>{job.location}</Text>
            </Box>
            <Divider />
            <Box>
              <Heading fontSize={"32px"}>Job Type</Heading>
              <Text>{job.job_type}</Text>
            </Box>
          </Flex>
          <Button
            bg={"#2D2D2D"}
            color={"#FFFFFF"}
            height={"40px"}
            _hover={"none"}
            onClick={handleBack}
          >
            {" "}
            <Flex
              gap={"24px"}
              align={"center"}
              justify={"center"}
              width={"100%"}
              fontSize={"20px"}
            >
              {" "}
              <ArrowBackIcon /> Back
            </Flex>{" "}
          </Button>
        </Flex>
        <Flex
          direction={"column"}
          padding={isLargerThan768 ? "20px" : ""}
          gap={"12px"}
          width={isLargerThan768 ? "52%" : "100%"}
        >
          <Heading fontSize={isLargerThan768 ? "48px" : "36px"}>
            Description
          </Heading>
          <Text fontWeight={700}>{job.job_title}</Text>
          <Text fontWeight={700} fontSize={isLargerThan768 ? "16px" : "12px"}>
            X Capital - {job.location} - {job.job_type}
          </Text>
          <Text>{job.description}</Text>
        </Flex>
      </Flex>
        <JobApply />
    </Flex>
  );
}
