import {
  GET_CANDIDATES_SUCCESS,
  GET_CANDIDATES_FAIL,
  GET_CANDIDATE_BY_ID_SUCCESS,
  GET_CANDIDATE_BY_ID_FAIL,
  CREATE_CANDIDATE_SUCCESS,
  CREATE_CANDIDATE_FAIL,
  UPDATE_CANDIDATE_SUCCESS,
  UPDATE_CANDIDATE_FAIL,
  DELETE_CANDIDATE_SUCCESS,
  DELETE_CANDIDATE_FAIL,
  GET_CANDIDATES_BY_CAREER_SUCCESS,
  GET_CANDIDATES_BY_CAREER_FAIL
} from '../actions/types';

const initialState = {
  candidates: [],
  candidate: null,  
  loading: true,
  error: null
};

const candidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CANDIDATES_SUCCESS:
      return {
        ...state,
        candidates: action.payload,
        loading: false,
        error: null
      };
    case GET_CANDIDATES_FAIL:
      return {
        ...state,
        candidates: [],
        loading: false,
        error: action.payload
      };
    case GET_CANDIDATE_BY_ID_SUCCESS:
      return {
        ...state,
        candidate: action.payload,
        loading: false,
        error: null
      };
    case GET_CANDIDATE_BY_ID_FAIL:
      return {
        ...state,
        candidate: null,
        loading: false,
        error: action.payload
      };
    case GET_CANDIDATES_BY_CAREER_SUCCESS:
      return {
        ...state,
        candidates: action.payload,
        loading: false,
        error: null
      };
    case GET_CANDIDATES_BY_CAREER_FAIL:
      return {
        ...state,
        candidates: [],
        loading: false,
        error: action.payload
      };
    case CREATE_CANDIDATE_SUCCESS:
      return {
        ...state,
        candidates: [...state.candidates, action.payload],
        error: null
      };
    case CREATE_CANDIDATE_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case UPDATE_CANDIDATE_SUCCESS:
      return {
        ...state,
        candidates: state.candidates.map(candidate =>
          candidate.id === action.payload.id ? action.payload : candidate
        ),
        error: null
      };
    case UPDATE_CANDIDATE_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case DELETE_CANDIDATE_SUCCESS:
      return {
        ...state,
        candidates: state.candidates.filter(candidate => candidate.id !== action.payload),
        error: null
      };
    case DELETE_CANDIDATE_FAIL:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default candidateReducer;