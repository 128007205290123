import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import SidebarWithHeader from '../components/SideBar'
import { useSelector , useDispatch} from 'react-redux';
import { Spinner,Flex,Box } from '@chakra-ui/react';
import { load_user } from '../actions/auth';
import { getTrialByClient, updtaeTrialStatus } from '../actions/trial';
import { getSubscriptionByClient, getSubscriptions } from '../actions/subscription'
import { getUserById } from '../actions/user'
import { getProducts } from '../actions/product';

const ClientLayout = () => {
  const navigate = useNavigate()
  const dispatch=useDispatch()
  const role=useSelector(state=>state.auth.user?.role)
  const userId=useSelector(state=>state.auth.user?.id)
  const isAuthenticated=useSelector(state=>state.auth.isAuthenticated)
  const usersTrial=useSelector(state=>state.trialReducer.trials)

  useEffect(() => {
    if (role && role !== "Client")
      navigate("/Not-Access")
    if(!isAuthenticated)
      navigate("/login")
  },[role,isAuthenticated])
  useEffect(()=>{
    const idT=setTimeout(()=>{
      dispatch(load_user())
    },500)
    dispatch(getProducts())
    return ()=>clearTimeout(idT)
  },[])
  useEffect(()=>{
    if(userId){
      dispatch(getTrialByClient(userId))
      dispatch(getSubscriptionByClient(userId))
      dispatch(getUserById(userId))
    }
  },[userId])
  useEffect(()=>{
    if(usersTrial && usersTrial.length!==0){
      const dateFin=new Date(usersTrial[0].ending_at)
      const dateNow=new Date()
      if(dateFin.getTime() < dateNow.getTime()){
        dispatch(updtaeTrialStatus(usersTrial[0].id))
        dispatch(getTrialByClient(userId))
      }
    }
  },[usersTrial])

  return (
    <Box>
      {
        role==="Client"?
        <>
          <SidebarWithHeader/>
          <Outlet/>
        </>
        :
        <Flex height="100vh" align="center" justify="space-around"><Spinner width="50px" height="50px"/></Flex>
      }
    </Box>
  )
}

export default ClientLayout