import {
    GET_SUB_ARTICLES_SUCCESS,
    GET_SUB_ARTICLES_FAIL,
    GET_SUB_ARTICLE_BY_ID_SUCCESS,
    GET_SUB_ARTICLE_BY_ID_FAIL,
    CREATE_SUB_ARTICLE_SUCCESS,
    CREATE_SUB_ARTICLE_FAIL,
    UPDATE_SUB_ARTICLE_SUCCESS,
    UPDATE_SUB_ARTICLE_FAIL,
    DELETE_SUB_ARTICLE_SUCCESS,
    DELETE_SUB_ARTICLE_FAIL,
    GET_SUB_ARTICLES_BY_ARTICLE_SUCCESS,
    GET_SUB_ARTICLES_BY_ARTICLE_FAIL
  } from '../actions/types';
  
  const initialState = {
    subArticles: [],
    subArticle: null,
    loading: true,
    error: null
  };
  
  const subArticleReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_SUB_ARTICLES_SUCCESS:
        return {
          ...state,
          subArticles: action.payload,
          loading: false,
          error: null
        };
      case GET_SUB_ARTICLES_FAIL:
        return {
          ...state,
          subArticles: [],
          loading: false,
          error: action.payload
        };
      case GET_SUB_ARTICLE_BY_ID_SUCCESS:
        return {
          ...state,
          subArticle: action.payload,
          loading: false,
          error: null
        };
      case GET_SUB_ARTICLE_BY_ID_FAIL:
        return {
          ...state,
          subArticle: null,
          loading: false,
          error: action.payload
        };
      case GET_SUB_ARTICLES_BY_ARTICLE_SUCCESS:
        return {
          ...state,
          subArticles: action.payload,
          loading: false,
          error: null
        };
      case GET_SUB_ARTICLES_BY_ARTICLE_FAIL:
        return {
          ...state,
          subArticles: [],
          loading: false,
          error: action.payload
        };
      case CREATE_SUB_ARTICLE_SUCCESS:
        return {
          ...state,
          subArticles: [...state.subArticles, action.payload],
          error: null
        };
      case CREATE_SUB_ARTICLE_FAIL:
        return {
          ...state,
          error: action.payload
        };
      case UPDATE_SUB_ARTICLE_SUCCESS:
        return {
          ...state,
          subArticles: state.subArticles.map(subArticle =>
            subArticle.id === action.payload.id ? action.payload : subArticle
          ),
          error: null
        };
      case UPDATE_SUB_ARTICLE_FAIL:
        return {
          ...state,
          error: action.payload
        };
      case DELETE_SUB_ARTICLE_SUCCESS:
        return {
          ...state,
          subArticles: state.subArticles.filter(subArticle => subArticle.id !== action.payload),
          error: null
        };
      case DELETE_SUB_ARTICLE_FAIL:
        return {
          ...state,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default subArticleReducer;
  