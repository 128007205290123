import React from 'react'
import { Flex, Box, Text,List, ListItem, UnorderedList, Heading } from '@chakra-ui/react'


function IntellectualPropertyPolicy() {
  return (
    <>
       <Flex direction={"column"} p={10} mb="2em">
        <Box mb="2em">
            <Heading size={{base:"lg", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>Intellectual Property Policy</Heading>
            <Text mb="1em"><b>Effective Date:</b> July 4, 2024</Text>
            <Text textAlign="left">X CAPITAL ("we," "us," "our") respects intellectual property rights and expects users to do the same. This Intellectual Property Policy outlines our practices regarding intellectual property.</Text>
        </Box>

        <Flex direction={"column"} gap={5}>
            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>1. Ownership</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>All content on our website, including text, graphics, logos, and software, is the property of X CAPITAL and is protected by copyright and trademark laws.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>2. Use of Content</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>You may not use, reproduce, or distribute any content from our website without our express permission.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>3. Reporting Infringements</Heading>
            <List>                    
                <Text>If you believe that your intellectual property rights have been infringed, please contact us at: contact@xcapitalterminal.com with the following information:</Text>
                <UnorderedList ml="3em">
                    <ListItem>A description of where the material is located on our website.</ListItem>
                    <ListItem>Your contact information.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>4. Contact Us</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem listStyleType="none">If you have any questions about our Intellectual Property Policy, please contact us at: contact@xcapitalterminal.com or call us at +212 632331441 or +212 632321083.</ListItem>
                </UnorderedList>
            </List>
        </Flex>
      </Flex>
    </>
  )
}

export default IntellectualPropertyPolicy
