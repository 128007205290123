import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  useMediaQuery,
  VStack,
  Card,
  CardHeader,
  Tooltip,
  Stack,
  Center,
  useBreakpointValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { CheckCircleIcon } from "@chakra-ui/icons";
import SolutionHero from "../../assets/SolutionHero.png";
import XCapitalVideo from "../../assets/XCapitalVideo.mp4";
import SolutionQuestion from "../../components/questions/SolutionQuestions";
import { useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";

const AnimatedFeatureCard = ({ title, description, delay }) => {

 
  
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const cardWidth = useBreakpointValue({
    base: "90%", // Mobile
    sm: "95%", // Small screens
    md: "85%", // Medium screens
    lg: "1200px", // Large screens and above
  });

  const cardPadding = useBreakpointValue({
    base: "4",
    sm: "6",
  });

  const titleFontSize = useBreakpointValue({
    base: "sm",
    sm: "md",
  });

  const descriptionFontSize = useBreakpointValue({
    base: "xs",
    sm: "sm",
    md: "md",
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.7, delay }}
    >
      <Card width={cardWidth} boxShadow="lg" mx="auto">
        <CardHeader padding={cardPadding}>
          <Flex
            alignItems="flex-start"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Flex alignItems="center" w={"40%"} mb={{ base: 2, sm: 0 }} mr={{ sm: 4 }}>
              <CheckCircleIcon color="green.500" mr="12px" boxSize={6} />
              <Heading size={titleFontSize}>{`${title}:`}</Heading>
            </Flex>
            <Box w={"55%"}>
              <Text fontSize={descriptionFontSize}>{description}</Text>
            </Box>
          </Flex>
        </CardHeader>
      </Card>
    </motion.div>
  );
};

const Terminal = () => {
  const navigate = useNavigate()
  const [isLargerThan840] = useMediaQuery("(min-width: 840px)");
   
  const bgColor=useSelector(state=>state.themeReducer.backgroundColor1)
  const grayScales=useSelector(state=>state.themeReducer.grayScales)

  const faqList = [
    {
      question: "What is X Capital Terminal?",
      answer: "X Capital Terminal is an advanced financial analysis platform that provides real-time data, historical data, advanced analytics, customizable dashboards, and portfolio management tools for investors and financial institutions.",
    },
    {
      question: "Who can benefit from using X Capital Terminal?",
      answer: "X Capital Terminal is designed for both B2C and B2B audiences, including individual investors, financial analysts, and institutional investors who need comprehensive market insights and tools to make informed investment decisions.",
    },
    {
      question: "What features does X Capital Terminal offer?",
      answer: "The terminal offers real-time and historical data, advanced analytics, customizable dashboards, portfolio management tools, and integrated news feeds to help users stay updated on market trends.",
    },
    {
      question: "How is the data in X Capital Terminal sourced?",
      answer: "Data is sourced from reputable financial markets and exchanges, providing users with accurate and up-to-date information.",
    },
    {
      question: "How much does X Capital Terminal cost?",
      answer: "Pricing for X Capital Terminal is tailored based on the needs of the user. Please contact X Capital for a detailed quote.",
    },
    {
      question: "Can I try X Capital Terminal before purchasing?",
      answer: "Yes, you can get a free trial.",
    },
    {
      question: "How do I customize the dashboard in X Capital Terminal?",
      answer: "Users can customize their dashboards by selecting the data, charts, and analytics tools most relevant to their investment strategies.",
    },
    {
      question: "What support options are available for X Capital Terminal?",
      answer: "Users can access customer support via email and also find helpful resources in the platform's support section.",
    },
  ];

  return (
    <Box>
      {/* Hero Section */}
      <Box
        height="648px"
        position="relative"
        backgroundImage={`url(${SolutionHero})`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition={
          isLargerThan840 ? "center" : "calc(100% - 70%) bottom"
        }
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="rgba(0, 0, 0, 0.4)"
        />
        <Flex
          position="absolute"
          bottom="15%"
          left="5%"
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-start"
          maxWidth={isLargerThan840 ? "45%" : "80%"}
          color="white"
        >
          <Heading
            as="h1"
            fontSize={isLargerThan840 ? "48px" : "28px"}
            fontWeight="800"
            marginBottom="28px"
          >
            X CAPITAL TERMINAL
          </Heading>
          <Text
            fontSize={isLargerThan840 ? "24px" : "20px"}
            marginBottom="24px"
            maxWidth={isLargerThan840 ? "90%" : "100%"}
          >
            X CAPITAL TERMINAL is your gateway to comprehensive financial market
            data and analytics. With real-time and historical data on stocks,
            bonds, and more, our terminal provides traders, investors, and
            businesses with the insights they need to make informed decisions.
          </Text>
          <Button
            bg="white"
            color="gray.700"
            width="160px"
            height="40px"
            borderRadius="12px"
            fontSize="20px"
            _hover={{ bg: "gray.100" }}
            onClick={() => navigate('/wait-list')}
          >
            Book a demo
          </Button>
        </Flex>
      </Box>

      {/* Features Section */}
      <Box bg={bgColor} width="full" py="60px">
        <VStack spacing="40px" align="center" maxW="1200px" mx="auto" px={4}>
          <Heading mb="24px">Our Key Features</Heading>
          <AnimatedFeatureCard
            title="Real-Time Data"
            description=" Immediate access to live market data from a variety of sources, enabling users to stay on top of fast-moving market conditions."
            delay={0.2}
          />
          <AnimatedFeatureCard
            title="Customizable Dashboards"
            description=" Tailored workspaces that allow investors to track specific stocks, markets, or trends relevant to their portfolios."
            delay={0.4}
          />
          <AnimatedFeatureCard
            title="In-Depth Historical Data"
            description=": Access to comprehensive historical data for deeper analysis, ideal for long-term trend forecasting and market research."
            delay={0.6}
          />
          <AnimatedFeatureCard
            title="Portfolio Management Tools"
            description=": Integrated tools to help users manage and optimize their investment portfolios directly from the platform."
            delay={0.8}
          />
          <AnimatedFeatureCard
            title="News Integration"
            description=": Real-time market news from trusted sources helps users make informed decisions based on the latest information."
            delay={1}
          />
        </VStack>
      </Box>
      <Stack spacing={10}>
        <Box bg={bgColor} color="white">
          <Center>
            <video width="1050px" height="800px" controls>
              <source src={XCapitalVideo} type="video/mp4" />
            </video>
          </Center>
          <Center bg={bgColor}>
            <SolutionQuestion faqList={faqList}/>
          </Center>
        </Box>
      </Stack>
    </Box>
  );
};

export default Terminal;
