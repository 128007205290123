import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  HStack,
  Text,
  Flex,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import {
  FiPlus,
  FiSearch,
  FiTrash2,
  FiArrowLeft,
  FiArrowRight,
  FiEdit,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { deleteTargetSale, getAllTargetSales } from "../../actions/targetSalesActions";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import AddTargetSaleModal from "../../components/AddTargetSaleModal";
import { useNavigate } from "react-router-dom";

const getMonthName = (monthNumber) => {
  if (monthNumber === null || monthNumber === undefined) return 'Unknown';
  const date = new Date();
  date.setMonth(monthNumber - 1);
  return date.toLocaleString('en-US', { month: 'long' });
};

export default function AdminTargetSalesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const targetSales = useSelector((state) => state.targetSalesReducer.targetSales) || [];
  const loading = useSelector((state) => state.targetSalesReducer.loading);
  const error = useSelector((state) => state.targetSalesReducer.error);

  const bg = useSelector((state) => state.themeReducer.backgroundColor1);
  const bg2 = useSelector((state) => state.themeReducer.backgroundColor2);
  const colorH = useSelector((state) => state.themeReducer.color1);
  const colorP = useSelector((state) => state.themeReducer.color2);
  const colorAdminP = useSelector((state) => state.themeReducer.colorAdmin2);
  const blueAdmin = useSelector((state) => state.themeReducer.blueAdmin);

  const [currentPage, setCurrentPage] = useState(0);
  const [targetSalesPerPage, setTargetSalesPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [targetSaleToDelete, setTargetSaleToDelete] = useState(null);
  const [isAddTargetSaleModalOpen, setIsAddTargetSaleModalOpen] = useState(false);
  const [isUpdateTargetSaleModalOpen, setIsUpdateTargetSaleModalOpen] = useState(false);
  const [targetSaleToUpdate, setTargetSaleToUpdate] = useState(null);

  useEffect(() => {
    dispatch(getAllTargetSales()).catch((error) => {
      if (error.response && error.response.status === 403) {
        navigate("/Not-Access");
      } else if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    });
  }, [dispatch, navigate]);

  const filteredTargetSales = useMemo(() => {
    return targetSales.filter((targetSale) => {
      if (!targetSale) return false;
      const monthName = getMonthName(targetSale.month).toLowerCase();
      const year = targetSale.year?.toString() || '';
      const salesTargeted = targetSale.sales_targeted?.toString() || '';
      const productTitle = targetSale.product?.title?.toLowerCase() || '';
      const searchLower = searchTerm.toLowerCase();
      
      return monthName.includes(searchLower) || 
             year.includes(searchLower) || 
             salesTargeted.includes(searchLower) ||
             productTitle.includes(searchLower);
    });
  }, [targetSales, searchTerm]);

  useEffect(() => {
    setCurrentPage(0);
  }, [searchTerm]);

  const offset = currentPage * targetSalesPerPage;
  const totalPages = Math.ceil(filteredTargetSales.length / targetSalesPerPage);

  const handleDeleteClick = (targetSale) => {
    setTargetSaleToDelete(targetSale);
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (targetSaleToDelete && targetSaleToDelete.id) {
      dispatch(deleteTargetSale(targetSaleToDelete.id))
        .then(() => {
          setIsModalOpen(false);
          setTargetSaleToDelete(null);
          dispatch(getAllTargetSales());
        })
        .catch((error) => console.error("Error deleting target sale:", error));
    }
  };

  const currentTargetSales = filteredTargetSales.slice(offset, offset + targetSalesPerPage);

  const handleUpdateClick = (targetSale) => {
    setTargetSaleToUpdate(targetSale);
    setIsUpdateTargetSaleModalOpen(true);
  };

  return (
    <Box minH="100vh" bg={bg}>
      <Box ml={{ base: 0, md: 60 }} p={4}>
        <Heading fontSize="24px" mb={4} color={blueAdmin}>
          Target Sales
        </Heading>

        <Flex justifyContent="center" alignItems="center" mb={4}>
          <HStack spacing={4} width="100%">
            <InputGroup flex={5}>
              <InputLeftElement pointerEvents="none">
                <FiSearch color="gray" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search target sales"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                bg={bg2}
                borderRadius="16px"
                _focus={{ boxShadow: "none" }}
              />
            </InputGroup>
            <Button
              rightIcon={<FiPlus />}
              color="#FFFFFF"
              backgroundColor="#001A72"
              onClick={() => setIsAddTargetSaleModalOpen(true)}
            >
              Add target sale
            </Button>
          </HStack>
        </Flex>

        <Box
          overflowX="auto"
          bg={bg2}
          borderRadius="16px"
          border="solid 1px #EFF4FA"
          p={4}
        >
          <Text
            color={colorH}
            fontSize="18px"
            fontWeight="bold"
            mb="10px"
            flex="1"
          >
            List of target sales
          </Text>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th color={colorAdminP}>Month</Th>
                <Th color={colorAdminP}>Year</Th>
                <Th color={colorAdminP}>Target Sales</Th>
                <Th color={colorAdminP}>Product</Th>
                <Th textAlign="center" color={colorAdminP}>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Tr>
                  <Td colSpan={5}>
                    <Flex justifyContent="center" alignItems="center" h="200px">
                      <Spinner size="xl" />
                    </Flex>
                  </Td>
                </Tr>
              ) : error ? (
                <Tr>
                  <Td colSpan={5}>
                    <Flex justifyContent="center" alignItems="center" h="200px">
                      <Text color="red.500">{error.message}</Text>
                    </Flex>
                  </Td>
                </Tr>
              ) : currentTargetSales.length === 0 ? (
                <Tr>
                  <Td colSpan={5}>
                    <Flex justifyContent="center" alignItems="center" h="200px">
                      <Text color={colorP}>No results found</Text>
                    </Flex>
                  </Td>
                </Tr>
              ) : (
                currentTargetSales.map((targetSale) => (
                  <Tr key={targetSale.id}>
                    <Td color={colorP}>{getMonthName(targetSale.month)}</Td>
                    <Td color={colorP}>{targetSale.year || 'N/A'}</Td>
                    <Td color={colorP}>{targetSale.sales_targeted || 'N/A'}</Td>
                    <Td color={colorP}>{targetSale.product?.title || 'N/A'}</Td>
                    <Td textAlign="center">
                      <HStack spacing={4} justifyContent="center">
                        <FiTrash2 color={blueAdmin} onClick={() => handleDeleteClick(targetSale)} />
                        <FiEdit color={blueAdmin} onClick={() => handleUpdateClick(targetSale)} />
                      </HStack>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="20px"
          bg={bg}
          p="10px"
          borderRadius="8px"
        >
          <Stack direction="row" spacing={4} alignItems="center">
            <Text mr="10px" color="#717171">
              Rows per page:
            </Text>
            <select
              onChange={(e) => {
                setCurrentPage(0);
                setTargetSalesPerPage(parseInt(e.target.value, 10));
              }}
              value={targetSalesPerPage}
              style={{
                background: bg,
                border: "none",
                borderRadius: "4px",
                padding: "5px",
                marginRight: "10px",
                color: "#717171",
              }}
            >
              {[5, 10, 15, 20].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <Text color="#717171">
              {offset + 1}–
              {Math.min(offset + targetSalesPerPage, filteredTargetSales.length)} of{" "}
              {filteredTargetSales.length}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center" ml={10}>
            <FiArrowLeft
              onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))}
              color={currentPage === 0 ? "#C4C4C4" : "#717171"}
              style={{ cursor: currentPage === 0 ? "not-allowed" : "pointer" }}
            />
            <FiArrowRight
              onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
              color={currentPage >= totalPages - 1 ? "#C4C4C4" : "#717171"}
              style={{
                cursor: currentPage >= totalPages - 1 ? "not-allowed" : "pointer",
              }}
            />
          </Stack>
        </Box>
      </Box>

      {targetSaleToDelete && (
        <DeleteConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onDelete={handleDeleteConfirm}
          userName={`${getMonthName(targetSaleToDelete.month)} ${targetSaleToDelete.year}`}
          pageName="Target Sale"
        />
      )}
      <AddTargetSaleModal
        isOpen={isAddTargetSaleModalOpen}
        onClose={() => setIsAddTargetSaleModalOpen(false)}
        type="add"
      />
      <AddTargetSaleModal
        isOpen={isUpdateTargetSaleModalOpen}
        onClose={() => setIsUpdateTargetSaleModalOpen(false)}
        type="update"
        initialData={targetSaleToUpdate}
      />
    </Box>
  );
}