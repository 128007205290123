import {
    Box,
    Heading,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Stack,
    StackDivider,
    Card,
    CardHeader,
    CardBody,
  } from "@chakra-ui/react";
  import { useState, useEffect } from "react";
  import { useSelector } from "react-redux";
  import fetchQuestions from "../../services/api/fetchQuestions";
  
  export default function Questions({faqList}) {
    const bg = useSelector((state) => state.themeReducer.backgroundColor1);
  
    
  
   
  
    return (
      <Card margin={"40px 0"} size={"lg"} width={"90%"} bgColor={bg}>
        <CardHeader>
          <Heading size="lg">Frequently asked questions</Heading>
        </CardHeader>
  
        <CardBody>
          <Accordion allowToggle>
            <Stack divider={<StackDivider />} spacing="4">
              {faqList.map((item, index) => (
                <AccordionItem key={index}>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {item.question}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>{item.answer}</AccordionPanel>
                </AccordionItem>
              ))}
  
              <AccordionItem key="services">
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      What Services Does X Capital Offer?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  X Capital offers a comprehensive suite of services designed to
                  empower investors and drive growth in the dynamic financial
                  markets of Africa and the Middle East. Our primary services
                  include:
                  <br />
                  <ul>
                    <li>
                      1. The X Capital Terminal is our flagship
                      product, providing real-time market data, historical data,
                      comprehensive analysis, and in-depth insights. This powerful
                      platform equips investors with the knowledge and resources
                      needed to make informed decisions and seize emerging
                      opportunities.
                    </li>
                    <li>
                      2. X Capital Intelligence leverages
                      advanced AI and machine learning algorithms to deliver
                      actionable market insights and predictive analytics. This
                      service helps investors anticipate market trends, optimize
                      their investment strategies, and stay ahead of the
                      competition.
                    </li>
                    <li>
                      3. X Capital Data offers extensive and
                      reliable financial data, including real-time updates and
                      historical records. This service provides the foundation for
                      informed decision-making, ensuring investors have access to
                      accurate and timely information.
                    </li>
                    <li>
                      4. X Capital Articles delivers
                      expert-written content and in-depth analysis on various
                      aspects of the financial markets. This service keeps
                      investors informed about the latest market trends, investment
                      strategies, and economic developments, providing valuable
                      insights and perspectives to support their decision-making
                      process.
                    </li>
                  </ul>
                </AccordionPanel>
              </AccordionItem>
            </Stack>
          </Accordion>
        </CardBody>
      </Card>
    );
  }