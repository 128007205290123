import axios from 'axios';
import {
  GET_PREORDERS_SUCCESS,
  GET_PREORDERS_FAIL,
  GET_PREORDER_BY_ID_SUCCESS,
  GET_PREORDER_BY_ID_FAIL,
  CREATE_PREORDER_SUCCESS,
  CREATE_PREORDER_FAIL,
  UPDATE_PREORDER_SUCCESS,
  UPDATE_PREORDER_FAIL,
  DELETE_PREORDER_SUCCESS,
  DELETE_PREORDER_FAIL,
  DOWNLOAD_PREORDERS_SUCCESS,
  DOWNLOAD_PREORDERS_FAIL
} from './types';
import { getAuthConfig } from './config';

export const getAllPreOrders = () => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/preorders`, config);
    dispatch({
      type: GET_PREORDERS_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_PREORDERS_FAIL,
      payload: err.message
    });
    return err.message;
  }
};

export const getPreOrderById = (id) => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/preorder/${id}`, config);
    dispatch({
      type: GET_PREORDER_BY_ID_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_PREORDER_BY_ID_FAIL,
      payload: err.message
    });
  }
};

export const createPreOrder = (preOrderData) => async dispatch => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/preorder/save`, preOrderData);
    dispatch({
      type: CREATE_PREORDER_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CREATE_PREORDER_FAIL,
      payload: err.response.data.message,
    });
    throw err.response.data.message;
  }
};

export const updatePreOrder = (id, preOrderData) => async dispatch => {
  try {
    const config = getAuthConfig();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/preorder/${id}/update`, preOrderData, config);
    dispatch({
      type: UPDATE_PREORDER_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATE_PREORDER_FAIL,
      payload: err.message
    });
  }
};

export const deletePreOrder = (id) => async dispatch => {
  try {
    const config = getAuthConfig();
    await axios.delete(`${process.env.REACT_APP_API_URL}/preorder/${id}/delete`, config);
    dispatch({
      type: DELETE_PREORDER_SUCCESS,
      payload: id
    });
  } catch (err) {
    dispatch({
      type: DELETE_PREORDER_FAIL,
      payload: err.message
    });
  }
};

export const downloadPreOrders = () => async dispatch => {
  try {
    const config = {
      ...getAuthConfig(),
      responseType: 'blob', 
    };
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/preorders/download`, config);
    
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'preorders.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    
    dispatch({
      type: DOWNLOAD_PREORDERS_SUCCESS
    });
  } catch (err) {
    dispatch({
      type: DOWNLOAD_PREORDERS_FAIL,
      payload: err.message
    });
  }
};