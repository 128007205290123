import React,{useEffect, useState} from 'react'
import { Box } from '@chakra-ui/react';

const TradingViewTicker = () => {
    const [isSet,setIsSet]=useState(false)
    useEffect(() => {
        if (!document.getElementById('tradingview-script')) {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
      script.async = true;
      script.id='tradingview-script'
      script.innerHTML = JSON.stringify({
        symbols: [
          { description: "", proName: "CSEMA:MASI" },
          { description: "", proName: "EGX:EGX30" },
          { description: "", proName: "FXOPEN:J225" },
          { description: "", proName: "INDEX:HSI" },
          { description: "", proName: "BSE:SENSEX" },
          { description: "", proName: "NASDAQ:IXIC" },
          { description: "", proName: "SPREADEX:FTSE" },
          { description: "", proName: "XETR:DAX" },
          { description: "", proName: "SPREADEX:CAC" },
          { description: "", proName: "BLACKBULL:US30" }
        ],
        showSymbolLogo: false,
        isTransparent: false,
        displayMode: "adaptive",
        colorTheme: "dark",
        locale: "en"
      });
  
      document.querySelector('.tradingview-widget-container__widget').appendChild(script);
    }
    }, []);
  
    return (
      <Box className="tradingview-widget-container">
        <Box className="tradingview-widget-container__widget"></Box>
      </Box>
    );
  };

  export default TradingViewTicker