import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  VStack,
} from "@chakra-ui/react";

const JobOfferModal = ({ isOpen, onClose, career, onEdit }) => {
  const navigate = useNavigate();

  if (!career) return null;

  const handleSeeCandidates = () => {
    navigate("/x-capital/terminal/admin/candidates", {
      state: { careerId: career.id },
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color="#222B45"
          textAlign="center"
          borderBottomWidth={1}
          borderBottomColor="#E2E8F0"
        >
          Job Offer Details
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="center" spacing={4} mx={10} mt={4}>
            <Text fontWeight="bold" fontSize="lg" width="100%">
              {career.job_title}
            </Text>
            <Text fontWeight="semibold" width="100%">
              X Capital – {career.location} – {career.job_type}
            </Text>
              <Text fontWeight="semibold" width="100%">
                Description:
              </Text>
              <Text width="100%" px={4}>{career.description}</Text>
            <Box width="100%" mt={4} textAlign="center">
              <Button
                color="#FFFFFF"
                bgColor="#171E6C"
                width="30%"
                _hover={{ bg: "#0F1553" }}
                onClick={handleSeeCandidates}
              >
                See Candidates
              </Button>
            </Box>
            <Box width="100%" textAlign="center" mb={2}>
              <Button
                colorScheme="gray"
                variant="outline"
                width="30%"
                onClick={() => onEdit(career)}
              >
                Edit Offer
              </Button>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default JobOfferModal;
