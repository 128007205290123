import { getAuthConfig } from "../../actions/config"

export const fetchViews=async(setViewed,userId,articleId)=>{
    const config=getAuthConfig()
    const response=await fetch(`${process.env.REACT_APP_API_URL}/specView/${userId}/${articleId}`,{
        method:"GET",
        ...config
    })
    await response.json().then((data)=>{
        if(response.ok){
            console.log(data);
            setViewed(data)
        }
    })
}

export const fetchAllViews=async (setViews)=>{
    const config=getAuthConfig()
    const response= await fetch(`${process.env.REACT_APP_API_URL}/view/`,{
        method:"GET",
        ...config})
    await response.json().then((data)=>{
        if(response.ok)
            setViews(data)
        else
            setViews([])
    })
}

export const addView=async(userId,articleId)=>{
    const config=getAuthConfig()
    const response=await fetch(`${process.env.REACT_APP_API_URL}/addView/`,{
        method:"POST",
        body:JSON.stringify({user:userId,article:articleId}),
        ...config
    })
}