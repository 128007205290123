import React from 'react'
import { Flex, Heading, List, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import { Link } from 'react-router-dom'


function LegalPage() {
  return (
    <>
     <Flex
        flexDirection="column"
        width="100%"
        minHeight="85vh"
        backgroundColor="#F3F3F3"
        paddingX={{ base: "1rem", md: "2rem" }}
      >
    <Flex direction="column" p="30px" gap={5}>
    <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>
      You can find here all our Policies.
    </Heading>

      <Flex direction="column" gap={10}>
            <List ml="3em" textColor="blue" textDecoration="underline">
                <UnorderedList>
                    <Link to="/legal/privacy-policy">
                        <ListItem>Privacy Policy</ListItem>
                    </Link>
                    <Link to="/legal/term-of-service">
                        <ListItem>Term of Service</ListItem>
                    </Link>
                    <Link to="/legal/cookie-policy">
                        <ListItem>Cookie Policy</ListItem>
                    </Link>
                    <Link to="/legal/refund-cancellation-policy">
                        <ListItem>Refund and Cancellation Policy</ListItem>
                    </Link>
                    <Link to="/legal/security-policy">
                        <ListItem>Security Policy</ListItem>
                    </Link>
                    <Link to="/legal/intellectual-property-policy">
                        <ListItem>Intellectual Property Policy</ListItem>
                    </Link>
                    <Link to="/legal/accessibility-statement">
                        <ListItem>Accessibility Statement</ListItem>
                    </Link>
                    <Link to="/legal/acceptable-use-policy">
                        <ListItem>Acceptable and Use Policy</ListItem>
                    </Link>
                </UnorderedList>

            </List>
      </Flex>

      <Text mt="2em">If You have any question, do not hesitate to  <Link to="/contact-us" textColor="blue"><u>contact Us</u></Link>.</Text>
      
      </Flex>
      </Flex>
    </>
  )
}

export default LegalPage
