import { GET_SESSION_SUCCESS, GET_SESSION_FAIL, SET_SESSION_FAIL, SET_SESSION_SUCCESS,DELETE_SESSION_FAIL , DELETE_SESSION_SUCCESS,UPDATE_SESSION_FAIL , UPDATE_SESSION_SUCCESS } from './types';
import axios from 'axios';
import { getAuthConfig } from './config';

export const getCart = () => async (dispatch) => {
    try {
      const config = getAuthConfig();
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/gsession`, {
        ...config,
        withCredentials: true, 
      });
  
      dispatch({
        type: GET_SESSION_SUCCESS,
        payload: res.data,
      });
  
      return res.data;
    } catch (err) {
      dispatch({
        type: GET_SESSION_FAIL,
        payload: err.message,
      });
      throw err;
    }
  };
export const updateCart = (id,period) => async (dispatch) => {
    try {
      const config = getAuthConfig();
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/usession/${id}/${period}`, {
        ...config,
        withCredentials: true, 
      });
  
      dispatch({
        type: UPDATE_SESSION_SUCCESS,
        payload: res.data,
      });
  
      return res.data;
    } catch (err) {
      dispatch({
        type: UPDATE_SESSION_FAIL,
        payload: err.message,
      });
      throw err;
    }
  };
export const delCart = (id) => async (dispatch) => {
    try {
      const config = getAuthConfig();
      const res = await axios.delete(`${process.env.REACT_APP_API_URL}/dsession/${id}/`, {
        ...config,
        withCredentials: true, 
      });
  
      dispatch({
        type: DELETE_SESSION_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch({
        type: DELETE_SESSION_FAIL,
        payload: err.message,
      });
      throw err;
    }
  };
export const setCart = (id,period) => async (dispatch) => {
    try {
      const config = getAuthConfig();
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/ssession/${id}/${period}`, {
        ...config,
        withCredentials: true, 
      });
  
      dispatch({
        type: SET_SESSION_SUCCESS,
        payload: res.data,
      });
  
      return res.data;
    } catch (err) {
      dispatch({
        type: SET_SESSION_FAIL,
        payload: err.message,
      });
      throw err;
    }
  };
