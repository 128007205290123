import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { verify } from '../actions/auth';
import FailedLoginModal from '../components/FailedLoginModal';
import {
  ACTIVATION_SUCCESS
} from '../actions/types';
import {
    Flex,
    Stack,
    Box,
    Heading,
    Text,
    Button,
  } from '@chakra-ui/react';


const checkIfLinkExpired = (uid, token) => {
    const decodedToken = parseInt(token, 36);
    const activationTokenCreationDate = new Date(decodedToken * 1000); 
    activationTokenCreationDate.setFullYear(activationTokenCreationDate.getFullYear()+31);
    const expirationDurationInHours = 24;
    const expirationDate = new Date(activationTokenCreationDate.getTime() + expirationDurationInHours * 60 * 60 * 1000);
    const currentDate = new Date();
  
    const isExpired = currentDate > expirationDate;
  
    return isExpired;
};


const Activate = ({ verify }) => {
  const [verified, setVerified] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [expired, setExpired] = useState(false); 
  const { uid, token } = useParams();

  useEffect(() => {
    const isLinkExpired = checkIfLinkExpired(uid, token);
    if (isLinkExpired) {
      setExpired(true);
    }
  }, [uid, token]);

  const verify_account = async () => {
    const res=await verify(uid, token);
    if(res===ACTIVATION_SUCCESS){
      setShowModal(false);
      setVerified(true);
    }else{
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 4000);
      setVerified(false);
    }
  };

  if (verified) {
    return <Navigate to="/login" />;
  }

  if (expired) { 
    return (
      <Flex
        flexDirection="column"
        width="100wh"
        minHeight="85vh"
        backgroundColor="#FFFFFF"
        justifyContent="center"
        alignItems="center"
      >
        <Text
          fontWeight="400px"
          fontSize="14px"
          lineHeight="20px"
          color="#828282"
          textAlign="center"
        >
          This activation link has expired. Please request a new one.
        </Text>
        <Button
          as={Link}
          to="/"
          variant="outline"
          colorScheme="black"
          mt={4}
        >
          Go back to Home
        </Button>
      </Flex>
    );
  }

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      minHeight="85vh"
      backgroundColor="#FFFFFF"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={{ base: "90%", md: "400px" }}>
          <Stack
            spacing={4}
            p="1rem"
            padding="40px"
            width={{ base: "90%", md: "400px" }}
            margin={"auto"}
            borderRadius="24px"
            border="solid 1px #828282"
            backgroundColor="#FFFFFF"
            boxShadow="md"
            height="100%"
          >
            <Heading
              fontWeight="400"
              fontSize="30px"
              lineHeight="50px"
              fontFamily="Open Sans"
              color="#00224E"
              textAlign="center"
              marginTop={0}
            >
              Activate Your account
            </Heading>
            {showModal && (
              <FailedLoginModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                title="Activate Error"
                message="Failed to Activate your account. Please try again."
              />
            )}
            <Text
              fontWeight="400px"
              fontSize="14px"
              lineHeight="20px"
              color="#828282"
              textAlign="left"
            >
              Click the button bellow to get access to premium content which
              will help you with your financial status
            </Text>
            <Button
              borderRadius={5}
              type="button"
              variant="solid"
              width="full"
              height="40px"
              border="none"
              marginY="10px"
              bg="#171E6C"
              color="#FFFFFF"
              onClick={verify_account}
            >
              Activate your account
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default connect(null, { verify })(Activate);
