import axios from 'axios';
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    LOGOUT
} from './types';
import { jwtDecode } from "jwt-decode";
import { deleteSession } from '../services/api/deleteSession';

export const load_user = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        }; 

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config);
    
            dispatch({
                type: USER_LOADED_SUCCESS,
                payload: res.data
            });
            return res.data;
        } catch (err) {
            dispatch({
                type: USER_LOADED_FAIL
            });
        }
    } else {
        dispatch({
            type: USER_LOADED_FAIL
        });
    }
};

export const checkAuthenticated = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }; 

        const body = JSON.stringify({ token: localStorage.getItem('access') });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/verify/`, body, config)

            if (res.data.code !== 'token_not_valid') {
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                });
            } else {
                dispatch({
                    type: AUTHENTICATED_FAIL
                });
            }
        } catch (err) {
            dispatch({
                type: AUTHENTICATED_FAIL
            });
        }

    } else {
        dispatch({
            type: AUTHENTICATED_FAIL
        });
    }
};

export const refreshToken = () => async dispatch => {
    const refreshToken = localStorage.getItem('refresh');
    if (!refreshToken) {
        dispatch({
            type: AUTHENTICATED_FAIL
        });
        return;
    }

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ refresh: refreshToken });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/refresh/`, body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        
        dispatch(load_user());

        localStorage.setItem('refresh', refreshToken);

        dispatch({
            type: AUTHENTICATED_SUCCESS
        });
        localStorage.setItem('refresh', refreshToken);
    } catch (err) {
        dispatch({
            type: AUTHENTICATED_FAIL
        });
    }
};

export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email, password });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, body, config);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });

        dispatch(load_user());
        return LOGIN_SUCCESS;
    } catch (err) {
        dispatch({
            type: LOGIN_FAIL
        })
        return LOGIN_FAIL;
    }
};


export const signup = (first_name, last_name, email, phone_number, password, re_password, role, type, company) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const body = JSON.stringify({ first_name, last_name, email, phone_number, password, re_password, role, type, company});
    console.log(body);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/`, body, config);

        dispatch({
            type: SIGNUP_SUCCESS,
            payload: res.data
        });
        return SIGNUP_SUCCESS;
    } catch (err) {
        dispatch({
            type: SIGNUP_FAIL
        });

        let errorMessage = "An error occurred. Please try again.";
        if (err.response && err.response.data) {
            if (err.response.data.password) {
                errorMessage = err.response.data.password[0];
            } else if (err.response.data.email) {
                errorMessage = err.response.data.email[0];
            } else {
                errorMessage = JSON.stringify(err.response.data);
            }
        }
        return errorMessage;
    }
};


export const verify = (uid, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ uid, token });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/activation/`, body, config);

        dispatch({
            type: ACTIVATION_SUCCESS,
        });
        return ACTIVATION_SUCCESS;
    } catch (err) {
        dispatch({
            type: ACTIVATION_FAIL
        })
        return ACTIVATION_FAIL;
    }
};

export const reset_password = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password/`, body, config);

        dispatch({
            type: PASSWORD_RESET_SUCCESS
        });
        return PASSWORD_RESET_SUCCESS;
    } catch (err) {
        dispatch({
            type: PASSWORD_RESET_FAIL
        });
        return PASSWORD_RESET_FAIL;
    }
};

export const reset_password_confirm = (uid, token, new_password, re_new_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`, body, config);

        dispatch({
            type: PASSWORD_RESET_CONFIRM_SUCCESS
        });
        return PASSWORD_RESET_CONFIRM_SUCCESS;
    } catch (err) {
        dispatch({
            type: PASSWORD_RESET_CONFIRM_FAIL
        });
        return PASSWORD_RESET_CONFIRM_FAIL;
    }
};

export const logout = () => dispatch => {
    deleteSession()
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('rememberMe');
    sessionStorage.removeItem('rememberMe');
    dispatch({
      type: LOGOUT
    });
  };

export function isTokenExpired(token) {
  if (!token) {
    return true;
  }

  const decodedToken = jwtDecode(token); 
  const expirationTime = decodedToken.exp * 1000; 
  const currentTime = Date.now();

  return expirationTime < currentTime;
}