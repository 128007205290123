import axios from 'axios';
import {
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
  GET_COMPANY_BY_ID_SUCCESS,
  GET_COMPANY_BY_ID_FAIL,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAIL,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
} from './types';
import { getAuthConfig } from './config';

export const getAllCompanies = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/companies`);
    dispatch({
      type: GET_COMPANIES_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_COMPANIES_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const getCompanyById = (id) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/company/${id}`, config);
    dispatch({
      type: GET_COMPANY_BY_ID_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_COMPANY_BY_ID_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const createCompany = (companyData) => async dispatch => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/company/save`, companyData);
      dispatch({
        type: CREATE_COMPANY_SUCCESS,
        payload: res.data
      });
      return res.data; 
    } catch (err) {
      dispatch({
        type: CREATE_COMPANY_FAIL,
        payload: err.response.data.message,
      });
      throw err.response.data.message;
    }
  };

export const updateCompany = (id, companyData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/company/${id}/update`, companyData, config);
    dispatch({
      type: UPDATE_COMPANY_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: UPDATE_COMPANY_FAIL,
      payload: err.message,
    });
  }
};

export const deleteCompany = (id) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    await axios.delete(`${process.env.REACT_APP_API_URL}/company/${id}/delete`, config);
    dispatch({
      type: DELETE_COMPANY_SUCCESS,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: DELETE_COMPANY_FAIL,
      payload: err.message,
    });
  }
};