import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { createCategory, updateCategory } from "../../actions/categoryActions";

const AddCategoryModal = ({ isOpen, onClose, type, initialData }) => {
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      if (type === 'update' && initialData) {
        setName(initialData.name);
      } else {
        setName('');
      }
    }
  }, [isOpen, type, initialData]);

  const handleAddOrUpdateCategory = async () => {
    setErrors({});

    const formData = {
      name,
    };

    try {
      if (type === 'update' && initialData) {
        await dispatch(updateCategory(initialData.id, formData));
      } else {
        await dispatch(createCategory(formData));
      }
      handleClose();
    } catch (error) {
      console.log("the error ", error);
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      } else {
        setErrors({ general: 'An unexpected error occurred' });
      }
    }
  };

  const handleClose = () => {
    setName('');
    setErrors({});
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{type === 'update' ? 'Update Category' : 'Add New Category'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl
            id="name"
            isRequired
            isInvalid={Boolean(errors.name)}
          >
            <FormLabel>Name</FormLabel>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Category name"
              borderColor={errors.name ? 'red.500' : 'gray.200'}
              _hover={{ borderColor: errors.name ? 'red.500' : 'gray.300' }}
              _focus={{ borderColor: errors.name ? 'red.500' : 'blue.500' }}
            />
            {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
          </FormControl>

          {errors.general && (
            <Alert status="error" mt={4}>
              <AlertIcon />
              {errors.general}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleAddOrUpdateCategory}
            bg="#001A72"
            color="#FFFFFF"
            _hover={{ bg: "#001A72" }}
            ml={3} mr={3}
          >
            {type === 'update' ? 'Update' : 'Save'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCategoryModal;
