import { Box, Heading, Image, Text } from "@chakra-ui/react";
import { XAxis, Tooltip, ResponsiveContainer, BarChart, Bar } from "recharts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import iconPng from "../../assets/star.png";
import bag from "../../assets/Bag1.svg";
import { getProducts } from "../../actions/product";
import { getSubscriptions } from "../../actions/subscription";
import { getTargetSales } from "../../actions/targetSales";

const BarChartComponent = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [targetSales, setTargetSales] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [totalRealitySales, setTotalRealitySales] = useState(0);
  const [totalTargetSales, setTotalTargetSales] = useState(0);
  const bg2 = useSelector((state) => state.themeReducer.backgroundColor2);
  const colorP = useSelector((state) => state.themeReducer.color2);
  const data1 = [
    { month: "jan", target: 4000, reality: 0 },
    { month: "feb", target: 3000, reality: 0 },
    { month: "mar", target: 4000, reality: 0 },
    { month: "apr", target: 2780, reality: 0 },
    { month: "may", target: 1890, reality: 0 },
    { month: "jun", target: 2390, reality: 0 },
    { month: "jul", target: 3490, reality: 0 },
    { month: "aug", target: 4000, reality: 0 },
    { month: "sep", target: 3000, reality: 0 },
    { month: "oct", target: 4000, reality: 0 },
    { month: "nov", target: 2780, reality: 0 },
    { month: "dec", target: 1890, reality: 0 },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productResponse, subscriptionResponse, targetResponse] =
          await Promise.all([
            dispatch(getProducts()),
            dispatch(getSubscriptions()),
            dispatch(getTargetSales()),
          ]);
        setTargetSales(targetResponse);
        setProducts(productResponse);
        setSubscriptions(subscriptionResponse);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-11, so add 1
    const isFirstHalf = currentMonth <= 6;

    const filteredData = isFirstHalf
      ? data1.slice(0, 6) // First six months (index 0 to 5)
      : data1.slice(6); // Last six months (index 6 to 11)

    const filteredSubscriptions = subscriptions.filter((subscription) => {
      const subscriptionMonth =
        new Date(subscription.started_at).getMonth() + 1;
      return isFirstHalf ? subscriptionMonth <= 6 : subscriptionMonth > 6;
    });

    const subscriptionCounts = filteredSubscriptions.reduce(
      (acc, subscription) => {
        const month = new Date(subscription.started_at)
          .toLocaleString("default", { month: "short" })
          .toLowerCase();
        if (!acc[month]) {
          acc[month] = 0;
        }
        acc[month] += 1;
        return acc;
      },
      {}
    );

    const realityData = filteredData.map((data) => {
      const month = data.month;
      const totalSales = products.reduce((acc, product) => {
        const productPrice = parseFloat(product.price);
        const subscriptionCount = subscriptionCounts[month] || 0;
        return acc + subscriptionCount * productPrice;
      }, 0);
      return { ...data, reality: totalSales };
    });

    setChartData(realityData);
    setTotalRealitySales(
      realityData.reduce((acc, data) => acc + data.reality, 0).toFixed(2)
    );
    setTotalTargetSales(
      filteredData.reduce((acc, data) => acc + data.target, 0).toFixed(2)
    );
  }, [products, subscriptions]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box bg={bg2} p="5px" borderRadius="md" boxShadow="md">
          <Heading as="h5" size="sm" textColor="#151D48">
            {label}
          </Heading>
          <Text>Target : {payload[1].value}</Text>
          <Text>Reality : {payload[0].value}</Text>
        </Box>
      );
    }

    return null;
  };

  return (
    <Box
      height="400px"
      overflow="hidden"
      borderRadius="md"
      boxShadow="md"
      bgColor={bg2}
    >
      <Heading as="h2" fontSize="20px" textColor="#05004E" pt="10px" pl="20px">
        Target vs Reality
      </Heading>
      <ResponsiveContainer width="100%" height="55%">
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis
            dataKey="month"
            tick={{ fill: colorP }}
            axisLine={{ stroke: colorP }}
          />
          <Tooltip
            content={<CustomTooltip />} // Pass bg2 as a prop to the custom tooltip
          />
          <Bar dataKey="reality" fill="#0095FF" />
          <Bar dataKey="target" fill="#07E098" />
        </BarChart>
      </ResponsiveContainer>
      <Box
        ml={"20px"}
        mt={"20px"}
        height={"40px"}
        width={"300px"}
        display="flex"
        flexWrap="wrap"
      >
        <Box
          height={"36px"}
          width={"36px"}
          bgColor={"#E2FFF3"}
          borderRadius={"5px"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Image src={bag} alt="Icon" boxSize="18px" />
        </Box>
        <Box as="h5" size="12px" ml={"15px"} textColor="#151D48">
          <Heading as="h5" size="sm" textColor="#151D48">
            Reality Sales
          </Heading>
          <Text as="p" size="10px" color={colorP}>
            {" "}
            Global{" "}
          </Text>
        </Box>
        <Box>
          <Text as="p" size="14px" ml="50px" color="#27AE60">
            {" "}
            {totalRealitySales}{" "}
          </Text>
        </Box>
      </Box>

      <Box
        ml={"20px"}
        mt={"20px"}
        height={"40px"}
        width={"300px"}
        display="flex"
        flexWrap="wrap"
      >
        <Box
          height={"36px"}
          width={"36px"}
          bgColor={"#E2FFF3"}
          borderRadius={"5px"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Image src={iconPng} alt="Icon" boxSize="18px" />
        </Box>
        <Box as="h5" size="12px" ml={"15px"} textColor="#151D48">
          <Heading as="h5" size="sm" textColor="#151D48">
            Target Sales
          </Heading>
          <Text as="p" size="10px" color={colorP}>
            {" "}
            Commercial{" "}
          </Text>
        </Box>
        <Box>
          <Text as="p" size="14px" ml="50px" color="#27AE60">
            {" "}
            {totalTargetSales}{" "}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default BarChartComponent;
