import { Box, Heading, Image, Text } from "@chakra-ui/react";
import {
  Area,
  AreaChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import mnt from "../../assets/monthIcon.png";
import mnt1 from "../../assets/mnt1.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getSubscriptions } from "../../actions/subscription";
import { getProducts } from "../../actions/product";


export default function SalesChart() {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [totalSales, setTotalSales] = useState({ thisMonth: 0, lastMonth: 0 });
  const bg2=useSelector(state=>state.themeReducer.backgroundColor2)
  const colorH=useSelector(state=>state.themeReducer.color1)
  const colorP=useSelector(state=>state.themeReducer.color2)

  const CustomLegend = ({ payload, totalSales }) => {
    // Ensure totalSales is defined and has the expected properties
    const thisMonthSales = totalSales?.thisMonth ?? 0;
    const lastMonthSales = totalSales?.lastMonth ?? 0;
  
    return (
      <Box display="flex" justifyContent="center" mt={4} bgColor={bg2}>
        {payload.map((entry, index) => (
          <Box
            key={`item-${index}`}
            display="flex"
            flexDirection="column"
            alignItems="center"
            mx={2}
          >
            <Box display="flex" alignItems="center" mb={1} >
              <Image src={entry.value === "ThisMonth" ? mnt1 : mnt} alt="Icon" />
              <Box>
                <Text fontSize="14px" fontWeight="400" textColor={colorP}>
                  {entry.value === "ThisMonth" ? "This Month" : "Last Month"}
                </Text>
                <Text fontSize="13" fontWeight="500" color={colorH}>
                  ${" "}
                  {entry.value === "ThisMonth"
                    ? thisMonthSales.toFixed(2)
                    : lastMonthSales.toFixed(2)}
                </Text>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box bg={bg2} p="5px" borderRadius="md" boxShadow="md">
          <Heading as="h5" size="sm">
            {label}
          </Heading>
          <Text>This Month: {payload[0].value}</Text>
          <Text>Last Month: {payload[1].value}</Text>
        </Box>
      );
    }
  
    return null;
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productResponse, subscriptionResponse] = await Promise.all([
          dispatch(getProducts()),
          dispatch(getSubscriptions()),
        ]);
        setProducts(productResponse);
        setSubscriptions(subscriptionResponse);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (products.length && subscriptions.length) {
      const calculateSales = () => {
        const now = new Date();
        const thisMonth = now.getMonth() + 1; 
        const thisYear = now.getFullYear();
        const lastMonth = thisMonth === 1 ? 12 : thisMonth - 1;
        const lastMonthYear = thisMonth === 1 ? thisYear - 1 : thisYear;

        const areas = Array(6)
          .fill()
          .map(() => ({ thisMonth: 0, lastMonth: 0 }));
        let totalThisMonth = 0;
        let totalLastMonth = 0;

        subscriptions.forEach((sub) => {
          const startDate = new Date(sub.started_at);
          const product = products.find((p) => p.id === sub.product);
          if (!product) {
            return;
          }

          const price = parseFloat(product.price);
          const day = startDate.getDate();
          const month = startDate.getMonth();
          const year = startDate.getFullYear();

          let areaIndex = Math.floor((day - 1) / 5);
          if (areaIndex > 5) areaIndex = 5;

          if (month + 1 === thisMonth && year === thisYear) {
            areas[areaIndex].thisMonth += price;
            totalThisMonth += price;
          } else if (month + 1 === lastMonth && year === lastMonthYear) {
            areas[areaIndex].lastMonth += price;
            totalLastMonth += price;
          } else {
            return
          }
        });

        setTotalSales({ thisMonth: totalThisMonth, lastMonth: totalLastMonth });

        return areas.map((area, index) => ({
          name: `${index * 5 + 1}-${(index + 1) * 5}`,
          ThisMonth: area.thisMonth,
          LastMonth: area.lastMonth,
        }));
      };

      setChartData(calculateSales());
    }
  }, [products, subscriptions]);

  return (
    <Box
      height="360px"
      overflow="hidden"
      borderRadius="md"
      boxShadow="md"
      bgColor={bg2}
    >
      <Heading as="h3" fontSize="20px" textColor="#05004E" pt="20px" pl="20px">
        Sales
      </Heading>
      <ResponsiveContainer width="100%" height="85%">
        <AreaChart
          data={chartData}
          margin={{
            top: 5,
            right: 10,
            left: 10,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="1%" stopColor="#07E098" stopOpacity={0.5} />
              <stop offset="50%" stopColor="#07E098" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="1%" stopColor="#0095FF" stopOpacity={0.5} />
              <stop offset="70%" stopColor="#0095FF" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Tooltip content={<CustomTooltip />} />
          <Legend content={<CustomLegend totalSales={totalSales} />} />
          <Area
            type="monotone"
            dataKey="ThisMonth"
            stroke="#07E098"
            fill="url(#colorUv)"
            dot={{ stroke: "#07E098", strokeWidth: 2, fill: "#07E098" }}
          />
          <Area
            type="monotone"
            dataKey="LastMonth"
            stroke="#0095FF"
            fill="url(#colorPv)"
            dot={{ stroke: "#0095FF", strokeWidth: 2, fill: "#0095FF" }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
}
