import {
  Flex,
  Image,
  Button,
  Text,
  Input,
  Hide,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createNewsletter } from "../actions/newsletter";
import logo from "../assets/logo.svg";
import facebook from "../assets/facebook.svg";
import twitter from "../assets/twitter.svg";
import linkedIn from "../assets/linkedin.svg";
import instagram from "../assets/instagram.svg"
import { Link } from "react-router-dom";


export default function Footer() {
  const [email, setEmail] = useState('');
  const toast = useToast();
  const dispatch = useDispatch();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubscribe = async () => {
    if (!email) {
      toast({
        title: "Email required.",
        description: "Please enter an email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!validateEmail(email)) {
      toast({
        title: "Invalid email.",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await dispatch(createNewsletter({ email }));
      toast({
        title: "Subscription successful.",
        description: "You have been subscribed to the newsletter.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setEmail('');
    } catch (error) {
      toast({
        title: "Subscription failed.",
        description: error.message || "An error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Hide below="840px">
        <Flex bg={"#2D2D2D"} justify={"center"} color={"white"}>
          <Flex direction={"column"} width={"80%"}>
            <Flex align={"center"} justify={"space-between"} padding={"32px 0"}>
              <Image src={logo} alt="XCAPITAL LOGO" />
              <Flex gap={"16px"} align={"center"}>
                <Text fontSize={"20px"}>Ready to get started?</Text>
                <Link to="/solutions"><Button>Get started</Button></Link>
              </Flex>
            </Flex>
            <Divider />
            <Flex justify={"space-between"} padding={"32px"}>
              <Flex direction={"column"} gap={"16px"}>
                <Text>Unlock exclusive insight by subscribing</Text>
                <Flex gap={"16px"}>
                  <Input
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    border={"none"}
                    borderBottom={"1px solid #ccc"}
                    type="email"
                  />
                  <Button onClick={handleSubscribe}>Subscribe</Button>
                </Flex>
              </Flex>
              <Flex direction={"column"} gap={"8px"}>
                <Link to="/solutions">
                    <Text>Solutions</Text>
                </Link>
                <Link to="/about-us">
                  <Text>About</Text>
                </Link>
                <Link to="/contact-us">
                  <Text>Contact Us</Text>
                </Link>
              </Flex>
              <Flex direction={"column"} gap={"8px"}>
                <Link to="/legal/privacy-policy">
                    <Text>Privacy Policy</Text>
                  </Link>
                  <Link to="/legal/term-of-service">
                    <Text>Term of Service</Text>
                  </Link>
                  <Link to="/legal/security-policy">
                    <Text>Security Policy</Text>
                  </Link>

                  <Link to="/legal/intellectual-property-policy">
                    <Text>Intellectual Property Policy</Text>
                  </Link>
                <Link to="/LEGAL/CMI-policies">
                  <Text>CMI policies</Text>
                </Link>

              </Flex>
            </Flex>
            <Flex justify={"center"} gap={"40px"} padding={"40px 0"}>
              <Link to="https://www.facebook.com/profile.php?id=61560422769620">
                <Image
                  src={facebook}
                  alt="facebook logo"
                  width={"32px"}
                  filter={"invert(1)"}
                />
              </Link>
              <Link to="https://x.com/xcapitalfin">
                <Image
                  src={twitter}
                  alt="twitter logo"
                  width={"32px"}
                  filter={"invert(1)"}
                />
              </Link>
              <Link to="https://www.linkedin.com/company/x-capitaltechnologies/">
                <Image
                  src={linkedIn}
                  alt="linkedIn logo"
                  width={"32px"}
                  filter={"invert(1)"}
                />
              </Link>
              <Link to="https://www.instagram.com/xcapitalfin/">
                <Image
                  src={instagram}
                  alt="Instagram logo"
                  width={"32px"}
                  filter={"invert(1)"}
                />
              </Link>
            </Flex>
            <Flex align={"center"} justify={"center"} padding={"40px 0"}>
              <Text>© 2024 X CAPITAL. All rights reserved.</Text>
            </Flex>
          </Flex>
        </Flex>
      </Hide>
      <Hide above="840px">
        <Flex bg={"#2D2D2D"} justify={"center"} color={"white"}>
          <Flex direction={"column"} width={"80%"}>
            <Flex
              direction={"column"}
              align={"center"}
              padding={"32px 0"}
              gap={"40px"}
            >
              <Flex gap={"16px"} align={"center"}>
                <Text>Ready to get started?</Text>
                <Button>
                    <Link to="/solutions">
                    Get started
                    </Link>
                </Button>
              </Flex>
            </Flex>
            <Divider />
            <Flex
              justify={"space-between"}
              padding={"0px"}
              direction={"column"}
            >
              <Flex justify={"space-between"} padding={"32px 15px"}>
                <Flex direction={"column"} gap={"12px"}>
                  <Link to="/legal/privacy-policy">
                    <Text>Privacy Policy</Text>
                  </Link>
                  <Link to="/legal/term-of-service">
                    <Text>Term of Service</Text>
                  </Link>

                  <Link to="/legal/security-policy">
                    <Text>Security Policy</Text>
                  </Link>

                  <Link to="/legal/intellectual-property-policy">
                    <Text>Intellectual Property Policy</Text>
                  </Link>
                </Flex>
               
                <Flex direction={"column"} gap={"8px"}>
                <Link to="/solutions">
                    <Text>Solutions</Text>
                  </Link>
                  <Link to="/about-us">
                    <Text>About</Text>
                  </Link>
                  <Link to="/contact-us">
                    <Text>Contact Us</Text>
                  </Link>
                </Flex>
              </Flex>
              <Flex direction={"column"} gap={"16px"} padding={"32px 0"}>
                <Text fontSize={"12px"}>
                  Unlock exclusive insight by subscribing
                </Text>
                <Flex gap={"16px"}>
                  <Input
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    border={"none"}
                    borderBottom={"1px solid #ccc"}
                  />
                  <Button onClick={handleSubscribe}>Subscribe</Button>
                </Flex>
              </Flex>
            </Flex>
            <Flex justify={"center"} gap={"40px"} padding={"40px 0"}>
              <Image
                src={facebook}
                alt="facebook logo"
                width={"32px"}
                filter={"invert(1)"}
              />
              <Image
                src={twitter}
                alt="twitter logo"
                width={"32px"}
                filter={"invert(1)"}
              />
              <Image
                src={linkedIn}
                alt="linkedIn logo"
                width={"32px"}
                filter={"invert(1)"}
              />
              <Image
                src={instagram}
                alt="Instagram logo"
                width={"32px"}
                filter={"invert(1)"}
              />
            </Flex>
            <Flex align={"center"} justify={"center"} padding={"40px 0"}>
              <Text>© 2024 X CAPITAL. All rights reserved.</Text>
            </Flex>
          </Flex>
        </Flex>
      </Hide>
    </>
  );
}
