import { Box, Input, Text, Image, Button } from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";
import { FaUpload, FaTimes } from "react-icons/fa";

export default function UploadImage(props) {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [file, setFile] = useState(null);
  const allowedExtensions = ['jpg', 'png', 'jpeg'];
  const inputFileRef = useRef(null);

  useEffect(() => {
    if (props.previewImage) {
      setPreviewUrl(props.previewImage);
    } else {
      setPreviewUrl(null);
      setFile(null);
    }
  }, [props.previewImage]);

  const handleImageChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) return;

    const extension = uploadedFile.name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(extension)) {
      return;
    }

    setFile(uploadedFile);
    props.onFileSelect(uploadedFile);
    setPreviewUrl(URL.createObjectURL(uploadedFile));
  };

  const handleRemoveImage = (e) => {
    e.stopPropagation();
    setPreviewUrl(null);
    setFile(null);
    props.onFileSelect(null);
    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
  };

  const handleClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="8px"
      border="1px dashed black"
      width={props.width}
      height={props.height}
      padding="8px"
      borderRadius="12px"
      textAlign="center"
      cursor="pointer"
      position="relative"
      onClick={!previewUrl ? handleClick : undefined}
    >
      <Input
        type="file"
        id={props.inputFileId}
        accept="image/*"
        onChange={handleImageChange}
        ref={inputFileRef}
        display="none"
      />
      {previewUrl ? (
        <>
          <Image 
            src={previewUrl} 
            alt="Uploaded preview" 
            objectFit="cover" 
            maxW="100%" 
            maxH="100%" 
            width="100%" 
            height="100%" 
            borderRadius="inherit"
          />
          <Button
            position="absolute"
            top="5px"
            right="5px"
            size="sm"
            onClick={handleRemoveImage}
          >
            <FaTimes />
          </Button>
        </>
      ) : (
        <>
          <FaUpload size={"24px"} color="#001A72" />
          <Text fontSize={{base:"16px",md:"18px",lg:"20px"}}>{props.label || "Upload an image"}</Text>
        </>
      )}
    </Box>
  );
}
