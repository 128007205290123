import React from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Flex,
  keyframes,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import map from "../assets/Map.svg";

const zoom = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const NotFound = () => {
  return (
    <Box
      minH="100vh"
      position="relative"
      overflow="hidden"
      display="flex"
      alignItems="center"
      justifyContent="center"
      _before={{
        content: `""`,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundImage: `url(${map})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        zIndex: -1,
        animation: `${zoom} 20s infinite`,
      }}
    >
      <Flex
        direction="column"
        align="center"
        justify="center"
        padding="20px"
        gap="20px"
      >
        <Heading
          fontFamily="'Share Tech Mono', monospace"
          fontWeight="900"
          textTransform="uppercase"
          letterSpacing="0.7em"
          fontSize="2rem"
          padding="0 0 0 1.4em"
          textAlign="center"
        >
          PAGE NOT FOUND
        </Heading>
        <Text
          fontFamily="'Space Mono', monospace"
          lineHeight="42px"
          fontSize="15px"
          letterSpacing="0.15rem"
          padding="0 20px"
          maxWidth="600px"
          margin="auto"
          textAlign="center"
        >
          The page you are looking for was moved, removed, renamed or might never existed.
        </Text>
        <Button bg="black" color="white" as={Link} to="/">
          Go back to Home
        </Button>
      </Flex>
    </Box>
  );
};

export default NotFound;
