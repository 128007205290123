import axios from 'axios';


const postArticle = async (formData) => {
  const accessToken = localStorage.getItem('access');

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/article/save/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${accessToken}`
      }
    });
    
    if (response.status === 204) {
      window.location.reload();
    }
    
    return response.data;
  } catch (error) {
    if (error.response) {
      return('Error details:', error.response.data);
    } else if (error.request) {
      return('No response received from server:', error.request);
    } else {
      return('Error setting up the request:', error.message);
    }
    
    throw error;
  }
};

export default postArticle;
