import axios from 'axios';
import {
  GET_CAREERS_SUCCESS,
  GET_CAREERS_FAIL,
  GET_CAREER_BY_ID_SUCCESS,
  GET_CAREER_BY_ID_FAIL,
  CREATE_CAREER_SUCCESS,
  CREATE_CAREER_FAIL,
  UPDATE_CAREER_SUCCESS,
  UPDATE_CAREER_FAIL,
  DELETE_CAREER_SUCCESS,
  DELETE_CAREER_FAIL,
} from './types';
import { getAuthConfig } from './config';

export const getAllCareers = () => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/careers`, config);
    dispatch({
      type: GET_CAREERS_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_CAREERS_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const getCareerById = (id) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/career/${id}`, config);
    dispatch({
      type: GET_CAREER_BY_ID_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_CAREER_BY_ID_FAIL,
      payload: err.message,
    });
    throw err;
  }
};

export const createCareer = (careerData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/career/save`, careerData, config);
    dispatch({
      type: CREATE_CAREER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_CAREER_FAIL,
      payload: err.response.data.message,
    });
    throw err.response.data.message;
  }
};

export const updateCareer = (id, careerData) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/career/${id}/update`, careerData, config);
    dispatch({
      type: UPDATE_CAREER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_CAREER_FAIL,
      payload: err.message,
    });
  }
};

export const deleteCareer = (id) => async (dispatch) => {
  try {
    const config = getAuthConfig();
    await axios.delete(`${process.env.REACT_APP_API_URL}/career/${id}/delete`, config);
    dispatch({
      type: DELETE_CAREER_SUCCESS,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: DELETE_CAREER_FAIL,
      payload: err.message,
    });
  }
};