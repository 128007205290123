import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { checkAuthenticated, load_user, refreshToken, isTokenExpired } from '../actions/auth';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, Flex, Image } from '@chakra-ui/react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import animatedLogo from '../assets/animated_logo_continuous.svg';
import CookieConsent from '../components/policies/CookieConsent';


const Layout = ({ checkAuthenticated, load_user }) => {
    const [loading, setLoading] = useState(true);
    const [transition, setTransition] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const access = useSelector(state => state.auth.access);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    useEffect(() => {
        const initialize = async () => {
            if (access && isTokenExpired(access)) {
                await dispatch(refreshToken());
            }
            await checkAuthenticated();
            await load_user();
            setLoading(false);
            setTimeout(() => setTransition(false), 3000);
        };
        initialize();
    }, [dispatch, checkAuthenticated, load_user, access]);

    useEffect(() => {
        const refreshInterval = setInterval(() => {
            if (access && isTokenExpired(access)) {
                dispatch(refreshToken());
            }
        });

        return () => clearInterval(refreshInterval);
    }, [dispatch, access]);

    if (loading || transition) {
        return (
            <Flex height="100vh" alignItems="center" justifyContent="center">
                <Image src={animatedLogo} alt="Loading..." />
            </Flex>
        );
    }

    return (
        <div>
            <NavBar />
            <Box as="main" minH="80vh">
                <Outlet />
            </Box>
            <Footer />
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { checkAuthenticated, load_user })(Layout);