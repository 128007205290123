const fetchArticleIdTeaser = async (id) => {

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/article-teasers-id/${id}`, {
        headers: {
          "Content-Type": "application/json",
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };
  
  export default fetchArticleIdTeaser;
  