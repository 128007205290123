import React from 'react'
import { Flex, Box, Text,List, ListItem, UnorderedList, Heading } from '@chakra-ui/react'


function PrivacyPolicy() {
  return (
    <>
      <Flex direction={"column"} p={10} mb="2em">
        <Box mb="2em">
            <Heading>Privacy Policy</Heading>
            <Text mb="1em"><b>Effective Date:</b> July 4, 2024</Text>
            <Text textAlign="left">X CAPITAL ("we," "us," "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website xcapitalterminal.com and use our services. Please read this policy carefully.</Text>
        </Box>

        <Flex direction={"column"} gap={5}>
            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>1. Information We Collect</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem><b>Personal Data: </b>Name, email address, phone number, mailing address, and other information you voluntarily provide when you register or contact us.</ListItem>
                    <ListItem><b>Usage Data:</b> Information about your interactions with our website, such as IP address, browser type, operating system, and pages visited.</ListItem>
                    <ListItem><b>Cookies and Tracking Technologies:</b> We use cookies to enhance your experience. You can manage your cookie preferences through your browser settings.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>2. How We Use Your Information</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>To provide and maintain our services.</ListItem>
                    <ListItem>To notify you about changes to our services.</ListItem>
                    <ListItem>To allow you to participate in interactive features of our website.</ListItem>
                    <ListItem>To provide customer support.</ListItem>
                    <ListItem>To gather analysis or valuable information to improve our website.</ListItem>
                    <ListItem>To monitor the usage of our website.</ListItem>
                    <ListItem>To detect, prevent, and address technical issues.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>3. Sharing Your Information</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>With service providers who assist us in operating our website.</ListItem>
                    <ListItem>To comply with legal obligations.</ListItem>
                    <ListItem>To protect and defend our rights and property.</ListItem>
                    <ListItem>With your consent.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>4. Data Security</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem listStyleType="none">We use appropriate security measures to protect your data. However, no method of transmission over the internet or electronic storage is 100% secure.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>5. Your Data Protection Rights</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem>Access, update, or delete your personal information.</ListItem>
                    <ListItem>Withdraw consent at any time.</ListItem>
                    <ListItem>Object to the processing of your data.</ListItem>
                    <ListItem>Request data portability.</ListItem>
                </UnorderedList>
            </List>

            <Heading size={{base:"md", lg:"lg", xl:"xl", md:"md", sm:"sm"}}>6. Contact Us</Heading>
            <List>
                <UnorderedList ml="3em">
                    <ListItem listStyleType="none">If you have any questions about this Privacy Policy, please contact us at: contact@xcapitalterminal.com or call us at +212 632331441 or +212 632321083.</ListItem>
                </UnorderedList>
            </List>
        </Flex>
      </Flex>
    </>
  )
}

export default PrivacyPolicy
