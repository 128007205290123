import React, { useEffect } from 'react';
import { ChakraProvider, Box, Heading, Text, Link, extendTheme, Center } from '@chakra-ui/react';
import { Global } from '@emotion/react';

const theme = extendTheme({
  fonts: {
    heading: `'Bungee', cursive`,
    body: `'Bungee', cursive`,
  },
});

const ForbiddenPage = () => {
  useEffect(() => {
    const root = document.documentElement;
    const eyef = document.getElementById('eyef');

    const handleMouseMove = (evt) => {
      let x = evt.clientX / window.innerWidth;
      let y = evt.clientY / window.innerHeight;

      root.style.setProperty("--mouse-x", x);
      root.style.setProperty("--mouse-y", y);

      let cx = 115 + 30 * x;
      let cy = 50 + 30 * y;
      eyef.setAttribute("cx", cx);
      eyef.setAttribute("cy", cy);
    };

    const handleTouchMove = (touchHandler) => {
      let x = touchHandler.touches[0].clientX / window.innerWidth;
      let y = touchHandler.touches[0].clientY / window.innerHeight;

      root.style.setProperty("--mouse-x", x);
      root.style.setProperty("--mouse-y", y);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchmove", handleTouchMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Global
        styles={`
          @import url('https://fonts.googleapis.com/css?family=Bungee');
          body {
            background: #1b1b1b;
            color: white;
            font-family: 'Bungee', cursive;
            margin-top: 50px;
            text-align: center;
          }
          .alarm {
            animation: alarmOn 0.5s infinite;
          }
          @keyframes alarmOn {
            to { fill: darkred; }
          }
        `}
      />
      <Box textAlign="center" py={10} px={6}>
        <Center>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 118.9" role="img" width="50%">
            <title>403 Error</title>
            <defs>
              <clipPath id="white-clip">
                <circle id="white-eye" fill="#cacaca" cx="130" cy="65" r="20" />
              </clipPath>
              <text id="text-s" x="50%" y="106" fontSize="120px" fill="#2b2b2b" textAnchor="middle">403</text>
            </defs>
            <path className="alarm" fill="#e62326" d="M120.9 19.6V9.1c0-5 4.1-9.1 9.1-9.1h0c5 0 9.1 4.1 9.1 9.1v10.6" />
            <use xlinkHref="#text-s" x="-0.5px" y="-1px" fill="black"></use>
            <use xlinkHref="#text-s"></use>
            <g id="robot">
              <g id="eye-wrap">
                <use xlinkHref="#white-eye"></use>
                <circle id="eyef" fill="#000" stroke="#2aa7cc" strokeWidth="2" strokeMiterlimit="10" cx="130" cy="65" r="11" clipPath="url(#white-clip)" />
                <ellipse id="white-eye" fill="#2b2b2b" cx="130" cy="40" rx="18" ry="12" />
              </g>
              <circle fill="#444" cx="105" cy="32" r="2.5" id="tornillo" />
              <use xlinkHref="#tornillo" x="50"></use>
              <use xlinkHref="#tornillo" x="50" y="60"></use>
              <use xlinkHref="#tornillo" y="60"></use>
            </g>
          </svg>
        </Center>
        <Heading as="h1" mt={4}>You are not allowed to enter here</Heading>
        <Text fontSize="lg" mt={2}>
          Go <Link href="/" isExternal color="#2aa7cc">Home!</Link>
        </Text>
      </Box>
    </ChakraProvider>
  );
};

export default ForbiddenPage;
